import React, { useContext, useEffect, useState } from "react";
import { ProductionCurrent } from "./components/ProductionCurrent";
import { Summary } from "./components/Summary";
import { Weather } from "./components/Weather";
import { SavingsLobby } from "./components/SavingsLobby";
import { ProductionDailyLobby } from "./components/ProductionDailyLobby";
import { ProductionMonthlyLobby } from "./components/ProductionMonthlyLobby";
import { ProductionYearlyLobby } from "./components/ProductionYearlyLobby";
import { ProductionLifetimeLobby } from "./components/ProductionLifetimeLobby";
import { HubContext } from "../MainScreen/MainScreen";
import { PleaseWait } from "../../components/PleaseWait";
import { RawSiteType } from "../../Types";

/**
 * Displays a dashboard in the lobby.
 */
export const LobbyDashboard = () => {
	const [ lobbyDetails, setLobbyDetails ] = useState(null);
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const uaNodeId = urlParams.get("uaNodeId");

	const { sendTwinHubRequest, } = useContext(HubContext);

	useEffect(() => {
		const interval = setInterval(() => {
			loadLobbyInfo();
		}, 300000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		loadLobbyInfo();
	}, [ uaNodeId ]);

	/**
	 * Loads the information for the dashboard.
	 */
	function loadLobbyInfo () {
		if (uaNodeId) {
			const isoDate = (new Date()).toISOString();

			const enterpriseRequestPackage = {
				onSuccess: handleSiteLoaded,
				parameters: {
					twinNodeTemplateId: 3,
					uaNodeId,
				},
				requestId: "Site" + isoDate,
				requestType: "TwinNodeTemplateGet",
			};
			sendTwinHubRequest(enterpriseRequestPackage);
		}
	}

	/**
	 * Called when the site details are loaded.
	 */
	function handleSiteLoaded (response: RawSiteType) {
		if (!response) {
			return;
		}

		const { properties, uaNodeId, } = response;
		setLobbyDetails({
			...properties,
			...{
				uaNodeId: uaNodeId || "Unknown",
			},
		});
	}

	if (!uaNodeId) {
		return <div>Please add a Site OPC UA Node ID to the URL.</div>;
	}

	if (!lobbyDetails) {
		return (
			<PleaseWait />
		);
	}

	const {
		plantPicture,
		plantLogo,
		maxACPower,
		nominalDCPower,
		weatherSymbol,
		installationDate,
		acPowerActive,
		// plantToGridPower,
		// plantToSitePower,
		treeSavingsToday,
		cO2SavingsToday,
		treeSavingsThisMonth,
		cO2SavingsThisMonth,
		treeSavingsThisYear,
		cO2SavingsThisYear,
		treeSavingsLifetime,
		cO2SavingsLifetime,
		dailyProductionSolarIrradiation,
		dailyProductionTotalPower,
		dailyProductionActivePower,
		monthlyProductionActual,
		monthlyProductionBudget,
		monthlyProductionActivePower,
		yearlyProductionActual,
		yearlyProductionBudget,
		yearlyProductionActivePower,
		lifetimeProductionActual,
		lifetimeProductionBudget,
		lifetimeProductionActivePower,
		ambientTemperature,
		horizontalIrradiation,
		rainAmount,
		siteName,
		timeZone,
		windSpeed,
	} = lobbyDetails;

	return (
		<div className="has-sticky-footer">

			<div className="container is-fluid px-2-mobile">
				<section className="pt-5">
					<div className="columns">
						<div className="column is-one-fourth">
							<Summary
								plantName={siteName}
								plantPicture={plantPicture}
								plantLogo={plantLogo}
								maxACPower={maxACPower}
								nominalDCPower={nominalDCPower}
								timezone={timeZone}
							/>
						</div>
						<div className="column is-one-fourth">
							<Weather
								temperature={ambientTemperature}
								windSpeed={windSpeed}
								irradiance={horizontalIrradiation}
								weatherSymbol={weatherSymbol}
								humidity={rainAmount}
							/>
						</div>
						<div className="column is-one-fourth">
							<ProductionCurrent
								acPowerActive={acPowerActive}
							/>
						</div>
						<div className="column is-one-fourth">
							<SavingsLobby
								treeSavingsToday={treeSavingsToday}
								co2SavingsToday={cO2SavingsToday}
								treeSavingsThisMonth={treeSavingsThisMonth}
								co2SavingsThisMonth={cO2SavingsThisMonth}
								treeSavingsThisYear={treeSavingsThisYear}
								co2SavingsThisYear={cO2SavingsThisYear}
								treeSavingsLifetime={treeSavingsLifetime}
								co2SavingsLifetime={cO2SavingsLifetime}
								installationDate={installationDate}
							/>
						</div>
					</div>
				</section>
				<section className="pt-5">
					<div className="columns">
						<div className="column is-6">
							<ProductionDailyLobby
								dailyProductionSolarIrradiation={dailyProductionSolarIrradiation}
								dailyProductionTotalPower={dailyProductionTotalPower}
								dailyProductionActivePower={dailyProductionActivePower}
								maxACPower={maxACPower}
							/>
						</div>
						<div className="column is-6">
							<ProductionMonthlyLobby
								monthlyProductionActual={monthlyProductionActual}
								monthlyProductionBudget={monthlyProductionBudget}
								monthlyProductionActivePower={monthlyProductionActivePower}
							/>
						</div>
					</div>
					<div className="columns">
						<div className="column is-6">
							<ProductionYearlyLobby
								yearlyProductionActual={yearlyProductionActual}
								yearlyProductionBudget={yearlyProductionBudget}
								yearlyProductionActivePower={yearlyProductionActivePower}
							/>
						</div>
						<div className="column is-6">
							<ProductionLifetimeLobby
								lifetimeProductionActual={lifetimeProductionActual}
								lifetimeProductionBudget={lifetimeProductionBudget}
								lifetimeProductionActivePower={lifetimeProductionActivePower}
							/>
						</div>
					</div>
				</section>
			</div>

			<footer className="footer is-transparent pb-3">
				<div className="is-flex justify-center is-size-7">
					<span>Powered by</span>
					<figure className="image is-16x16 mx-2">
						<img src="https://perceptiongui.blob.core.windows.net/zonco/ZC_logo_only.png" alt="Logo" />
					</figure>
					<span>Zoncoalitie</span>
				</div>
			</footer>
		</div>
	);
};
