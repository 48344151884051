import React, { useContext, useEffect, useState } from "react";
import { HubContext } from "../screens/MainScreen/MainScreen";
import { AlarmType } from "../Types";

type Props = {
	uaNodeIds: Array<string>,
};

/**
 * Displays a list of alarms broken down by severity.
 */
export const AlarmList = (props: Props) => {
	const { uaNodeIds, } = props;

	const [ activeTab, setActiveTab ] = useState("high");
	const [ alarms, setAlarms ] = useState<Array<AlarmType>>([]);
	const { sendTwinHubRequest, } = useContext(HubContext);

	const endTime = new Date();
	const startTime = new Date(endTime.getFullYear(), endTime.getMonth(), endTime.getDate() - 7, endTime.getHours(), endTime.getMinutes(), endTime.getSeconds());

	useEffect(() => {
		if (uaNodeIds && uaNodeIds.length > 0) {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: (response: {
					alarms?: Array<AlarmType>,
				}) => {
					if (!response?.alarms) {
						return;
					}

					setAlarms(response?.alarms);
				},
				parameters: {
					endTime: endTime.toISOString(),
					numValues: 10000,
					startTime: startTime.toISOString(),
					twinNodeTemplateId: 3,
					uaNodeIds,
				},
				requestId: "TwinNodeTemplate" + isoDate,
				requestType: "TwinNodeTemplateGetAlarms",
			};
			sendTwinHubRequest(requestPackage);
		}
	}, [ uaNodeIds ]);

	let highSeverityAlarms: Array<AlarmType> = [];
	let mediumSeverityAlarms: Array<AlarmType> = [];
	let lowSeverityAlarms: Array<AlarmType> = [];
	if (alarms && alarms.length > 0) {
		highSeverityAlarms = alarms.filter((item) => {
			return item.severity === "high";
		});
		mediumSeverityAlarms = alarms.filter((item) => {
			return item.severity === "medium";
		});
		lowSeverityAlarms = alarms.filter((item) => {
			return item.severity === "low";
		});
	}
	if (!alarms || alarms.length <= 0) {
		return <h4 className="flex-column is-full-height justify-center has-text-centered has-text-opacity--60 has-background-opacity--5 py-4">No Alarms</h4>;
	}

	/**
     * Displays a row for each alarm.
     */
	function eachAlarm (item: AlarmType, index: number) {
		return (
			<div className="mb-3">
				<h3 className="is-collapsible is-clickable">{item?.text}</h3>
			</div>
		);
	}

	return (
		<>
			<div className="tabs mb-0">
				<ul className="has-text-weight-semibold is-uppercase">
					<li
						className={
							activeTab === "high"
								? "tab is-active"
								: "tab"
						}
						onClick={() => setActiveTab("high")}
					>
						<span className="is-block is-clickable py-3 px-4">
							<i
								className="fas fa-xs fa-times-circle has-text-danger"
								aria-hidden="true"
							/>
							<span className="ml-2 is-size-7">High ({highSeverityAlarms.length})</span>
						</span>
					</li>
					<li
						className={
							activeTab === "medium"
								? "tab is-active"
								: "tab"
						}
						onClick={() => setActiveTab("medium")}
					>
						<span className="is-block is-clickable py-3 px-4">
							<i
								className="fas fa-xs fa-exclamation-triangle has-text-warning"
								aria-hidden="true"
							/>
							<span className="ml-2 is-size-7">Medium ({mediumSeverityAlarms.length})</span>
						</span>
					</li>
					<li
						className={
							activeTab === "low"
								? "tab is-active"
								: "tab"
						}
						onClick={() => setActiveTab("low")}
					>
						<span className="is-block is-clickable py-3 px-4">
							<i
								className="fas fa-xs fa-exclamation-triangle has-text-info"
								aria-hidden="true"
							/>
							<span className="ml-2 is-size-7">Low ({lowSeverityAlarms.length})</span>
						</span>
					</li>
				</ul>
			</div>

			<div className="px-4 py-4">
				<div
					className={
						activeTab === "high" ? "is-block" : "is-hidden"
					}
				>
					{highSeverityAlarms &&
                          highSeverityAlarms.length > 0 &&
                          highSeverityAlarms.map(eachAlarm)}
				</div>
				<div
					className={
						activeTab === "medium" ? "is-block" : "is-hidden"
					}
				>
					{mediumSeverityAlarms &&
                          mediumSeverityAlarms.length > 0 &&
                          mediumSeverityAlarms.map(eachAlarm)}
				</div>
				<div
					className={
						activeTab === "low" ? "is-block" : "is-hidden"
					}
				>
					{lowSeverityAlarms &&
                          lowSeverityAlarms.length > 0 &&
                          lowSeverityAlarms.map(eachAlarm)}
				</div>
			</div>
		</>
	);
};
