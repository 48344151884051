import React from "react";
import { useHistory } from "react-router-dom";
import { TileType } from "../../../Types";

/**
 * Displays a status square for an inverter.
 */
export function StringCombinerSquare (props: TileType) {
	const history = useHistory();
	const { id, name, status, } = props;

	const className = status === "Ok" ? "button is-danger is-size-small has-text-weight-semibold px-1" : "button is-success is-size-small has-text-weight-semibold px-1";

	return (
		<>
			<span
				className={className}
				onClick={() => { history.push("/solarStringSet?uaNodeId=" + id); }}
			>{name}
			</span>
		</>
	);
}
