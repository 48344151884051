import { makeTwoDigit } from "./UtilityFunctions";

/**
 * Works out the period parameters for graphs based on the length of time given.
 */
export function getGraphGranularity (startTime: Date, endTime: Date) {
	const diff = endTime.getTime() - startTime.getTime();
	const minuteDiff = diff / (1000 * 60);
	const hourDiff = minuteDiff / 60;
	const dayDiff = hourDiff / 24;

	if (hourDiff <= 1) {
		return "1 minutes";
	} else if (dayDiff <= 2) {
		return "1 hours";
	} else if (dayDiff <= 32) {
		return "1 days";
	} else {
		return "month";
	}
}

/**
 * Gets the labels for a graph.
 */
export function getGraphLabels (dateLabels: Array<Date>, startTime: Date, endTime: Date): Array<string> {
	const labels = [];

	const diff = endTime.getTime() - startTime.getTime();
	const minuteDiff = diff / (1000 * 60);
	const hourDiff = minuteDiff / 60;
	const dayDiff = hourDiff / 24;

	if (hourDiff <= 1) {
		for (let i = 0; i < dateLabels.length; i++) {
			const loopTime = dateLabels[i];
			labels.push(makeTwoDigit(loopTime.getHours()) + ":" + makeTwoDigit(loopTime.getMinutes()));
		}
	} else if (dayDiff <= 2) {
		for (let i = 0; i < dateLabels.length; i++) {
			const loopTime = dateLabels[i];
			labels.push(makeTwoDigit(loopTime.getHours()) + ":" + makeTwoDigit(loopTime.getMinutes()));
		}
	} else if (dayDiff <= 32) {
		for (let i = 0; i < dateLabels.length; i++) {
			const loopTime = dateLabels[i];
			labels.push(makeTwoDigit(loopTime.getDate()) + "/" + makeTwoDigit(loopTime.getMonth() + 1));
		}
	} else {
		for (let i = 0; i < dateLabels.length; i++) {
			const loopTime = dateLabels[i];
			labels.push(makeTwoDigit(loopTime.getMonth() + 1) + "/" + loopTime.getFullYear());
		}
	}

	return labels;
}
