import React, { useEffect, useRef, useState } from "react";
import ROUTES, { RenderRoutes } from "../../Routes/Routes";
import { ToastMessages } from "./components/ToastMessages";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { PleaseWait } from "../../components/PleaseWait";
import { connectToApisHub } from "./functions/connectToApisHub";
import { connectToTwinHub } from "./functions/connectToTwinHub";
import { connectToHub } from "./functions/connectToHub";
import { sendHubRequestWithCallbacks } from "./functions/sendHubRequestWithCallbacks";
import { sendApisHubRequestWithCallbacks } from "./functions/sendApisHubRequestWithCallbacks";
import { sendTwinHubRequestWithCallbacks } from "./functions/sendTwinHubRequestWithCallbacks";
import { handleHubResponseWithCallbacks } from "./functions/handleHubResponseWithCallbacks";
import { getEntityStructure, getEntitySqlInformation, getEntityOpcInformation } from "./functions/getEntityInformation";
import { HubContextType, SignalRRequestPackageType, SignalRResponsePackageType, StyleContextType } from "../../Types";
import { LoginScreen } from "../LoginScreen/LoginScreen";
import { loginRequest } from "../../authConfig";

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
		REACT_APP_ENTERPRISE_CLASS_ID: number;
		REACT_APP_REGION_CLASS_ID: number;
		REACT_APP_SITE_CLASS_ID: number;
	}
}

export const HubContext = React.createContext<HubContextType>({
	enterprise: {},
	regions: [],
	sendApisHubRequest: () => {},
	sendHubRequest: () => {},
	sendTwinHubRequest: () => {},
	sites: [],
});
export const StyleContext = React.createContext<StyleContextType>({
	mode: "dark",
	setMessagesVisible: () => {},
	setMode: () => {},
	style: "prediktor",
});

/** Displays a side bar with available routes and content area to display them */
export function MainScreen () {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const styleParam = urlParams.get("style") || "default";
	const modeParam = urlParams.get("mode") || "dark";

	const [ style, setStyle ] = useState(styleParam);
	const [ mode, setMode ] = useState(modeParam);
	const [ messagesVisible, setMessagesVisible ] = useState(false);

	const { instance, accounts, inProgress, } = useMsal();
	const [ accessToken, setAccessToken ] = useState<string | null>(null);
	const [ refreshTimer ] = useState(new Date());

	useEffect(() => {
		if (inProgress === "none" && accounts.length > 0) {
			/** Retrieve an access token */
			const fetchAccessToken = async () => {
				instance.acquireTokenSilent({
					account: accounts[0],
					scopes: loginRequest.scopes,
				}).then((response: any) => {
					if (response.accessToken) {
						setAccessToken(response.accessToken);
					}
					return null;
				});
			};

			fetchAccessToken();
		}
	}, [ inProgress, accounts, instance ]);

	useEffect(() => {
		setStyle(styleParam);
	}, [ styleParam ]);

	useEffect(() => {
		setMode(modeParam);
	}, [ modeParam ]);

	useEffect(() => {
		const interval = setInterval(() => {
			const now = new Date();
			const timeout = new Date(now.getTime() - (24 * 60 * 60 * 1000));

			if (refreshTimer < timeout) {
				location.reload();
			}
		}, 60000);
		return () => clearInterval(interval);
	}, []);

	const [ hubConnection, setHubConnection ] = useState(null);
	const [ twinHubConnection, setTwinHubConnection ] = useState(null);
	const [ apisHubConnection, setApisHubConnection ] = useState(null);
	const [ callbacks, setCallbacks ] = useState<any>({});
	const entitiesRef = useRef<any>({});
	const structureRef = useRef<any>({});
	const enterpriseCountRef = useRef<number>(0);
	const regionsCountRef = useRef<number>(0);
	const sitesCountRef = useRef<number>(0);
	const [ , setEntityTimestamp ] = useState<number>();
	const dispatch = useDispatch();

	useEffect(() => {
		if (accessToken || !window.REACT_APP_AAD_AUTHORITY) {
			connectToHub(handleHubResponse, setHubConnection, messagesVisible, accessToken);
			connectToTwinHub(handleHubResponse, setTwinHubConnection, messagesVisible, accessToken);
			connectToApisHub(handleHubResponse, setApisHubConnection, messagesVisible, accessToken);
		}
	}, [ accessToken ]);

	useEffect(() => {
		if ((accounts.length > 0 || !window.REACT_APP_AAD_AUTHORITY) && hubConnection && twinHubConnection && apisHubConnection) {
			loadEnterprise();
			loadRegions();
			loadSites();

			const sqlInterval = setInterval(() => {
				if (accounts.length > 0 || !window.REACT_APP_AAD_AUTHORITY) {
					getEntitySqlInformation(
						entitiesRef,
						structureRef,
						enterpriseCountRef,
						setEntityTimestamp,
						sendHubRequest,
						"enterprise"
					);
					getEntitySqlInformation(
						entitiesRef,
						structureRef,
						regionsCountRef,
						setEntityTimestamp,
						sendHubRequest,
						"regions"
					);
					getEntitySqlInformation(
						entitiesRef,
						structureRef,
						sitesCountRef,
						setEntityTimestamp,
						sendHubRequest,
						"sites"
					);
				}
			}, 60000);

			const opcInterval = setInterval(() => {
				if (accounts.length > 0 || !window.REACT_APP_AAD_AUTHORITY) {
					getEntityOpcInformation(
						entitiesRef,
						structureRef,
						enterpriseCountRef,
						setEntityTimestamp,
						sendApisHubRequest,
						sendHubRequest,
						"enterprise",
						false
					);
					getEntityOpcInformation(
						entitiesRef,
						structureRef,
						regionsCountRef,
						setEntityTimestamp,
						sendApisHubRequest,
						sendHubRequest,
						"regions",
						false
					);
					getEntityOpcInformation(
						entitiesRef,
						structureRef,
						sitesCountRef,
						setEntityTimestamp,
						sendApisHubRequest,
						sendHubRequest,
						"sites",
						false
					);
				}
			}, 3600000);

			return () => {
				clearInterval(sqlInterval);
				clearInterval(opcInterval);
			};
		}
	}, [ accounts, hubConnection, twinHubConnection, apisHubConnection ]);

	/**
	 * Called when the Backend Hub sends a message.
	 */
	function handleHubResponse (signalRResponsePackage: SignalRResponsePackageType) {
		handleHubResponseWithCallbacks(signalRResponsePackage, dispatch, callbacks, messagesVisible, handleSubscribeResponse, setEntityTimestamp);
	}

	/**
	 * Called when a subscribed value returns a response.
	 */
	function handleSubscribeResponse (message: any) {
		const { node: messageNode, value, } = message;
		if (!messageNode) {
			return;
		}

		let found = null;
		for (const prop in structureRef.current) {
			const entities = structureRef.current[prop];

			for (let i = 0; i < entities.length; i++) {
				const entity = entities[i];
				const { opcUaRequests, } = entity;

				if (opcUaRequests && opcUaRequests.length > 0) {
					for (let j = 0; j < opcUaRequests.length; j++) {
						const request = opcUaRequests[j];
						const { name, node: requestNode, } = request;

						if (messageNode.namespaceIndex === requestNode.namespaceIndex && messageNode.identifier === requestNode.identifier) {
							entitiesRef.current[prop][i].properties[name] = value;

							found = prop;
							break;
						}
					}
				}

				if (found) {
					break;
				}
			}

			if (found) {
				break;
			}
		}

		if (found) {
			switch (found) {
				case "enterprise":
					setEntityTimestamp((new Date()).getSeconds());
					break;
				case "regions":
					setEntityTimestamp((new Date()).getSeconds());
					break;
				case "sites":
					setEntityTimestamp((new Date()).getSeconds());
					break;
				default:
					break;
			}
		}
	}

	/**
	 * Sends a request to the backend API.
	 */
	function sendHubRequest (signalRRequestPackage: SignalRRequestPackageType) {
		const { requestId, onSuccess, } = signalRRequestPackage;
		if (requestId && onSuccess) {
			const newCallbacks = callbacks;
			newCallbacks[requestId] = {
				onSuccess,
			};

			setCallbacks(newCallbacks);
		}

		sendHubRequestWithCallbacks(signalRRequestPackage, hubConnection, messagesVisible);
	}

	/**
	 * Sends a request to the backend API.
	 */
	function sendTwinHubRequest (signalRRequestPackage: SignalRRequestPackageType) {
		const { requestId, onSuccess, } = signalRRequestPackage;
		if (requestId && onSuccess) {
			const newCallbacks = callbacks;
			newCallbacks[requestId] = {
				onSuccess,
			};

			setCallbacks(newCallbacks);
		}

		sendTwinHubRequestWithCallbacks(signalRRequestPackage, twinHubConnection, messagesVisible);
	}

	/**
	 * Sends a request to the backend API.
	 */
	function sendApisHubRequest (signalRRequestPackage: SignalRRequestPackageType) {
		const { requestId, onSuccess, } = signalRRequestPackage;
		if (requestId && onSuccess) {
			const newCallbacks = callbacks;
			newCallbacks[requestId] = {
				onSuccess,
			};

			setCallbacks(newCallbacks);
		}

		sendApisHubRequestWithCallbacks(signalRRequestPackage, apisHubConnection, messagesVisible);
	}

	/**
	 * Gets Enterprise information from the backend.
	 */
	function loadEnterprise () {
		getEntityStructure(
			entitiesRef,
			structureRef,
			enterpriseCountRef,
			setEntityTimestamp,
			sendTwinHubRequest,
			sendApisHubRequest,
			sendHubRequest,
			window.REACT_APP_ENTERPRISE_CLASS_ID,
			"enterprise"
		);
	}

	/**
	 * Gets Site information from the backend.
	 */
	function loadSites () {
		getEntityStructure(
			entitiesRef,
			structureRef,
			sitesCountRef,
			setEntityTimestamp,
			sendTwinHubRequest,
			sendApisHubRequest,
			sendHubRequest,
			window.REACT_APP_SITE_CLASS_ID,
			"sites"
		);
	}

	/**
	 * Gets Region information from the backend.
	 */
	function loadRegions () {
		getEntityStructure(
			entitiesRef,
			structureRef,
			regionsCountRef,
			setEntityTimestamp,
			sendTwinHubRequest,
			sendApisHubRequest,
			sendHubRequest,
			window.REACT_APP_REGION_CLASS_ID,
			"regions"
		);
	}

	if (!hubConnection && (accounts.length > 0 || !window.REACT_APP_AAD_AUTHORITY)) {
		return (
			<PleaseWait />
		);
	}

	const hubContextPackage = {
		apisHubConnection,
		enterprise: entitiesRef?.current?.enterprise && entitiesRef?.current?.enterprise.length > 0 ? entitiesRef?.current?.enterprise[0] : {},
		hubConnection,
		regions: entitiesRef?.current?.regions || [],
		sendApisHubRequest,
		sendHubRequest,
		sendTwinHubRequest,
		sites: entitiesRef?.current?.sites || [],
		twinHubConnection,
	};

	const styleContextPackage = {
		messagesVisible,
		mode,
		setMessagesVisible,
		setMode,
		setStyle,
		style,
	};

	if (!window.REACT_APP_AAD_AUTHORITY) {
		return (
			<HubContext.Provider
				value={hubContextPackage}
			>
				<StyleContext.Provider value={styleContextPackage}>
					<RenderRoutes
						mode={mode}
						routes={ROUTES}
						style={style}
					/>
					<ToastMessages />
				</StyleContext.Provider>
			</HubContext.Provider>
		);
	}

	return (
		<>
			<AuthenticatedTemplate>
				<HubContext.Provider
					value={hubContextPackage}
				>
					<StyleContext.Provider value={styleContextPackage}>
						<RenderRoutes
							mode={mode}
							routes={ROUTES}
							style={style}
						/>
						<ToastMessages />
					</StyleContext.Provider>
				</HubContext.Provider>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<HubContext.Provider
					value={hubContextPackage}
				>
					<StyleContext.Provider value={styleContextPackage}>
						<LoginScreen />
						<ToastMessages />
					</StyleContext.Provider>
				</HubContext.Provider>
			</UnauthenticatedTemplate>
		</>
	);
}
