import React from "react";
import { getMonthName } from "../../../functions/UtilityFunctions";
import { formatDisplayNumber } from "../../../functions/formatDisplayNumber";
import { MixedLineAndBarChart } from "../../../components/MixedLineAndBarChart/MixedLineAndBarChart";

type Props = {
	monthlyProductionActual: Array<Number>,
	monthlyProductionActivePower: Number,
}

/**
 * Displays the monthly information in the lobby.
 */
export const ProductionMonthlyLobby = (props: Props) => {
	const {
		monthlyProductionActual,
		monthlyProductionActivePower,
	} = props;

	const fixedMonthlyProductionActivePower = formatDisplayNumber(monthlyProductionActivePower);

	const labels = [];
	const actData = [];
	if (monthlyProductionActual) {
		for (let i = 0; i < monthlyProductionActual.length; i++) {
			const time = new Date(monthlyProductionActual[i].time);
			const monthName = getMonthName(time.getMonth());

			const actValue = monthlyProductionActual[i].value ? monthlyProductionActual[i].value / 1000 : 0;

			labels.push(time.getDate() + " " + monthName.substring(0, 3));
			actData.push(formatDisplayNumber(actValue));
		}
	}

	return (
		<div className="card flex-column is-full-height">
			<header className="card-header">
				<h2 className="card-header-title is-uppercase is-size-7">Monthly Production</h2>
			</header>
			<div className="card-content py-1">
				<div className="chart is-large">
					<MixedLineAndBarChart
						labels={labels}
						series={[
							{
								axisIndex: 0,
								data: actData,
								hasLegend: false,
								name: "Actual",
								numberOfDecimals: 1,
								unit: "kWh",
							}
						]}
					/>
				</div>
			</div>
			<footer className="px-5 pb-4 has-text-right mt-auto">
				<h2>
					<span className="has-text-weight-semibold is-size-4 is-block has-line-height-1">
						{fixedMonthlyProductionActivePower}<small className="is-size-7 has-text-weight-light">&nbsp;kWh</small>
					</span>
				</h2>
			</footer>
		</div>
	);
};
