import React from "react";
import { ValueUnit } from "./ValueUnit";

type Props = {
	horizontalIrradiation?: number,
}

/**
 * Displays Irradiance information.
 */
export const Irradiance = (props: Props) => {
	const irradiance = props.horizontalIrradiation;
	const irradianceUnit = "W/m" + String.fromCharCode(178);
	const name = "Irradiance";
	return (
		<div className="is-flex is-align-items-center">
			<span className="icon is-large is-rounded has-text-theme--1 has-background-theme--2 mr-3">
				<i className="icon-solar-irradiance is-size-4" aria-hidden="true" />
			</span>
			<ValueUnit value={irradiance || 0} uom={irradianceUnit} name={name} />
		</div>
	);
};
