import React, { useEffect, useState } from "react";
import { TwinClassType } from "../../../../Types";

type Props = {
	onClose: Function,
	onReload: Function,
	sendTwinHubRequest: Function,
	twinClass: TwinClassType | null
}

/**
 * Lets the user create a new class.
 */
export const TwinClassModal = (props: Props) => {
	const {
		onClose,
		onReload,
		sendTwinHubRequest,
		twinClass,
	} = props;
	const [ editClass, setEditClass ] = useState<TwinClassType>({
		name: "",
	});

	useEffect(() => {
		if (twinClass) {
			setEditClass(twinClass);
		} else {
			setEditClass({
				name: "",
			});
		}
	}, [ twinClass ]);

	/**
	 * Adds a new class to the backend.
	 */
	function handleSave (close: Boolean) {
		if (editClass.twinClassId) {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: close === true ? saveCompleted : blurCompleted,
				parameters: editClass,
				requestId: isoDate,
				requestType: "TwinClassUpdate",
			};

			sendTwinHubRequest(requestPackage);
		} else {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: close === true ? saveCompleted : blurCompleted,
				parameters: editClass,
				requestId: isoDate,
				requestType: "TwinClassAdd",
			};

			sendTwinHubRequest(requestPackage);
		}
	}

	/**
	 * Called when the add completes.
	 */
	function saveCompleted (newClass: TwinClassType) {
		setEditClass({
			name: "",
		});
		onReload();
		onClose();
	}

	/**
	 * Called when the blur of a field completes.
	 */
	function blurCompleted (newRecord: TwinClassType) {
		setEditClass(newRecord);
	}

	return (
		<div className="modal is-active">
			<div className="modal-background">&nbsp;</div>
			<div className="modal-content">
				<div className="field">
					<label htmlFor="" className="label">ID</label>
					<div className="control">
						<label>{editClass.twinClassId}</label>
					</div>
				</div>
				<div className="field">
					<label htmlFor="" className="label">Name</label>
					<div className="control">
						<input
							type="text"
							className="input"
							onChange={(event) => {
								if (!event) {
									return;
								}

								const { target, } = event;
								if (!target) {
									return;
								}

								setEditClass({
									...editClass,
									...{
										name: target.value,
									},
								});
							}}
							placeholder="Class Name"
							value={editClass.name}
						/>
					</div>
				</div>
				<button className="button" onClick={() => { handleSave(true); }}>Save &amp; Close</button>
			</div>
			<button className="modal-close is-large" aria-label="close" onClick={() => { onClose(); }} />
		</div>
	);
};
