import { createSlice } from "@reduxjs/toolkit";

/**
 * Saves side info panel data created by the user.
 */
const sideInfoSlice = createSlice({
	initialState: {
		categories: [],
	},
	name: "sideInfos",
	reducers: {
		resetSideInfo (state, action) {
			state.categories = [];
		},
		saveSideInfo (state, action) {
			state.categories = action.payload;
		},
	},
});

export const { loadSideInfo, resetSideInfo, saveSideInfo, } = sideInfoSlice.actions;

/**
 * Clears all of the side info data.
 */
export const ResetSideInfo = () => async (dispatch) => {
	dispatch(resetSideInfo()); // save data to state
};

/**
 * Saves side info data to Redux.
 * @param {*} sideInfoData The side info data object to save.
 */
export const SaveSideInfo = (sideInfoData) => async (dispatch) => {
	dispatch(saveSideInfo(sideInfoData)); // save data to state
};

// Export reducers for use in store.js etc
export default sideInfoSlice.reducer;
