import React from "react";
import { useHistory } from "react-router-dom";

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
		REACT_APP_ORGANISATION_NAME: string;
	}
}

/**
 * The initial screen
 */
export const HomeScreen = () => {
	const history = useHistory();

	let worldMapExtra = "";
	if (window.REACT_APP_ORGANISATION_NAME === "Scatec") {
		worldMapExtra = "?style=scatec&mode=light";
	}

	return (
		<div className="container">
			<ul className="menu-list">
				<li>
					<a onClick={() => { history.push(`/worldmap${worldMapExtra}`); }}>World Map</a>
					<a onClick={() => { history.push("/startUpScreen"); }}>Start Up</a>
					<a onClick={() => { history.push("/enterprise"); }}>Enterprise Dashboard</a>
					<a onClick={() => { history.push("/lobby"); }}>Lobby Dashboard</a>
					<a onClick={() => { history.push("/twinAdminMenu"); }}>Twin Administration</a>
					<a onClick={() => { history.push("/apiTesting"); }}>API Testing</a>
				</li>
			</ul>
		</div>
	);
};
