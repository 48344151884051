import React from "react";
import { useHistory } from "react-router-dom";
import { CategorizedProgressBar } from "../../../components/CategorizedProgressBar";
import { ButtonExpand } from "../../../components/ButtonExpand";
import { SiteType } from "../../../Types";

type Props = {
	includeExpand: Boolean,
	signals: SiteType,
}

/**
 * Displays KPI bars
 */
export const KPIs = (props: Props) => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const id = urlParams.get("i");
	const history = useHistory();

	const { includeExpand, signals, } = props;
	const categories = [];

	// PR
	const pr = signals?.pRMeasure;
	categories.push({
		category: "Performance",
		progressbars: [
			{
				color: "success", // success and danger depending on the specific percentage
				dotted: false,
				name: "PR",
				value: pr || 0,
			}
		],
	});

	// Availability
	const capacityAvailability = signals?.capacityAvailability;
	const technicalAvailability = signals?.technicalAvailability;
	categories.push({
		category: "Availability",
		progressbars: [
			{
				color: "", // empty if = 100%, if < 100% "danger"
				dotted: false,
				name: "Capacity",
				value: capacityAvailability || 0,
			},
			{
				color: "", // empty if = 100%, if < 100% "danger"
				dotted: false,
				name: "Technical",
				value: technicalAvailability || 0,
			}
		],
	});

	// Grid Communication
	// const gridCommunication = null;
	// TODO: Find communication signal!
	categories.push({
		category: "Status",
		progressbars: [
			{
				color: "success", // success and danger depending on the specific percentage
				dotted: false,
				name: "Grid",
				value: 0,
			},
			{
				color: "success", // success and danger depending on the specific percentage
				dotted: false,
				name: "Network",
				value: 0,
			}
		],
	});

	return (
		<div className="card is-full-height">
			<header className="card-header pr-5">
				<h2 className="card-header-title is-uppercase is-size-7">
					KPI<span className="is-lowercase">s</span>
				</h2>
				<span className={includeExpand ? "is-absolute is-top is-right" : "is-hidden"}>
					<ButtonExpand onClick={() => { history.push(`/solarSiteKPIs?i=${id}`); }} />
				</span>
			</header>
			<div className="card-content px-4" style={{ height: "calc(100% - 42px)", }}>
				{categories && categories.length > 0
					? <CategorizedProgressBar categories={categories} />
					: (
						<h4 className="flex-column is-full-height justify-center has-text-centered has-text-opacity--60 has-background-opacity--5 py-4">No signals found</h4>
					)}
			</div>
		</div>

	);
};
