import React, { useState, useEffect } from "react";
import { EPICard } from "./EPICard";
import { BarChartCard } from "./barChartCard";
import { ProductionGauge } from "../../WorldMapScreen/components/TopBar/ProductionGauge";
import { formatDisplayNumber } from "../../../functions/formatDisplayNumber";
import { SiteType } from "../../../Types";

type Props = {
	cardIsClickable: boolean,
	site: SiteType,
 };

/** Displays site card in start up grid view  */
export const StartUpSiteCard = (props: Props) => {
	const {
		location,
		countryCode,
		horizontalIrradiation,
		totalACCapacity,
		totalActivePowerExport,
		actualVsBudget,
		ePI,
		plantDetailsURL,
	} = props.site;
	const { cardIsClickable, } = props;
	const [ cardClass, setCardClass ] = useState("is-clickable");

	/***
	 * Get background color for EPI
	 */
	const epiLowLimit = 97;
	const epiHighLimit = 103;

	const symbol = props.site?.weatherSymbolNumber;
	let symbolUrl = "";

	if (symbol) {
		try {
			if (symbol >= 10) {
				symbolUrl = symbol ? require(`./../../../assets/images/yr/svg/${symbol}d.svg`).default : "";
			} else {
				symbolUrl = symbol ? require(`./../../../assets/images/yr/svg/0${symbol}d.svg`).default : "";
			}
		} catch (err) {
			try {
				if (symbol >= 10) {
					symbolUrl = symbol ? require(`./../../../assets/images/yr/svg/${symbol}.svg`).default : "";
				} else {
					symbolUrl = symbol ? require(`./../../../assets/images/yr/svg/0${symbol}.svg`).default : "";
				}
			} catch (err) {}
		}
	}

	/**
	 * set if card is clickable or not based on card props
	*/
	useEffect(() => {
		if (!cardIsClickable) {
			setCardClass("is-disabled");
		} else {
			setCardClass("is-clickable");
		}
	}, [ cardIsClickable ]);

	let displayCountryCode = countryCode;
	if (!(typeof countryCode === "string")) {
		displayCountryCode = "N/A";
	}

	let displayLocation = location;
	if (!(typeof location === "string")) {
		displayLocation = "N/A";
	}

	return (
		<a
			href={plantDetailsURL}
			target="_parent"
			className={`card is-clipped has-shadow--small ${cardClass}`}
		>
			<header className="card-header is-shadowless has-background--grey-lightest--black">
				<p className="card-header-title pl-4 pr-2 py-2">
					{displayCountryCode} {displayLocation}
				</p>
				<button className="card-header-icon is-unclickable is-borderless is-transparent p-2">
					<span className="icon">
						{symbolUrl !== "" && <img className="image" alt="Weather" src={symbolUrl} />}
					</span>
				</button>
			</header>

			<div className="card-content p-0">
				<div
					className="columns is-mobile is-vcentered is-clipped has-border-top-opacity--20 has-border-top-width-1 m-0 pl-1-touch pr-2-touch py-3-touch pl-1-desktop pr-2-desktop py-4
					-desktop"
				>
					<div className="column is-flex is-justify-content-flex-start has-arrow-right is-relative pl-1 pr-4 py-0">
						<div className="media is-align-items-center has-line-height-1">

							<div className="media-left is-flex mr-2-desktop mr-2-touch">
								<span className="icon gauge--2 is-rounded">
									<ProductionGauge value={totalActivePowerExport} max={totalACCapacity} />
								</span>
							</div>
							<div className="media-content">
								<h3>
									<span className="has-text-weight-semibold is-size-4-desktop is-narrowed">
										{formatDisplayNumber(totalActivePowerExport || 0)}
									</span>
									<span className="is-size-7">&nbsp;MW</span>
									<span className="is-block is-size-6 is-size-7-mobile">Power</span>
								</h3>
							</div>
						</div>
					</div>
					<div className="column is-flex is-justify-content-flex-end is-paddingless">
						<div className="media is-align-items-center has-line-height-1">
							<div className="media-left is-flex mr-1">
								<span className="icon">
									<i className="icon-solar-irradiance is-size-4" aria-hidden="true" />
								</span>
							</div>
							<div className="media-content">
								<h3>
									<span
										className="has-text-weight-semibold is-size-8-fullhd is-narrowed"
										data-id="Irradiation"
									>
										{formatDisplayNumber(horizontalIrradiation || 0)}
									</span>
									<span className="is-size-8">&nbsp;W/m&#178;</span>
									<span className="is-block is-size-7 is-size-8-mobile ">Irradiance</span>
								</h3>
							</div>

						</div>
					</div>
				</div>

				<div
					className="columns is-mobile is-vcentered has-border-top-opacity--20 has-border-top-width-1 m-0 pl-1-touch pr-2-touch py-1-touch pl-1-desktop pr-2-desktop pt-2-desktop pb-1-desktop"
				>
					<div className="column is-paddingless">
						<div className="media is-align-items-center has-line-height-1">
							<div className="media-left is-flex mr-1">
								<span className="icon">
									<BarChartCard value={actualVsBudget || 0} limit={100} />
								</span>
							</div>
							<div className="media-content">
								<h3>
									<span
										className="has-text-weight-semibold is-size-5-desktop is-narrowed"
									>
										{formatDisplayNumber(actualVsBudget || 0)}
									</span>
									<span className="is-size-8">&#37;</span>
									<span className="is-block is-size-7 is-size-8-mobile">Actual vs Budget</span>
								</h3>
							</div>
						</div>
					</div>
					<div className="column is-narrow-desktop is-flex-touch is-justify-content-flex-end is-paddingless">
						<EPICard value={ePI || 0} lowLimit={epiLowLimit} highLimit={epiHighLimit} />
					</div>
				</div>

				<div className="is-flex is-align-items-center has-line-height-1">
					<div className="divider is-right is-gradient-left-opacity mx-2-touch ml-1-desktop mr-2-desktop mb-1">
						<small className="is-uppercase is-size-9 has-text-opacity--80">yesterday</small>
					</div>
				</div>
			</div>
		</a>
	);
};
