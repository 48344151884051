import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FetchTwinDetails } from "../../../Redux/Slices/backendSlice";
import { KPIs } from "../components/KPIs";
import solarSiteFields from "../../../json/General/solarsite";

/**
 * Displays a zoomed in view of the KPIs.
 */
export const SolarSiteKPIScreen = () => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const id = urlParams.get("i");
	const history = useHistory();

	const [ signals, setSignals ] = useState({});

	const dispatch = useDispatch();

	useEffect(() => {
		const interval = setInterval(() => {
			loadSignals();
		}, 300000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		loadSignals();
	}, []);

	/**
	 * Loads the site info from the backend.
	 */
	const loadSignals = useCallback(() => {
		dispatch(
			FetchTwinDetails({
				id: id,
				json: solarSiteFields,
				onSuccess: setSignals,
				token: null,
			})
		);
	}, [ id ]);

	return (
		<section>
			<div className="columns">
				<div
					className="column"
					onClick={() => { history.goBack(); }}
					style={{ cursor: "pointer", }}
				>
					<i className="fas fa-arrow-left mr-3" style={{ float: "left", }} />
					<p style={{ float: "left", }}>Return</p>
				</div>
			</div>
			<div className="columns">
				<div className="column">
					<KPIs
						includeExpand={false}
						signals={signals}
					/>
				</div>
			</div>
		</section>
	);
};
