import React from "react";

type Props = {
	name: string,
	sitesNumber: number,
};

/** Display Region info */
export function RegionInfo (props: Props) {
	const { name, sitesNumber, } = props;
	if (name && sitesNumber) {
		return (
			<h4 className="is-flex is-align-items-center is-size-small has-text-weight-semibold mb-3 mt-5">
				<span className="is-uppercase">{name}</span>
				<span className="divider is-left is-gradient-right-opacity ml-3">
					<span className="tag has-background-opacity--10 has-text-opacity--60">{sitesNumber}</span>
				</span>
			</h4>
		);
	} else {
		return null;
	}
}
