import React from "react";
import { useHistory } from "react-router-dom";

type Props = {
	name?: string,
	value?: {
		nodeId?: string,
		value?: number,
	},
}

/**
 * Displays a row for a signal.
 */
export const TwinNodeTemplateSignal = (props: Props) => {
	const { name, value: nodeValue, } = props;
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const twinNodeTemplateIdString = urlParams.get("twinNodeTemplateId");
	const twinNodeTemplateId = twinNodeTemplateIdString ? parseInt(twinNodeTemplateIdString, 10) : null;
	const history = useHistory();

	return (
		<tr className="level--0">
			<td className="is-borderless">
				{name}
			</td>
			<td className="is-borderless">
				{nodeValue?.value ? nodeValue.value.toString() : null}
			</td>
			<td
				className={nodeValue?.nodeId ? "is-borderless is-clickable" : "is-hidden"}
				onClick={() => { history.push(`/twinNodeTemplateHistoryTest?twinNodeTemplateId=${twinNodeTemplateId}&uaNodeId=${nodeValue?.nodeId}`); }}
			>
				<i className="fa fa-chart-line" />
			</td>
		</tr>
	);
};
