import React, { useRef, useEffect } from "react";

type Props = {
    limit: number,
    value: number,
}

/** EPI card */
export function BarChartCard (props: Props) {
	const { limit, value, } = props;
	const canvasRef = useRef<HTMLCanvasElement>(null);

	useEffect(() => {
		const canvas = canvasRef.current;
		if (canvas) {
			const context = canvas.getContext("2d");
			if (context) {
				canvas.width = 30;
				canvas.height = 40;
				const barChartFill = "#0f5caf"; // $blue-dark === $primary
				const barChartBackground = "#9FBEDF"; // $blue-lighter
				context.rotate(180 * Math.PI / 180);

				/***
				 * Draw frames
				 */
				const draw = (ctx: any, x: number, y: number, width: number, height: number, color: string) => {
					//
					ctx.fillStyle = color;
					ctx.beginPath();
					ctx.rect(x, y, width, height);
					ctx.fill();
				};

				/***
				 * Draw bar
				 */
				const render = () => {
					// context.clearRect(0, 0, context.canvas.width, context.canvas.height);
					draw(context, -(canvas.width - 8), -canvas.height, canvas.width - 16, limit / (canvas.height / 10), barChartBackground);
					draw(context, -(canvas.width - 10), -canvas.height, canvas.width - 20, value / (canvas.height / 10), barChartFill);
					// context.rotate(20 * Math.PI / 180);
				};
				render();
			}
		}
	}, [ limit, value ]);
	return <canvas ref={canvasRef} {...props} />;
}
