import React from "react";
import { useHistory } from "react-router-dom";

/**
 * The twin admin menu
 */
export const TwinAdminMenu = () => {
	const history = useHistory();

	return (
		<div className="container">
			<ul className="menu-list">
				<li>
					<a onClick={() => { history.push("/twinNodeTemplateAdmin"); }}>Model Based</a>
					<a onClick={() => { history.push("/twinAdmin"); }}>Custom</a>
				</li>
			</ul>
		</div>
	);
};
