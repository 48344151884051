/* eslint-disable require-jsdoc */

import React from "react";

type Props = {
	max?: number,
	min?: number,
	value?: number,
};

/**
 * Displays a simple gauge.
 */
export const Gauge = (props: Props) => {
	// Provides size and with for gauge and its elements
	const gauge = {
		size: 200,
		width: 26,
	};

	let { max, min, value, } = props;

	if (!max || isNaN(max)) {
		max = 100;
	}

	if (!min || isNaN(min)) {
		min = 0;
	}

	if (!value || isNaN(value)) {
		value = 0;
	}

	let range = 0;
	if (max >= 0 && min >= 0) {
		range = max - min;
	} else if (max <= 0 && min <= 0) {
		range = Math.abs(min - max);
	} else {
		range = Math.abs(min) + Math.abs(max);
	}

	let valueInRange = 0;
	if (value >= 0 && min >= 0) {
		valueInRange = value - min;
	} else if (value <= 0 && min <= 0) {
		valueInRange = Math.abs(min - value);
	} else {
		valueInRange = Math.abs(min) + Math.abs(value);
	}

	const rangePoint = range / 290;

	let transformation;
	if (valueInRange && rangePoint) {
		const position = valueInRange / rangePoint;

		if (position && !isNaN(position)) {
			transformation = `rotate(${position - 145}, 100, 100)`;
		}
	} else {
		transformation = `rotate(${0 - 145}, 100, 100)`;
	}

	const strokeDashPercent = Math.floor((75 * valueInRange) / range);

	function c () {
		return gauge.size / 2;
	}

	function radius () {
		return gauge.size / 2 - gauge.width / 2;
	}

	function circumferenceLength () {
		return 2 * Math.PI * radius();
	}

	function strokeDashArray () {
		const solid = (Math.round(circumferenceLength()) * strokeDashPercent) / 100;
		const transparent =
			(Math.round(circumferenceLength()) * (100 - strokeDashPercent)) / 100;
		return `${solid} ${transparent}`;
	}

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			strokeLinejoin="round"
			strokeMiterlimit="2"
			clipRule="evenodd"
			viewBox={`0 0 ${gauge.size} ${gauge.size}`}
			style={{ height: "inherit", width: "inherit", }}
		>
			<defs>
				<clipPath
					id="donut-path"
					transform="matrix(1.33333,0,0,1.33333,-486.941,-89.8718)"
				>
					<path
						fill="none"
						d="M387.625,195.864C373.79,182.255 365.206,163.324 365.206,142.404C365.206,101.01 398.812,67.404 440.206,67.404C481.599,67.404 515.206,101.01 515.206,142.404C515.206,163.254 506.679,182.128 492.926,195.727L478.701,181.671C488.883,171.689 495.206,157.779 495.206,142.404C495.206,112.048 470.561,87.404 440.206,87.404C409.85,87.404 385.206,112.048 385.206,142.404C385.206,157.848 391.586,171.815 401.851,181.808L387.625,195.864Z"
					/>
				</clipPath>
			</defs>
			<g clipPath="url(#donut-path)">
				<circle
					className="gauge-donut"
					fill="transparent"
					cx={c()}
					cy={c()}
					r={radius()}
					strokeWidth={gauge.width}
				/>
			</g>
			{/* <g clipPath="url(#donut-path)"> */}
			<circle
				className="gauge-current"
				transform={`rotate(135 ${c()} ${c()})`}
				fill="transparent"
				cx={c()}
				cy={c()}
				r={radius()}
				strokeWidth={gauge.width}
				strokeDasharray={strokeDashArray()}
			/>
			{/* </g> */}
			<g transform={transformation}>
				<path
					className="gauge-arrow"
					d="M90.327,-2.919C90.4,-2.919 90.407,-2.028 90.548,-0.402C91.517,10.776 95.33,54.854 95.327,55.295C95.325,55.601 95.279,55.987 95.279,55.987C94.942,58.42 92.851,60.295 90.327,60.295C87.802,60.295 85.712,58.42 85.374,55.987C85.374,55.987 85.327,55.454 85.327,55.295C85.327,54.853 89.146,10.71 90.109,-0.402C90.247,-2.004 90.253,-2.919 90.327,-2.919Z"
					transform="matrix(1.33333,0,0,1.33333,-20.4355,29.7495)"
				/>
			</g>
		</svg>
	);
};
