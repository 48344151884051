export default {
	acCurrentPhaseA: {
		definitionAddress: "Current.properties.phase_a",
		numberOfDecimals: 1,
		signalName: "signals.ac.current.phase_a",
		unit: "A",
	},
	acCurrentPhaseB: {
		definitionAddress: "Current.properties.phase_b",
		numberOfDecimals: 1,
		signalName: "signals.ac.current.phase_b",
		unit: "A",
	},
	acCurrentPhaseC: {
		definitionAddress: "Current.properties.phase_c",
		numberOfDecimals: 1,
		signalName: "signals.ac.current.phase_c",
		unit: "A",
	},
	acFrequency: {
		definition: {
			description: "Frequency describes the number of waves that pass a fixed place in a given amount of time.",
			title: "Frequency",
		},
		numberOfDecimals: 2,
		signalName: "signals.ac.frequency",
		unit: "Hz",
	},
	acPowerFactor: {
		definitionAddress: "Power.properties.factor",
		numberOfDecimals: 2,
		signalName: "signals.ac.power.factor",
		unit: "",
	},
	acPowerReactive: {
		definitionAddress: "Power.properties.reactive",
		numberOfDecimals: 1,
		signalName: "signals.ac.power.reactive",
		unit: "VAr",
	},
	acVoltagePhaseAB: {
		definitionAddress: "Voltage.properties.phase_ab",
		numberOfDecimals: 1,
		signalName: "signals.ac.voltage.phase_ab",
		unit: "V",
	},
	acVoltagePhaseBC: {
		definitionAddress: "Voltage.properties.phase_bc",
		numberOfDecimals: 1,
		signalName: "signals.ac.voltage.phase_bc",
		unit: "V",
	},
	acVoltagePhaseCA: {
		definitionAddress: "Voltage.properties.phase_ca",
		numberOfDecimals: 1,
		signalName: "signals.ac.voltage.phase_ca",
		unit: "V",
	},
	activePower: {
		definitionAddress: "Power.properties.active",
		max: 100,
		min: 0,
		numberOfDecimals: 1,
		signalName: "signals.ac.power.active",
		unit: "W",
	},
	assetDescription: {
		signalName: "asset.description",
	},
	assetGpsLatitude: {
		signalName: "asset.gps_latitude",
	},
	assetGpsLongitude: {
		signalName: "asset.gps_longitude",
	},
	assetID: {
		signalName: "asset.id",
	},
	assetName: {
		signalName: "asset.name",
	},
	capacityAvailability: {
		numberOfDecimals: 1,
		signalName: "signals.kpi.capacity_availability",
		unit: "%",
	},
	co2Factor: {
		defaultValue: 1,
		numberOfDecimals: 8,
		signalName: "signals.co2_factor",
		unit: "ktons",
	},
	communicating: {
		signalName: "signals.communicating",
	},
	dailyActive: {
		defaultValue: "N/A",
		definitionAddress: "Energy.properties.daily_active",
		numberOfDecimals: 1,
		signalName: "signals.ac.energy.daily_active",
		unit: "Wh",
	},
	dailyProduction: {
		defaultValue: "N/A",
		definitionAddress: "Energy.properties.daily_active",
		numberOfDecimals: 0,
		signalName: "signals.ac.energy.daily_active",
		unit: "Wh",
	},
	errors: {
		signalName: "asset.errors",
	},
	failureCount: {
		signalName: "signals.count.failure",
	},
	ghi: {
		definitionAddress: "Weather.properties.ghi",
		numberOfDecimals: 1,
		signalName: "signals.weather_forecast.ghi",
		skipUnitConversion: true,
		unit: "W/m²",
	},
	gridAlarmStatus: {
		signalName: "signals.gridAlarm.status",
	},
	gridAlarmTooltip: {
		signalName: "signals.gridAlarm.tooltip",
	},
	gridAlarmValue: {
		signalName: "signals.gridAlarm.value",
	},
	id: {
		signalName: "id",
	},
	internetStatus: {
		signalName: "signals.internet.status",
	},
	internetTooltip: {
		signalName: "signals.internet.tooltip",
	},
	internetValue: {
		signalName: "signals.internet.value",
	},
	inverterAlarmStatus: {
		signalName: "signals.inverterAlarm.status",
	},
	inverterAlarmTooltip: {
		signalName: "signals.inverterAlarm.tooltip",
	},
	inverterAlarmValue: {
		signalName: "signals.inverterAlarm.value",
	},
	kind: {
		signalName: "kind",
	},
	kpiActualProduction: {
		definitionAddress: "KPI.properties.actual_production",
		numberOfDecimals: 0,
		signalName: "signals.kpi.actual_production",
		unit: "Wh/W",
	},
	kpiCO2Savings: {
		definitionAddress: "KPI.properties.co2_savings",
		numberOfDecimals: 0,
		signalName: "signals.kpi.co2_savings",
		unit: "kg",
	},
	kpiCapacityAvailability: {
		numberOfDecimals: 1,
		signalName: "signals.kpi.capacity_availability",
		unit: "%",
	},
	kpiCommunicationAvailability: {
		numberOfDecimals: 1,
		signalName: "signals.kpi.communication_availability",
		unit: "%",
	},
	kpiDailyCO2Savings: {
		definitionAddress: "KPI.properties.daily_co2_savings",
		numberOfDecimals: 0,
		signalName: "signals.kpi.daily_co2_savings",
		unit: "kg",
	},
	kpiDailyTreeSavings: {
		definitionAddress: "KPI.properties.daily_tree_savings",
		numberOfDecimals: 0,
		signalName: "signals.kpi.daily_tree_savings",
		unit: "",
	},
	kpiGHI: {
		definitionAddress: "KPI.properties.actual_ghi",
		numberOfDecimals: 1,
		signalName: "signals.kpi.actual_ghi",
		unit: "W/m²",
	},
	kpiInverterEfficiency: {
		definitionAddress: "KPI.properties.efficiency",
		numberOfDecimals: 1,
		signalName: "signals.kpi.efficiency",
		unit: "%",
	},
	kpiMonthlyCO2Savings: {
		definitionAddress: "KPI.properties.monthly_co2_savings",
		numberOfDecimals: 0,
		signalName: "signals.kpi.monthly_co2_savings",
		unit: "kg",
	},
	kpiMonthlyTreeSavings: {
		definitionAddress: "KPI.properties.monthly_tree_savings",
		numberOfDecimals: 0,
		signalName: "signals.kpi.monthly_tree_savings",
		unit: "trees",
	},
	kpiPOA: {
		definitionAddress: "KPI.properties.actual_poa",
		numberOfDecimals: 1,
		signalName: "signals.kpi.actual_poa",
		unit: "W/m²",
	},
	kpiPerformanceRatio: {
		definitionAddress: "KPI.properties.pr",
		numberOfDecimals: 1,
		signalName: "signals.kpi.pr",
		unit: "%",
	},
	kpiReferenceYield: {
		definitionAddress: "KPI.properties.reference_yield",
		numberOfDecimals: 0,
		signalName: "signals.kpi.reference_yield",
		unit: "Wh",
	},
	kpiSpecificPower: {
		definitionAddress: "KPI.properties.specific_power",
		numberOfDecimals: 1,
		signalName: "signals.kpi.specific_power",
		unit: "W/Wp",
	},
	kpiSpecificYield: {
		definitionAddress: "KPI.properties.specific_yield",
		numberOfDecimals: 0,
		signalName: "signals.kpi.specific_yield",
		unit: "Wh",
	},
	kpiTechnicalAvailability: {
		numberOfDecimals: 1,
		signalName: "signals.kpi.technical_availability",
		unit: "%",
	},
	kpiTempCorrectedPerformanceRatio: {
		definitionAddress: "KPI.properties.temperature_corrected_pr",
		numberOfDecimals: 1,
		signalName: "signals.kpi.temperature_corrected_pr",
		unit: "%",
	},
	kpiTreeSavings: {
		definitionAddress: "KPI.properties.tree_savings",
		numberOfDecimals: 0,
		signalName: "signals.kpi.tree_savings",
		unit: "trees",
	},
	kpiYearlyCO2Savings: {
		definitionAddress: "KPI.properties.yearly_co2_savings",
		numberOfDecimals: 0,
		signalName: "signals.kpi.yearly_co2_savings",
		unit: "kg",
	},
	kpiYearlyTreeSavings: {
		definitionAddress: "KPI.properties.yearly_tree_savings",
		numberOfDecimals: 0,
		signalName: "signals.kpi.yearly_tree_savings",
		unit: "trees",
	},
	maintenance: {
		signalName: "asset.maintenance",
	},
	monthlyActive: {
		defaultValue: "N/A",
		definitionAddress: "Energy.properties.monthly_active",
		numberOfDecimals: 1,
		signalName: "signals.ac.energy.monthly_active",
		unit: "Wh",
	},
	monthlyProduction: {
		defaultValue: "N/A",
		definitionAddress: "Energy.properties.monthly_active",
		numberOfDecimals: 0,
		signalName: "signals.ac.energy.monthly_active",
		unit: "Wh",
	},
	name: {
		signalName: "asset.name",
	},
	networkStatus: {
		signalName: "signals.network.status",
	},
	networkTooltip: {
		signalName: "signals.network.tooltip",
	},
	networkValue: {
		signalName: "signals.network.value",
	},
	nominalPower: {
		defaultValue: "N/A",
		numberOfDecimals: 1,
		signalName: "params.nominals.ac_power",
		unit: "W",
	},
	numberOfInverters: {
		count: true,
		signalName: "relations.children",
	},
	paramDataCollectionStartDate: {
		signalName: "params.data_collection_start_date",
	},
	paramInstallationDate: {
		signalName: "params.installation_date",
	},
	paramNominalACPower: {
		numberOfDecimals: 1,
		signalName: "params.nominals.ac_power",
		unit: "W",
	},
	paramNominalDCPower: {
		numberOfDecimals: 1,
		signalName: "params.nominals.dc_power",
		unit: "W",
	},
	paramTimezone: {
		signalName: "params.timezone",
	},
	parentId: {
		signalName: "relations.parent.id",
	},
	pr: {
		defaultValue: 0,
		definitionAddress: "KPI.properties.pr",
		numberOfDecimals: 1,
		signalName: "signals.kpi.pr",
		unit: "%",
	},
	shortName: {
		signalName: "asset.short_name",
	},
	specificPower: {
		definitionAddress: "KPI.properties.specific_power",
		numberOfDecimals: 1,
		signalName: "signals.kpi.specific_power",
		unit: "W/Wp",
	},
	status: {
		signalName: "signals.status",
	},
	stringAlarmStatus: {
		signalName: "signals.stringAlarm.status",
	},
	stringAlarmTooltip: {
		signalName: "signals.stringAlarm.tooltip",
	},
	stringAlarmValue: {
		signalName: "signals.stringAlarm.value",
	},
	technicalAvailability: {
		numberOfDecimals: 1,
		signalName: "signals.kpi.technical_availability",
		unit: "%",
	},
	totalDCInputNominal: {
		defaultValue: "N/A",
		numberOfDecimals: 1,
		signalName: "params.nominals.dc_power",
		unit: "W",
	},
	totalProduction: {
		defaultValue: "N/A",
		definitionAddress: "Energy.properties.total_active",
		numberOfDecimals: 0,
		signalName: "signals.ac.energy.total_active",
		unit: "Wh",
	},
	trackerAlarmStatus: {
		signalName: "signals.trackerAlarm.status",
	},
	trackerAlarmTooltip: {
		signalName: "signals.trackerAlarm.tooltip",
	},
	trackerAlarmValue: {
		signalName: "signals.trackerAlarm.value",
	},
	utcOffset: {
		defaultValue: 0,
		signalName: "calculations.utc_offset",
	},
	warnings: {
		signalName: "asset.warnings",
	},
	weatherAirTemperature: {
		definitionAddress: "Weather.properties.air_temperature",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.air_temperature",
		skipUnitConversion: true,
		unit: "°C",
	},
	weatherApparentElevation: {
		definitionAddress: "Weather.properties.apparent_elevation",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.apparent_elevation",
		skipUnitConversion: true,
		unit: "°",
	},
	weatherApparentZenith: {
		definitionAddress: "Weather.properties.apparent_zenith",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.apparent_zenith",
		skipUnitConversion: true,
		unit: "°",
	},
	weatherAzimuth: {
		definitionAddress: "Weather.properties.azimuth",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.azimuth",
		skipUnitConversion: true,
		unit: "°",
	},
	weatherCloudOpacity: {
		definitionAddress: "Weather.properties.cloud_opacity",
		signalName: "signals.weather_observed.cloud_opacity",
		skipUnitConversion: true,
		unit: "",
	},
	weatherDHI: {
		definitionAddress: "Weather.properties.dhi",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.dhi",
		skipUnitConversion: true,
		unit: "W/m²",
	},
	weatherDNI: {
		definitionAddress: "Weather.properties.dni",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.dni",
		skipUnitConversion: true,
		unit: "W/m²",
	},
	weatherEBH: {
		definitionAddress: "Weather.properties.ebh",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.ebh",
		skipUnitConversion: true,
		unit: "W/m²",
	},
	weatherElevation: {
		definitionAddress: "Weather.properties.elevation",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.elevation",
		skipUnitConversion: true,
		unit: "°",
	},
	weatherEquationOfTime: {
		definitionAddress: "Weather.properties.equation_of_time",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.equation_of_time",
		skipUnitConversion: true,
		unit: "s",
	},
	weatherGHI: {
		definitionAddress: "Weather.properties.ghi",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.ghi",
		skipUnitConversion: true,
		unit: "W/m²",
	},
	weatherIrradiance: {
		definitionAddress: "Weather.properties.ghi",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.ghi",
		skipUnitConversion: true,
		unit: "W/m²",
	},
	weatherPOA: {
		definitionAddress: "Weather.properties.poa",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.poa",
		skipUnitConversion: true,
		unit: "W/m²",
	},
	weatherRelativeHumidity: {
		definitionAddress: "Weather.properties.relative_humidity",
		signalName: "signals.weather_observed.relative_humidity",
		skipUnitConversion: true,
		unit: "%",
	},
	weatherSymbol: {
		signalName: "signals.weather_forecast.weather_symbol_old_id",
		skipUnitConversion: true,
	},
	weatherSymbolDescription: {
		definitionAddress: "Weather.properties.weather_symbol_description",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.weather_symbol_description",
		skipUnitConversion: true,
		unit: "",
	},
	weatherTrackerTheta: {
		definitionAddress: "Weather.properties.tracker_theta",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.tracker_theta",
		skipUnitConversion: true,
		unit: "°",
	},
	weatherWindFromDirection: {
		definitionAddress: "Weather.properties.wind_from_direction",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.wind_from_direction",
		skipUnitConversion: true,
		unit: "°",
	},
	weatherWindSpeed: {
		definitionAddress: "Weather.properties.wind_speed",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.wind_speed",
		skipUnitConversion: true,
		unit: "m/s",
	},
	weatherWindSpeedOfGust: {
		definitionAddress: "Weather.properties.wind_speed_of_gust",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.wind_speed_of_gust",
		skipUnitConversion: true,
		unit: "m/s",
	},
	weatherZenith: {
		definitionAddress: "Weather.properties.zenith",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.zenith",
		skipUnitConversion: true,
		unit: "°",
	},
	weeklyActive: {
		defaultValue: "N/A",
		definitionAddress: "Energy.properties.weekly_active",
		numberOfDecimals: 1,
		signalName: "signals.ac.energy.weekly_active",
		unit: "Wh",
	},
	yearlyActive: {
		definitionAddress: "Energy.properties.yearly_active",
		numberOfDecimals: 1,
		signalName: "signals.ac.energy.yearly_active",
		unit: "Wh",
	},
};
