import React from "react";
import { formatDisplayNumber } from "../../../../functions/formatDisplayNumber";
import { isNull } from "../../../../functions/UtilityFunctions";
import { ProductionGauge } from "./ProductionGauge";

type Props = {
	totalActivePower?: number,
	totalACCapacity?: number,
}

/**
 * The current production
 */
export const CurrentProduction = (props: Props) => {
	const currentProductionRealTime = props?.totalActivePower || 0;
	const currentProductionUnit = "MW";

	return (
		<div className="is-flex is-align-items-center has-radius has-background-theme--1 px-4 py-2">
			<span className="icon is-large gauge--2 mr-2">
				<ProductionGauge
					max={props?.totalACCapacity}
					value={currentProductionRealTime}
				/>
			</span>
			<h3 className="has-line-height-1">
				<span className="has-text-weight-semibold is-size-3 is-size-4-mobile">
					<small>
						{isNull(currentProductionRealTime)
							? "N/A"
							: formatDisplayNumber(currentProductionRealTime)}
					</small>
				</span>
				<span className="has-text-medium is-size-7-mobile">&nbsp;{currentProductionUnit}</span>
				<span className="is-block pt-1">Current Production</span>
			</h3>
		</div>
	);
};
