import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FetchHistorianData } from "../../../Redux/Slices/backendSlice";
import { getGraphGranularity, getGraphLabels } from "../../../functions/PeriodFunctions";
import { MixedLineAndBarChart } from "../../../components/MixedLineAndBarChart/MixedLineAndBarChart";
import { ApexChartSeriesType, HistoricalResponseType } from "../../../Types";

type Props = {
	id: string,
	startTime: Date,
	endTime: Date,
}

/**
 * Displays the production chart.
 */
export const ProductionChart = (props: Props) => {
	const { id, startTime, endTime, } = props;
	const dispatch = useDispatch();
	const [ series, setSeries ] = useState<Array<ApexChartSeriesType>>([]);
	const [ labels, setLabels ] = useState<Array<string>>([]);

	useEffect(() => {
		const granularity = getGraphGranularity(startTime, endTime);

		/**
		 * Handles the historian data loading.
		 */
		const handleTrendsLoaded = (trends: Array<HistoricalResponseType>) => {
			const prSeries: Array<number> = [];
			const availabilitySeries: Array<number> = [];
			const totalPowerSeries: Array<number> = [];
			const efficiencySeries: Array<number> = [];
			const dateLabels: Array<Date> = [];
			// for (let i = 0; i < trends.length; i++) {
			// const trend = trends[i];

			// prSeries.push(trend["kpi.pr_avg"]);
			// availabilitySeries.push(trend["kpi.technical_availability_avg"]);
			// totalPowerSeries.push(trend["ac.power.active_avg"] ? trend["ac.power.active_avg"] / 1000 : 0);
			// efficiencySeries.push(trend["kpi.efficiency_avg"]);
			// dateLabels.push(new Date(trend.timestamp));
			// }
			const graphLabels = getGraphLabels(dateLabels, startTime, endTime);
			setLabels(graphLabels);

			setSeries([
				{
					axisIndex: 0,
					axisPosition: "right",
					data: prSeries,
					name: "PR",
					numberOfDecimals: 1,
					type: "column",
					unit: "%",
					unitPosition: "before",
				},
				{
					axisIndex: 0,
					axisPosition: "right",
					data: availabilitySeries,
					name: "Availability",
					numberOfDecimals: 1,
					type: "column",
					unit: "%",
					unitPosition: "before",
				},
				{
					axisIndex: 1,
					axisPosition: "left",
					data: totalPowerSeries,
					name: "Total Power",
					numberOfDecimals: 1,
					type: "line",
					unit: "kW",
					unitPosition: "before",
				},
				{
					axisIndex: 2,
					axisPosition: "left",
					data: efficiencySeries,
					name: "Efficiency",
					numberOfDecimals: 1,
					type: "area",
					unit: "%",
					unitPosition: "before",
				}
			]);
		};

		dispatch(
			FetchHistorianData({
				endTime: endTime.toISOString(),
				entityIds: [ id ],
				granularity,
				onFailure: (response: string) => alert(response),
				onSuccess: (trends: Array<HistoricalResponseType>) => { handleTrendsLoaded(trends); },
				startTime: startTime.toISOString(),
				variables: [
					"ac.power.active",
					"kpi.technical_availability",
					"kpi.pr",
					"kpi.efficiency"
				],
			})
		);
	}, [ id, startTime, endTime ]);

	return (
		<MixedLineAndBarChart labels={labels} series={series} />
	);
};
