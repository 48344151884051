import React from "react";
import { CircularProgressBar } from "../../../components/CircularProgressBar";
import { Gauge } from "../../../components/Gauge";
import { formatDisplayNumber } from "../../../functions/formatDisplayNumber";
import { scaleUnitAndAmount } from "../../../functions/scaleUnitAndAmount";
import { EnterpriseType } from "../../../Types";

type Props = {
	enterpriseDetails: EnterpriseType,
}

/** */
export const PlantProduction = (props: Props) => {
	const { enterpriseDetails, } = props;
	const { totalActiveEnergy, totalActivePower, } = enterpriseDetails;
	const availability = undefined;

	const {
		amount: displayTotalActiveEnergy,
		unit: displayTotalActiveEnergyUnit,
	} = scaleUnitAndAmount(totalActiveEnergy || 0, "MW");

	const {
		amount: displayTotalActivePower,
		max: displayTotalActivePowerMax,
		unit: displayTotalActivePowerUnit,
	} = scaleUnitAndAmount(totalActivePower || 0, "MW");

	return (
		<div className="card is-full-height flex-column">
			<header className="card-header">
				<h2 className="card-header-title is-uppercase is-size-7">Production</h2>
			</header>
			<div className="card-content has-line-height-1 mt-auto pb-4 px-1">
				<div className="columns is-mobile is-centered is-variable is-2 align-items-flex-end">
					<div className="column has-text-centered">
						<div className="icon gauge--2 is-larger m-auto">
							<Gauge max={displayTotalActivePowerMax} min={0} value={displayTotalActivePower} />
						</div>
						<h2 className="mt-2">
							<span className="has-text-weight-semibold is-size-5 is-block mb-1">
								{formatDisplayNumber(displayTotalActivePower, 0)}
								<small className="is-size-7 has-text-weight-light"> {displayTotalActivePowerUnit}</small>
							</span>
							<small>Active Power</small>
						</h2>
					</div>
					<div className="column is-narrow has-text-centered">
						<span className="icon is-larger has-text--grey--grey-dark m-auto">
							<i className="fas fa-bolt is-size-1" aria-hidden="true" />
						</span>
						<h2 className="mt-2">
							<span className="has-text-weight-semibold is-size-5 is-block mb-1">
								{formatDisplayNumber(displayTotalActiveEnergy, 0)}
								<small className="is-size-7 has-text-weight-light"> {displayTotalActiveEnergyUnit}</small>
							</span>
							<small>Total Production</small>
						</h2>
					</div>
					<div className="column has-text-centered is-flex align-items-center" style={{ flexDirection: "column", }}>
						<div className="chart is-square is-small">
							<CircularProgressBar
								unit="%"
								value={availability}
							/>
						</div>
						<h2 className="mt-2">
							<span className="has-text-weight-semibold is-size-5 is-block mb-1">
								{formatDisplayNumber(availability || 0, 0)}
								<small className="is-size-7 has-text-weight-light"> %</small>
							</span>
							<small>Availability</small>
						</h2>
					</div>
				</div>
			</div>
		</div>

	);
};
