import React from "react";
import { useHistory } from "react-router-dom";
import { SiteWeather } from "./SiteWeather";
import { SiteCommunication } from "./SiteCommunication";
import { SiteNominal } from "./SiteNominal";
import { SitePR } from "./SitePR";
import { SiteProduction } from "./SiteProduction";
import { SitePower } from "./SitePower";
import { formatDisplayNumber } from "../../../functions/formatDisplayNumber";

type Props = {
	aCActivePower?: number,
	countryCode?: string,
	deviceCommunication?: number,
	errors?: number,
	gridAlarm?: number,
	horizontalIrradiation?: number,
	inverterAlarm?: number,
	location?: number,
	maintenance?: number,
	nominalACPower?: number,
	nominalDCPower?: number,
	networkCommunication?: number,
	pRMeasure?: number,
	pRTarget?: number,
	uaNodeId: String,
	warnings?: number,
	weatherSymbolNumber?: number,
}

/**
 * Displays a status row for a site.
 */
export function SiteRow (props: Props) {
	const history = useHistory();

	/** Pushes the user to the screen for the site row */
	function handleRowClick () {
		history.push("/solarSite?uaNodeId=" + props?.uaNodeId);
	}

	const {
		aCActivePower,
		deviceCommunication,
		gridAlarm,
		horizontalIrradiation,
		inverterAlarm,
		location,
		nominalACPower,
		nominalDCPower,
		networkCommunication,
	} = props;

	const pr = props?.pRMeasure;
	const prTarget = props?.pRTarget;

	const symbol = props?.weatherSymbolNumber;
	let symbolUrl = "";

	if (symbol) {
		try {
			if (symbol >= 10) {
				symbolUrl = symbol && symbol.toString() !== "" ? require(`./../../../assets/images/yr/svg/${symbol}d.svg`).default : "";
			} else {
				symbolUrl = symbol && symbol.toString() !== "" ? require(`./../../../assets/images/yr/svg/0${symbol}d.svg`).default : "";
			}
		} catch (err) {
			try {
				if (symbol >= 10) {
					symbolUrl = symbol && symbol.toString() !== "" ? require(`./../../../assets/images/yr/svg/${symbol}.svg`).default : "";
				} else {
					symbolUrl = symbol && symbol.toString() !== "" ? require(`./../../../assets/images/yr/svg/0${symbol}.svg`).default : "";
				}
			} catch (err) {}
		}
	}

	return (
		<tr onClick={handleRowClick} className="is-clickable">

			<td className="is-narrow">
				{props?.countryCode}
			</td>

			<td className="is-truncated">{location}</td>

			<td className="is-truncated">
				<div className="media has-line-height-1">
					<div className="media-left mr-2">
						<figure className="image is-24x24 has-text-centered is-vcentered">
							{symbol && symbol.toString() !== "" && <img alt={symbol.toString()} src={symbolUrl} />}
						</figure>
					</div>
					<div className="media-content">
						<SiteWeather weatherValue={horizontalIrradiation} weatherValueUnit="W/m²" weatherValueProgressValue={horizontalIrradiation} />
					</div>
				</div>
			</td>

			<td className="is-truncated">
				<SitePower max={(nominalACPower || 0) / 1000000} value={formatDisplayNumber((aCActivePower || 0) / 1000000)} valueUnit="MW" progressValue={(aCActivePower || 0) / 1000000} />
			</td>

			<td className="has-text-right">
				<SiteNominal value={formatDisplayNumber((nominalDCPower || 0) / 1000000)} valueUnit="MWp" />
			</td>

			<SitePR value={pr || 0} prTarget={prTarget || 0} />

			<td className="is-narrow">
				<SiteCommunication
					internetValue={deviceCommunication}
					networkValue={networkCommunication}
				/>
			</td>

			<td className="is-narrow">
				<SiteProduction
					gridAlarm={gridAlarm}
					stringAlarm={0}
					trackerAlarm={0}
					inverterAlarm={inverterAlarm}
				/>
			</td>

			<td className="is-narrow has-text-right">
				{props?.warnings &&
				<span className="icon has-text-warning has-tooltip-arrow" data-tooltip="N/A Warnings">
					<i className="fas fa-exclamation-triangle" aria-hidden="true" />
				</span>}

				{props?.errors &&
					<span className="icon has-text-danger has-tooltip-arrow" data-tooltip="N/A errors">
						<i className="fa fa-times-circle" aria-hidden="true" />
					</span>}
			</td>

			<td className="is-narrow has-text-centered">
				{props?.maintenance &&
					<span className="icon has-text-info is-vcentered has-tooltip-arrow" data-tooltip="N/A Service Requested">
						<i className="icon-service-requested is-size-4" aria-hidden="true" />
					</span>}
			</td>
		</tr>
	);
}
