import React, { useContext, useEffect, useRef, useState } from "react";
import { HubContext } from "../../../MainScreen/MainScreen";
import XLSX from "xlsx";
import FileSaver from "file-saver";
import { stringToBlob } from "../../../../functions/stringToBlob";
import { breakUpCellName } from "../../../../functions/breakUpCellName";
import { AddMessage } from "../../../../Redux/Slices/messageSlice";
import { useDispatch } from "react-redux";
import { PleaseWait } from "../../../../components/PleaseWait";
import { TwinStoredProcedureType } from "../../../../Types";

/**
 * Exports/Imports stored procedure information to Excel.
 */
export const TwinStoredProcedures = () => {
	const exportName = "TwinStoredProcedures";
	const { sendTwinHubRequest, } = useContext(HubContext);
	const [ sps, setSPs ] = useState<Array<TwinStoredProcedureType>>([]);
	const fileBrowser = useRef(null);
	const dispatch = useDispatch();

	useEffect(() => {
		handleLoadSPs();
	}, []);

	/**
	 * Loads the stored procedures from the backend.
	 */
	function handleLoadSPs () {
		const isoDate = (new Date()).toISOString();

		const requestPackage = {
			onSuccess: setSPs,
			requestId: "TwinStoredProcedures" + isoDate,
			requestType: "TwinStoredProcedureGetAll",
		};
		sendTwinHubRequest(requestPackage);
	}

	/**
	 * Exports the loaded stored procedures to Excel.
	 */
	function handleExport () {
		const currentDate = new Date();

		const wb = XLSX.utils.book_new();
		wb.Props = {
			Author: "Prediktor AS",
			CreatedDate: currentDate,
			Subject: exportName,
			Title: exportName,
		};

		wb.SheetNames.push(exportName);
		const wsData = [];
		wsData.push([ "Stored Procedure Id", "Name", "Twin Id", "Twin Class Id", "Twin Node Template Id", "Delete" ]);

		for (let i = 0; i < sps.length; i++) {
			const { twinStoredProcedureId, storedProcedureName, twinId, twinClassId, twinNodeTemplateId, } = sps[i];

			wsData.push([ twinStoredProcedureId, storedProcedureName, twinId, twinClassId, twinNodeTemplateId, "No" ]);
		}

		const ws = XLSX.utils.aoa_to_sheet(wsData);
		wb.Sheets[exportName] = ws;

		const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary", });
		FileSaver.saveAs(new Blob([ stringToBlob(wbout) ], { type: "application/octet-stream", }), `${exportName}_${currentDate.toLocaleDateString()}.xlsx`);
	}

	/**
	 * Imports a twin class file.
	 */
	function handleImport () {
		const current: any = fileBrowser?.current;
		if (current) {
			current.click();
		}
	}

	/**
	 * Called when the user selects a file.
	 */
	function handleChangeFile () {
		if (fileBrowser) {
			const current: any = fileBrowser?.current;

			if (current) {
				const { files, } = current;

				if (files && files.length > 0) {
					const file = files[0];
					const reader = new FileReader();

					reader.onloadend = function (e) {
						if (e.target) {
							fileLoaded(file.name, e.target.result);
						}
					};

					if (file) {
						reader.readAsArrayBuffer(file);
					}
				}
			}
		}
	}

	/**
	 * Called when the import file is loaded.
	 */
	function fileLoaded (fileName: string, fileContent: ArrayBuffer | string | null) {
		if (!fileContent || typeof fileContent === "string") {
			return;
		}

		const data = new Uint8Array(fileContent);
		const workbook = XLSX.read(data, { type: "array", });
		const Sheets = workbook.Sheets;

		const spSheet = Sheets[exportName];
		const rows: Array<Array<{ columnNumber: number, value: string }>> = [];

		// eslint-disable-next-line no-unused-vars
		for (const cellName in spSheet) {
			if (cellName.substring(0, 1) !== "!") {
				const inputCell = spSheet[cellName];

				const { rowNumber, columnNumber, } = breakUpCellName(
					cellName
				);

				if (!rows[rowNumber]) {
					rows[rowNumber] = [];
				}

				rows[rowNumber][columnNumber] = {
					columnNumber,
					value: inputCell.v,
				};
			}
		}

		const deleteSPs: Array<number> = [];
		const newSPs: Array<TwinStoredProcedureType> = [];
		const updatedSPs: Array<TwinStoredProcedureType> = [];
		for (let i = 0; i < rows.length; i++) {
			const row = rows[i];

			if (row) {
				const sourceId = row[1] ? row[1].value : "0";

				if (sourceId !== "Stored Procedure Id") {
					const twinStoredProcedureId = parseInt(sourceId, 10);
					const storedProcedureName = row[2] ? row[2].value : undefined;
					const twinId = row[3] ? parseInt(row[3].value, 10) : undefined;
					const twinClassId = row[4] ? parseInt(row[4].value, 10) : undefined;
					const twinNodeTemplateId = row[5] ? parseInt(row[5].value, 10) : undefined;
					const deleteFlag = row[6] ? row[6].value === "Yes" : false;

					if (twinStoredProcedureId) {
						if (deleteFlag) {
							deleteSPs.push(twinStoredProcedureId);
						} else {
							updatedSPs.push({
								storedProcedureName,
								twinClassId,
								twinId,
								twinNodeTemplateId,
								twinStoredProcedureId,
							});
						}
					} else {
						newSPs.push({
							storedProcedureName,
							twinClassId,
							twinId,
							twinNodeTemplateId,
							twinStoredProcedureId,
						});
					}
				}
			}
		}

		for (let i = 0; i < updatedSPs.length; i++) {
			const updatedSP = updatedSPs[i];

			for (let j = 0; j < sps.length; j++) {
				const sp = sps[i];

				if (sp.twinStoredProcedureId === updatedSP.twinStoredProcedureId) {
					if (sp.storedProcedureName !== updatedSP.storedProcedureName) {
						newSPs.push(updatedSP);
						break;
					}
				}
			}
		}

		let somethingChanged = false;
		if (deleteSPs.length > 0) {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: deleteCompleted,
				parameters: deleteSPs,
				requestId: "BatchDelete" + isoDate,
				requestType: "TwinStoredProcedureBatchDelete",
			};

			sendTwinHubRequest(requestPackage);
			somethingChanged = true;
		}

		if (newSPs.length > 0) {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: saveCompleted,
				parameters: newSPs,
				requestId: "BatchUpdate" + isoDate,
				requestType: "TwinStoredProcedureBatchUpdate",
			};

			sendTwinHubRequest(requestPackage);
			somethingChanged = true;
		}

		if (!somethingChanged) {
			dispatch(
				AddMessage({
					message: "No updated or added stored procedures found in the Excel spreadsheet.",
					type: "error",
				})
			);
		}
	}

	/**
	 * Called when the save completes.
	 */
	function saveCompleted () {
		dispatch(
			AddMessage({
				message: `${exportName} Updated`,
				type: "info",
			})
		);

		handleLoadSPs();
	}

	/**
	 * Called when the delete completes.
	 */
	function deleteCompleted () {
		dispatch(
			AddMessage({
				message: `${exportName} Deleted`,
				type: "info",
			})
		);

		handleLoadSPs();
	}

	if (sps == null) {
		return (
			<PleaseWait />
		);
	}

	return (
		<div>
			<p>
				{`${sps.length} Stored Procedure Found`}
			</p>
			<button
				className="button"
				onClick={handleExport}
			>
				Export Stored Procedures
			</button>
			<button
				className="button"
				onClick={handleImport}
			>
				Import Stored Procedures
			</button>
			<input
				accept="*.*"
				onChange={handleChangeFile}
				ref={fileBrowser}
				style={{
					display: "none",
				}}
				type="file"
			/>
		</div>
	);
};
