import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TwinStoredProcedureParameters } from "./components/TwinStoredProcedureParameters";
import { HubContext } from "../../MainScreen/MainScreen";
import { TwinStoredProcedureParameterModal } from "./components/TwinStoredProcedureParameterModal";
import { TwinStoredProcedureParameterType } from "../../../Types";

/**
 * The initial screen.
 */
export const TwinStoredProcedureParameterAdminScreen = () => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const id = urlParams.get("i") || "";
	const twinStoredProcedureId = parseInt(id, 10);
	const history = useHistory();

	const [ twinStoredProcedureParameters, setTwinStoredProcedureParameters ] = useState<Array<TwinStoredProcedureParameterType>>([]);
	const [ modalTwinStoredProcedureParameter, setModalTwinStoredProcedureParameter ] = useState<TwinStoredProcedureParameterType | null>(null);
	const { sendTwinHubRequest, } = useContext(HubContext);

	useEffect(() => {
		handleLoadTwinStoredProcedureParameters();
	}, []);

	/**
	 * Loads the twin stored procedures from the backend.
	 */
	function handleLoadTwinStoredProcedureParameters () {
		const isoDate = (new Date()).toISOString();

		const requestPackage = {
			onSuccess: (response: Array<TwinStoredProcedureParameterType>) => { setTwinStoredProcedureParameters(response); },
			parameters: {
				twinStoredProcedureId,
			},
			requestId: isoDate,
			requestType: "TwinStoredProcedureParameterGet",
		};
		sendTwinHubRequest(requestPackage);
	}

	return (
		<div className="container is-fluid" style={{ height: "100vh", }}>
			<section className="section px-6">
				<div className="level">
					<div className="level-left">
						<h1 className="title is-size-4 has-text-weight-medium">Twin Stored Procedure Parameter Manager</h1>
					</div>
					<div className="level-right">
						<a
							className="button is-icon has-tooltip-arrow"
							data-tooltip="Back"
							onClick={() => { history.goBack(); }}
						>
							<span className="icon">
								<i className="fas fa-arrow-left" aria-hidden="true" />
							</span>
						</a>
						<a
							className="button is-icon has-tooltip-arrow"
							data-tooltip="Create Stored Procedure"
							onClick={() => { setModalTwinStoredProcedureParameter({}); }}
						>
							<span className="icon">
								<i className="fas fa-plus" aria-hidden="true" />
							</span>
						</a>
					</div>
				</div>
			</section>
			<section className="section pt-0 px-6">
				<div className="has-shadow--small">
					<TwinStoredProcedureParameters
						onDelete={(twinStoredProcedureParameter: TwinStoredProcedureParameterType) => {
							const confirm = window.confirm("Permanently delete this stored procedure?");
							if (confirm) {
								const isoDate = (new Date()).toISOString();

								const requestPackage = {
									onSuccess: handleLoadTwinStoredProcedureParameters,
									parameters: {
										twinStoredProcedureParameterId: twinStoredProcedureParameter.twinStoredProcedureParameterId,
									},
									requestId: isoDate,
									requestType: "TwinStoredProcedureParameterDelete",
								};
								sendTwinHubRequest(requestPackage);
							}
						}}
						onEdit={(twinStoredProcedureParameter: TwinStoredProcedureParameterType) => { setModalTwinStoredProcedureParameter(twinStoredProcedureParameter); }}
						twinStoredProcedureParameters={twinStoredProcedureParameters}
					/>
				</div>
			</section>
			<div className={modalTwinStoredProcedureParameter ? "is-block" : "is-hidden"}>
				<TwinStoredProcedureParameterModal
					onClose={() => { setModalTwinStoredProcedureParameter(null); }}
					onReload={handleLoadTwinStoredProcedureParameters}
					sendTwinHubRequest={sendTwinHubRequest}
					twinStoredProcedureId={twinStoredProcedureId}
					twinStoredProcedureParameter={modalTwinStoredProcedureParameter}
				/>
			</div>
		</div>
	);
};
