import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Twins } from "./components/Twins";
import { HubContext } from "../../MainScreen/MainScreen";
import { TwinModal } from "./components/TwinModal";
import { TwinClassType, TwinType } from "../../../Types";

/**
 * The initial screen.
 */
export const TwinAdminScreen = () => {
	const [ twins, setTwins ] = useState<Array<TwinType>>([]);
	const [ classes, setClasses ] = useState<Array<TwinClassType>>([]);
	const [ modalTwin, setModalTwin ] = useState<TwinType | null>(null);
	const [ searchText, setSearchText ] = useState("");
	const [ searchClass, setSearchClass ] = useState(-1);
	const { sendTwinHubRequest, } = useContext(HubContext);
	const history = useHistory();

	useEffect(() => {
		const isoDate = (new Date()).toISOString();

		const requestPackage = {
			onSuccess: setClasses,
			requestId: "TwinClasses" + isoDate,
			requestType: "TwinClassGetAll",
		};
		sendTwinHubRequest(requestPackage);

		handleLoadTwins();
	}, []);

	/**
	 * Loads the twins from the backend.
	 */
	function handleLoadTwins () {
		const isoDate = (new Date()).toISOString();

		const requestPackage = {
			onSuccess: (response: Array<TwinType>) => { setTwins(response); },
			requestId: "Twins" + isoDate,
			requestType: "TwinGetAll",
		};
		sendTwinHubRequest(requestPackage);
	}

	const lowerSearch = searchText.toLowerCase();
	const visibleTwins = [];
	for (let i = 0; i < twins.length; i++) {
		const twin = twins[i];
		const { name, twinClassId, } = twin;
		if (name) {
			const lowerName = name.toLowerCase();

			if (lowerSearch && searchClass) {
				if ((!lowerSearch || lowerName.indexOf(lowerSearch) >= 0) && (searchClass === -1 || twinClassId === searchClass)) {
					visibleTwins.push(twin);
				}
			} else if (lowerSearch) {
				if (!lowerSearch || lowerName.indexOf(lowerSearch) >= 0) {
					visibleTwins.push(twin);
				}
			} else if (searchClass) {
				if (searchClass === -1 || twinClassId === searchClass) {
					visibleTwins.push(twin);
				}
			} else {
				visibleTwins.push(twin);
			}
		} else {
			visibleTwins.push(twin);
		}
	}

	return (
		<div className="container is-fluid is-scrollable">
			<section className="section px-6">
				<div className="level">
					<div className="level-left">
						<h1 className="title is-size-4 has-text-weight-medium">Twin Manager</h1>
					</div>
					<div className="level-right">
						<a
							className="button is-icon has-tooltip-arrow"
							data-tooltip="Create Twin"
							onClick={() => { setModalTwin({}); }}
						>
							<span className="icon">
								<i className="fas fa-plus" aria-hidden="true" />
							</span>
						</a>
						<a
							className="button is-icon has-tooltip-arrow"
							data-tooltip="Twin Classes"
							onClick={() => { history.push("/twinClass"); }}
						>
							<span className="icon">
								<i className="fas fa-graduation-cap" aria-hidden="true" />
							</span>
						</a>
						<a
							className="button is-icon has-tooltip-arrow"
							data-tooltip="Export/Import to Excel"
							onClick={() => { history.push("/twinExcel"); }}
						>
							<span className="icon">
								<i className="fas fa-file-excel" aria-hidden="true" />
							</span>
						</a>
					</div>
				</div>
			</section>
			<section className="section pt-0 pb-3 px-6">
				<div className="is-flex">
					<div>
						<div className="field">
							<label htmlFor="" className="label">Search</label>
							<div className="control">
								<input
									type="text"
									className="input"
									onChange={(event) => {
										if (!event) {
											return;
										}

										const { target, } = event;
										if (!target) {
											return;
										}

										setSearchText(target.value);
									}}
									placeholder="Search Names"
									value={searchText}
								/>
							</div>
						</div>
					</div>
					<div>
						<div className="field">
							<label htmlFor="" className="label">Class</label>
							<div className="control">
								<div className="select">
									<select
										onChange={(event) => {
											if (!event) {
												return;
											}

											const { target, } = event;
											if (!target) {
												return;
											}

											const numberValue = parseInt(target.value, 10);
											setSearchClass(numberValue);
										}}
										value={searchClass}
									>
										<option value={-1}>None</option>
										{classes && classes.length > 0 && classes.map((item, index) => {
											return <option key={index} value={item.twinClassId}>{item.name}</option>;
										})}
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="section pt-0 px-6">
				<div className="has-shadow--small">
					<Twins
						onDelete={(twin: TwinType) => {
							const confirm = window.confirm("Permanently delete this twin?");
							if (confirm) {
								const isoDate = (new Date()).toISOString();

								const requestPackage = {
									onSuccess: handleLoadTwins,
									parameters: {
										twinId: twin.twinId,
									},
									requestId: isoDate,
									requestType: "TwinDelete",
								};
								sendTwinHubRequest(requestPackage);
							}
						}}
						onEdit={(twin: TwinType) => { setModalTwin(twin); }}
						twins={visibleTwins}
					/>
				</div>
			</section>
			<div className={modalTwin ? "is-block" : "is-hidden"}>
				<TwinModal
					onClose={() => { setModalTwin(null); }}
					onReload={handleLoadTwins}
					sendTwinHubRequest={sendTwinHubRequest}
					twin={modalTwin}
					twins={twins}
					twinClasses={classes}
				/>
			</div>
		</div>
	);
};
