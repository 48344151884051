import React from "react";
import { TwinStoredProcedureType } from "../../../../Types";
import { TwinStoredProcedure } from "./TwinStoredProcedure";

type Props = {
	onDelete: Function,
	onEdit: Function,
	twinStoredProcedures: Array<TwinStoredProcedureType>,
}

/**
 * Displays a table of the twin stored procedures available.
 */
export const TwinStoredProcedures = (props: Props) => {
	const { onDelete, onEdit, twinStoredProcedures, } = props;

	/**
	 * Displays a row for each twin stored procedure.
	 */
	function eachTwinStoredProcedure (item: TwinStoredProcedureType, index: number) {
		return <TwinStoredProcedure key={index} item={item} storedProcedureName={item?.storedProcedureName} onDelete={onDelete} onEdit={onEdit} />;
	}

	return (
		<table className="table is-fullwidth is-hoverable">
			<thead>
				<tr>
					<th className="is-size-7 is-uppercase has-text-weight-semibold">Name</th>
					<th><span className="is-hidden">Actions</span></th>
				</tr>
			</thead>
			<tbody>
				{twinStoredProcedures && twinStoredProcedures.length > 0 && twinStoredProcedures.map(eachTwinStoredProcedure)}
			</tbody>
		</table>
	);
};
