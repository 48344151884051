import React, { useContext, useEffect, useRef, useState } from "react";
import { HubContext } from "../../../MainScreen/MainScreen";
import XLSX from "xlsx";
import FileSaver from "file-saver";
import { stringToBlob } from "../../../../functions/stringToBlob";
import { breakUpCellName } from "../../../../functions/breakUpCellName";
import { AddMessage } from "../../../../Redux/Slices/messageSlice";
import { useDispatch } from "react-redux";
import { PleaseWait } from "../../../../components/PleaseWait";
import { TwinStoredProcedureParameterType } from "../../../../Types";

/**
 * Exports/Imports stored procedure parameter information to Excel.
 */
export const TwinStoredProcedureParameters = () => {
	const exportName = "TwinParameters";
	const { sendTwinHubRequest, } = useContext(HubContext);
	const [ spps, setSPPs ] = useState<Array<TwinStoredProcedureParameterType>>([]);
	const fileBrowser = useRef(null);
	const dispatch = useDispatch();

	useEffect(() => {
		handleLoadSPPs();
	}, []);

	/**
	 * Loads the parameters from the backend.
	 */
	function handleLoadSPPs () {
		const isoDate = (new Date()).toISOString();

		const requestPackage = {
			onSuccess: setSPPs,
			requestId: "TwinStoredProcedureParameters" + isoDate,
			requestType: "TwinStoredProcedureParameterGetAll",
		};
		sendTwinHubRequest(requestPackage);
	}

	/**
	 * Exports the loaded parameters to Excel.
	 */
	function handleExport () {
		const currentDate = new Date();

		const wb = XLSX.utils.book_new();
		wb.Props = {
			Author: "Prediktor AS",
			CreatedDate: currentDate,
			Subject: exportName,
			Title: exportName,
		};

		wb.SheetNames.push(exportName);
		const wsData = [];
		wsData.push([ "Parameter Id", "Name", "Value", "Value Type", "Stored Procedure Id" ]);

		for (let i = 0; i < spps.length; i++) {
			const { twinStoredProcedureParameterId, name, value, valueType, twinStoredProcedureId, } = spps[i];

			wsData.push([ twinStoredProcedureParameterId, name, value, valueType, twinStoredProcedureId ]);
		}

		const ws = XLSX.utils.aoa_to_sheet(wsData);
		wb.Sheets[exportName] = ws;

		const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary", });
		FileSaver.saveAs(new Blob([ stringToBlob(wbout) ], { type: "application/octet-stream", }), `${exportName}_${currentDate.toLocaleDateString()}.xlsx`);
	}

	/**
	 * Imports a twin class file.
	 */
	function handleImport () {
		const current: any = fileBrowser?.current;
		if (current) {
			current.click();
		}
	}

	/**
	 * Called when the user selects a file.
	 */
	function handleChangeFile () {
		if (fileBrowser) {
			const current: any = fileBrowser?.current;

			if (current) {
				const { files, } = current;

				if (files && files.length > 0) {
					const file = files[0];
					const reader = new FileReader();

					reader.onloadend = function (e) {
						if (e.target) {
							fileLoaded(file.name, e.target.result);
						}
					};

					if (file) {
						reader.readAsArrayBuffer(file);
					}
				}
			}
		}
	}

	/**
	 * Called when the import file is loaded.
	 */
	function fileLoaded (fileName: string, fileContent: ArrayBuffer | string | null) {
		if (!fileContent || typeof fileContent === "string") {
			return;
		}

		const data = new Uint8Array(fileContent);
		const workbook = XLSX.read(data, { type: "array", });
		const Sheets = workbook.Sheets;

		const sppSheet = Sheets[exportName];
		const rows: Array<Array<{ columnNumber: number, value: string }>> = [];

		// eslint-disable-next-line no-unused-vars
		for (const cellName in sppSheet) {
			if (cellName.substring(0, 1) !== "!") {
				const inputCell = sppSheet[cellName];

				const { rowNumber, columnNumber, } = breakUpCellName(
					cellName
				);

				if (!rows[rowNumber]) {
					rows[rowNumber] = [];
				}

				rows[rowNumber][columnNumber] = {
					columnNumber,
					value: inputCell.v,
				};
			}
		}

		const newSPPs: Array<TwinStoredProcedureParameterType> = [];
		const updatedSPPs: Array<TwinStoredProcedureParameterType> = [];
		for (let i = 0; i < rows.length; i++) {
			const row = rows[i];

			if (row) {
				const sourceId = row[1] ? row[1].value : "0";

				if (sourceId !== "Parameter Id") {
					const twinStoredProcedureParameterId = parseInt(sourceId, 10);
					const name = row[2] ? row[2].value : undefined;
					const value = row[3] ? row[3].value : undefined;
					const valueType = row[4] ? row[4].value : undefined;
					const twinStoredProcedureId = row[5] ? parseInt(row[5].value, 10) : undefined;

					if (twinStoredProcedureParameterId) {
						updatedSPPs.push({
							name, twinStoredProcedureId, twinStoredProcedureParameterId, value, valueType,
						});
					} else {
						newSPPs.push({
							name, twinStoredProcedureId, twinStoredProcedureParameterId, value, valueType,
						});
					}
				}
			}
		}

		for (let i = 0; i < updatedSPPs.length; i++) {
			const updatedSPP = updatedSPPs[i];

			for (let j = 0; j < spps.length; j++) {
				const spp = spps[i];

				if (spp.twinStoredProcedureParameterId === updatedSPP.twinStoredProcedureParameterId) {
					if (spp.name !== updatedSPP.name || spp.value !== updatedSPP.value || spp.valueType !== updatedSPP.valueType) {
						newSPPs.push(updatedSPP);
						break;
					}
				}
			}
		}

		if (newSPPs.length > 0) {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: saveCompleted,
				parameters: newSPPs,
				requestId: isoDate,
				requestType: "TwinStoredProcedureParameterBatchUpdate",
			};

			sendTwinHubRequest(requestPackage);
		} else {
			dispatch(
				AddMessage({
					message: "No updated or added stored procedure parameters found in the Excel spreadsheet.",
					type: "error",
				})
			);
		}
	}

	/**
	 * Called when the save completes.
	 */
	function saveCompleted () {
		dispatch(
			AddMessage({
				message: `${exportName} Updated`,
				type: "info",
			})
		);

		handleLoadSPPs();
	}

	if (spps == null) {
		return (
			<PleaseWait />
		);
	}

	return (
		<div>
			<p>
				{`${spps.length} Stored Procedure Parameters Found`}
			</p>
			<button
				className="button"
				onClick={handleExport}
			>
				Export Stored Procedure Parameters
			</button>
			<button
				className="button"
				onClick={handleImport}
			>
				Import Stored Procedure Parameters
			</button>
			<input
				accept="*.*"
				onChange={handleChangeFile}
				ref={fileBrowser}
				style={{
					display: "none",
				}}
				type="file"
			/>
		</div>
	);
};
