/**
 * Takes a cell name and breaks it into the alpha and numeric parts.
 */
export function breakUpCellName (cellName: string) {
	const result = {
		columnNumber: 0,
		rowNumber: 0,
	};

	cellName = cellName.replace(/([a-zA-Z])/g, "$1,");
	const split = cellName.split(",");
	const columnString = split[0];
	result.rowNumber = parseInt(split[1], 10);

	const alphabet = [
		"a",
		"b",
		"c",
		"d",
		"e",
		"f",
		"g",
		"h",
		"i",
		"j",
		"k",
		"l",
		"m",
		"n",
		"o",
		"p",
		"q",
		"r",
		"s",
		"t",
		"u",
		"v",
		"w",
		"x",
		"y",
		"z"
	];
	for (let i = 0; i < columnString.length; i++) {
		const character = columnString.charAt(i).toLowerCase();
		const alphaIndex = alphabet.indexOf(character) + 1;

		result.columnNumber = i * 26 + alphaIndex;
	}

	return result;
};
