import React, { useEffect, useState } from "react";
import { TwinParameterType } from "../../../../Types";

type Props = {
	onClose: Function,
	onReload: Function,
	sendTwinHubRequest: Function,
	twinId: number,
	twinClassId?: number,
	twinNodeTemplateId?: number,
	twinParameter: object | null,
}

/**
 * Lets the user create a new twin parameter.
 */
export const TwinParameterModal = (props: Props) => {
	const {
		onClose,
		onReload,
		sendTwinHubRequest,
		twinId,
		twinClassId,
		twinNodeTemplateId,
		twinParameter,
	} = props;
	const [ editTwinParameter, setEditTwinParameter ] = useState<TwinParameterType>({
		name: "",
		twinClassId,
		twinId,
		twinNodeTemplateId,
		value: "",
	});

	useEffect(() => {
		if (twinParameter) {
			setEditTwinParameter(twinParameter);
		} else {
			setEditTwinParameter({
				name: "",
				twinClassId,
				twinId,
				twinNodeTemplateId,
				value: "",
			});
		}
	}, [ twinParameter ]);

	/**
	 * Adds a new Twin Parameter to the backend.
	 */
	function handleSave (close: Boolean) {
		if (editTwinParameter.twinParameterId) {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: close === true ? saveCompleted : blurCompleted,
				parameters: {
					...editTwinParameter,
					...{
						twinClassId,
						twinId,
						twinNodeTemplateId,
					},
				},
				requestId: isoDate,
				requestType: "TwinParameterUpdate",
			};

			sendTwinHubRequest(requestPackage);
		} else {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: close === true ? saveCompleted : blurCompleted,
				parameters: {
					...editTwinParameter,
					...{
						twinClassId,
						twinId,
						twinNodeTemplateId,
					},
				},
				requestId: isoDate,
				requestType: "TwinParameterAdd",
			};

			sendTwinHubRequest(requestPackage);
		}
	}

	/**
	 * Called when the add completes.
	 */
	function saveCompleted (newTwinParameter: TwinParameterType) {
		setEditTwinParameter({
			name: "",
			twinClassId,
			twinId,
			twinNodeTemplateId,
			value: "",
		});
		onReload();
		onClose();
	}

	/**
	 * Called when the blur of a field completes.
	 */
	function blurCompleted (newRecord: TwinParameterType) {
		setEditTwinParameter(newRecord);
	}

	return (
		<div className="modal is-active">
			<div className="modal-background">&nbsp;</div>
			<div className="modal-content">
				<div className="field">
					<label htmlFor="" className="label">Name</label>
					<div className="control">
						<input
							type="text"
							className="input"
							onChange={(event) => {
								if (!event) {
									return;
								}

								const { target, } = event;
								if (!target) {
									return;
								}

								setEditTwinParameter({
									...editTwinParameter,
									...{
										name: target.value,
									},
								});
							}}
							placeholder="Parameter Name"
							value={editTwinParameter.name}
						/>
					</div>
				</div>
				<div className="field">
					<label htmlFor="" className="label">Value</label>
					<div className="control">
						<input
							type="text"
							className="input"
							onChange={(event) => {
								if (!event) {
									return;
								}

								const { target, } = event;
								if (!target) {
									return;
								}

								setEditTwinParameter({
									...editTwinParameter,
									...{
										value: target.value,
									},
								});
							}}
							placeholder="Parameter Value"
							value={editTwinParameter.value}
						/>
					</div>
				</div>
				<button className="button" onClick={() => { handleSave(true); }}>Save &amp; Close</button>
			</div>
			<button className="modal-close is-large" aria-label="close" onClick={() => { onClose(); }} />
		</div>
	);
};
