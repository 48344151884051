import { configureStore } from "@reduxjs/toolkit";
import backendReducer from "./Slices/backendSlice";
import inputReducer from "./Slices/inputSlice";
import locationReducer from "./Slices/locationSlice";
import sideInfoReducer from "./Slices/sideInfoSlice";
import messageReducer from "./Slices/messageSlice";

export default configureStore({
	reducer: {
		backend: backendReducer,
		input: inputReducer,
		location: locationReducer,
		message: messageReducer,
		sideInfo: sideInfoReducer,
	},
});
