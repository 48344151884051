import React from "react";

type Props = {
    children: any,
    value?: string,
    metric?: string,
    description?: string,
};

// Will we use yr.no icons with the new weather service or do they have specific icons
// TODO:
/** Visual stats for yr weather symbols */
export const WeatherSymbolStats = (props: Props) => {
	const { children, value = "00", metric = "", description = "", } = props;

	return (
		<>
			<div className="has-text-centered">
				<figure className="image is-48x48 is-inline-block mb-2">
					{children}
				</figure>
				<h2 className="is-truncated">
					<span className="has-text-weight-semibold is-size-5 is-block has-line-height-1">
						{value}
						{value && (
							<small className="is-size-7 has-text-weight-light">
								&nbsp;&#176;{metric === "Imperial" ? "F" : "C"}
							</small>
						)}
					</span>
					<small>{description}&nbsp;</small>
				</h2>
			</div>
		</>
	);
};
