import { createSlice } from "@reduxjs/toolkit";
import { readLocalStorage, saveLocalStorageItem } from "./LocalStorage";

/**
 * Saves input and status data created by the user.
 */
const inputSlice = createSlice({
	initialState: {},
	name: "inputs",
	reducers: {
		loadInput (state, action) {
			for (const prop in action.payload) {
				state[prop] = action.payload[prop];
			}
		},
		resetInput (state, action) {
			state = {};
		},
		saveInput (state, action) {
			const { screenId, } = action.payload;

			if (screenId) {
				state[screenId] = action.payload;
			} else {
				for (const prop in action.payload) {
					state[prop] = action.payload[prop];
				}
			}
		},
	},
});

export const { loadInput, resetInput, saveInput, } = inputSlice.actions;

/**
 * Loads the input data from local storage when the app starts.
 */
export const LoadInput = () => async (dispatch) => {
	const session = readLocalStorage();
	dispatch(loadInput(session)); // load data from local storage
};

/**
 * Clears all of the input data.
 */
export const ResetInput = () => async (dispatch) => {
	dispatch(resetInput()); // save data to state
};

/**
 * Saves input data to local storage and Redux.
 * @param {*} inputData The input data object to save.
 */
export const SaveInput = (inputData) => async (dispatch) => {
	saveLocalStorageItem(inputData);
	dispatch(saveInput(inputData)); // save data to state
};

// Export reducers for use in store.js etc
export default inputSlice.reducer;
