import React from "react";
import { IconValueStats } from "../../../components/IconValueStats";
import { isNull, isNumber } from "../../../functions/UtilityFunctions";

/** Total production card */
export const TotalProduction = ({ value, }: { value: number }) => {
	let displayValue = "N/A";
	if (!isNull(value) && isNumber(value)) {
		displayValue = (value / 1000000).toFixed(1);
	}

	return (
		<div className="card flex-column is-full-height px-1 py-3">
			<IconValueStats
				value={displayValue}
				unit=" MWh"
				description="Total Production"
				icon="fas fa-bolt"
			/>
		</div>

	);
};
