import React from "react";
import { SiteType } from "../../../Types";
import { StartUpSiteCard } from "./StartUpSiteCard";

type Props = {
	sites: Array<SiteType>,
	cardIsClickable: boolean,
};

/** Display Region info */
export function SiteCardsGrid (props: Props) {
	const { sites, cardIsClickable, } = props;

	/**
	 * Returns a card for each twin
	 */
	function eachSite (item: SiteType, index: number) {
		return <StartUpSiteCard site={item} cardIsClickable={cardIsClickable} key={index} />;
	}

	return (
		<div className="grid is-8-fullhd is-5-widescreen is-4-desktop is-2-tablet is-1-mobile has-gap-small">
			{sites && sites.length > 0 && sites.map(eachSite)}
		</div>
	);
}
