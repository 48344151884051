import React from "react";
import { isNull } from "../functions/UtilityFunctions";

type Props = {
    icon?: string,
    value: string,
    unit: string,
    sup?: string,
    description: string,
};

/** Visual stats for solar specific weather data */
export const IconValueStats = (props: Props) => {
	const { icon, value, unit, sup, description, } = props;

	return (
		<div className="flex-column is-full-height">
			{icon &&
			<span className="icon is-larger has-text--grey--grey-dark m-auto">
				<i className={`${icon} is-size-1`} aria-hidden="true" />
			</span>}

			<h2 className="mt-auto">
				<span className="has-text-weight-semibold is-size-5 is-block has-line-height-1">
					{isNull(value) ? "N/A" : value}
					&nbsp;
					<small className="is-size-7 has-text-weight-light">
						{unit}
						{sup && <sup>{sup}</sup>}
					</small>
				</span>
				<small>{description}</small>
			</h2>
		</div>
	);
};
