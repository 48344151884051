import React from "react";
import { Forecast } from "./Forecast";
import { SiteData } from "./SiteData";
import { SiteImage } from "./SiteImage";
import { Location } from "./Location";
import { SiteType } from "../../../../Types";

/**
 * Displays a bar with site information.
 */
export const SiteInfoBar = (props: SiteType) => {
	return (
		<>
			<div className="columns is-hidden-touch is-variable m-2">
				<div className="column is-10 is-clipped p-0">
					<div className="is-flex has-line-height-1">
						<div className="mr-4">
							<figure className="image is-128x128 is-square">
								<SiteImage imageURL={props?.imageURL} />
							</figure>
						</div>
						<div className="is-flex is-flex-direction-column is-clipped mr-4">
							<Location
								country={props?.country}
								localTime={props?.localTime}
								location={props?.location}
								startedDate={props?.startedDate}
							/>
							<SiteData
								{...props}
							/>
						</div>
					</div>
				</div>
				<div className="column is-2 p-0">
					<Forecast
						annualCO2FootprintForecast={props?.annualCO2FootprintForecast}
						annualHouseholdsForecast={props?.annualHouseholdsForecast}
						annualYieldForecast={props?.annualYieldForecast}
					/>
				</div>
			</div>
			<div className="is-hidden-desktop mx-2 pb-2-touch">
				<div className="columns is-gapless my-2-tablet mb-0-mobile">
					<div className="column is-flex my-2-mobile">
						<div className="has-width-150-tablet has-width-50-mobile mr-4-tablet mr-2-mobile">
							<figure className="image is-square is-full-width">
								<SiteImage imageURL={props?.imageURL} />
							</figure>
						</div>
						<Location
							country={props?.country}
							localTime={props?.localTime}
							location={props?.location}
							startedDate={props?.startedDate}
						/>
					</div>
					<div className="column is-hidden-mobile is-narrow-tablet">
						<Forecast
							annualCO2FootprintForecast={props?.annualCO2FootprintForecast}
							annualHouseholdsForecast={props?.annualHouseholdsForecast}
							annualYieldForecast={props?.annualYieldForecast}
						/>
					</div>
				</div>
				<SiteData
					{...props}
				/>
				<div className="is-hidden-tablet">
					<Forecast
						annualCO2FootprintForecast={props?.annualCO2FootprintForecast}
						annualHouseholdsForecast={props?.annualHouseholdsForecast}
						annualYieldForecast={props?.annualYieldForecast}
					/>
				</div>
			</div>
		</>
	);
};
