import React from "react";
import { isNull, isNumber } from "../../../functions/UtilityFunctions";

type Props = {
	avg?: number,
	max?: number,
	min?: number,
}

/**
 * Displays boxes for average, max and min values.
 */
export const AvgMaxMinBoxes = (props: Props) => {
	const { avg, max, min, } = props;

	let displayAvg = "N/A";
	if (!isNull(avg) && isNumber(avg)) {
		displayAvg = avg.toFixed(1);
	}

	let displayMax = "N/A";
	if (!isNull(max) && isNumber(max)) {
		displayMax = max.toFixed(1);
	}

	let displayMin = "N/A";
	if (!isNull(min) && isNumber(min)) {
		displayMin = min.toFixed(1);
	}

	return (
		<div className="columns is-mobile is-variable is-1">
			<div className="column">
				<div className="is-relative py-2 has-background--white-ter--black-ter has-text-centered">
					<span className="icon is-small is-absolute is-top is-right">
						<i className="icon-average has-text-warning is-size-7" />
					</span>
					<h2>
						<span className="has-text-weight-semibold is-size-5 is-block has-line-height-1">
							{displayAvg}
						</span>
						<small>Average</small>
					</h2>
				</div>
			</div>
			<div className="column">
				<div className="is-relative py-2 has-background--white-ter--black-ter has-text-centered">
					<span className="icon is-small is-absolute is-top is-right">
						<i className="fas fa-long-arrow-alt-down has-text-danger is-size-7" />
					</span>
					<h2>
						<span className="has-text-weight-semibold is-size-5 is-block has-line-height-1">
							{displayMin}
						</span>
						<small>Min</small>
					</h2>
				</div>
			</div>
			<div className="column">
				<div className="is-relative py-2 has-background--white-ter--black-ter has-text-centered">
					<span className="icon is-small is-absolute is-top is-right">
						<i className="fas fa-long-arrow-alt-up has-text-success is-size-7" />
					</span>
					<h2>
						<span className="has-text-weight-semibold is-size-5 is-block has-line-height-1">
							{displayMax}
						</span>
						<small>Max</small>
					</h2>
				</div>
			</div>
		</div>
	);
};
