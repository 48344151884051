import React from "react";
import { roundNumber } from "../../../functions/UtilityFunctions";

type Props = {
	sites: Array<{
		name: string,
		value: number,
	}>,
	summaryPercentages: {
		high: number,
		low: number,
		middle: number,
	},
}

/** Displays performance card */
export const PerformanceCard = (props: Props) => {
	const { sites, summaryPercentages, } = props;
	const { high, low, middle, } = summaryPercentages;

	/** Checks if the value is within range or not
	 * returns obj with classname & height..
	 */
	function isInRange (value: number) {
		if (value > 85 && value <= 100) {
			return { class: "is--increased", height: value, };
		} else if (value < 70) {
			let height = Math.round((70 - value) * (100 / 70));
			if (height < 20) {
				height = 20;
			}
			return { class: "is--decreased", height: height, };
		} else if (value > 100 || value < 0) {
			// what to do with these ones?
			return { class: "is--outrange", height: 100, };
		} else if (value >= 70 && value <= 85) { // the value is between 70 - 85
			let height = Math.round(((value - 70) * 100 / (85 - 70)) / 2);
			if (height < 10) {
				height = 10;
			}
			return { class: "is--inrange", height: height, };
		} else {
			return { class: "is--not-available", height: 100, };
		}
	}

	return (
		<div className="card is-full-height">
			<header className="card-header">
				<h2 className="card-header-title is-uppercase is-size-7">Performance</h2>
			</header>
			<div className="card-content" style={{ height: "calc(100% - 42px)", }}>
				<div className="chart__performance flex-column is-full-height">
					<div className="columns is-mobile m-0 is-full-height">
						<div className="column is-9 p-0 flex-row">
							{
								sites.map((site, idx) => {
									const valueObj = isInRange(site?.value);
									return (
										<div className={`chart__performance-item ${valueObj?.class}`} key={"site-" + idx}>
											<div
												style={{ height: valueObj?.height + "%", }}
												className="has-tooltip-arrow has-tooltip-text-centered"
												data-tooltip={`${site.name + "\n"}PR: ${roundNumber(site?.value, 0)}%`}
											/>
										</div>
									);
								})
							}
						</div>
						<div className="column is-3 p-0 is-full-height has-text-weight-semibold">
							<div className="chart__performance-label">
								<span>
									<i className="icon-increase has-text-success has-text-vcentered mr-1" aria-label="Increased PR for all plants" />
									{roundNumber(high, 0) || 0}%
								</span>
							</div>
							<div className="chart__performance-label">
								<span>{roundNumber(middle, 0) || 0}%</span>
							</div>
							<div className="chart__performance-label">
								<span>
									<i className="icon-decrease has-text-danger has-text-vcentered mr-1" aria-label="Decreased PR for all plants" />
									{roundNumber(low, 0) || 0}%
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	);
};
