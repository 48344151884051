import React from "react";

type Props = {
	country?: string,
	localTime?: Date,
	location?: string,
	startedDate?: Date,
}

/**
 * Displays a card with Location and started at: information.
 */
export const Location = (props: Props) => {
	const started = props?.startedDate;
	const startedDate = started
		? new Date(started).toLocaleDateString()
		: "N/A";
	const name = props?.location + ", " + props?.country;
	const localTime = props?.localTime;

	return (
		<div className="is-full-width">
			<h2 className="is-size-4 is-size-5-mobile has-text-weight-semibold has-line-height-1 mb-2 my-1-mobile">
				{name}
			</h2>
			<small className="is-block is-size-7-mobile has-text-opacity--60 has-line-height-1 mt-1-mobile">
				Local time: {localTime}
				<i className="fas fa-circle is-size-11 has-text-opacity--20 has-text-vcentered mx-2" />
				Start of Operation: {startedDate}
			</small>
		</div>
	);
};
