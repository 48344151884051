import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TotalProduction } from "./components/TotalProduction";
import { KPIs } from "./components/KPIs";
import { InverterSummary } from "./components/InverterSummary";
import { ProductionChart } from "./components/ProductionChart";
import { BottomMiddleTabs } from "./components/BottomMiddleTabs";
import { StringSetOverview } from "./components/StringSetOverview";
import { roundNumber } from "../../functions/UtilityFunctions";
import { HubContext } from "../MainScreen/MainScreen";
import { IconValueStats } from "../../components/IconValueStats";
import { Gauge } from "../../components/Gauge";
import { AlarmList } from "../../components/AlarmList";
import { PeriodSelect } from "../../components/PeriodSelect";
import { ButtonExpand } from "../../components/ButtonExpand";
import { InverterType, RawInverterType, RawStringSetType, StringSetType } from "../../Types";

/**
 * Displays an overview of the string combiners and transformers for the inverter.
 */
export const SolarInverter = () => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const uaNodeId = urlParams.get("uaNodeId");
	const history = useHistory();
	const { sendTwinHubRequest, } = useContext(HubContext);

	const currentDate = new Date();
	const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours() - 24, currentDate.getMinutes(), currentDate.getSeconds());
	const [ productionChartTimes, setProductionChartTimes ] = useState({
		endTime: currentDate,
		startTime: startDate,
	});
	// const history = useHistory();
	const [ inverterDetails, setInverterDetails ] = useState<InverterType | null>(null);
	const [ stringSets, setStringSets ] = useState<Array<StringSetType> | null>(null);

	useEffect(() => {
		loadInverterInfo();
	}, [ uaNodeId ]);

	useEffect(() => {
		const interval = setInterval(() => {
			loadInverterInfo();
		}, 300000);
		return () => clearInterval(interval);
	}, []);

	/**
	 * Loads the inverter info from the backend.
	 */
	function loadInverterInfo () {
		if (uaNodeId) {
			const isoDate = (new Date()).toISOString();

			const enterpriseRequestPackage = {
				onSuccess: handleInverterLoaded,
				parameters: {
					twinNodeTemplateId: 7,
					uaNodeId,
				},
				requestId: "Inverter" + isoDate,
				requestType: "TwinNodeTemplateGet",
			};
			sendTwinHubRequest(enterpriseRequestPackage);
		}
	}

	/**
	 * Called when the site details are loaded.
	 */
	function handleInverterLoaded (response: RawInverterType) {
		if (!response) {
			return;
		}

		const { properties, uaNodeId, } = response;
		setInverterDetails({
			...properties,
			...{
				uaNodeId,
			},
		});

		const isoDate = (new Date()).toISOString();

		const siteRequestPackage = {
			onSuccess: handleStringSetsLoaded,
			parameters: {
				ancestorTwinNodeTemplateId: 7,
				ancestorUaNodeId: uaNodeId,
				restrictions: {
					rootOnly: true,
				},
				twinNodeTemplateId: 9,
			},
			requestId: "Sites" + isoDate,
			requestType: "TwinNodeTemplateGet",
		};
		sendTwinHubRequest(siteRequestPackage);
	}

	/**
	 * Called when the inverters are loaded.
	 */
	function handleStringSetsLoaded (response: Array<RawStringSetType>) {
		const newInverters = [];
		for (let i = 0; i < response.length; i++) {
			const newInverter = response[i];
			const { properties, uaNodeId, } = newInverter;

			newInverters.push({
				...properties,
				...{
					uaNodeId,
				},
			});
		}

		setStringSets(newInverters);
	}

	const name = inverterDetails?.nodeName;
	const acOutNominal = inverterDetails?.nominalACPower;
	const model = inverterDetails?.model;
	const parentName = inverterDetails?.parentName;
	const supplier = inverterDetails?.supplier;
	const totalDCJnNominal = inverterDetails?.nominalDCPower;
	const totalProduction = inverterDetails?.totalProduction;
	const type = inverterDetails?.deviceType;

	return (
		<div className="container my-4 mx-2-mobile">
			<section>
				<div className="columns is-variable is-1">
					<div className="column is-6">
						<div className="columns is-variable is-1 mb-0">
							<div className="column is-6">
								<InverterSummary
									siteName={parentName || "Unknown"}
									inverterName={name || "Unknown"}
									model={model || "Unknown"}
									supplier={supplier || "Unknown"}
									type={type || "Unknown"}
									ACOutNominal={acOutNominal || 0}
									ACOutNominalUnit="W"
									totalDCJnNominal={totalDCJnNominal || 0}
									totalDCJnNominalUnit="W"
									status={inverterDetails?.status || 0}
								/>
							</div>
							<div className="column is-6 pb-2">
								<KPIs signals={inverterDetails} />
							</div>
						</div>
						<div className="columns is-variable is-1">
							<div className="column is-6">
								<div className="columns is-mobile is-full-height is-gapless">
									<div className="column has-text-centered is-full-height mr-1">
										<div className="card flex-column is-full-height px-1 py-3">
											<div className="icon gauge--2 is-larger m-auto">
												<Gauge
													min={0}
													max={100}
													value={inverterDetails?.dCVoltage}
												/>
											</div>
											<IconValueStats
												value={roundNumber(inverterDetails?.dCVoltage || 0, 1).toString()}
												unit="V"
												description="DC Voltage"
											/>
										</div>
									</div>

									<div className="column has-text-centered is-full-height ml-1">
										<div className="card flex-column is-full-height px-1 py-3">
											<div className="icon gauge--2 is-larger m-auto">
												<Gauge
													min={0}
													max={100}
													value={inverterDetails?.activePower}
												/>
											</div>
											<IconValueStats
												value={roundNumber(inverterDetails?.aCActivePower || 0, 1).toString()}
												unit="W"
												description="AC Active Power"
											/>
										</div>
									</div>

								</div>
							</div>

							<div className="column is-6">
								<div className="columns is-mobile is-full-height is-gapless">
									<div className="column has-text-centered is-full-height mr-1">
										<div className="card flex-column is-full-height px-1 py-3">
											<div className="icon gauge--2 is-larger m-auto">
												<Gauge
													min={0}
													max={100}
													value={roundNumber(inverterDetails?.aCReactivePower || 0, 1)}
												/>
											</div>
											<IconValueStats
												value={roundNumber(inverterDetails?.aCReactivePower || 0, 1).toString()}
												unit="W"
												description="AC Reactive Power"
											/>
										</div>
									</div>
									<div className="column has-text-centered is-full-height ml-1">
										<TotalProduction value={totalProduction || 0} />
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="column is-6">
						<div className="card is-full-height">
							<header className="card-header pr-5">
								<h2 className="card-header-title is-uppercase is-size-7">Production history</h2>
								<div className="m-auto pr-3">
									<PeriodSelect
										classNames="is-smaller"
										onSelect={(startTime, endTime) => {
											setProductionChartTimes({
												endTime,
												startTime,
											});
										}}
										value="Last 24 hours"
									/>
								</div>

								<span className="is-absolute is-top is-right">
									<ButtonExpand onClick={() => { history.push(`/solarInverterProductionHistory?i=${uaNodeId}`); }} />
								</span>

							</header>
							<div className="card-content px-2" style={{ height: "calc(100% - 42px)", }}>
								<div className="chart is-full is-large-mobile">
									<ProductionChart
										id={uaNodeId || "Unknown"}
										startTime={productionChartTimes.startTime}
										endTime={productionChartTimes.endTime}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="columns">
					{stringSets && stringSets.length > 0 && (
						<div className="column is-8">
							<h2 className="is-flex is-size-5 has-text-weight-medium mb-3 pr-1">
								<span>
									<i className="icon-string is-size-6 mr-2" aria-hidden="true" />
									Strings
								</span>
								<span className="divider is-right ml-3">
									{stringSets.length}
								</span>
							</h2>

							<div className="columns is-variable is-1">
								<div className="column is-half">
									<div className="card is-full-height">
										<header className="card-header">
											<h2 className="card-header-title is-uppercase is-size-7">Status</h2>
											<span className="is-absolute is-top is-right">
												<ButtonExpand onClick={() => { history.push(`/solarInverterStringSets?i=${uaNodeId}`); }} />
											</span>
										</header>
										<div className="card-content px-4">
											<StringSetOverview
												stringSets={stringSets}
											/>
										</div>
									</div>
								</div>
								<div className="column is-half">
									<BottomMiddleTabs stringSets={stringSets} />
								</div>
							</div>

						</div>
					)}
					<div className="column is-4">
						<h2 className="divider is-left is-size-5 has-text-weight-medium mb-3">
							Alarms
						</h2>
						<div className="card" style={{ height: "calc(100% - 42px)", }}>
							<span className="is-absolute is-top is-right">
								<ButtonExpand onClick={() => { history.push(`/solarInverterAlarms?i=${uaNodeId}`); }} />
							</span>
							<AlarmList uaNodeIds={uaNodeId ? [ uaNodeId ] : []} />
						</div>
					</div>
				</div>

			</section>
		</div>
	);
};
