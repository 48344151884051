import React from "react";

type Props = {
	name?: string,
	value?: number,
}

/**
 * Displays a row for a signal.
 */
export const TwinSignal = (props: Props) => {
	const { name, value, } = props;

	return (
		<tr className="level--0">
			<td className="is-borderless">
				{name}
			</td>
			<td className="is-borderless">
				{value}
			</td>
		</tr>
	);
};
