import React, { useContext, useEffect, useState } from "react";
import { TwinNodeTemplates } from "./components/TwinNodeTemplates";
import { HubContext } from "../../MainScreen/MainScreen";
import { TwinNodeTemplateModal } from "./components/TwinNodeTemplateModal";
import { AvailableConnectionType, TwinNodeTemplateType } from "../../../Types";

/**
 * The initial screen.
 */
export const TwinNodeTemplateAdminScreen = () => {
	const [ templates, setTemplates ] = useState<Array<TwinNodeTemplateType>>([]);
	const [ availableConnections, setAvailableConnections ] = useState<Array<AvailableConnectionType>>([]);
	const [ modalTwinNodeTemplate, setModalTwinNodeTemplate ] = useState<TwinNodeTemplateType | null>(null);
	const [ searchText, setSearchText ] = useState("");
	const { sendApisHubRequest, sendTwinHubRequest, } = useContext(HubContext);

	useEffect(() => {
		const isoDate = (new Date()).toISOString();

		const requestPackage = {
			onSuccess: setTemplates,
			requestId: "TwinTemplates" + isoDate,
			requestType: "TwinNodeTemplateGetAll",
		};
		sendTwinHubRequest(requestPackage);

		handleLoadTwinNodeTemplates();
		handleLoadAvailableOPCServers();
	}, []);

	/**
	 * Loads the twin node templates from the backend.
	 */
	function handleLoadTwinNodeTemplates () {
		const isoDate = (new Date()).toISOString();

		const requestPackage = {
			onSuccess: (response: Array<TwinNodeTemplateType>) => { setTemplates(response); },
			requestId: "TwinNodeTemplates" + isoDate,
			requestType: "TwinNodeTemplateGetAll",
		};
		sendTwinHubRequest(requestPackage);
	}

	/**
	 * Loads the available OPC servers from the backend.
	 */
	function handleLoadAvailableOPCServers () {
		const isoDate = (new Date()).toISOString();

		const requestPackage = {
			onSuccess: (response: Array<AvailableConnectionType>) => { setAvailableConnections(response); },
			requestId: "AvailableOPCServers" + isoDate,
			requestType: "OpcUaGetConnections",
		};
		if (sendApisHubRequest) {
			sendApisHubRequest(requestPackage);
		}
	}

	const lowerSearch = searchText.toLowerCase();
	const visibleTemplates = [];
	for (let i = 0; i < templates.length; i++) {
		const twin = templates[i];
		const { name, } = twin;
		const lowerName = name ? name.toLowerCase() : "";

		if (!lowerSearch || lowerName.indexOf(lowerSearch) >= 0) {
			visibleTemplates.push(twin);
		}
	}

	return (
		<div className="container is-fluid" style={{ height: "100vh", }}>
			<section className="section px-6">
				<div className="level">
					<div className="level-left">
						<h1 className="title is-size-4 has-text-weight-medium">Twin Node Template Manager</h1>
					</div>
					<div className="level-right">
						<a
							className="button is-icon has-tooltip-arrow"
							data-tooltip="Create Twin"
							onClick={() => { setModalTwinNodeTemplate({}); }}
						>
							<span className="icon">
								<i className="fas fa-plus" aria-hidden="true" />
							</span>
						</a>
					</div>
				</div>
			</section>
			<section className="section pt-0 pb-3 px-6">
				<div className="is-flex">
					<div>
						<div className="field">
							<label htmlFor="" className="label">Search</label>
							<div className="control">
								<input
									type="text"
									className="input"
									onChange={(event) => {
										if (!event) {
											return;
										}

										const { target, } = event;
										if (!target) {
											return;
										}

										setSearchText(target.value);
									}}
									placeholder="Search Names"
									value={searchText}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="section pt-0 px-6">
				<div className="has-shadow--small">
					<TwinNodeTemplates
						onDelete={(template: TwinNodeTemplateType) => {
							const confirm = window.confirm("Permanently delete this template?");
							if (confirm) {
								const isoDate = (new Date()).toISOString();

								const requestPackage = {
									onSuccess: handleLoadTwinNodeTemplates,
									parameters: {
										twinNodeTemplateId: template.twinNodeTemplateId,
									},
									requestId: isoDate,
									requestType: "TwinNodeTemplateDelete",
								};
								sendTwinHubRequest(requestPackage);
							}
						}}
						onEdit={(twin: TwinNodeTemplateType) => { setModalTwinNodeTemplate(twin); }}
						twinNodeTemplates={templates}
					/>
				</div>
			</section>
			<div className={modalTwinNodeTemplate ? "is-block" : "is-hidden"}>
				<TwinNodeTemplateModal
					availableConnections={availableConnections}
					onClose={() => { setModalTwinNodeTemplate(null); }}
					onReload={handleLoadTwinNodeTemplates}
					sendTwinHubRequest={sendTwinHubRequest}
					twinNodeTemplate={modalTwinNodeTemplate}
					twinNodeTemplates={templates}
				/>
			</div>
		</div>
	);
};
