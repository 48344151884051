import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { TestLine } from "./components/TestLine";
import { HubContext } from "../MainScreen/MainScreen";

/**
 * A screen for automated testing of the backend API.
 */
export const APITesting = () => {
	const [ testLines, setTestLines ] = useState([
		{},
		{},
		{},
		{}
	]);
	const history = useHistory();
	const { sendApisHubRequest, } = useContext(HubContext);

	/**
	 * Reads a value from the API.
	 */
	function handleRead (item: object, index: number) {
		if (sendApisHubRequest) {
			const signalRRequestPackage = {
				onSuccess: handleReadSuccess,
				parameters: {
				},
				requestId: "ApiTestingRequest_Read",
				requestName: "ApiTestingRequest_Read",
			};

			sendApisHubRequest(signalRRequestPackage);
		}
	}

	/**
	 * Called when a value is read.
	 */
	function handleReadSuccess (responses: Array<any>) {
		console.log(responses);
	}

	/**
	 * Writes a value to the API.
	 */
	function handleWrite (item: object, index: number) {

	}

	/**
	 * Called when a value is written.
	 */
	function handleWriteSuccess (responses: Array<any>) {

	}

	/**
	 * Displays each signal and its value.
	 */
	function eachLine (item: object, index: number) {
		return (
			<TestLine
				key={index}
				item={item}
				onRead={() => handleRead(item, index)}
				onUpdate={(newItem: object) => {
					const newTestLines = testLines;
					newTestLines[index] = newItem;

					setTestLines(newTestLines);
				}}
				onWrite={() => handleWrite(item, index)}
			/>
		);
	}

	return (
		<div className="container is-fluid" style={{ height: "100vh", }}>
			<section className="section px-6">
				<div className="level">
					<div className="level-left">
						<h1 className="title is-size-4 has-text-weight-medium">Signal Tester</h1>
					</div>
					<div className="level-right">
						<a
							className="button is-icon has-tooltip-arrow"
							data-tooltip="Back"
							onClick={() => { history.goBack(); }}
						>
							<span className="icon">
								<i className="fas fa-arrow-left" aria-hidden="true" />
							</span>
						</a>
					</div>
				</div>
			</section>
			<section className="section pt-0 px-6">
				<div className="has-shadow--small">
					<table className="table is-fullwidth is-hoverable">
						<thead>
							<tr>
								<th className="is-size-7 is-uppercase has-text-weight-semibold">Server</th>
								<th className="is-size-7 is-uppercase has-text-weight-semibold">Signal Name</th>
								<th className="is-size-7 is-uppercase has-text-weight-semibold">Read</th>
								<th className="is-size-7 is-uppercase has-text-weight-semibold">Read Value</th>
								<th className="is-size-7 is-uppercase has-text-weight-semibold">Write Value</th>
								<th className="is-size-7 is-uppercase has-text-weight-semibold">Update</th>
							</tr>
						</thead>
						<tbody>
							{testLines && testLines.length > 0 && testLines.map(eachLine)}
						</tbody>
					</table>
				</div>
			</section>
		</div>
	);
};
