import React, { useContext, useEffect, useState } from "react";
import { HubContext } from "../../MainScreen/MainScreen";
import { useHistory } from "react-router-dom";
import { HistoryRow } from "./components/HistoryRow";
import { TwinNodeTemplateHistoryType } from "../../../Types";

/**
 * Attempts to fetch the history for a given node.
 */
export const TwinNodeTemplateHistoryTest = () => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const twinNodeTemplateIdString = urlParams.get("twinNodeTemplateId");
	const twinNodeTemplateId = twinNodeTemplateIdString ? parseInt(twinNodeTemplateIdString, 10) : null;
	const uaNodeId = urlParams.get("uaNodeId");
	const history = useHistory();

	const [ historyData, setHistoryData ] = useState < Array < TwinNodeTemplateHistoryType >>([]);
	const { sendTwinHubRequest, } = useContext(HubContext);

	useEffect(() => {
		loadHistoryData();
	}, []);

	/**
	 * Loads history data from the backend.
	 */
	function loadHistoryData () {
		if (twinNodeTemplateId && uaNodeId) {
			const endDate = new Date();
			const startDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endDate.getHours() - 1, endDate.getMinutes(), endDate.getSeconds());

			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: handleTwinHistory,
				parameters: {
					endTime: endDate.toISOString(),
					numValues: 100,
					startTime: startDate.toISOString(),
					twinNodeTemplateId,
					uaNodeId,
				},
				requestId: "TwinNodeTemplate" + isoDate,
				requestType: "TwinNodeTemplateGetHistory",
			};
			sendTwinHubRequest(requestPackage);
		}
	}

	/**
	 * Called when the history data is returned.
	 */
	function handleTwinHistory (response: {
		values: Array<TwinNodeTemplateHistoryType>,
	}) {
		const { values, } = response;

		setHistoryData(values);
	}

	/**
	 * Displays a row for each data point.
	 */
	function eachDatum (item: TwinNodeTemplateHistoryType, index: number) {
		return (
			<HistoryRow key={index} {...item} />
		);
	}

	return (
		<div className="container is-fluid" style={{ height: "100vh", }}>
			<section className="section px-6">
				<div className="level">
					<div className="level-left">
						<h1 className="title is-size-4 has-text-weight-medium">Twin Node Template History Tester</h1>
					</div>
					<div className="level-right">
						<a
							className="button is-icon has-tooltip-arrow"
							data-tooltip="Back"
							onClick={() => { history.goBack(); }}
						>
							<span className="icon">
								<i className="fas fa-arrow-left" aria-hidden="true" />
							</span>
						</a>
					</div>
				</div>
			</section>
			<section className="section pt-0 px-6">
				<div className="has-shadow--small">
					<table className="table is-fullwidth is-hoverable">
						<thead>
							<tr>
								<th className="is-size-7 is-uppercase has-text-weight-semibold">Value</th>
								<th className="is-size-7 is-uppercase has-text-weight-semibold">Timestamp</th>
								<th className="is-size-7 is-uppercase has-text-weight-semibold">Status</th>
							</tr>
						</thead>
						<tbody>
							{historyData && historyData.length > 0 && historyData.map(eachDatum)}
						</tbody>
					</table>
				</div>
			</section>
		</div>
	);
};
