import React from "react";
import { useHistory } from "react-router-dom";
import { getSolarStatusClass } from "../../../functions/StatusFunctions";
import { InverterSquare } from "./InverterSquare";
import { ButtonExpand } from "../../../components/ButtonExpand";
import { InverterType } from "../../../Types";

type Props = {
	inverters: Array<InverterType>,
	siteId: string,
}

/** Inverter Overview Screen */
export function InverterOverviewScreen (props: Props) {
	const { inverters, siteId, } = props;
	const history = useHistory();

	/** Takes status number as param and returns the bulma color name */
	function getStatusTooltip (statusNumber: number) {
		switch (statusNumber) {
			case 0:
				return "OK";
			case 1:
				return "Warning";
			case 2:
				return "Error";
			case 3:
				return "Idle";
			case 4:
				return "Communication Loss";
			case 5:
				return "Grounded";
			case 6:
				return "Statcom";
			default:
				return "Indeterminate";
		}
	}

	/** Redirects the user to inverter overview expand screen */
	function handleExpandClick (id: string) {
		history.push(`/solarSiteInverters?i=${siteId}`);
	}

	const tiles: Array<InverterType> = [];
	for (let i = 0; i < inverters.length; i++) {
		const inverter = inverters[i];
		const defaultInverterName = "I" + (i + 1);

		const signalShortName = inverter?.nodeName;
		const shortName = !signalShortName ? defaultInverterName : signalShortName;

		tiles.push({
			id: inverter?.nodeId,
			name: shortName,
			statusText: getSolarStatusClass(inverter?.status || 0),
			tooltip: getStatusTooltip(inverter?.status || 0),
		});
	}

	tiles.sort((a, b) => {
		const aName = a.name;
		const bName = b.name;

		if (!aName || !bName) {
			return 0;
		}

		if (aName < bName) {
			return -1;
		}

		return 1;
	});

	const showStatusHeader = inverters.length > 40;

	return (
		<div className="card is-full-height">
			<header className="card-header">
				<h2 className="card-header-title is-uppercase is-size-7">Status</h2>
				<span className="is-absolute is-top is-right">
					<ButtonExpand onClick={handleExpandClick} />
				</span>
			</header>

			{
				showStatusHeader &&
				<div className="is-relative" style={{ marginTop: "1px", }}>
					<div className="is-flex is-absolute has-background-blurred--white--black-bis is-full-width has-z-index-1">
						<div className="field has-addons py-2 mx-auto">
							<div className="control">
								<button type="button" className="button is-small has-tooltip-arrow" data-tooltip="OK">
									<span className="icon mr-1">
										<i className="fas fa-check has-text-success" />
									</span>
									<span className="has-text-weight-semibold is-size-6">5</span>
								</button>
							</div>

							<div className="control">
								<button type="button" className="button is-small has-tooltip-arrow" data-tooltip="Error">
									<span className="icon mr-1">
										<i className="fas fa-times has-text-danger" />
									</span>
									<span className="has-text-weight-semibold is-size-6">5</span>
								</button>
							</div>

							<div className="control">
								<button type="button" className="button is-small has-tooltip-arrow" data-tooltip="Warning">
									<span className="icon mr-1">
										<i className="fas fa-exclamation-triangle has-text-warning" />
									</span>
									<span className="has-text-weight-semibold is-size-6">5</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			}

			<div className="chart is-larger is-scrollable-y px-4 pt-6">
				<div className="grid is-4-fullhd is-3-widescreen is-3-desktop is-1-tablet is-2-mobile has-gap-smaller">
					{tiles && tiles.length > 0 && tiles.map((item: {
						id?: string,
						name?: string,
						status?: number
					}, index: number) => {
						const {
							id,
							name,
							status,
						} = item;

						return <InverterSquare key={index} id={id} name={name} status={status} />;
					})}
				</div>
			</div>
		</div>

	);
}
