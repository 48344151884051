import React from "react";
import { scaleUnitAndAmount } from "../../../functions/scaleUnitAndAmount";
import { formatDisplayNumber } from "../../../functions/formatDisplayNumber";

type Props = {
    ACOutNominal: number,
    ACOutNominalUnit: string,
    badgeColor?: string,
	badgeText?: string,
    name: string,
    siteLocation?: string,
    siteStatus?: string,
	siteStatusClass: string,
    time: Date,
    totalDCJnNominal: number,
    totalDCJnNominalUnit: string,
};

/** Visual card for site dashboard */
export const SiteCard = (props: Props) => {
	const {
		badgeColor,
		badgeText,
		name,
		time,
		siteLocation,
		siteStatus,
		siteStatusClass,
		ACOutNominal,
		ACOutNominalUnit,
		totalDCJnNominal,
		totalDCJnNominalUnit,
	} = props;

	const {
		amount: displayACOutNominal,
		unit: displayACOutNominalUnit,
	} = scaleUnitAndAmount(ACOutNominal, ACOutNominalUnit);
	const {
		amount: displayTotalDCJnNominal,
		unit: displayTotalDCJnNominalUnit,
	} = scaleUnitAndAmount(totalDCJnNominal, totalDCJnNominalUnit);

	return (
		<>
			<div className="card flex-column is-full-height">
				<div className="is-flex">
					<span
						title={badgeText || siteStatus}
						className={`tag ${siteStatusClass} is-small ml-3 mt-3 is-borderless is-shadowless has-text-weight-semibold px-2`}
						style={{
							backgroundColor: badgeColor,
						}}
					>
						<i
							className="fas fa-xs fa-check mr-1 has-text-vcentered"
							aria-hidden="true"
						/>
						<span className="has-text-vcentered">
							{badgeText || siteStatus}
						</span>
					</span>

					<div className="ml-auto">
						<small
							className="has-text--grey-dark--grey is-size-8 pt-2 pr-3"
						>
							<i className="mi mi-schedule has-text-vcentered mr-1" aria-hidden="true" />
							{time || "N/A"}
						</small>
					</div>
				</div>
				<div className="card-content px-0 has-line-height-1 has-text-centered mt-auto">
					<span className="icon is-large has-text--grey--grey-dark mb-2">
						<i
							className="icon-plant is-size-0"
							aria-hidden="true"
						/>
					</span>
					<h1>
						<span className="has-text-weight-semibold is-size-4 is-block mb-1">
							{name}
						</span>
						<span className="is-size-7 has-text-grey">
							{siteLocation}
						</span>
					</h1>
				</div>
				<footer className="is-size-7 px-4 py-4 mt-auto">
					<ul>
						<li className="is-flex">
							AC Output Nominal
							<span className="divider is-dashed is-right has-text-weight-semibold ml-3">
								{formatDisplayNumber(displayACOutNominal, 0) + " " + displayACOutNominalUnit}
							</span>
						</li>
						<li className="is-flex">
							Total DC Input Nominal
							<span className="divider is-dashed is-right has-text-weight-semibold ml-3">
								{formatDisplayNumber(displayTotalDCJnNominal, 0) + " " + displayTotalDCJnNominalUnit}
							</span>
						</li>
					</ul>
				</footer>
			</div>
		</>
	);
};
