import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FetchTwinChildDetails } from "../../../Redux/Slices/backendSlice";
import solarInverterFields from "../../../json/General/solarinverter";
import { BottomMiddleTabs } from "../components/BottomMiddleTabs";
import { ButtonExpand } from "../../../components/ButtonExpand";

/**
 * This dashboard displays details about a site/plant.
 */
export const SolarInverterBottomMiddleScreen = () => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const id = urlParams.get("i");

	const [ inverters, setInverters ] = useState([]);

	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		const interval = setInterval(() => {
			loadSiteInfo();
		}, 300000);
		return () => clearInterval(interval);
	}, []);

	/**
	 * Loads the site info from the backend.
	 */
	const loadSiteInfo = useCallback(() => {
		if (id && solarInverterFields) {
			dispatch(
				FetchTwinChildDetails({
					id,
					json: JSON.stringify(solarInverterFields),
					onSuccess: setInverters,
				})
			);
		}
	}, [ id, solarInverterFields ]);

	useEffect(() => {
		loadSiteInfo();
	}, [ id, loadSiteInfo ]);

	return (
		<section>
			<div className="columns">
				<div
					className="column"
					onClick={() => { history.goBack(); }}
					style={{ cursor: "pointer", }}
				>
					<i className="fas fa-arrow-left mr-3" style={{ float: "left", }} />
					<p style={{ float: "left", }}>Return</p>
				</div>
			</div>
			<div className="columns">
				<div className="column">
					<h2 className="is-flex is-size-5 has-text-weight-medium mb-3 pr-1">
						<span>
							<i className="icon-inverter is-size-6 mr-2" aria-hidden="true" />
							Inverters
						</span>
						<span className="divider is-right ml-3">
							{inverters.length}
						</span>
					</h2>

					<div className="columns is-variable is-1">
						<div className="column">
							<div className="card is-full-height">
								<header className="card-header">
									<h2 className="card-header-title is-uppercase is-size-7">Status</h2>
									<span className="is-absolute is-top is-right">
										<ButtonExpand onClick={() => { }} />
									</span>
								</header>
								<div className="card-content px-4">
									<BottomMiddleTabs stringSets={inverters} />
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
