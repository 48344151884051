import { isNull } from "./UtilityFunctions";

/**
 * Scales an amount down to maximum 4 significant digits.
 */
export function scaleUnitAndAmount (amount: number, baseUnit: string) {
	if (isNull(amount) || isNaN(amount) || !isFinite(amount)) {
		return {
			amount,
			unit: baseUnit,
		};
	}

	let newAmount = amount;
	let newUnit = baseUnit;

	while (newAmount.toFixed(0).length > 4) {
		const newValues = scaleDown(newAmount, newUnit);
		newAmount = newValues.amount;
		newUnit = newValues.unit;
	}

	const min = 0;
	let max = 10000;
	if (newAmount.toFixed(0).length === 1) {
		max = 10;
	} else if (newAmount.toFixed(0).length === 2) {
		max = 100;
	} else if (newAmount.toFixed(0).length === 3) {
		max = 1000;
	}

	return {
		amount: newAmount,
		max,
		min,
		unit: newUnit,
	};
}

/**
 * Scales down an amount and unit.
 */
function scaleDown (amount: number, unit: string) {
	let newAmount = 0;
	let newUnit = "";

	if (unit.indexOf("K") === 0) {
		newAmount = amount / 1000;
		newUnit = "M" + unit.substring(1, unit.length);
	} else if (unit.indexOf("M") === 0) {
		newAmount = amount / 1000;
		newUnit = "G" + unit.substring(1, unit.length);
	} else if (unit.indexOf("G") === 0) {
		newAmount = amount / 1000;
		newUnit = "T" + unit.substring(1, unit.length);
	} else {
		newAmount = amount / 1000;
		newUnit = "K" + unit;
	}

	return {
		amount: newAmount,
		unit: newUnit,
	};
}
