import React from "react";
import { useMsal } from "@azure/msal-react";

/**
 * Front page
 * @param {*} param0
 */
export function LoginScreen () {
	const { instance, } = useMsal();

	return (
		<div
			className="is-flex"
			style={{
				alignItems: "center",
				flexDirection: "column",
				height: "100vh",
				justifyContent: "center",
				width: "100vw",
			}}
		>
			<div style={{
				margin: "auto",
				paddingBottom: 50,
				width: 300,
			}}
			>
				<picture>
					<source
						media="(prefers-color-scheme: dark)"
						srcSet="./images/logo/powerview/prediktor_main.svg"
					/>
					<source
						media="(prefers-color-scheme: light), (prefers-color-scheme: no-preference)"
						srcSet="./images/logo/powerview/prediktor_main.svg"
					/>
					<img
						alt=""
						height="300"
						src="./images/logo/powerview/prediktor_main.svg"
						width="300"
					/>
				</picture>
				<button
					className="button is-primary is-large is-fullWidth is-outlined"
					onClick={() => { instance.loginRedirect(); }}
					style={{ margin: "auto", width: 300, }}
				>
					Login
				</button>
			</div>
		</div>
	);
}
