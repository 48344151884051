import React, { useContext, useEffect, useRef, useState } from "react";
import { HubContext } from "../../../MainScreen/MainScreen";
import XLSX from "xlsx";
import FileSaver from "file-saver";
import { stringToBlob } from "../../../../functions/stringToBlob";
import { breakUpCellName } from "../../../../functions/breakUpCellName";
import { AddMessage } from "../../../../Redux/Slices/messageSlice";
import { useDispatch } from "react-redux";
import { PleaseWait } from "../../../../components/PleaseWait";
import { TwinParameterType } from "../../../../Types";

/**
 * Exports/Imports twin parameter information to Excel.
 */
export const TwinParameters = () => {
	const exportName = "TwinParameters";
	const { sendTwinHubRequest, } = useContext(HubContext);
	const [ parameters, setParameters ] = useState<Array<TwinParameterType>>([]);
	const fileBrowser = useRef(null);
	const dispatch = useDispatch();

	useEffect(() => {
		handleLoadParameters();
	}, []);

	/**
	 * Loads the parameters from the backend.
	 */
	function handleLoadParameters () {
		const isoDate = (new Date()).toISOString();

		const requestPackage = {
			onSuccess: setParameters,
			requestId: "TwinParameters" + isoDate,
			requestType: "TwinParameterGetAll",
		};
		sendTwinHubRequest(requestPackage);
	}

	/**
	 * Exports the loaded parameters to Excel.
	 */
	function handleExport () {
		const currentDate = new Date();

		const wb = XLSX.utils.book_new();
		wb.Props = {
			Author: "Prediktor AS",
			CreatedDate: currentDate,
			Subject: exportName,
			Title: exportName,
		};

		wb.SheetNames.push(exportName);
		const wsData = [];
		wsData.push([ "Parameter Id", "Twin Id", "Twin Class Id", "Twin Node Template Id", "Name", "Value", "Delete" ]);

		for (let i = 0; i < parameters.length; i++) {
			const { twinId, twinClassId, twinNodeTemplateId, name, twinParameterId, value, } = parameters[i];

			wsData.push([ twinParameterId, twinId, twinClassId, twinNodeTemplateId, name, value, "No" ]);
		}

		const ws = XLSX.utils.aoa_to_sheet(wsData);
		wb.Sheets[exportName] = ws;

		const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary", });
		FileSaver.saveAs(new Blob([ stringToBlob(wbout) ], { type: "application/octet-stream", }), `${exportName}_${currentDate.toLocaleDateString()}.xlsx`);
	}

	/**
	 * Imports a twin file.
	 */
	function handleImport () {
		const current: any = fileBrowser?.current;
		if (current) {
			current.click();
		}
	}

	/**
	 * Called when the user selects a file.
	 */
	function handleChangeFile () {
		if (fileBrowser) {
			const current: any = fileBrowser?.current;

			if (current) {
				const { files, } = current;

				if (files && files.length > 0) {
					const file = files[0];
					const reader = new FileReader();

					reader.onloadend = function (e) {
						if (e.target) {
							fileLoaded(file.name, e.target.result);
						}
					};

					if (file) {
						reader.readAsArrayBuffer(file);
					}
				}
			}
		}
	}

	/**
	 * Called when the import file is loaded.
	 */
	function fileLoaded (fileName: string, fileContent: ArrayBuffer | string | null) {
		if (!fileContent || typeof fileContent === "string") {
			return;
		}

		const data = new Uint8Array(fileContent);
		const workbook = XLSX.read(data, { type: "array", });
		const Sheets = workbook.Sheets;

		const parameterSheet = Sheets[exportName];
		const rows: Array<Array<{ columnNumber: number, value: string }>> = [];

		// eslint-disable-next-line no-unused-vars
		for (const cellName in parameterSheet) {
			if (cellName.substring(0, 1) !== "!") {
				const inputCell = parameterSheet[cellName];

				const { rowNumber, columnNumber, } = breakUpCellName(
					cellName
				);

				if (!rows[rowNumber]) {
					rows[rowNumber] = [];
				}

				rows[rowNumber][columnNumber] = {
					columnNumber,
					value: inputCell.v,
				};
			}
		}

		const deleteParameters: Array<number> = [];
		const newParameters: Array<TwinParameterType> = [];
		const updatedParameters: Array<TwinParameterType> = [];
		for (let i = 0; i < rows.length; i++) {
			const row = rows[i];

			if (row) {
				const sourceId = row[1] ? row[1].value : "0";
				if (sourceId !== "Parameter Id") {
					const twinParameterId = parseInt(sourceId, 10);
					const twinId = row[2] ? parseInt(row[2].value, 10) : undefined;
					const twinClassId = row[3] ? parseInt(row[3].value, 10) : undefined;
					const twinNodeTemplateId = row[4] ? parseInt(row[4].value, 10) : undefined;
					const name = row[5] ? row[5].value : undefined;
					const value = row[6] ? row[6].value : undefined;
					const deleteFlag = row[7] ? row[7].value === "Yes" : false;

					if (twinParameterId) {
						if (deleteFlag) {
							deleteParameters.push(twinParameterId);
						} else {
							updatedParameters.push({
								name,
								twinClassId,
								twinId,
								twinNodeTemplateId,
								twinParameterId,
								value: value ? value.toString() : undefined,
							});
						}
					} else {
						newParameters.push({
							name,
							twinClassId,
							twinId,
							twinNodeTemplateId,
							twinParameterId,
							value: value ? value.toString() : undefined,
						});
					}
				}
			}
		}

		for (let i = 0; i < updatedParameters.length; i++) {
			const updatedParameter: TwinParameterType = updatedParameters[i];

			for (let j = 0; j < parameters.length; j++) {
				const parameter = parameters[i];

				if (parameter.twinParameterId === updatedParameter.twinParameterId) {
					if (parameter.name !== updatedParameter.name || parameter.value !== updatedParameter.value) {
						newParameters.push(updatedParameter);
						break;
					}
				}
			}
		}

		let somethingChanged = false;
		if (deleteParameters.length > 0) {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: deleteCompleted,
				parameters: deleteParameters,
				requestId: "BatchDelete" + isoDate,
				requestType: "TwinParameterBatchDelete",
			};

			sendTwinHubRequest(requestPackage);
			somethingChanged = true;
		}

		if (newParameters.length > 0) {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: saveCompleted,
				parameters: newParameters,
				requestId: "BatchUpdate" + isoDate,
				requestType: "TwinParameterBatchUpdate",
			};

			sendTwinHubRequest(requestPackage);
			somethingChanged = true;
		}

		if (!somethingChanged) {
			dispatch(
				AddMessage({
					message: "No updated or added parameters found in the Excel spreadsheet.",
					type: "error",
				})
			);
		}
	}

	/**
	 * Called when the save completes.
	 */
	function saveCompleted () {
		dispatch(
			AddMessage({
				message: `${exportName} Updated`,
				type: "info",
			})
		);

		handleLoadParameters();
	}

	/**
	 * Called when the delete completes.
	 */
	function deleteCompleted () {
		dispatch(
			AddMessage({
				message: `${exportName} Deleted`,
				type: "info",
			})
		);

		handleLoadParameters();
	}

	if (parameters == null) {
		return (
			<PleaseWait />
		);
	}

	return (
		<div>
			<p>
				{`${parameters.length} Parameters Found`}
			</p>
			<button
				className="button"
				onClick={handleExport}
			>
				Export Parameters
			</button>
			<button
				className="button"
				onClick={handleImport}
			>
				Import Parameters
			</button>
			<input
				accept="*.*"
				onChange={handleChangeFile}
				ref={fileBrowser}
				style={{
					display: "none",
				}}
				type="file"
			/>
		</div>
	);
};
