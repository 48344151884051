import React from "react";

type Props = {
	gridAlarm?: number,
	gridAlarmTooltip?: string,
	inverterAlarm?: number,
	inverterAlarmTooltip?: string,
	stringAlarm?: number,
	stringAlarmTooltip?: string,
	trackerAlarm?: number,
	trackerAlarmTooltip?: string,
}

/** */
export const SiteProduction = (props: Props) => {
	return (
		<div className="tags are-small is-flex-wrap-nowrap">
			<div className={props?.gridAlarm ? "tag is-warning has-tooltip-arrow px-1" : "is-hidden"} data-tooltip={props?.gridAlarmTooltip}>
				<i className="icon-grid is-size-6 mr-1" aria-hidden="true" />
				<span className="has-text-weight-bold">{props?.gridAlarm}</span>
			</div>
			<div className={props?.trackerAlarm ? "tag is-danger has-tooltip-arrow px-1" : "is-hidden"} data-tooltip={props?.trackerAlarmTooltip}>
				<i className="icon-tracker is-size-6 mr-1" aria-hidden="true" />
				<span className="has-text-weight-bold">{props?.trackerAlarm}</span>
			</div>
			<div className={props?.inverterAlarm ? "tag is-danger has-tooltip-arrow px-1" : "is-hidden"} data-tooltip={props?.inverterAlarmTooltip}>
				<i className="icon-inverter-outlined is-size-6 mr-1" aria-hidden="true" />
				<span className="has-text-weight-bold">{props?.inverterAlarm}</span>
			</div>
			<div className={props?.stringAlarm ? "tag is-danger has-tooltip-arrow px-1" : "is-hidden"} data-tooltip={props?.stringAlarmTooltip}>
				<i className="icon-string is-size-6 mr-1" aria-hidden="true" />
				<span className="has-text-weight-bold">{props?.stringAlarm}</span>
			</div>
		</div>
	);
};
