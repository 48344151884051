import React from "react";
import Chart from "react-apexcharts";

type Props = {
    onToggle: Function,
    series: {
		name: string,
	},
    visible: boolean,
};

/**
 * Displays a miniature graph for each legend item.
 */
export const Legend = (props: Props) => {
	const { onToggle, series, visible, } = props;

	const legendOptions = {
		chart: {
			animations: {
				enabled: true,
			},
			// background: "transparent",
			height: 350,
			sparkline: {
				enabled: true,
			},
			toolbar: {
				show: false,
			},
			type: "line",
		},
		dataLabels: {
			enabled: false,
		},
		fill: {
			opacity: 1,
		},
		grid: {
			show: false,
		},
		legend: {
			show: false,
		},
		plotOptions: {
			bar: {
				columnWidth: "55%",
				horizontal: false,
			},
		},
		stroke: {
			show: true,
			width: 2,
		},
		theme: {
			monochrome: {
				color: "#0F5CAF",
				enabled: true,
				shadeIntensity: 0.65,
				shadeTo: "light",
			},
		},
		tooltip: {
			show: false,
		},
		xaxis: {
			labels: {
				show: false,
			},
			show: false,
		},
		yaxis: {
			show: false,
		},
	};

	return (
		<div
			style={{
				height: "100%",
				position: "relative",
				width: "100%",
			}}
		>
			<div
				className="mixed-chart"
				style={{
					display: "grid",
					gridTemplateRows: "1fr auto",
					height: "100%",
					position: "absolute",
					width: "100%",
					zIndex: 0,
				}}
			>
				<label
					style={{
						height: "100%",
						textAlign: "center",
						width: "100%",
					}}
				>
					{series.name}
				</label>
				<Chart
					// className="mixed-chart--graph"
					height="65%"
					options={legendOptions}
					series={[ series ]}
					width="100%"
				/>
			</div>
			<div
				className={visible ? "mixed-chart--item is-active" : "mixed-chart--item"}
				onClick={() => {
					onToggle(series.name);
				}}
				style={{
					cursor: "pointer",
					height: "100%",
					position: "absolute",
					width: "100%",
					zIndex: 1000,
				}}
			/>
		</div>
	);
};
