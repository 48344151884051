import React from "react";
import { SiteType } from "../../../Types";
import { RegionInfo } from "./RegionInfo";
import { SiteCardsGrid } from "./SiteCardsGrid";

type Props = {
	sites: Array<SiteType>,
    name: string,
	cardIsClickable: boolean,
};

/** Display Site cards by region */
export function SiteCardsByRegion (props: Props) {
	const { sites, name, cardIsClickable, } = props;
	return (
		<section className="mb-4">
			<RegionInfo name={name} sitesNumber={sites.length} />
			<SiteCardsGrid cardIsClickable={cardIsClickable} sites={sites} />
		</section>
	);
}
