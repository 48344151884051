import React from "react";
import { formatDisplayNumber } from "../../../functions/formatDisplayNumber";

type Props = {
	value: number,
	prTarget: number,
}

/** */
export const SitePR = (props: Props) => {
	const { value, prTarget, } = props;

	return (
		<td className="is-relative has-text-right">
			{value > prTarget &&
				<span className="icon has-text-success is-vcentered is-pulled-left mr-2">
					<i className="icon-increase is-size-5" aria-hidden="true" />
				</span>}
			{value < prTarget - 5 &&
				<span className="icon has-text-danger is-vcentered mr-2 is-pulled-left">
					<i className="icon-decrease is-size-5" aria-hidden="true" />
				</span>}
			{(value === prTarget) && <span className="has-width-50 has-background-stripes is-absolute is-top is-left is-full-height" />}
			{formatDisplayNumber(value) + " %"}
		</td>
	);
};
