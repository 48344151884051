import React from "react";
import { ValueUnit } from "./ValueUnit";

type Props = {
	totalACCapacity?: number,
}

/**
 * The total capacity
 */
export const TotalCapacity = (props: Props) => {
	const totalCapacity = props?.totalACCapacity;
	const totalCapacityUnit = "MWp";
	const name = "Installed Capacity";

	return (
		<div className="is-flex is-align-items-center">
			<span className="icon is-large is-rounded has-text-theme--1 has-background-theme--2 mr-3">
				<i className="icon-capacity is-size-4" aria-hidden="true" />
			</span>
			<ValueUnit value={totalCapacity || 0} uom={totalCapacityUnit} name={name} numberOfDecimals={1} />
		</div>
	);
};
