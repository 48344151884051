import React from "react";
import { TwinNodeTemplateSignal } from "./TwinNodeTemplateSignal";
import { useHistory } from "react-router-dom";
import { TwinNodeTemplateType, TwinSignalType } from "../../../../Types";

type Props = {
	childTemplates: Array<TwinNodeTemplateType>,
	nodeId?: string,
	signals?: Array<TwinSignalType>,
}

/**
 * Displays a section for each twin.
 */
export const TwinNodeTemplateRow = (props: Props) => {
	const { childTemplates, nodeId, signals, } = props;
	const history = useHistory();

	/**
	 * Displays each signal and its value.
	 */
	function eachTwinSignal (item: TwinSignalType, index: number) {
		const {
			name,
			value,
		} = item;

		return (
			<TwinNodeTemplateSignal key={index} name={name} value={value} />
		);
	}

	return (
		<>
			<section className="section pt-0 px-6">
				<div className="has-shadow--small">
					<table className="table is-fullwidth is-hoverable">
						<thead>
							<tr>
								<th className="is-size-7 is-uppercase has-text-weight-semibold">Name</th>
								<th><span className="is-hidden">Value</span></th>
							</tr>
						</thead>
						<tbody>
							{signals && signals.length > 0 && signals.map(eachTwinSignal)}
						</tbody>
					</table>
				</div>
			</section>
			<section className={childTemplates && childTemplates.length > 0 ? "section pt-0 px-6" : "is-hidden"}>
				<h1 className="title is-size-4 has-text-weight-medium">Children</h1>
				{childTemplates && childTemplates.length > 0 && childTemplates.map((item, index) => {
					return (
						<div
							className="is-clickable"
							key={index}
							onClick={() => { history.push(`/twinNodeTemplateSignalTest?twinNodeTemplateId=${item.twinNodeTemplateId}&parentUaNodeId=${nodeId}`); }}
						>
							{item.name}
						</div>
					);
				})}
			</section>
		</>
	);
};
