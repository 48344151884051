import React from "react";

type Props = {
	mode: string,
	style: string,
}

/**
 * Loads the stylesheet based on the parameters specified.
 */
export const StyleLoader = (props: Props) => {
	const { mode, style, } = props;

	const basename = window.REACT_APP_BASE_NAME ? window.REACT_APP_BASE_NAME + "/" : "";
	const styles: any = {
		default: {
			branding: "prediktor",
			url: "/css/branding",
		},
		scatec: {
			branding: "scatec",
			url: "/css/branding",
		},
	};

	const css = styles[style || "default"];
	let cssFileUrl = `${css.url}/${css.branding}/${css.branding}-${mode || "dark"}.css`;
	if (basename) {
		cssFileUrl = `${basename}${css.url}/${css.branding}/${css.branding}-${mode || "dark"}.css`;
	}
	cssFileUrl += "?" + 1;

	return (
		<link rel="stylesheet" type="text/css" href={cssFileUrl} />
	);
};
