import React from "react";
import { DoubleCircularProgressBar } from "../../DoubleCircularProgressBar";
type Props = {
    innerPercentage: number,
    outerPercentage: number,
};

/** Display double circular progress bar in a card */
export const DoubleCircularCard = (props: Props) => {
	const { innerPercentage, outerPercentage, } = props;
	return (
		<>
			<div className="card is-full-height">
				<header className="card-header">
					<h2 className="card-header-title is-uppercase is-size-7">
						Communication
					</h2>
				</header>
				<div className="card-content">
					<div className="columns">
						<div className="column is-flex justify-center">
							<DoubleCircularProgressBar
								innerPercentage={innerPercentage}
								outerPercentage={outerPercentage}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
