import { createSlice } from "@reduxjs/toolkit";

/**
 * Saves input and status data created by the user.
 */
const messageSlice = createSlice({
	initialState: {},
	name: "inputs",
	reducers: {
		addMessage (state, action) {
			if (!state.messages) {
				state.messages = [];
			}

			state.messages.push(action.payload);
		},
	},
});

export const { addMessage, } = messageSlice.actions;

/**
 * Displays a toast message to the user.
 * @param {*} message The message to display.
 */
export const AddMessage = ({
	message,
	type,
}: {
	message: String,
	type: String,
}) => async (dispatch) => {
	const currentTime = new Date();

	dispatch(addMessage({
		datetime: currentTime.toISOString(),
		message,
		type,
	}));
};

// Export reducers for use in store.js etc
export default messageSlice.reducer;
