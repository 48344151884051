import React, { useContext } from "react";
import { StyleContext } from "../../MainScreen/MainScreen";

/**
 * The Powered by Pv Info
 */
export const PoweredBy = () => {
	const {
		mode,
	} = useContext(StyleContext);

	return (
		<div className="is-full-width is-absolute is-right is-bottom is-flex is-justify-content-flex-end is-size-7 is-size-8-mobile has-line-height-1 has-text-opacity--60 px-3 pb-1" style={{ zIndex: 2, }}>
			<span className="pt-2">Powered by</span>
			<img
				className="image ml-1 has-height-20"
				alt="Prediktor Logo"
				src={mode === "light" ? "Images/logo/prediktor/main.svg" : "Images/logo/prediktor/main--negative.svg"}
			/>
		</div>
	);
};
