import React from "react";
import { WeatherListStats } from "../../WeatherListStats";
import { WeatherSymbolStats } from "../../WeatherSymbolStats";
import { IconValueStats } from "../../IconValueStats";
import { formatDisplayNumber } from "../../../functions/formatDisplayNumber";

type Props = {
	ambientTemperature?: number,
	horizontalIrradiation?: number,
	rainAmount?: number,
	symbolDescription?: string,
	weatherRelativeHumidity?: number,
	weatherSymbolNumber?: number,
	windSpeed?: number,
}

/**
 * Displays a card with weather information.
 */
export const WeatherCard = (props: Props) => {
	const symbolDescription = props?.symbolDescription;
	const wind = formatDisplayNumber(props?.windSpeed);
	const windUnit = "m/s";
	const rain = props?.rainAmount;
	const rainUnit = "mm/s";
	const humidity = props?.weatherRelativeHumidity;
	const humidityUnit = "%";
	const temperature = formatDisplayNumber(props?.ambientTemperature);
	const irradiance = formatDisplayNumber(props?.horizontalIrradiation);

	const symbol = props?.weatherSymbolNumber || 0;
	let symbolUrl = "";

	try {
		if (symbol >= 10) {
			symbolUrl = symbol ? require(`./../../../assets/images/yr/svg/${symbol}d.svg`).default : "";
		} else {
			symbolUrl = symbol ? require(`./../../../assets/images/yr/svg/0${symbol}d.svg`).default : "";
		}
	} catch (err) {
		try {
			if (symbol >= 10) {
				symbolUrl = symbol ? require(`./../../../assets/images/yr/svg/${symbol}.svg`).default : "";
			} else {
				symbolUrl = symbol ? require(`./../../../assets/images/yr/svg/0${symbol}.svg`).default : "";
			}
		} catch (err) {}
	}

	return (
		<>
			<div className="card flex-column is-full-height px-3">
				<div className="columns is-mobile is-full-height m-0">
					<div className="column is-4 flex-column is-full-height px-0">
						<h2 className="has-text-weight-bold is-uppercase is-size-7">Weather</h2>
						<div className="mt-auto">
							<WeatherListStats
								wind={wind}
								wUnit={windUnit}
								rain={rain || 0}
								rUnit={rainUnit}
								humidity={humidity || 0}
								hUnit={humidityUnit}
							/>
						</div>
					</div>

					<div className="column is-4 mt-auto px-1 py-3">
						<WeatherSymbolStats
							value={temperature}
							description={symbolDescription || ""}
						>
							{symbolUrl !== "" && <img alt={symbolDescription} src={symbolUrl} title={symbolDescription} />}
						</WeatherSymbolStats>
					</div>

					<div className="column is-4 mt-auto has-text-centered px-1 py-3">
						<IconValueStats
							value={irradiance}
							unit="W/m²"
							description="Irradiance"
							icon="icon-solar-irradiance"
						/>
					</div>
				</div>
			</div>
		</>
	);
};
