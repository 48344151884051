import React from "react";

type Props = {
	numberOfPlants: Number,
}

/** Card displaying number of plants */
export const PlantCard = (props: Props) => {
	const { numberOfPlants, } = props;

	return (
		<div className="card is-full-height flex-column">
			<div className="card-content has-text-centered has-line-height-1 my-auto px-0">
				<span className="icon is-large has-text--grey--grey-dark">
					<i className="icon-plant is-size-0" aria-hidden="true" />
				</span>
				<h2 className="has-text-weight-semibold">
					<span className="is-size-3 is-block mb-2">{numberOfPlants}</span>
					<span className="is-uppercase is-size-7 has-text-weight-bold is-block">Plants</span>
				</h2>
			</div>
		</div>
	);
};
