import React from "react";
import { MixedLineAndBarChart } from "../../../components/MixedLineAndBarChart/MixedLineAndBarChart";
import { formatDisplayNumber } from "../../../functions/formatDisplayNumber";

type Props = {
	lifetimeProductionActual: Array<Number>,
	lifetimeProductionActivePower: Number,
}

/**
 * Displays the monthly information in the lobby.
 */
export const ProductionLifetimeLobby = (props: Props) => {
	const {
		lifetimeProductionActual,
		lifetimeProductionActivePower,
	} = props;

	const fixedLifetimeProductionActivePower = formatDisplayNumber(lifetimeProductionActivePower);

	const labels = [];
	const actData = [];
	if (lifetimeProductionActual) {
		for (let i = 0; i < lifetimeProductionActual.length; i++) {
			const time = new Date(lifetimeProductionActual[i].time);

			const actValue = lifetimeProductionActual[i].value ? lifetimeProductionActual[i].value / 1000000 : 0;

			labels.push(time.getFullYear());
			actData.push(actValue);
		}
	}

	return (
		<div className="card flex-column is-full-height">
			<header className="card-header">
				<h2 className="card-header-title is-uppercase is-size-7">Lifetime Production</h2>
			</header>
			<div className="card-content py-1">
				<div className="chart is-large">
					<MixedLineAndBarChart
						labels={labels}
						series={[
							{
								axisIndex: 0,
								data: actData,
								hasLegend: false,
								name: "Actual",
								numberOfDecimals: 1,
								unit: "MWh",
							}
						]}
					/>
				</div>
			</div>
			<footer className="px-5 pb-4 has-text-right mt-auto">
				<h2>
					<span className="has-text-weight-semibold is-size-4 is-block has-line-height-1">
						{fixedLifetimeProductionActivePower}<small className="is-size-7 has-text-weight-light">&nbsp;MWh</small>
					</span>
				</h2>
			</footer>
		</div>
	);
};
