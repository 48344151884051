import React from "react";
import { ProductionGauge } from "../TopBar/ProductionGauge";
import { ValueUnit } from "./ValueUnit";

type Props = {
	totalACCapacity?: number,
	totalActivePowerExport?: number,
}

/**
 * The current production
 */
export const CurrentProduction = (props: Props) => {
	const currentProduction = props?.totalActivePowerExport;
	const currentProductionUnit = "MW";
	const name = "Current production";
	return (

		<div className="is-flex is-align-items-center">
			<span className="icon gauge--1 is-large is-rounded has-text-theme--1 has-background-theme--2 mr-3 p-2">
				<ProductionGauge
					max={props?.totalACCapacity}
					value={currentProduction}
				/>
			</span>
			<ValueUnit value={currentProduction || 0} uom={currentProductionUnit} name={name} />
		</div>
	);
};
