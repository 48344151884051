import React, { useContext, useEffect, useRef, useState } from "react";
import { HubContext } from "../../../MainScreen/MainScreen";
import XLSX from "xlsx";
import FileSaver from "file-saver";
import { stringToBlob } from "../../../../functions/stringToBlob";
import { breakUpCellName } from "../../../../functions/breakUpCellName";
import { AddMessage } from "../../../../Redux/Slices/messageSlice";
import { useDispatch } from "react-redux";
import { PleaseWait } from "../../../../components/PleaseWait";
import { TwinSignalType } from "../../../../Types";

/**
 * Exports/Imports signal information to Excel.
 */
export const TwinSignals = () => {
	const exportName = "TwinSignals";
	const { sendTwinHubRequest, } = useContext(HubContext);
	const [ signals, setSignals ] = useState<Array<TwinSignalType>>([]);
	const fileBrowser = useRef(null);
	const dispatch = useDispatch();

	useEffect(() => {
		handleLoadSignals();
	}, []);

	/**
	 * Loads the signals from the backend.
	 */
	function handleLoadSignals () {
		const isoDate = (new Date()).toISOString();

		const requestPackage = {
			onSuccess: setSignals,
			requestId: "TwinSignals" + isoDate,
			requestType: "TwinSignalGetAll",
		};
		sendTwinHubRequest(requestPackage);
	}

	/**
	 * Exports the loaded signals to Excel.
	 */
	function handleExport () {
		const currentDate = new Date();

		const wb = XLSX.utils.book_new();
		wb.Props = {
			Author: "Prediktor AS",
			CreatedDate: currentDate,
			Subject: exportName,
			Title: exportName,
		};

		wb.SheetNames.push(exportName);
		const wsData = [];
		wsData.push([ "Signal Id", "Name", "Api Server Name", "Module Name", "Signal Name", "Twin Id", "Twin Class Id", "Twin Node Template Id", "Delete" ]);

		for (let i = 0; i < signals.length; i++) {
			const { twinSignalId, name, apiServerName, moduleName, signalName, twinId, twinClassId, twinNodeTemplateId, } = signals[i];

			wsData.push([ twinSignalId, name, apiServerName, moduleName, signalName, twinId, twinClassId, twinNodeTemplateId, "No" ]);
		}

		const ws = XLSX.utils.aoa_to_sheet(wsData);
		wb.Sheets[exportName] = ws;

		const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary", });
		FileSaver.saveAs(new Blob([ stringToBlob(wbout) ], { type: "application/octet-stream", }), `${exportName}_${currentDate.toLocaleDateString()}.xlsx`);
	}

	/**
	 * Imports a twin class file.
	 */
	function handleImport () {
		const current: any = fileBrowser?.current;
		if (current) {
			current.click();
		}
	}

	/**
	 * Called when the user selects a file.
	 */
	function handleChangeFile () {
		if (fileBrowser) {
			const current: any = fileBrowser?.current;

			if (current) {
				const { files, } = current;

				if (files && files.length > 0) {
					const file = files[0];
					const reader = new FileReader();

					reader.onloadend = function (e) {
						if (e.target) {
							fileLoaded(file.name, e.target.result);
						}
					};

					if (file) {
						reader.readAsArrayBuffer(file);
					}
				}
			}
		}
	}

	/**
	 * Called when the import file is loaded.
	 */
	function fileLoaded (fileName: string, fileContent: ArrayBuffer | string | null) {
		if (!fileContent || typeof fileContent === "string") {
			return;
		}

		const data = new Uint8Array(fileContent);
		const workbook = XLSX.read(data, { type: "array", });
		const Sheets = workbook.Sheets;

		const signalSheet = Sheets[exportName];
		const rows: Array<Array<{ columnNumber: number, value: string }>> = [];

		// eslint-disable-next-line no-unused-vars
		for (const cellName in signalSheet) {
			if (cellName.substring(0, 1) !== "!") {
				const inputCell = signalSheet[cellName];

				const { rowNumber, columnNumber, } = breakUpCellName(
					cellName
				);

				if (!rows[rowNumber]) {
					rows[rowNumber] = [];
				}

				rows[rowNumber][columnNumber] = {
					columnNumber,
					value: inputCell.v,
				};
			}
		}

		const deleteSignals = [];
		const newSignals = [];
		const updatedSignals = [];
		for (let i = 0; i < rows.length; i++) {
			const row = rows[i];

			if (row) {
				const twinSignalId = row[1] ? row[1].value : 0;
				const name = row[2] ? row[2].value : null;
				const apiServerName = row[3] ? row[3].value : null;
				const moduleName = row[4] ? row[4].value : null;
				const signalName = row[5] ? row[5].value : null;
				const twinId = row[6] ? row[6].value : null;
				const twinClassId = row[7] ? row[7].value : null;
				const twinNodeTemplateId = row[8] ? row[8].value : null;
				const deleteFlag = row[9] ? row[9].value === "Yes" : false;

				if (twinSignalId !== "Signal Id") {
					if (twinSignalId) {
						if (deleteFlag) {
							deleteSignals.push(twinSignalId);
						} else {
							updatedSignals.push({
								apiServerName, moduleName, name, signalName, twinClassId, twinId, twinNodeTemplateId, twinSignalId,
							});
						}
					} else {
						newSignals.push({
							apiServerName, moduleName, name, signalName, twinClassId, twinId, twinNodeTemplateId, twinSignalId,
						});
					}
				}
			}
		}

		for (let i = 0; i < updatedSignals.length; i++) {
			newSignals.push(updatedSignals[i]);
		}

		let somethingChanged = false;
		if (deleteSignals.length > 0) {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: deleteCompleted,
				parameters: deleteSignals,
				requestId: "BatchDelete" + isoDate,
				requestType: "TwinSignalBatchDelete",
			};

			sendTwinHubRequest(requestPackage);
			somethingChanged = true;
		}

		if (newSignals.length > 0) {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: saveCompleted,
				parameters: newSignals,
				requestId: "BatchUpdate" + isoDate,
				requestType: "TwinSignalBatchUpdate",
			};

			sendTwinHubRequest(requestPackage);
			somethingChanged = true;
		}

		if (!somethingChanged) {
			dispatch(
				AddMessage({
					message: "No updated or added signals found in the Excel spreadsheet.",
					type: "error",
				})
			);
		}
	}

	/**
	 * Called when the save completes.
	 */
	function saveCompleted () {
		dispatch(
			AddMessage({
				message: `${exportName} Updated`,
				type: "info",
			})
		);

		handleLoadSignals();
	}

	/**
	 * Called when the delete completes.
	 */
	function deleteCompleted () {
		dispatch(
			AddMessage({
				message: `${exportName} Deleted`,
				type: "info",
			})
		);

		handleLoadSignals();
	}

	if (signals == null) {
		return (
			<PleaseWait />
		);
	}

	return (
		<div>
			<p>
				{`${signals.length} Signals Found`}
			</p>
			<button
				className="button"
				onClick={handleExport}
			>
				Export Signals
			</button>
			<button
				className="button"
				onClick={handleImport}
			>
				Import Signals
			</button>
			<input
				accept="*.*"
				onChange={handleChangeFile}
				ref={fileBrowser}
				style={{
					display: "none",
				}}
				type="file"
			/>
		</div>
	);
};
