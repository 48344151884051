import React from "react";
import { TwinNodeTemplateRow } from "./TwinNodeTemplateRow";
import { TwinNodeTemplateType } from "../../../../Types";

type Props = {
	onDelete: Function,
	onEdit: Function,
	twinNodeTemplates: Array<TwinNodeTemplateType>,
}

/**
 * Displays a table of the twins available.
 */
export const TwinNodeTemplates = (props: Props) => {
	const { onDelete, onEdit, twinNodeTemplates, } = props;

	/**
	 * Displays a row for each twin.
	 */
	function eachTwin (item: TwinNodeTemplateType, index: number) {
		return <TwinNodeTemplateRow item={item} key={index} name={item?.name} onDelete={onDelete} onEdit={onEdit} />;
	}

	return (
		<table className="table is-fullwidth is-hoverable">
			<thead>
				<tr>
					<th className="is-size-7 is-uppercase has-text-weight-semibold">Name</th>
					<th><span className="is-hidden">Actions</span></th>
				</tr>
			</thead>
			<tbody>
				{twinNodeTemplates && twinNodeTemplates.length > 0 && twinNodeTemplates.map(eachTwin)}
			</tbody>
		</table>
	);
};
