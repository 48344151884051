import React from "react";
import { formatDisplayNumber } from "./../../../functions/formatDisplayNumber";

type Props = {
	temperature: Number,
	windSpeed: Number,
	irradiance: Number,
	weatherSymbol: String,
	humidity: Number,
};

/**
 * Displays weather information for the lobby.
 */
export const Weather = (props: Props) => {
	const {
		temperature,
		windSpeed,
		irradiance,
		weatherSymbol,
		humidity,
	} = props;

	const imageContext = require.context("./../../../assets/images/yr/svg/");
	const symbolUrl = weatherSymbol && imageContext(`./${weatherSymbol}.svg`);

	return (
		<div className="card flex-column is-full-height">
			<header className="card-header is-shadowless">
				<h2 className="card-header-title is-uppercase is-size-7">Weather</h2>
			</header>
			<div className="card-content px-5 pt-0 mt-auto">
				<div className="columns is-mobile is-multiline graph__divider--ewsn">
					<div className="column is-6 has-text-centered mt-auto">
						{symbolUrl && <img alt="Weather" src={symbolUrl} title="Temperature" style={{ height: "2rem", }} />}
						<h2>
							<span className="has-text-weight-semibold is-size-4 is-block has-line-height-1">
								{formatDisplayNumber(temperature)}<small className="is-size-7 has-text-weight-light">&nbsp;&#176;C</small>
							</span>
						</h2>
					</div>
					<div className="column is-6 has-text-centered">
						<span className="is-relative has-text--grey--grey-dark mb-1">
							<i className="icon-humidity is-size-3" title="Humidity" />
						</span>
						<h2>
							<span className="has-text-weight-semibold is-size-4 is-block has-line-height-1">
								{formatDisplayNumber(humidity)}<small className="is-size-7 has-text-weight-light">&nbsp;%</small>
							</span>
						</h2>
					</div>
					<div className="column is-6 has-text-centered">
						<span className="icon is-large has-text--grey--grey-dark mt-4 mb-1">
							<i className="fas fa-wind is-size-3" title="Wind" />
						</span>
						<h2>
							<span className="has-text-weight-semibold is-size-4 is-block has-line-height-1">
								{formatDisplayNumber(windSpeed)}<small className="is-size-7 has-text-weight-light">&nbsp;m/s</small>
							</span>
						</h2>
					</div>
					<div className="column is-6 has-text-centered">
						<span className="icon is-large has-text--grey--grey-dark mt-4 mb-1">
							<i className="icon-solar-irradiance is-size-3" title="Irradiance" />
						</span>
						<h2>
							<span className="has-text-weight-semibold is-size-4 is-block has-line-height-1">
								{formatDisplayNumber(irradiance)}<small className="is-size-7 has-text-weight-light">&nbsp;W/m<sup>2</sup></small>
							</span>
						</h2>
					</div>
				</div>
			</div>
		</div>
	);
};
