import React, { useEffect, useState } from "react";
import { PerformanceCard } from "./PerformanceCard";
import { PleaseWait } from "../../../components/PleaseWait";
import { SiteType } from "../../../Types";

type Props = {
	sites: Array<SiteType>,
}

/**
	 * Displays a plant performance graph.
	 */
export const PlantPerformance = (props: Props) => {
	const { sites, } = props;
	const [ percentages, setPercentages ] = useState({ high: 0, low: 0, middle: 0, });
	const [ sitesData, setSitesData ] = useState<Array<{ name: string, value: number }>>([]);

	useEffect(() => {
		if (sites) {
			let siteArr: Array<{ name: string, value: number }> = [];
			let high = 0;
			let low = 0;
			let middle = 0;

			for (let i = 0; i < sites.length; i++) {
				const siteObj: any = {};
				const child = sites[i];
				const value = child?.pRMeasure || 0;

				siteObj.name = child?.shortName || child?.name || child?.nodeName || "";
				siteObj.value = value;
				siteArr = [ ...siteArr, siteObj ];

				if (value > 85) {
					high++;
				} else if (value < 70) {
					low++;
				} else {
					middle++;
				}
			}

			setSitesData(siteArr);
			setPercentages({
				high: (high / sites.length) * 100,
				low: (low / sites.length) * 100,
				middle: (middle / sites.length) * 100,
			});
		}
	}, [ sites ]);

	if (!sites) {
		return (
			<PleaseWait />
		);
	}

	return (
		<PerformanceCard
			summaryPercentages={percentages} sites={sitesData}
		/>

	);
};
