import React, { useState } from "react";
import { isNull, roundNumber } from "../../../functions/UtilityFunctions";
import { AvgMaxMinBoxes } from "./AvgMaxMinBoxes";
import { useHistory } from "react-router-dom";
import { BarChartWithLimit } from "../../../components/BarChartWithLimit";
import { ButtonExpand } from "../../../components/ButtonExpand";
import { InverterType } from "../../../Types";

type Props = {
	inverters: Array<InverterType>,
}

/**
 * The tabbable controls at the bottom middle of the plant dasboard.
 */
export const BottomMiddleTabs = (props: Props) => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const id = urlParams.get("i");
	const history = useHistory();

	const { inverters, } = props;
	const [ activeTab, setActiveTab ] = useState("specificPower");

	const labels = [];
	const specificPowers = [];
	const dailyYields = [];
	const prs = [];

	let totalSpecificPower = 0;
	let totalDailyYield = 0;
	let totalPR = 0;
	let maxSpecificPower = 0;
	let maxDailyYield = 0;
	let maxPR = 0;
	let minSpecificPower = null;
	let minDailyYield = null;
	let minPR = null;
	let avgSpecificPower = null;
	let avgDailyYield = null;
	let avgPR = null;

	if (inverters) {
		inverters.sort((a, b) => {
			const aName = a?.name;
			const aShortName = a?.siteName;
			const bName = b?.name;
			const bShortName = b?.siteName;

			if (aShortName && bShortName) {
				if (aShortName < bShortName) {
					return -1;
				}

				return 1;
			} else if (aName && bName) {
				if (aName < bName) {
					return -1;
				}

				return 1;
			}

			return 1;
		});

		for (let i = 0; i < inverters.length; i++) {
			const inverter = inverters[i];

			labels.push(inverter?.siteName || inverter?.name || "");

			const dcPower = inverter?.dCPower || 0;
			const nominalDCPower = inverter?.nominalDCPower || 0;
			if (!isNull(dcPower) && !isNull(nominalDCPower)) {
				const specificPower = dcPower / nominalDCPower;

				totalSpecificPower += specificPower;
				specificPowers.push(roundNumber(specificPower, 4));
				if (specificPower > maxSpecificPower) {
					maxSpecificPower = specificPower;
				}
				if (isNull(minSpecificPower) || specificPower < minSpecificPower) {
					minSpecificPower = specificPower;
				}
			}

			const dailyYield = inverter?.aCDailyActiveEnergy;
			if (!isNull(dailyYield)) {
				totalDailyYield += dailyYield;
				dailyYields.push(dailyYield);
				if (dailyYield > maxDailyYield) {
					maxDailyYield = dailyYield;
				}
				if (isNull(minDailyYield) || dailyYield < minDailyYield) {
					minDailyYield = dailyYield;
				}
			}

			const pr = inverter?.pr;
			if (!isNull(pr)) {
				totalPR += pr;
				prs.push(roundNumber(pr, 4));
				if (pr > maxPR) {
					maxPR = pr;
				}
				if (isNull(minPR) || pr < minPR) {
					minPR = pr;
				}
			}
		}

		avgSpecificPower = totalSpecificPower / specificPowers.length || 0;
		avgDailyYield = totalDailyYield / dailyYields.length || 0;
		avgPR = totalPR / prs.length || 0;
	}

	return (
		<div className="card is-full-height">
			<header className="card-header has-tabs">
				<div className="tabs mb-0">
					<ul>
						<li
							className={activeTab === "specificPower" ? "tab has-text-weight-semibold is-uppercase is-size-7 is-active" : "tab has-text-weight-semibold is-uppercase is-size-7"}
							onClick={() => setActiveTab("specificPower")}
						>
							<span className="is-clickable py-3 px-4">
								Specific power
							</span>
						</li>
						<li
							className={activeTab === "dailyYield" ? "tab has-text-weight-semibold is-uppercase is-size-7 is-active" : "tab has-text-weight-semibold is-uppercase is-size-7"}
							onClick={() => setActiveTab("dailyYield")}
						>
							<span className="is-clickable py-3 px-4">
								Daily yield
							</span>
						</li>
						<li
							className={activeTab === "pr" ? "tab has-text-weight-semibold is-uppercase is-size-7 is-active" : "tab has-text-weight-semibold is-uppercase is-size-7"}
							onClick={() => setActiveTab("pr")}
						>
							<span className="is-clickable py-3 px-4">
								PR
							</span>
						</li>
					</ul>
				</div>

				<span className="is-absolute is-top is-right">
					<ButtonExpand onClick={() => { history.push(`/solarSiteBottomMiddleTabs?i=${id}`); }} />
				</span>

			</header>
			<div className="card-content p-2">
				{
					activeTab === "specificPower" && (
						<div id="tab-name" className="content-tab">
							<div className="chart is-large">
								<BarChartWithLimit
									labels={labels}
									average={avgSpecificPower || 0}
									max={maxSpecificPower}
									min={minSpecificPower || 0}
									series={[ {
										data: specificPowers,
										name: "Specific Power",
										numberOfDecimals: 1,
										unit: "W/Wp",
										unitPosition: "before",
									} ]}
								/>
							</div>
							<div className="px-2">
								<AvgMaxMinBoxes avg={avgSpecificPower || 0} max={maxSpecificPower || 0} min={minSpecificPower || 0} />
							</div>
						</div>
					)
				}
				{
					activeTab === "dailyYield" && (
						<div id="tab-name" className="content-tab">
							<div className="chart is-large">
								<BarChartWithLimit
									labels={labels}
									// limit={avgDailyYield}
									average={avgDailyYield || 0}
									max={maxDailyYield}
									min={minDailyYield || 0}
									series={
										[
											{
												data: dailyYields,
												name: "Daily Yield",
												numberOfDecimals: 1,
												unit: "Wh",
												unitPosition: "before",
											}
										]
									}
								/>
							</div>
							<div className="px-2">
								<AvgMaxMinBoxes avg={avgDailyYield || 0} max={maxDailyYield} min={minDailyYield || 0} />
							</div>
						</div>
					)
				}
				{
					activeTab === "pr" && (
						<div id="tab-name" className="content-tab">
							<div className="chart is-large">
								<BarChartWithLimit
									labels={labels}
									// limit={avgPR}
									average={avgPR || 0}
									max={maxPR}
									min={minPR || 0}
									series={[ {
										data: prs,
										name: "PR",
										numberOfDecimals: 1,
										unit: "%",
										unitPosition: "before",
									} ]}
								/>
							</div>
							<div className="px-2">
								<AvgMaxMinBoxes avg={avgPR || 0} max={maxPR} min={minPR || 0} />
							</div>
						</div>
					)
				}
			</div>
		</div>

	);
};
