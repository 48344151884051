import React from "react";
import { formatDisplayNumber } from "../../../functions/formatDisplayNumber";

type Props = {
	lowLimit: number,
	highLimit: number,
	value: number,
};

/***
 * Gets status for EPI card based on value and limit
 */
function getStatus (value: number, lowLimit: number, highLimit: number) {
	if (value < lowLimit) {
		return "danger";
	} else if (value < highLimit) {
		return "grey";
	}
	return "success";
}

/** EPI card */
export function EPICard (props: Props) {
	const { lowLimit, highLimit, value, } = props;
	const className =
		"media is-align-items-center has-line-height-1 p-1 has-radius has-width-60 px-2 has-text--inverted has-background-" +
		getStatus(value, lowLimit, highLimit);

	return (
		<div className={className}>
			<div className="media-content">
				<h3>
					<span className="has-text-weight-semibold is-size-5-desktop is-narrowed">
						{formatDisplayNumber(value)}
					</span>
					<span className="is-size-8">&nbsp;&#37;</span>
					<span className="is-block is-size-7 is-size-8-mobile has-text-weight-medium">EPI</span>
				</h3>
			</div>
		</div>
	);
}
