import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FetchHistorianData } from "../../../Redux/Slices/backendSlice";
import { getGraphGranularity, getGraphLabels } from "../../../functions/PeriodFunctions";
import { MixedLineAndBarChart } from "../../../components/MixedLineAndBarChart/MixedLineAndBarChart";
import { ApexChartSeriesType, HistoricalResponseType } from "../../../Types";

type Props = {
	id: string,
	startTime: Date,
	endTime: Date,
}

/**
 * Displays the power chart.
 */
export const PowerChart = (props: Props) => {
	const { id, startTime, endTime, } = props;
	const dispatch = useDispatch();
	const [ series, setSeries ] = useState<Array<ApexChartSeriesType>>([]);
	const [ labels, setLabels ] = useState<Array<string>>([]);

	useEffect(() => {
		const granularity = getGraphGranularity(startTime, endTime);
		/**
		 * Handles the historian data loading.
		 */
		const handleTrendsLoaded = (trends: Array<HistoricalResponseType>) => {
			const powerSeries: Array<number> = [];
			const dateLabels: Array<Date> = [];
			// for (let i = 0; i < data.length; i++) {
			// const trend = data[i];

			// powerSeries.push(trend["dc.power_avg"]);
			// dateLabels.push(new Date(trend.__time));
			// }
			const graphLabels = getGraphLabels(dateLabels, startTime, endTime);
			setLabels(graphLabels);

			setSeries([
				{
					axisIndex: 0,
					axisPosition: "left",
					data: powerSeries,
					name: "Power",
					numberOfDecimals: 1,
					type: "area",
					unit: "W",
					unitPosition: "after",
				}
			]);
		};

		dispatch(
			FetchHistorianData({
				endTime: endTime.toISOString(),
				entityIds: [ id ],
				granularity,
				onFailure: (response: string) => alert(response),
				onSuccess: (trends: Array<HistoricalResponseType>) => { handleTrendsLoaded(trends); },
				startTime: startTime.toISOString(),
				variables: [
					"dc.power"
				],
			})
		);
	}, [ id, startTime, endTime, dispatch ]);

	return (
		<MixedLineAndBarChart labels={labels} series={series} />
	);
};
