import React from "react";
import { ProductionGauge } from "./TopBar/ProductionGauge";
import { isNull } from "../../../functions/UtilityFunctions";
import { formatDisplayNumber } from "../../../functions/formatDisplayNumber";

type Props = {
	regionTitle?: string,
	totalACCapacity?: number,
	totalActiveEnergy?: number,
	totalActivePower?: number,
}

/**
 * Card info per region
 */
export const RegionInfoCard = (props: Props) => {
	const regionTitle = props?.regionTitle;
	const currentProduction = props?.totalActivePower;
	const totalProduction = props?.totalActiveEnergy;
	const totalACCapacity = props?.totalACCapacity;
	const currentProductionUnit = "MW";
	const totalProductionUnit = "MWh";

	return (
		<foreignObject id="G" width="160px" height="100px">
			<div className="worldmap-info is-flex is-flex-direction-column is-justify-content-space-evenly has-radius is-size-8 m-2">
				<h3 className="has-text-weight-semibold is-uppercase is-size-small has-border-bottom-width-1 has-border-bottom-opacity--10 px-2 py-1">
					{regionTitle}
				</h3>
				<div className="is-flex is-align-items-center has-border-bottom-width-1 has-border-bottom-opacity--10 px-2 py-1">
					<span className="icon gauge--2 is-rounded mr-2">
						<ProductionGauge
							value={currentProduction}
							max={totalACCapacity}
						/>
					</span>
					<h4 className="has-line-height-1">
						<span className="has-text-weight-semibold is-size-7">
							{isNull(currentProduction) ? "N/A" : formatDisplayNumber(currentProduction)}
						</span>
						<span className="is-size-9">&nbsp;{currentProductionUnit}</span>
						<span className="is-block is-size-small pt-1">Current Production</span>
					</h4>
				</div>
				<div className="is-flex is-align-items-center px-2 py-1">
					<span className="icon is-rounded has-background-opacity--5 mr-2">
						<i className="fas fa-bolt" aria-hidden="true" />
					</span>
					<h4 className="has-line-height-1">
						<span className="has-text-weight-semibold is-size-7">
							{isNull(totalProduction) ? "N/A" : formatDisplayNumber(totalProduction)}
						</span>
						<span className="is-size-9">&nbsp;{totalProductionUnit}</span>
						<span className="is-block is-size-small pt-1">Lifetime Production</span>
					</h4>
				</div>
			</div>
		</foreignObject>
	);
};
