import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TwinSignals } from "./components/TwinSignals";
import { HubContext } from "../../MainScreen/MainScreen";
import { TwinSignalModal } from "./components/TwinSignalModal";
import { AvailableConnectionType, TwinSignalType } from "../../../Types";

/**
 * The initial screen.
 */
export const TwinSignalAdminScreen = () => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const twinIdString = urlParams.get("twinId") || "";
	const twinId = parseInt(twinIdString, 10);
	const twinClassIdString = urlParams.get("twinClassId");
	const twinClassId = twinClassIdString ? parseInt(twinClassIdString, 10) : undefined;
	const twinNodeTemplateIdString = urlParams.get("twinNodeTemplateId");
	const twinNodeTemplateId = twinNodeTemplateIdString ? parseInt(twinNodeTemplateIdString, 10) : undefined;
	const history = useHistory();

	const [ availableConnections, setAvailableConnections ] = useState<Array<AvailableConnectionType>>([]);
	const [ twinSignals, setTwinSignals ] = useState<Array<TwinSignalType>>([]);
	const [ modalTwinSignal, setModalTwinSignal ] = useState<TwinSignalType | null>(null);
	const { sendApisHubRequest, sendTwinHubRequest, } = useContext(HubContext);

	useEffect(() => {
		handleLoadTwinSignals();
		handleLoadAvailableOPCServers();
	}, []);

	/**
	 * Loads the twin signals from the backend.
	 */
	function handleLoadTwinSignals () {
		const isoDate = (new Date()).toISOString();

		const requestPackage = {
			onSuccess: (response: Array<TwinSignalType>) => { setTwinSignals(response); },
			parameters: {
				twinClassId,
				twinId,
				twinNodeTemplateId,
			},
			requestId: isoDate,
			requestType: "TwinSignalGet",
		};
		sendTwinHubRequest(requestPackage);
	}

	/**
	 * Loads the available OPC servers from the backend.
	 */
	function handleLoadAvailableOPCServers () {
		const isoDate = (new Date()).toISOString();

		const requestPackage = {
			onSuccess: (response: Array<AvailableConnectionType>) => { setAvailableConnections(response); },
			requestId: "AvailableOPCServers" + isoDate,
			requestType: "OpcUaGetConnections",
		};
		if (sendApisHubRequest) {
			sendApisHubRequest(requestPackage);
		}
	}

	return (
		<div className="container is-fluid" style={{ height: "100vh", }}>
			<section className="section px-6">
				<div className="level">
					<div className="level-left">
						<h1 className="title is-size-4 has-text-weight-medium">Twin Signal Manager</h1>
					</div>
					<div className="level-right">
						<a
							className="button is-icon has-tooltip-arrow"
							data-tooltip="Back"
							onClick={() => { history.goBack(); }}
						>
							<span className="icon">
								<i className="fas fa-arrow-left" aria-hidden="true" />
							</span>
						</a>
						<a
							className="button is-icon has-tooltip-arrow"
							data-tooltip="Create Signal"
							onClick={() => { setModalTwinSignal({}); }}
						>
							<span className="icon">
								<i className="fas fa-plus" aria-hidden="true" />
							</span>
						</a>
					</div>
				</div>
			</section>
			<section className="section pt-0 px-6">
				<div className="has-shadow--small">
					<TwinSignals
						onDelete={(twinSignal: TwinSignalType) => {
							const confirm = window.confirm("Permanently delete this signal?");
							if (confirm) {
								const isoDate = (new Date()).toISOString();

								const requestPackage = {
									onSuccess: handleLoadTwinSignals,
									parameters: {
										twinSignalId: twinSignal.twinSignalId,
									},
									requestId: isoDate,
									requestType: "TwinSignalDelete",
								};
								sendTwinHubRequest(requestPackage);
							}
						}}
						onEdit={(twinSignal: TwinSignalType) => { setModalTwinSignal(twinSignal); }}
						twinSignals={twinSignals}
					/>
				</div>
			</section>
			<div className={modalTwinSignal ? "is-block" : "is-hidden"}>
				<TwinSignalModal
					availableConnections={availableConnections}
					onClose={() => { setModalTwinSignal(null); }}
					onReload={handleLoadTwinSignals}
					sendTwinHubRequest={sendTwinHubRequest}
					twinId={twinId}
					twinClassId={twinClassId}
					twinNodeTemplateId={twinNodeTemplateId}
					twinSignal={modalTwinSignal}
				/>
			</div>
		</div>
	);
};
