import React from "react";
import { formatDisplayNumber } from "../../../../functions/formatDisplayNumber";

type Props = {
	rainAmount?: number,
	windSpeed?: number,
}

/**
 * Displays wind and rain information.
 */
export const WindRain = (props: Props) => {
	const wind = props.windSpeed;
	const rain = props.rainAmount;

	return (
		<div className="is-flex is-align-items-center">
			<span className="icon is-large is-rounded is-flex is-flex-direction-column is-clipped has-text-theme--1 has-background-theme--2 mr-3">
				<i className="fas fa-wind is-align-self-flex-start" aria-hidden="true" />
				<i className="icon-rain is-align-self-flex-end" aria-hidden="true" />
			</span>
			<h3>
				<span className="has-text-weight-semibold is-size-4 is-size-5-touch">
					{formatDisplayNumber(wind)}
				</span>
				<span className="has-text-weight-medium is-size-7-touch">&nbsp;m/s</span>
				<span className="is-block">
					<span className="has-text-weight-semibold is-size-4 is-size-5-touch">
						{formatDisplayNumber(rain)}
					</span>
					<span className="has-text-weight-medium is-size-7-touch">&nbsp;mm/h</span>
				</span>
			</h3>
		</div>
	);
};
