import React, { useEffect, useState } from "react";
import { AvailableConnectionType, TwinNodeTemplateType } from "../../../../Types";

type Props = {
	availableConnections: Array<AvailableConnectionType>,
	onClose: Function,
	onReload: Function,
	sendTwinHubRequest: Function,
	twinNodeTemplate: TwinNodeTemplateType | null,
	twinNodeTemplates: Array<TwinNodeTemplateType>,
}

/**
 * Lets the user create a new twin.
 */
export const TwinNodeTemplateModal = (props: Props) => {
	const {
		availableConnections,
		onClose,
		onReload,
		sendTwinHubRequest,
		twinNodeTemplate,
		twinNodeTemplates,
	} = props;
	const [ editTemplate, setEditTemplate ] = useState<TwinNodeTemplateType>({
		apiServerName: "",
		isRoot: false,
		name: "",
		parentTwinNodeTemplateId: -1,
		uaNodeId: "",
		uaType: "",
	});

	useEffect(() => {
		if (twinNodeTemplate) {
			setEditTemplate(twinNodeTemplate);
		} else {
			setEditTemplate({
				apiServerName: "",
				isRoot: false,
				name: "",
				parentTwinNodeTemplateId: -1,
				uaNodeId: "",
				uaType: "",
			});
		}
	}, [ twinNodeTemplate ]);

	/**
	 * Adds a new Twin to the backend.
	 */
	function handleSave (close: Boolean) {
		if (editTemplate.twinNodeTemplateId) {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: close === true ? saveCompleted : blurCompleted,
				parameters: {
					...editTemplate,
					...{
						parentTwinNodeTemplateId: editTemplate.parentTwinNodeTemplateId === -1 ? null : editTemplate.parentTwinNodeTemplateId,
					},
				},
				requestId: isoDate,
				requestType: "TwinNodeTemplateUpdate",
			};

			sendTwinHubRequest(requestPackage);
		} else {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: close === true ? saveCompleted : blurCompleted,
				parameters: {
					...editTemplate,
					...{
						parentTwinNodeTemplateId: editTemplate.parentTwinNodeTemplateId === -1 ? null : editTemplate.parentTwinNodeTemplateId,
					},
				},
				requestId: isoDate,
				requestType: "TwinNodeTemplateAdd",
			};

			sendTwinHubRequest(requestPackage);
		}
	}

	/**
	 * Called when the add completes.
	 */
	function saveCompleted (newTwin: TwinNodeTemplateType) {
		setEditTemplate({
			apiServerName: "",
			isRoot: false,
			name: "",
			parentTwinNodeTemplateId: -1,
			uaNodeId: "",
			uaType: "",
		});
		onReload();
		onClose();
	}

	/**
	 * Called when the blur of a field completes.
	 */
	function blurCompleted (newRecord: TwinNodeTemplateType) {
		setEditTemplate(newRecord);
	}

	return (
		<div className="modal is-active">
			<div className="modal-background">&nbsp;</div>
			<div className="modal-content">
				<div className="field">
					<label htmlFor="" className="label">ID</label>
					<div className="control">
						<label>{editTemplate.twinNodeTemplateId}</label>
					</div>
				</div>
				<div className="field">
					<label htmlFor="" className="label">Name</label>
					<div className="control">
						<input
							type="text"
							className="input"
							onChange={(event) => {
								if (!event) {
									return;
								}

								const { target, } = event;
								if (!target) {
									return;
								}

								setEditTemplate({
									...editTemplate,
									...{
										name: target.value,
									},
								});
							}}
							placeholder="Twin Name"
							value={editTemplate.name}
						/>
					</div>
				</div>
				<div className="field">
					<label htmlFor="" className="label">Root?</label>
					<div className="control">
						<input
							className="checkbox"
							type="checkbox"
							onChange={(event) => {
								if (!event) {
									return;
								}

								const { target, } = event;
								if (!target) {
									return;
								}

								setEditTemplate({
									...editTemplate,
									...{
										isRoot: target.checked,
									},
								});
							}}
							placeholder="Root?"
							checked={editTemplate.isRoot}
						/>
					</div>
				</div>
				<div className={editTemplate.isRoot ? "is-hidden" : "field"}>
					<label htmlFor="" className="label">Parent</label>
					<div className="control">
						<div className="select">
							<select
								onChange={(event) => {
									if (!event) {
										return;
									}

									const { target, } = event;
									if (!target) {
										return;
									}

									const numberValue = parseInt(target.value, 10);
									setEditTemplate({
										...editTemplate,
										...{
											parentTwinNodeTemplateId: numberValue,
										},
									});
								}}
								value={editTemplate.parentTwinNodeTemplateId}
							>
								<option value={-1}>None</option>
								{twinNodeTemplates && twinNodeTemplates.length > 0 && twinNodeTemplates.map((item, index) => {
									return <option key={index} value={item.twinNodeTemplateId}>{item.name}</option>;
								})}
							</select>
						</div>
					</div>
				</div>
				<div className={editTemplate.isRoot ? "is-hidden" : "field"}>
					<label htmlFor="" className="label">OPC UA Type</label>
					<div className="control">
						<input
							type="text"
							className="input"
							onChange={(event) => {
								if (!event) {
									return;
								}

								const { target, } = event;
								if (!target) {
									return;
								}

								setEditTemplate({
									...editTemplate,
									...{
										uaType: target.value,
									},
								});
							}}
							placeholder="OPC UA Type"
							value={editTemplate.uaType}
						/>
					</div>
				</div>
				<div className={editTemplate.isRoot ? "field" : "is-hidden"}>
					<label htmlFor="" className="label">OPC UA Node ID</label>
					<div className="control">
						<input
							type="text"
							className="input"
							onChange={(event) => {
								if (!event) {
									return;
								}

								const { target, } = event;
								if (!target) {
									return;
								}

								setEditTemplate({
									...editTemplate,
									...{
										uaNodeId: target.value,
									},
								});
							}}
							placeholder="OPC UA Node ID"
							value={editTemplate.uaNodeId}
						/>
					</div>
				</div>
				<div className={editTemplate.isRoot ? "field" : "is-hidden"}>
					<label htmlFor="" className="label">OPC Server</label>
					<div className="control">
						<div className="select">
							<select
								onChange={(event) => {
									if (!event) {
										return;
									}

									const { target, } = event;
									if (!target) {
										return;
									}

									setEditTemplate({
										...editTemplate,
										...{
											apiServerName: target.value,
										},
									});
								}}
								value={editTemplate.apiServerName}
							>
								<option value="None">None</option>
								{availableConnections && availableConnections.length > 0 && availableConnections.map((item, index) => {
									return <option key={index} value={item.serverName}>{item.serverName}</option>;
								})}
							</select>
						</div>
					</div>
				</div>
				<button className="button" onClick={() => { handleSave(true); }}>Save &amp; Close</button>
			</div>
			<button className="modal-close is-large" aria-label="close" onClick={() => { onClose(); }} />
		</div>
	);
};
