import React, { useContext, useEffect, useState } from "react";
import { HubContext } from "../MainScreen/MainScreen";
import { PleaseWait } from "../../components/PleaseWait";
import { PlantCard } from "./components/PlantCard";
import { PlantPerformance } from "./components/PlantPerformance";
import { PlantProduction } from "./components/PlantProduction";
import { PlantTable } from "./components/PlantTable";
import { SiteCards } from "./components/SiteCards";
import { EnterpriseType, RawSiteType, SiteType } from "../../Types";

/**
 * Displays a list of available sites for user selection.
 */
export function Enterprise () {
	const [ enterpriseDetails, setEnterpriseDetails ] = useState<EnterpriseType | null>(null);
	const [ sites, setSites ] = useState<Array<SiteType> | null>(null);
	const [ displayInfo, setDisplayInfo ] = useState(true);
	const [ displayGrid, setDisplayGrid ] = useState(false);
	const { sendTwinHubRequest, } = useContext(HubContext);

	useEffect(() => {
		const interval = setInterval(() => {
			loadEnterpriseTwin();
		}, 300000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		loadEnterpriseTwin();
	}, []);

	/**
	 * Loads the enterprise information from the backend.
	 */
	function loadEnterpriseTwin () {
		const isoDate = (new Date()).toISOString();

		const enterpriseRequestPackage = {
			onSuccess: handleEnterpriseLoaded,
			parameters: {
				restrictions: {
					rootOnly: true,
					signals: [],
					storedProcedures: [],
				},
				twinNodeTemplateId: 1,
			},
			requestId: "Enterprise" + isoDate,
			requestType: "TwinNodeTemplateGet",
		};
		sendTwinHubRequest(enterpriseRequestPackage);
	}

	/**
	 * Called when the enterprise information is loaded.
	 */
	function handleEnterpriseLoaded (response: {
		properties: Array<Object>,
		uaNodeId: string,
	}) {
		if (!response) {
			return;
		}

		const { properties, uaNodeId, } = response;
		setEnterpriseDetails({
			...properties,
			...{
				uaNodeId,
			},
		});

		const isoDate = (new Date()).toISOString();

		const siteRequestPackage = {
			onSuccess: handleSitesLoaded,
			parameters: {
				parentUaNodeId: uaNodeId,
				restrictions: {
					rootOnly: false,
					signals: [],
					storedProcedures: [
						"cmc.GetSiteData",
						"cmc.[GetPRStatus]"
					],
				},
				twinNodeTemplateId: 3,
			},
			requestId: "Sites" + isoDate,
			requestType: "TwinNodeTemplateGet",
		};
		sendTwinHubRequest(siteRequestPackage);
	}

	/**
	 * Called when the sites finish loading.
	 */
	function handleSitesLoaded (response: Array<RawSiteType>) {
		const newSites: Array<SiteType> = [];
		for (let i = 0; i < response.length; i++) {
			const newSite = response[i];
			const { properties, uaNodeId, } = newSite;

			newSites.push({
				...properties,
				...{
					uaNodeId,
				},
			});
		}

		setSites(newSites);
	}

	if (!enterpriseDetails || !sites) {
		return (
			<PleaseWait />
		);
	}

	return (
		<div className="container mx-2-mobile">
			<section>
				<div className="is-flex my-4">
					<h1 className="is-size-4 has-text-weight-medium mr-4">
						{enterpriseDetails?.nodeName}
					</h1>
					<span className="divider is-right" />
					<button type="button" className="button icon is-small is-text is-light is-transparent ml-auto" aria-label="Expand/Collapse">
						<i className={`mi ${displayInfo ? "mi-remove-circle-outline" : "mi-add-circle-outline"} is-size-4`} onClick={() => setDisplayInfo(!displayInfo)} />
					</button>
				</div>
			</section>
			{
				displayInfo && (
					<>
						<section className="pb-5">
							<div className="columns is-variable is-1">
								<div className="column is-2">
									<PlantCard
										numberOfPlants={sites?.length || 0}
									/>
								</div>
								<div className="column is-5">
									<PlantPerformance
										sites={sites}
									/>
								</div>
								<div className="column is-5">
									<PlantProduction
										enterpriseDetails={enterpriseDetails}
									/>
								</div>
							</div>
						</section>
					</>
				)
			}
			<section>
				<div className="is-flex mb-5">
					<span className="divider is-right" />
					<div className="ml-auto is-size-6">
						<button type="button" aria-label="View as grid" className={`button icon is-small is-text is-light is-transparent ${displayGrid ? "" : "is-active"}`} onClick={() => setDisplayGrid(false)}>
							<i className="fas fa-bars is-size-6" />
						</button>
						<button type="button" aria-label="View as list" className={`button icon is-small is-text is-light is-transparent ${displayGrid ? "is-active" : ""} `} onClick={() => setDisplayGrid(true)}>
							<i className="fas fa-th is-size-6" />
						</button>
					</div>
				</div>

				{
					!displayGrid &&
					<PlantTable sites={sites} />
				}
				{
					displayGrid &&
					<SiteCards sites={sites} />
				}
			</section>
		</div>
	);
}
