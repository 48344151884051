import React from "react";
import { getSolarInverterStatusText, getSolarStatusClass } from "../../../functions/StatusFunctions";

type Props = {
    inverterName: string,
    model: string,
    time?: string,
    siteName: string,
    supplier: string,
    status: number,
    ACOutNominal: number,
    ACOutNominalUnit: string,
    totalDCJnNominal: number,
    totalDCJnNominalUnit: string,
    type: string,
}

/** */
export const InverterSummary = (props: Props) => {
	const {
		time,
		status,
		supplier,
		type,
		siteName,
		inverterName,
		ACOutNominal,
		ACOutNominalUnit,
		totalDCJnNominal,
		totalDCJnNominalUnit,
		model,
	} = props;

	const signalText = getSolarInverterStatusText(status);
	const signalColorClass = getSolarStatusClass(status);

	return (
		<div className="card is-full-height flex-column">
			<div className="is-flex">
				<span title={signalText} className={`tag is-small ml-3 mt-3 is-borderless is-shadowless has-text-weight-semibold px-2 ${signalColorClass}`}>
					<i className="fas fa-xs fa-check mr-1 has-text-vcentered" aria-hidden="true" />
					<span className="has-text-vcentered">{signalText || "N/A"}</span>
				</span>

				<div className="ml-auto mt-2 mr-2">
					{time &&
					<small className="has-text--grey-dark--grey is-size-8 pt-2 pr-3">
						<i className="mi mi-schedule has-text-vcentered mr-1" aria-hidden="true" />
						{time}
					</small>}

					<div className="dropdown is-hoverable">
						<div className="dropdown-trigger">
							<button type="button" className="button icon is-light is-rounded is-small" aria-label="Edit">
								<span className="icon">
									<i className="mi mi-edit" aria-hidden="true" />
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>

			<div className="card-content px-0 has-line-height-1 has-text-centered mt-auto">
				<span className="icon is-large has-text--grey--grey-dark my-4">
					<i className="icon-inverter-outlined is-size-1" aria-hidden="true" />
				</span>
				<h1>
					<span className="has-text-weight-semibold is-size-4 is-block mb-1">{inverterName}</span>
					<span className="link is-clickable is-size-7">{siteName}</span>
				</h1>
			</div>
			<footer className="is-size-7 px-4 py-4 mt-auto">
				<ul>
					<li className="is-flex">
						AC Output Nominal
						<span className="divider is-right has-text-weight-semibold ml-3">
							{ACOutNominal + " " + ACOutNominalUnit}
						</span>
					</li>
					<li className="is-flex">
						Total DC Input Nominal
						<span className="divider is-right has-text-weight-semibold ml-3">
							{totalDCJnNominal + " " + totalDCJnNominalUnit}
						</span>
					</li>
				</ul>

				<div className="columns is-mobile has-background--white-ter--black-ter has-text-centered mt-3">
					<div className="column is-narrow">Supplier<span className="has-text-weight-semibold is-block">{supplier}</span></div>
					<div className="column">Type<span className="has-text-weight-semibold is-block">{type}</span></div>
					<div className="column">Model<span className="has-text-weight-semibold is-block">{model}</span></div>
				</div>
			</footer>
		</div>

	);
};
