import React from "react";
import { Route, Switch } from "react-router-dom";
import { HomeScreen } from "./../screens/HomeScreen/HomeScreen";
import { WorldMapScreen } from "./../screens/WorldMapScreen/WorldMapScreen";
import { StartUpScreen } from "./../screens/StartUpScreen/StartUpScreen";
import { Enterprise } from "./../screens/Enterprise/Enterprise";
import { SolarSite } from "./../screens/SolarSite/SolarSite";
import { SolarInverter } from "./../screens/SolarInverter/SolarInverter";
import { SolarStringSet } from "./../screens/SolarStringSet/SolarStringSet";
import { SolarSiteKPIScreen } from "../screens/SolarSite/subscreens/SolarSiteKPIScreen";
import { SolarSiteProductionHistory } from "../screens/SolarSite/subscreens/SolarSiteProductionHistory";
import { SolarSiteInverterScreen } from "../screens/SolarSite/subscreens/SolarSiteInverterScreen";
import { SolarSiteBottomMiddleScreen } from "../screens/SolarSite/subscreens/SolarSiteBottomMiddleScreen";
import { SolarSiteAlarmsScreen } from "../screens/SolarSite/subscreens/SolarSiteAlarmsScreen";
import { SolarInverterKPIScreen } from "../screens/SolarInverter/subscreens/SolarInverterKPIScreen";
import { SolarInverterProductionHistory } from "../screens/SolarInverter/subscreens/SolarInverterProductionHistory";
import { SolarInverterStringSetScreen } from "../screens/SolarInverter/subscreens/SolarInverterStringSetScreen";
import { SolarInverterBottomMiddleScreen } from "../screens/SolarInverter/subscreens/SolarInverterBottomMiddleScreen";
import { SolarInverterAlarmsScreen } from "../screens/SolarInverter/subscreens/SolarInverterAlarmsScreen";
import { SolarStringSetAlarmsScreen } from "../screens/SolarStringSet/subscreens/SolarStringSetAlarmsScreen";
import { SolarStringSetCurrentAndVoltage } from "../screens/SolarStringSet/subscreens/SolarStringSetCurrentAndVoltage";
import { SolarStringSetPower } from "../screens/SolarStringSet/subscreens/SolarStringSetPower";
import { TwinAdminScreen } from "../screens/TwinAdmin/TwinAdminScreen/TwinAdminScreen";
import { TwinSignalAdminScreen } from "../screens/TwinAdmin/TwinSignalAdminScreen/TwinSignalAdminScreen";
import { TwinSignalTestScreen } from "../screens/TwinAdmin/TwinSignalTest/TwinSignalTestScreen";
import { TwinStoredProcedureAdminScreen } from "../screens/TwinAdmin/TwinStoredProcedureAdminScreen/TwinStoredProcedureAdminScreen";
import { TwinStoredProcedureParameterAdminScreen } from "../screens/TwinAdmin/TwinStoredProcedureParameterAdminScreen/TwinStoredProcedureParameterAdminScreen";
import { TwinParameterAdminScreen } from "../screens/TwinAdmin/TwinParameterAdminScreen/TwinParameterAdminScreen";
import { TwinExcelScreen } from "../screens/TwinAdmin/TwinExcelScreen/TwinExcelScreen";
import { TwinClassAdminScreen } from "../screens/TwinAdmin/TwinClassAdminScreen/TwinClassAdminScreen";
import { LobbyDashboard } from "../screens/LobbyDashboard/LobbyDashboard";
import { TwinNodeTemplateAdminScreen } from "../screens/TwinAdmin/TwinNodeTemplateAdminScreen/TwinNodeTemplateAdminScreen";
import { TwinNodeTemplateSignalTestScreen } from "../screens/TwinAdmin/TwinNodeTemplateSignalTest/TwinNodeTemplateSignalTestScreen";
import { TwinNodeTemplateHistoryTest } from "../screens/TwinAdmin/TwinNodeTemplateHistoryTest/TwinNodeTemplateHistoryTest";
import { StyleLoader } from "../components/StyleLoader";
import { TwinAdminMenu } from "../screens/TwinAdmin/TwinAdminMenu";
import { APITesting } from "../screens/APITesting/APITesting";

const ROUTES = [
	{
		component: () => <HomeScreen />,
		exact: true,
		key: "Home",
		menuIcon: "home",
		path: "/",
		showInMenu: false,
	},
	{
		component: () => <TwinAdminScreen />,
		exact: true,
		key: "Twin Admin Screen",
		menuIcon: "home",
		path: "/twinAdmin",
		showInMenu: false,
	},
	{
		component: () => <TwinAdminMenu />,
		exact: true,
		key: "Twin Admin Menu",
		menuIcon: "home",
		path: "/twinAdminMenu",
		showInMenu: false,
	},
	{
		component: () => <TwinSignalAdminScreen />,
		exact: true,
		key: "Twin Signal Admin Screen",
		menuIcon: "home",
		path: "/twinSignalAdmin",
		showInMenu: false,
	},
	{
		component: () => <TwinParameterAdminScreen />,
		exact: true,
		key: "Twin Parameter Admin Screen",
		menuIcon: "home",
		path: "/twinParameterAdmin",
		showInMenu: false,
	},
	{
		component: () => <TwinStoredProcedureAdminScreen />,
		exact: true,
		key: "Twin Stored Procedure Admin Screen",
		menuIcon: "home",
		path: "/twinStoredProcedureAdmin",
		showInMenu: false,
	},
	{
		component: () => <TwinStoredProcedureParameterAdminScreen />,
		exact: true,
		key: "Twin Stored Procedure Parameter Admin Screen",
		menuIcon: "home",
		path: "/twinStoredProcedureParameterAdmin",
		showInMenu: false,
	},
	{
		component: () => <TwinSignalTestScreen />,
		exact: true,
		key: "Twin Signal Test Screen",
		menuIcon: "home",
		path: "/twinSignalTest",
		showInMenu: false,
	},
	{
		component: () => <TwinNodeTemplateSignalTestScreen />,
		exact: true,
		key: "Twin Node Template Signal Test Screen",
		menuIcon: "home",
		path: "/twinNodeTemplateSignalTest",
		showInMenu: false,
	},
	{
		component: () => (
			<TwinNodeTemplateHistoryTest />
		),
		exact: true,
		key: "Twin Node Template History Test Screen",
		menuIcon: "home",
		path: "/twinNodeTemplateHistoryTest",
		showInMenu: false,
	},
	{
		component: () => <TwinExcelScreen />,
		exact: true,
		key: "Twin Excel Screen",
		menuIcon: "home",
		path: "/twinExcel",
		showInMenu: false,
	},
	{
		component: () => <TwinClassAdminScreen />,
		exact: true,
		key: "Twin Class Admin Screen",
		menuIcon: "home",
		path: "/twinClass",
		showInMenu: false,
	},
	{
		component: () => <TwinNodeTemplateAdminScreen />,
		exact: true,
		key: "Twin Node Tample Admin Screen",
		menuIcon: "home",
		path: "/twinNodeTemplateAdmin",
		showInMenu: false,
	},
	{
		component: () => <WorldMapScreen />,
		exact: true,
		key: "World Map",
		path: "/worldMap",
		showInMenu: true,
	},
	{
		component: () => <StartUpScreen />,
		exact: true,
		key: "Startup Screen",
		path: "/StartUpScreen",
		showInMenu: true,
	},
	{
		component: () => <Enterprise />,
		exact: true,
		key: "Enterprise",
		menuIcon: "globe",
		path: "/enterprise",
		showInMenu: true,
	},
	{
		component: () => <SolarSite />,
		exact: true,
		key: "Solar Site",
		path: "/solarSite",
		showInMenu: false,
	},
	{
		component: () => <SolarSiteKPIScreen />,
		exact: true,
		key: "Solar Site KPIs",
		path: "/solarSiteKPIs",
		showInMenu: false,
	},
	{
		component: () => <SolarSiteProductionHistory />,
		exact: true,
		key: "Solar Site Production History",
		path: "/solarSiteProductionHistory",
		showInMenu: false,
	},
	{
		component: () => <SolarSiteInverterScreen />,
		exact: true,
		key: "Solar Site Inverters",
		path: "/solarSiteInverters",
		showInMenu: false,
	},
	{
		component: () => <SolarSiteBottomMiddleScreen />,
		exact: true,
		key: "Solar Site Bottom Middle Tabs",
		path: "/solarSiteBottomMiddleTabs",
		showInMenu: false,
	},
	{
		component: () => <SolarSiteAlarmsScreen />,
		exact: true,
		key: "Solar Site Alarms",
		path: "/solarSiteAlarms",
		showInMenu: false,
	},
	{
		component: () => <SolarInverter />,
		exact: true,
		key: "Solar Inverter",
		path: "/solarInverter",
		showInMenu: false,
	},
	{
		component: () => <SolarInverterKPIScreen />,
		exact: true,
		key: "Solar Inverter KPIs",
		path: "/solarInverterKPIs",
		showInMenu: false,
	},
	{
		component: () => <SolarInverterProductionHistory />,
		exact: true,
		key: "Solar Inverter Production History",
		path: "/solarInverterProductionHistory",
		showInMenu: false,
	},
	{
		component: () => <SolarInverterStringSetScreen />,
		exact: true,
		key: "Solar Site Inverters",
		path: "/solarInverterStringSets",
		showInMenu: false,
	},
	{
		component: () => <SolarInverterBottomMiddleScreen />,
		exact: true,
		key: "Solar Inverter Bottom Middle Tabs",
		path: "/solarInverterBottomMiddleTabs",
		showInMenu: false,
	},
	{
		component: () => <SolarInverterAlarmsScreen />,
		exact: true,
		key: "Solar Inverter Alarms",
		path: "/solarInverterAlarms",
		showInMenu: false,
	},
	{
		component: () => <SolarStringSet />,
		exact: true,
		key: "Solar String Set",
		path: "/solarStringSet",
		showInMenu: false,
	},
	{
		component: () => <SolarStringSetAlarmsScreen />,
		exact: true,
		key: "Solar String Set Alarms",
		path: "/solarStringSetAlarms",
		showInMenu: false,
	},
	{
		component: () => <SolarStringSetCurrentAndVoltage />,
		exact: true,
		key: "Solar String Set Current and Voltage",
		path: "/solarStringSetCurrentAndVoltage",
		showInMenu: false,
	},
	{
		component: () => <SolarStringSetPower />,
		exact: true,
		key: "Solar String Set Power",
		path: "/solarStringSetPower",
		showInMenu: false,
	},
	{
		component: () => <LobbyDashboard />,
		exact: true,
		key: "Lobby Dashboard",
		path: "/lobby",
		showInMenu: false,
	},
	{
		component: () => <APITesting />,
		exact: true,
		key: "API Testing",
		path: "/apiTesting",
		showInMenu: false,
	}
];

export default ROUTES;

/**
 * Render a route with potential sub routes
 * https://reacttraining.com/react-router/web/example/route-config
 */
function RouteWithSubRoutes (route) {
	const {
		mode,
		style,
	} = route;

	return (
		<Route
			exact={route.exact}
			path={route.path}
			render={(props) => {
				return (
					<>
						<route.component {...props} routes={route.routes} />
						<StyleLoader style={style} mode={mode} />
					</>
				);
			}}
		/>
	);
}

/**
 * Use this component for any new section of routes (any config object that has a "routes" property
 */
export function RenderRoutes ({ mode, routes, style, }: { mode: string, routes: Array<Object>, style: Object }) {
	return (
		<Switch>
			{routes.map((route, i) => {
				return <RouteWithSubRoutes key={route.key} {...route} style={style} mode={mode} />;
			})}
			<Route component={() => <h1>Not Found!</h1>} />
		</Switch>
	);
}
