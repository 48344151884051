import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FetchHistorianData } from "../../../Redux/Slices/backendSlice";
import { getGraphGranularity, getGraphLabels } from "../../../functions/PeriodFunctions";
import { ApexChartSeriesType, HistoricalResponseType } from "../../../Types";
import { MixedLineAndBarChart } from "../../../components/MixedLineAndBarChart/MixedLineAndBarChart";

type Props = {
	id: string,
	startTime: Date,
	endTime: Date,
}

/**
 * Displays the current and voltage chart.
 */
export const CurrentAndVoltageChart = (props: Props) => {
	const { id, startTime, endTime, } = props;
	const dispatch = useDispatch();
	const [ series, setSeries ] = useState<Array<ApexChartSeriesType>>([]);
	const [ labels, setLabels ] = useState<Array<string>>([]);

	useEffect(() => {
		const granularity = getGraphGranularity(startTime, endTime);
		/**
		 * Handles the historian data loading.
		 */
		const handleTrendsLoaded = (trends: Array<HistoricalResponseType>) => {
			const currentSeries: Array<number> = [];
			const voltageSeries: Array<number> = [];
			const dateLabels: Array<Date> = [];
			// for (let i = 0; i < trends.length; i++) {
			// const trend = trends[i];

			// currentSeries.push(trend["dc.current_avg"]);
			// voltageSeries.push(trend["dc.voltage_avg"]);
			// dateLabels.push(new Date(trend.__time));
			// }
			const graphLabels = getGraphLabels(dateLabels, startTime, endTime);
			setLabels(graphLabels);

			setSeries([
				{
					axisIndex: 0,
					axisPosition: "right",
					data: currentSeries,
					name: "Current",
					numberOfDecimals: 1,
					type: "area",
					unit: "A",
					unitPosition: "after",
				},
				{
					axisIndex: 1,
					axisPosition: "left",
					data: voltageSeries,
					name: "Voltage",
					numberOfDecimals: 1,
					type: "area",
					unit: "V",
					unitPosition: "after",
				}
			]);
		};

		dispatch(
			FetchHistorianData({
				endTime: endTime.toISOString(),
				entityIds: [ id ],
				granularity,
				onFailure: (response: string) => alert(response),
				onSuccess: (trends: Array<HistoricalResponseType>) => { handleTrendsLoaded(trends); },
				startTime: startTime.toISOString(),
				variables: [
					"dc.current",
					"dc.voltage"
				],
			})
		);
	}, [ id, startTime, endTime, dispatch ]);

	return (
		<MixedLineAndBarChart labels={labels} series={series} />
	);
};
