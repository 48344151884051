import React, { useEffect, useState } from "react";

type Props = {
	classNames: string,
	onSelect: (startTime: Date, endTime: Date) => void,
	value: string,
};

/**
 * Displays an area chart.
 */
export const PeriodSelect = (props: Props) => {
	const { classNames, value, onSelect, } = props;
	const [ currentValue, setCurrentValue ] = useState(value);

	useEffect(() => {
		setCurrentValue(value);
	}, [ value ]);

	/**
	 * Called when a new period is selected.
	 */
	function handleSelect (event: any) {
		const { target, } = event;
		if (!target) {
			return;
		}

		const { value, } = target;
		if (value === currentValue) {
			return;
		}

		setCurrentValue(value);

		const currentTime = new Date();
		const startOfHour = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), currentTime.getHours(), 0, 0, 0);
		const startOfDay = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate(), 0, 0, 0, 0);
		const startOfMonth = new Date(currentTime.getFullYear(), currentTime.getMonth(), 1, 0, 0, 0, 0);
		const endOfMonth = new Date(currentTime.getFullYear(), currentTime.getMonth() + 1, 0, 0, 0, 0, 0);
		const startOfPreviousMonth = new Date(currentTime.getFullYear(), currentTime.getMonth() - 1, 1, 0, 0, 0, 0);
		const endOfPreviousMonth = new Date(currentTime.getFullYear(), currentTime.getMonth(), 0, 0, 0, 0, 0);
		const startOfYear = new Date(currentTime.getFullYear(), 0, 1, 0, 0, 0, 0);
		const endOfYear = new Date(currentTime.getFullYear() + 1, 0, 0, 0, 0, 0, 0);
		const startOfPreviousYear = new Date(currentTime.getFullYear() - 1, 0, 1, 0, 0, 0, 0);
		const endOfPreviousYear = new Date(currentTime.getFullYear(), 0, 0, 0, 0, 0, 0);

		switch (value) {
			case "Last 60 minutes":
				onSelect(addHours(currentTime, -1), currentTime);
				break;
			case "Current hour":
				onSelect(addHours(startOfHour, -1), startOfHour);
				break;
			case "Previous hour":
				onSelect(addHours(startOfHour, -2), addHours(startOfHour, -1));
				break;
			case "Last 24 hours":
				onSelect(addHours(currentTime, -24), currentTime);
				break;
			case "Current day":
				onSelect(startOfDay, addHours(startOfDay, 24));
				break;
			case "Previous day":
				onSelect(addHours(startOfDay, -24), startOfDay);
				break;
			case "Current month":
				onSelect(startOfMonth, endOfMonth);
				break;
			case "Previous month":
				onSelect(startOfPreviousMonth, endOfPreviousMonth);
				break;
			case "Current year":
				onSelect(startOfYear, endOfYear);
				break;
			case "Previous year":
				onSelect(startOfPreviousYear, endOfPreviousYear);
				break;
			default:
				onSelect(addHours(currentTime, -1), currentTime);
				break;
		}
	}

	return (
		<div className={"select " + (classNames || "")}>
			<select
				aria-label="Period select"
				onChange={handleSelect}
				value={currentValue}
			>
				<option value="Last 60 minutes">Last 60 minutes</option>
				<option value="Current hour">Current hour</option>
				<option value="Previous hour">Previous hour</option>
				<option disabled>------------------</option>
				<option value="Last 24 hours">Last 24 hours</option>
				<option value="Current day">Current day</option>
				<option value="Previous day">Previous day</option>
				<option disabled>------------------</option>
				<option value="Current month">Current month</option>
				<option value="Previous month">Previous month</option>
				<option disabled>------------------</option>
				<option value="Current quarter">Current quarter</option>
				<option value="Previous quarter">Previous quarter</option>
				<option disabled>------------------</option>
				<option value="Current year">Current year</option>
				<option value="Previous year">Previous year</option>
			</select>
		</div>
	);
};

/**
 * Adds hours to a date.
 */
function addHours (oldDate: Date, numberOfHours: number) {
	const newDate = new Date();

	newDate.setTime(oldDate.getTime() + (numberOfHours * 60 * 60 * 1000));
	return newDate;
}
