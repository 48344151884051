import React from "react";
import { useHistory } from "react-router-dom";

type Props = {
	id?: string,
	name?: string,
	status?: number,
}

/**
 * Displays a status square for an inverter.
 */
export function InverterSquare (props: Props) {
	const history = useHistory();
	const { id, name, status, } = props;

	const className = status === 0 ? "button is-danger is-size-small has-text-weight-semibold px-1" : "button is-success is-size-small has-text-weight-semibold px-1";

	return (
		<span
			className={className}
			onClick={() => { history.push("/solarInverter?uaNodeId=" + id); }}
		>{name}
		</span>
	);
}
