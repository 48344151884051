import React, { useState } from "react";
import { isNull, roundNumber } from "../../../functions/UtilityFunctions";
import { AvgMaxMinBoxes } from "./AvgMaxMinBoxes";
import { useHistory } from "react-router-dom";
import { BarChartWithLimit } from "../../../components/BarChartWithLimit";
import { ButtonExpand } from "../../../components/ButtonExpand";
import { StringSetType } from "../../../Types";

type Props = {
	stringSets: Array<StringSetType>,
}

/**
 * The tabbable controls at the bottom middle of the plant dasboard.
 */
export const BottomMiddleTabs = (props: Props) => {
	const { stringSets, } = props;
	const [ activeTab, setActiveTab ] = useState("specificPower");
	const history = useHistory();
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const id = urlParams.get("i");

	const labels = [];
	const specificPowers = [];
	const dcPowers = [];
	const dcCurrents = [];
	const dcVoltages = [];

	let totalSpecificPower = 0;
	let totaldcPower = 0;
	let totaldcCurrent = 0;
	let totaldcVoltage = 0;
	let maxSpecificPower = 0;
	let maxdcPower = 0;
	let maxdcCurrent = 0;
	let maxdcVoltage = 0;
	let minSpecificPower = 0;
	let mindcPower = 0;
	let mindcCurrent = 0;
	let mindcVoltage = 0;
	let avgSpecificPower = 0;
	let avgdcPower = 0;
	let avgdcCurrent = 0;
	let avgdcVoltage = 0;

	if (stringSets) {
		stringSets.sort((a, b) => {
			const aName = a?.name;
			const aShortName = a?.shortName;
			const bName = b?.name;
			const bShortName = b?.shortName;

			if (aShortName && bShortName) {
				if (aShortName < bShortName) {
					return -1;
				}

				return 1;
			} else if (aName && bName) {
				if (aName < bName) {
					return -1;
				}

				return 1;
			}

			return 1;
		});

		for (let i = 0; i < stringSets.length; i++) {
			const stringSet = stringSets[i];

			labels.push(stringSet?.shortName || stringSet?.name || "");

			const specificPower = stringSet?.specificPower || 0;
			if (!isNull(specificPower)) {
				totalSpecificPower += specificPower;
				specificPowers.push(roundNumber(specificPower, 4));
				if (specificPower > maxSpecificPower) {
					maxSpecificPower = specificPower;
				}
				if (isNull(minSpecificPower) || specificPower < minSpecificPower) {
					minSpecificPower = specificPower;
				}
			}

			const dcPower = stringSet?.dcPower || stringSet?.dcPowerCalculated;
			if (!isNull(dcPower)) {
				totaldcPower += dcPower;
				dcPowers.push(dcPower);
				if (dcPower > maxdcPower) {
					maxdcPower = dcPower;
				}
				if (isNull(mindcPower) || dcPower < mindcPower) {
					mindcPower = dcPower;
				}
			}

			const dcVoltage = stringSet?.dcVoltage;
			if (!isNull(dcVoltage) && !isNull(dcPower)) {
				totaldcVoltage += dcVoltage;
				dcVoltages.push(dcVoltage);
				if (dcPower > maxdcVoltage) {
					maxdcVoltage = dcVoltage;
				}
				if (isNull(mindcVoltage) || dcVoltage < mindcVoltage) {
					mindcVoltage = dcVoltage;
				}
			}

			const dcCurrent = stringSet?.dcCurrent;
			if (!isNull(dcCurrent)) {
				totaldcCurrent += dcCurrent;
				dcCurrents.push(dcCurrent);
				if (dcCurrent > maxdcCurrent) {
					maxdcCurrent = dcCurrent;
				}
				if (isNull(mindcCurrent) || dcCurrent < mindcCurrent) {
					mindcCurrent = dcCurrent;
				}
			}
		}

		avgSpecificPower = totalSpecificPower / specificPowers.length;
		avgdcPower = totaldcPower / dcPowers.length;
		avgdcCurrent = totaldcCurrent / dcCurrents.length;
		avgdcVoltage = totaldcVoltage / dcVoltages.length;
	}

	return (
		<div className="card is-full-height">
			<header className="card-header has-tabs">
				<div className="tabs mb-0">
					<ul>
						<li
							className={activeTab === "specificPower" ? "tab has-text-weight-semibold is-uppercase is-size-7 is-active" : "tab has-text-weight-semibold is-uppercase is-size-7"}
							onClick={() => setActiveTab("specificPower")}
						>
							<span className="is-clickable py-3 px-4">
								Specific power
							</span>
						</li>
						<li
							className={activeTab === "dcPower" ? "tab has-text-weight-semibold is-uppercase is-size-7 is-active" : "tab has-text-weight-semibold is-uppercase is-size-7"}
							onClick={() => setActiveTab("dcPower")}
						>
							<span className="is-clickable py-3 px-4">
								DC Power
							</span>
						</li>
						<li
							className={activeTab === "dcCurrent" ? "tab has-text-weight-semibold is-uppercase is-size-7 is-active" : "tab has-text-weight-semibold is-uppercase is-size-7"}
							onClick={() => setActiveTab("dcCurrent")}
						>
							<span className="is-clickable py-3 px-4">
								DC Current
							</span>
						</li>
						<li
							className={activeTab === "dcVoltage" ? "tab has-text-weight-semibold is-uppercase is-size-7 is-active" : "tab has-text-weight-semibold is-uppercase is-size-7"}
							onClick={() => setActiveTab("dcVoltage")}
						>
							<span className="is-clickable py-3 px-4">
								DC Voltage
							</span>
						</li>
					</ul>
				</div>

				<span className="is-absolute is-top is-right">
					<ButtonExpand onClick={() => { history.push(`/solarInverterBottomMiddleTabs?i=${id}`); }} />
				</span>

			</header>
			<div className="card-content p-2">
				{
					activeTab === "specificPower" && (
						<div id="tab-name" className="content-tab">
							<div className="chart is-medium px-2">
								<BarChartWithLimit
									labels={labels}
									// limit={avgSpecificPower}
									average={avgSpecificPower || 0}
									max={maxSpecificPower}
									min={minSpecificPower}
									series={[ {
										data: specificPowers,
										name: "Specific Power",
										numberOfDecimals: 2,
										unit: "W/Wp",
										unitPosition: "before",
									} ]}
								/>
							</div>
							<div className="px-2">
								<AvgMaxMinBoxes avg={avgSpecificPower} max={maxSpecificPower} min={minSpecificPower} />
							</div>
						</div>
					)
				}
				{
					activeTab === "dcPower" && (
						<div id="tab-name" className="content-tab">
							<div className="chart is-medium px-2">
								<BarChartWithLimit
									labels={labels}
									// limit={avgdcPower}
									average={avgdcPower || 0}
									max={maxdcPower}
									min={mindcPower}
									series={
										[
											{
												data: dcPowers,
												name: "DC Power",
												numberOfDecimals: 2,
												unit: "W",
												unitPosition: "before",
											}
										]
									}
								/>
							</div>
							<div className="px-2">
								<AvgMaxMinBoxes avg={avgdcPower} max={maxdcPower} min={mindcPower} />
							</div>
						</div>
					)
				}
				{
					activeTab === "dcCurrent" && (
						<div id="tab-name" className="content-tab">
							<div className="chart is-medium px-2">
								<BarChartWithLimit
									labels={labels}
									// limit={avgdcCurrent}
									average={avgdcCurrent || 0}
									max={maxdcCurrent}
									min={mindcCurrent}
									series={[ {
										data: dcCurrents,
										name: "DC Current",
										numberOfDecimals: 2,
										unit: "A",
										unitPosition: "before",
									} ]}
								/>
							</div>
							<div className="px-2">
								<AvgMaxMinBoxes avg={avgdcCurrent} max={maxdcCurrent} min={mindcCurrent} />
							</div>
						</div>
					)
				}
				{
					activeTab === "dcVoltage" && (
						<div id="tab-name" className="content-tab">
							<div className="chart is-medium px-2">
								<BarChartWithLimit
									labels={labels}
									// limit={avgdcVoltage}
									average={avgdcVoltage || 0}
									max={maxdcVoltage}
									min={mindcVoltage}
									series={[ {
										data: dcVoltages,
										name: "DC Voltage",
										numberOfDecimals: 2,
										unit: "V",
										unitPosition: "before",
									} ]}
								/>
							</div>
							<div className="px-2">
								<AvgMaxMinBoxes avg={avgdcVoltage} max={maxdcVoltage} min={mindcVoltage} />
							</div>
						</div>
					)
				}
			</div>
		</div>

	);
};
