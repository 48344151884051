import React from "react";
import { formatDisplayNumber } from "../../../../functions/formatDisplayNumber";
import { isNull } from "../../../../functions/UtilityFunctions";

type Props = {
	maxACPower: Number,
	nominalDCPower: Number,
	timezone: String,
}

/**
 * Displays summary metrics for the lobby.
 */
export const Metrics = (props: Props) => {
	const {
		maxACPower,
		nominalDCPower,
		timezone,
	} = props;

	const currentDate = new Date();

	/**
	 * Converts the current time to the site time.
	 */
	function convertTZ (date, tzString) {
		const formattedDate = typeof date === "string" ? new Date(date) : date;

		try {
			const localeDate = formattedDate.toLocaleString("en-US", { timeZone: tzString, });
			const date = new Date(localeDate);

			return date.toLocaleTimeString();
		} catch {
			return "N/A";
		}
	}

	const displayDate = convertTZ(currentDate, timezone);

	const showMaxACPower = formatDisplayNumber(maxACPower);
	const showNominalDCPower = formatDisplayNumber(nominalDCPower);

	return (
		<ul>
			<li className="is-flex">
				Max AC Power
				<div className="divider is-dashed is-right ml-3">
					<span className="has-text-weight-semibold">{showMaxACPower} KW</span>
				</div>
			</li>
			<li className="is-flex">
				Nominal DC Power
				<div className="divider is-dashed is-right ml-3">
					<span className="has-text-weight-semibold">{showNominalDCPower} KW</span>
				</div>
			</li>
			<li>
				<div
					className="tag is-small is-dark is-absolute is-top is-right px-1 m-1"
					title={displayDate === "N/A" ? "Invalid timezone: " + timezone : "Timezone: " + timezone}
					style={{ opacity: "0.8", }}
				>
					<i className="mi mi-schedule has-text-vcentered mr-1" aria-hidden="true" />
					<small>{isNull(timezone) ? "N/A" : displayDate}</small>
				</div>
			</li>
		</ul>
	);
};
