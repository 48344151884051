import React from "react";

type Props = {
	max?: number,
	value?: number,
	valueUnit?: string,
	progressValue?: number
}

/** Displays the current value */
export const SitePower = (props: Props) => {
	const { max, value, valueUnit, progressValue, } = props;

	return (
		<div className="has-line-height-1">
			<small>{value + " " + valueUnit}</small>
			<progress className="progress is-smallest mt-1" value={progressValue} max={max || 100}>{progressValue} %</progress>
		</div>
	);
};
