import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { PeriodSelect } from "../../../components/PeriodSelect";
import { ProductionChart } from "../components/ProductionChart";

/**
 * This dashboard displays details about a site/plant.
 */
export const SolarSiteProductionHistory = () => {
	const currentDate = new Date();
	const oneDayAgo = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours() - 24, currentDate.getMinutes(), currentDate.getSeconds());

	const [ productionChartTimes, setProductionChartTimes ] = useState({
		endTime: currentDate,
		startTime: oneDayAgo,
	});

	const history = useHistory();

	return (
		<section>
			<div className="columns">
				<div
					className="column"
					onClick={() => { history.goBack(); }}
					style={{ cursor: "pointer", }}
				>
					<i className="fas fa-arrow-left mr-3" style={{ float: "left", }} />
					<p style={{ float: "left", }}>Return</p>
				</div>
			</div>
			<div className="columns" style={{ height: "90vh", }}>
				<div className="column is-full-height">
					<div className="card is-full-height">
						<header className="card-header pr-5">
							<h2 className="card-header-title is-uppercase is-size-7">Production history</h2>
							<div className="m-auto pr-3">
								<PeriodSelect
									classNames="is-smaller"
									onSelect={(startTime, endTime) => {
										setProductionChartTimes({
											endTime,
											startTime,
										});
									}}
									value="Last 24 hours"
								/>
							</div>
						</header>
						<div className="card-content px-2" style={{ height: "calc(100% - 42px)", }}>
							<div className="chart is-full-height">
								<ProductionChart
									extendedProperties={{}}
									startTime={productionChartTimes.startTime}
									endTime={productionChartTimes.endTime}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
