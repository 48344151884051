import React from "react";
import { StringCombinerSquare } from "./StringCombinerSquare";
import { getSolarStringSetStatusText, getSolarStatusColor } from "../../../functions/StatusFunctions";
import { StringSetType, TileType } from "../../../Types";

type Props = {
	stringSets: Array<StringSetType>,
}

/** Inverter Overview Screen */
export function StringSetOverview (props: Props) {
	const { stringSets, } = props;

	const tiles: Array<TileType> = [];
	for (let i = 0; i < stringSets.length; i++) {
		const stringSet = stringSets[i];
		const shortNameLoaded = stringSet?.nodeName;
		const shortName = shortNameLoaded || "S" + (i + 1);

		tiles.push({
			backgroundColor: getSolarStatusColor(stringSet?.status || 0),
			id: stringSet?.nodeId,
			name: shortName,
			status: "Ok",
			tooltip: getSolarStringSetStatusText(stringSet?.status || 0),
		});
	}

	tiles.sort((a, b) => {
		const aName = a.name;
		const bName = b.name;

		if (aName < bName) {
			return -1;
		}

		return 1;
	});

	return (
		<div className="grid is-2-fullhd is-2-widescreen is-3-desktop is-1-tablet is-2-mobile has-gap-smaller">
			{tiles && tiles.length > 0 && tiles.map((item: TileType, index: number) => {
				const {
					backgroundColor,
					id,
					name,
					status,
					tooltip,
				} = item;

				return (
					<StringCombinerSquare
						key={index}
						backgroundColor={backgroundColor}
						id={id}
						name={name}
						status={status || "Ok"}
						tooltip={tooltip}
					/>
				);
			})}
		</div>
	);
}
