import React from "react";
import { TwinStoredProcedureParameterType } from "../../../../Types";

type Props = {
	item: TwinStoredProcedureParameterType,
	name?: string,
	onDelete: Function,
	onEdit: Function
}

/**
 * Displays a row for a parameter.
 */
export const TwinStoredProcedureParameter = (props: Props) => {
	const { item, name, onDelete, onEdit, } = props;

	return (
		<tr className="level--0">
			<td className="is-borderless">
				{name}
			</td>
			<td className="is-narrow is-vcentered is-borderless">
				<div className="field is-grouped is-pulled-right">
					<a
						className="button is-small is-icon is-hoverable mr-2 has-tooltip-arrow"
						data-tooltip="Edit"
						onClick={() => { onEdit(item); }}
					>
						<span className="icon is-small">
							<i className="fa fa-edit" />
						</span>
					</a>
				</div>
			</td>
			<td className="is-narrow is-vcentered is-borderless">
				<div className="field is-grouped is-pulled-right">
					<a
						className="button is-small is-icon is-hoverable mr-2 has-tooltip-arrow"
						data-tooltip="Delete"
						onClick={() => { onDelete(item); }}
					>
						<span className="icon is-small">
							<i className="fa fa-trash" />
						</span>
					</a>
				</div>
			</td>
		</tr>
	);
};
