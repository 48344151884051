import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SiteCard, WeatherCard } from "../../components";
import { InverterOverviewScreen } from "./components/InverterOverviewScreen";
import { BottomMiddleTabs } from "./components/BottomMiddleTabs";
import { KPIs } from "./components/KPIs";
import { ProductionChart } from "./components/ProductionChart";
import { ProductionCounters } from "./components/ProductionCounters";
import { getSolarSiteStatusText, getSolarStatusClass } from "../../functions/StatusFunctions";
import { HubContext } from "../MainScreen/MainScreen";
import { PleaseWait } from "../../components/PleaseWait";
import { IconValueStats } from "../../components/IconValueStats";
import { Gauge } from "../../components/Gauge";
import { scaleUnitAndAmount } from "../../functions/scaleUnitAndAmount";
import { formatDisplayNumber } from "../../functions/formatDisplayNumber";
import { PeriodSelect } from "../../components/PeriodSelect";
import { AlarmList } from "../../components/AlarmList";
import { ButtonExpand } from "../../components/ButtonExpand";
import { ExtendedSiteType, InverterType, RawInverterType, RawSiteType, SiteType } from "../../Types";

/**
 * This dashboard displays details about a site/plant.
 */
export const SolarSite = () => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const uaNodeId = urlParams.get("uaNodeId");

	const currentDate = new Date();
	const oneDayAgo = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours() - 24, currentDate.getMinutes(), currentDate.getSeconds());
	const { sendTwinHubRequest, } = useContext(HubContext);

	const [ siteDetails, setSiteDetails ] = useState<SiteType | null>(null);
	const [ siteExtendedDetails, setSiteExtendedDetails ] = useState<ExtendedSiteType | null>(null);
	const [ inverters, setInverters ] = useState<Array<InverterType>>([]);
	const [ alarmNodes, setAlarmNodes ] = useState<Array<string>>([]);
	const [ productionChartTimes, setProductionChartTimes ] = useState({
		endTime: currentDate,
		startTime: oneDayAgo,
	});

	const history = useHistory();

	useEffect(() => {
		const interval = setInterval(() => {
			loadSiteInfo();
		}, 300000);
		return () => clearInterval(interval);
	}, []);

	/**
	 * Loads the site info from the backend.
	 */
	function loadSiteInfo () {
		if (uaNodeId) {
			const isoDate = (new Date()).toISOString();

			const enterpriseRequestPackage = {
				onSuccess: handleSiteLoaded,
				parameters: {
					twinNodeTemplateId: 3,
					uaNodeId,
				},
				requestId: "Site" + isoDate,
				requestType: "TwinNodeTemplateGet",
			};
			sendTwinHubRequest(enterpriseRequestPackage);
		}
	}

	useEffect(() => {
		if (uaNodeId) {
			loadSiteInfo();
		}
	}, [ uaNodeId ]);

	/**
	 * Called when the site details are loaded.
	 */
	function handleSiteLoaded (response: RawSiteType) {
		if (!response) {
			return;
		}

		const { extendedProperties, properties, uaNodeId, } = response;
		setSiteDetails({
			...properties,
			...{
				uaNodeId,
			},
		});
		setSiteExtendedDetails(extendedProperties);

		const isoDate = (new Date()).toISOString();

		const siteRequestPackage = {
			onSuccess: (response: Array<RawInverterType>) => { handleInvertersLoaded(uaNodeId || "Unknown", response); },
			parameters: {
				ancestorTwinNodeTemplateId: 3,
				ancestorUaNodeId: uaNodeId,
				restrictions: {
					rootOnly: false,
				},
				twinNodeTemplateId: 7,
			},
			requestId: "Inverters" + isoDate,
			requestType: "TwinNodeTemplateGet",
		};
		sendTwinHubRequest(siteRequestPackage);
	}

	/**
	 * Called when the inverters are loaded.
	 */
	function handleInvertersLoaded (siteNodeId: string, response: Array<RawInverterType>) {
		const newInverters: Array<InverterType> = [];
		const newAlarmNodes: Array<string> = [ siteNodeId ];
		for (let i = 0; i < response.length; i++) {
			const newInverter = response[i];
			const { properties, uaNodeId, } = newInverter;

			if (uaNodeId) {
				newAlarmNodes.push(uaNodeId);
				newInverters.push({
					...properties,
					...{
						uaNodeId,
					},
				});
			}
		}

		setInverters(newInverters);
		setAlarmNodes(newAlarmNodes);
	}

	if (!siteDetails || !inverters) {
		return (
			<PleaseWait />
		);
	}

	const {
		localTime,
		siteName,
	} = siteDetails;

	const siteStatus = siteDetails?.status;
	const siteCardClass = getSolarStatusClass(siteStatus || 0);
	const siteCardText = getSolarSiteStatusText(siteStatus || 0);

	const {
		amount: displayACActivePower,
		unit: displayACActivePowerUnit,
	} = scaleUnitAndAmount(siteDetails?.aCActivePower || 0, "W");

	return (
		<div className="container my-4 mx-2-mobile">
			<section>

				<div className="columns is-variable is-1">
					<div className="column is-6">
						<div className="columns is-variable is-1 mb-0">
							<div className="column is-6 pb-2">
								<SiteCard
									name={siteName || "Unknown"}
									ACOutNominal={siteDetails?.nominalACPower || 0}
									ACOutNominalUnit="W"
									siteStatusClass={siteCardClass || "Unknown"}
									badgeText={siteCardText}
									totalDCJnNominal={siteDetails?.nominalDCPower || 0}
									totalDCJnNominalUnit="W"
									time={localTime || new Date()}
								/>
							</div>
							<div className="column is-6 pb-2">
								<KPIs
									includeExpand
									signals={siteDetails}
								/>
							</div>
						</div>
						<div className="columns is-variable is-1">
							<div className="column is-6">
								<WeatherCard {...siteDetails} />
							</div>
							<div className="column is-6">
								<div className="columns is-mobile is-full-height is-gapless">
									<div className="column is-narrow has-text-centered is-full-height mr-1">
										<div className="card flex-column is-full-height p-3">
											<div className="icon gauge--2 is-larger m-auto">
												<Gauge
													min={0}
													max={siteDetails?.nominalACPower}
													value={siteDetails?.aCActivePower}
												/>
											</div>
											<IconValueStats
												value={formatDisplayNumber(displayACActivePower, 0).toString()}
												unit={displayACActivePowerUnit}
												description="Active Power"
											/>
										</div>
									</div>

									<div className="column has-text-centered is-full-height ml-1">
										<div className="card flex-column is-full-height">
											<header className="card-header">
												<h2 className="card-header-title is-uppercase is-size-7 is-truncated px-3">Production counters</h2>
											</header>
											<footer className="is-size-7 mt-auto px-3 pb-3">
												<ProductionCounters {...siteDetails} />
											</footer>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
					<div className="column is-6">
						<div className="card is-full-height">
							<header className="card-header pr-5">
								<h2 className="card-header-title is-uppercase is-size-7">Production history</h2>
								<div className="m-auto pr-3">
									<PeriodSelect
										classNames="is-smaller"
										onSelect={(startTime, endTime) => {
											setProductionChartTimes({
												endTime,
												startTime,
											});
										}}
										value="Last 24 hours"
									/>
								</div>
								<span className="is-absolute is-top is-right">
									<ButtonExpand onClick={() => { history.push(`/solarSiteProductionHistory?i=${uaNodeId}`); }} />
								</span>
							</header>
							<div className="card-content px-2 pb-2" style={{ height: "calc(100% - 42px)", }}>
								<div className="chart is-full is-large-mobile">
									<ProductionChart
										extendedProperties={siteExtendedDetails}
										startTime={productionChartTimes.startTime}
										endTime={productionChartTimes.endTime}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="columns">
					<div className="column is-8">
						<h2 className="is-flex is-size-5 has-text-weight-medium mb-3 pr-1">
							<span>
								<i className="icon-inverter-outlined is-size-6 mr-2" aria-hidden="true" />
								Inverters
							</span>
							<span className="divider is-right ml-3">
								{siteDetails?.noOfInverters || 0}
							</span>
						</h2>

						<div className="columns is-variable is-1">
							<div className="column">
								<InverterOverviewScreen
									inverters={inverters}
									siteId={uaNodeId || "Unknown"}
								/>
							</div>
							<div className="column">
								<BottomMiddleTabs inverters={inverters} />
							</div>
						</div>
					</div>

					<div className="column is-4">
						<h2 className="divider is-left is-size-5 has-text-weight-medium mb-3">
							Alarms
						</h2>
						<div className="card" style={{ height: "calc(100% - 42px)", }}>
							<span className="is-absolute is-top is-right">
								<ButtonExpand onClick={() => { history.push(`/solarSiteAlarms?i=${uaNodeId}`); }} />
							</span>
							<AlarmList uaNodeIds={alarmNodes} />
						</div>
					</div>
				</div>

			</section>
		</div>
	);
};
