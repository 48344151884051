import React, { useContext, useEffect, useState } from "react";
import { HubContext, StyleContext } from "../MainScreen/MainScreen";
import { SiteCardsByRegion } from "./components/SiteCardsByRegion";
import { SiteCardsGrid } from "./components/SiteCardsGrid";
import { PleaseWait } from "../../components/PleaseWait";
import { SiteType } from "../../Types";

/**
 * This is the startup screen
 */
export const StartUpScreen = () => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const cardIsClickable = urlParams.get("clickable") !== "false";
	const showSelectors = urlParams.get("showselectors") !== "false";
	const showCardsByRegion = urlParams.get("regions") !== "false";

	const { sites, } = useContext(HubContext);
	const { mode, setMode, } = useContext(StyleContext);
	const [ showByRegion, setShowByRegion ] = useState(showCardsByRegion);

	useEffect(() => {
		setShowByRegion(showCardsByRegion);
	}, [ showCardsByRegion ]);

	let displayRegions: Array<{
		name: string,
		sites: Array<SiteType>,
	}> = [];

	if (sites && sites.length > 0) {
		if (!showByRegion) {
			const newSites = [];
			for (let i = 0; i < sites.length; i++) {
				newSites.push(sites[i].properties);
			}

			displayRegions = [
				{
					name: "Sites",
					sites: newSites,
				}
			];
		} else {
			const newRegions: Record<string, Array<SiteType>> = {};
			for (let i = 0; i < sites.length; i++) {
				const newSite = sites[i];
				const { properties, twinId, } = newSite;
				const regionTitle = properties?.regionTitle || "Unknown";

				if (!newRegions[regionTitle]) {
					newRegions[regionTitle] = [];
				}

				newRegions[regionTitle].push({
					...properties,
					...{
						twinId,
					},
				});
			}

			const newRegionsArray = [];
			for (const prop in newRegions) {
				newRegionsArray.push({
					name: prop,
					sites: newRegions[prop],
				});
			}

			displayRegions = newRegionsArray;
		}
	}

	displayRegions.sort((a, b) => {
		const { name: aName, } = a;
		const { name: bName, } = b;

		if (aName < bName) {
			return -1;
		}

		return 1;
	});

	for (let i = 0; i < displayRegions.length; i++) {
		const { sites, } = displayRegions[i];

		sites.sort((a, b) => {
			const { countryCode: aCountryCode, } = a;
			const { countryCode: bCountryCode, } = b;

			if (aCountryCode && bCountryCode) {
				if (aCountryCode < bCountryCode) {
					return -1;
				}
			}

			if (aCountryCode === bCountryCode) {
				const { siteName: aSiteName, } = a;
				const { siteName: bSiteName, } = b;

				if (!aSiteName || !bSiteName) {
					return 0;
				}

				if (aSiteName < bSiteName) {
					return -1;
				}
			}

			return 1;
		});
	}

	/**
	 * Displays a sitecards per region or all site cards -based on showByRegion.
	 */
	function eachRegion (item: {
		name: string,
		sites: Array<SiteType>,
	}, index: number) {
		const { name, sites, } = item;

		if (showByRegion) {
			return (
				<SiteCardsByRegion
					name={name}
					sites={sites}
					cardIsClickable={cardIsClickable}
					key={index}
				/>
			);
		} else {
			return (
				<SiteCardsGrid
					sites={sites}
					cardIsClickable={cardIsClickable}
					key={index}
				/>
			);
		}
	}

	if (!displayRegions || displayRegions.length <= 0) {
		return <PleaseWait />;
	}

	return (
		<div className="container is-fluid is-scrollable-y pt-5">
			{showSelectors && (
				<div className="level">
					<div className="level-left">
						<div className="is-size-small">
							<span className="icon has-background-opacity--5 mr-2">
								<i className="mi mi-sort" />
							</span>
							Sort&nbsp;
							<span
								className={showByRegion ? "link ml-1" : "ml-1"}
								onClick={() => {
									setShowByRegion(false);
								}}
							>
								alphabetically
							</span>
							<span className="has-text-opacity--40 mx-2">|</span>
							<span
								className={showByRegion ? "" : "link"}
								onClick={() => {
									setShowByRegion(true);
								}}
							>
								by region
							</span>
						</div>
					</div>
					<div className="level-right">
						<button
							className="button icon is-small is-rounded is-text has-tooltip-arrow has-tooltip-left"
							data-tooltip="Switch mode"
						>
							<i
								className="fas fa-adjust"
								onClick={() => {
									if (mode === "dark") {
										setMode("light");
									} else {
										setMode("dark");
									}
								}}
							/>
						</button>
					</div>
				</div>
			)}
			{displayRegions.map(eachRegion)}
		</div>
	);
};
