import React from "react";
import moment from "moment-timezone";

type Props = {
	locale: string,
	meridiem: boolean,
	timezone: string,
};

/**
 * Displays a clock
 */
export const Clock = (props: Props) => {
	const { locale = "en", meridiem = false, timezone, } = props;

	const theMoment = getMoment(timezone, locale, meridiem);

	/**
	 *
	 * Get Date and Time using :
	 * - moment.js [http://momentjs.com](http://momentjs.com)
	 * - moment-timezone.js [http://momentjs.com/timezone/](http://momentjs.com/timezone/)
	 *
	 * ISO Timezones : [https://en.wikipedia.org/wiki/List_of_tz_database_time_zones](https://en.wikipedia.org/wiki/List_of_tz_database_time_zones)
	 * ISO Language Codes : [http://www.w3schools.com/tags/ref_language_codes.asp](http://www.w3schools.com/tags/ref_language_codes.asp)
	 */
	function getMoment (timezone: string, locale: string, useMeridiem: boolean) {
		moment.locale(locale);
		let now = moment().tz(timezone);
		let tz = moment().tz(timezone).format("z");

		if (tz === "") {
			now = moment().tz("GMT");
			tz = moment().tz("GMT").format("z");
		}

		// Day
		const weekdays = moment.weekdays();
		const dayName = weekdays[now.get("day")];
		const day = now.get("date");

		// Time
		let hours = now.get("hour");
		const meridiem = hours < 12 ? "AM" : "PM";
		hours = useMeridiem && meridiem === "PM" ? hours - 12 : hours;
		const minutes = now.get("minute");

		// return time object
		return {
			day,
			dayName,
			hours: (hours < 10 ? "0" : "") + hours,
			meridiem,
			minutes: (minutes < 10 ? "0" : "") + minutes,
			timezone: tz,
		};
	}

	return (
		<>
			{/* <div>{theMoment.meridiem}</div> */}
			<small>
				{theMoment.dayName}, {theMoment.hours}:{theMoment.minutes} (GMT)
			</small>
		</>
	);
};
