import React from "react";
import { getMonthName } from "../../../functions/UtilityFunctions";
import { formatDisplayNumber } from "../../../functions/formatDisplayNumber";
import { MixedLineAndBarChart } from "../../../components/MixedLineAndBarChart/MixedLineAndBarChart";

type Props = {
	yearlyProductionActual: Array<Number>,
	yearlyProductionActivePower: Number,
}

/**
 * Displays the monthly information in the lobby.
 */
export const ProductionYearlyLobby = (props: Props) => {
	const {
		yearlyProductionActual,
		yearlyProductionActivePower,
	} = props;

	const fixedYearlyProductionActivePower = formatDisplayNumber(yearlyProductionActivePower);

	const labels = [];
	const actData = [];
	if (yearlyProductionActual) {
		for (let i = 0; i < yearlyProductionActual.length; i++) {
			const time = new Date(yearlyProductionActual[i].time);
			const month = getMonthName(time.getMonth());

			const actValue = yearlyProductionActual[i].value ? yearlyProductionActual[i].value / 1000000 : 0;

			labels.push(month.substring(0, 3));
			actData.push(actValue);
		}
	}

	return (
		<div className="card flex-column is-full-height">
			<header className="card-header">
				<h2 className="card-header-title is-uppercase is-size-7">Yearly Production</h2>
			</header>
			<div className="card-content py-1">
				<div className="chart is-large">
					<MixedLineAndBarChart
						labels={labels}
						series={[
							{
								axisIndex: 0,
								data: actData,
								hasLegend: false,
								name: "Actual",
								numberOfDecimals: 1,
								unit: "MWh",
							}
						]}
					/>
				</div>
			</div>
			<footer className="px-5 pb-4 has-text-right mt-auto">
				<h2>
					<span className="has-text-weight-semibold is-size-4 is-block has-line-height-1">
						{fixedYearlyProductionActivePower}<small className="is-size-7 has-text-weight-light">&nbsp;MWh</small>
					</span>
				</h2>
			</footer>
		</div>
	);
};
