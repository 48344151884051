import { MutableRefObject } from "react";
import { toCamelWord } from "../../../functions/UtilityFunctions";

/**
 * Gets the entity structure from the backend.
 */
export function getEntityStructure (
	entitiesRef: MutableRefObject<any>,
	structureRef: MutableRefObject<any>,
	countRef: MutableRefObject<number>,
	setEntityTimestamp: Function,
	sendTwinHubRequest: Function,
	sendApisHubRequest: Function,
	sendHubRequest: Function,
	classNumber: number,
	entityName: string
) {
	const isoDate = (new Date()).toISOString();

	const requestPackage = {
		onSuccess: (responses: Array<object>) => {
			structureRef.current[entityName] = responses;

			getEntityOpcInformation(
				entitiesRef,
				structureRef,
				countRef,
				setEntityTimestamp,
				sendApisHubRequest,
				sendHubRequest,
				entityName,
				true
			);
		},
		parameters: {
			restrictions: {
				signals: [],
				storedProcedures: [],
			},
			twinClassId: classNumber,
		},
		requestId: entityName + isoDate,
		requestType: "TwinClassGetTwins",
	};

	sendTwinHubRequest(requestPackage);
}

/**
 * Gets the entity information from the backend.
 */
export function getEntityOpcInformation (
	entitiesRef: MutableRefObject<any>,
	structureRef: MutableRefObject<any>,
	countRef: MutableRefObject<number>,
	setEntityTimestamp: Function,
	sendApisHubRequest: Function,
	sendHubRequest: Function,
	entityName: string,
	loadSql: boolean
) {
	if (!structureRef.current[entityName] || structureRef.current[entityName].length <= 0) {
		return;
	}

	countRef.current = 0;
	const structures = structureRef.current[entityName];

	for (let j = 0; j < structureRef.current[entityName].length; j++) {
		const { opcUaRequests, } = structures[j];

		const isoDate = (new Date()).toISOString();

		if (!entitiesRef.current[entityName]) {
			entitiesRef.current[entityName] = [];
		}

		if (opcUaRequests && opcUaRequests.length > 0) {
			countRef.current++;

			const sigRequestPackage = {
				onFailure: () => {
					countRef.current--;
				},
				onSuccess: (responses: Array<object>) => {
					if (!responses || responses.length <= 0) {
						return;
					}

					const newProperties: any = {};
					for (let i = 0; i < responses.length; i++) {
						const response: any = responses[i];
						const { name, value, } = response;

						if (name) {
							newProperties[name] = value;
						}
					}

					if (!entitiesRef.current[entityName][j]) {
						entitiesRef.current[entityName][j] = {};
					}
					if (!entitiesRef.current[entityName][j].properties) {
						entitiesRef.current[entityName][j].properties = {};
					}

					entitiesRef.current[entityName][j].properties = {
						...entitiesRef.current[entityName][j].properties,
						...newProperties,
					};

					countRef.current--;
					if (countRef.current <= 0) {
						setEntityTimestamp((new Date()).getSeconds());
						if (loadSql) {
							getEntitySqlInformation(
								entitiesRef,
								structureRef,
								countRef,
								setEntityTimestamp,
								sendHubRequest,
								entityName
							);
						}
					}
				},
				parameters: opcUaRequests,
				requestId: entityName + "opc" + j + isoDate,
				requestType: "OpcUaGetCurrent",
			};
			sendApisHubRequest(sigRequestPackage);
		} else {
			if (loadSql) {
				getEntitySqlInformation(
					entitiesRef,
					structureRef,
					countRef,
					setEntityTimestamp,
					sendHubRequest,
					entityName
				);
			}
		}
	}
}

/**
 * Gets the entity information from the backend.
 */
export function getEntitySqlInformation (
	entitiesRef: MutableRefObject<any>,
	structureRef: MutableRefObject<any>,
	countRef: MutableRefObject<number>,
	setEntityTimestamp: Function,
	sendHubRequest: Function,
	entityName: string
) {
	if (!structureRef.current[entityName] || structureRef.current[entityName].length <= 0) {
		return;
	}

	countRef.current = 0;
	const structures = structureRef.current[entityName];

	for (let j = 0; j < structureRef.current[entityName].length; j++) {
		const { sQLRequests, } = structures[j];

		const isoDate = (new Date()).toISOString();

		if (!entitiesRef.current[entityName]) {
			entitiesRef.current[entityName] = [];
		}

		if (sQLRequests && sQLRequests.length > 0) {
			countRef.current++;

			const spRequestPackage = {
				onFailure: (error: string) => {
					console.error(error);
					countRef.current--;
				},
				onSuccess: (responses: Array<object>) => {
					for (let k = 0; k < responses.length; k++) {
						const response: any = responses[k];
						const firstRow = response.rows[0];
						const { cells, } = firstRow;

						const newProperties: any = {};
						for (let i = 0; i < cells.length; i++) {
							const cell = cells[i];
							const { name, value, } = cell;
							const camelName = toCamelWord(name);

							if (camelName) {
								newProperties[camelName] = value;
							}
						}

						if (!entitiesRef.current[entityName][j]) {
							entitiesRef.current[entityName][j] = {};
						}
						if (!entitiesRef.current[entityName][j].properties) {
							entitiesRef.current[entityName][j].properties = {};
						}

						entitiesRef.current[entityName][j].properties = {
							...entitiesRef.current[entityName][j].properties,
							...newProperties,
						};
					}

					countRef.current--;
					if (countRef.current <= 0) {
						setEntityTimestamp((new Date()).getSeconds());
					}
				},
				parameters: sQLRequests,
				requestId: entityName + "sql" + j + isoDate,
				requestType: "SQLRunQuery",
			};
			sendHubRequest(spRequestPackage);
		}
	}
}
