import React, { useContext, useEffect, useRef, useState } from "react";
import { HubContext } from "../../../MainScreen/MainScreen";
import XLSX from "xlsx";
import FileSaver from "file-saver";
import { stringToBlob } from "../../../../functions/stringToBlob";
import { breakUpCellName } from "../../../../functions/breakUpCellName";
import { AddMessage } from "../../../../Redux/Slices/messageSlice";
import { useDispatch } from "react-redux";
import { PleaseWait } from "../../../../components/PleaseWait";
import { TwinClassType } from "../../../../Types";

/**
 * Exports/Imports class information to Excel.
 */
export const TwinClasses = () => {
	const exportName = "TwinClasses";
	const { sendTwinHubRequest, } = useContext(HubContext);
	const [ twinClasses, setTwinClasses ] = useState<Array<TwinClassType>>([]);
	const fileBrowser = useRef(null);
	const dispatch = useDispatch();

	useEffect(() => {
		handleLoadTwinClasses();
	}, []);

	/**
	 * Loads the twin classes from the backend.
	 */
	function handleLoadTwinClasses () {
		const isoDate = (new Date()).toISOString();

		const requestPackage = {
			onSuccess: setTwinClasses,
			requestId: "TwinClasses" + isoDate,
			requestType: "TwinClassGetAll",
		};
		sendTwinHubRequest(requestPackage);
	}

	/**
	 * Exports the loaded twin classes to Excel.
	 */
	function handleExport () {
		const currentDate = new Date();

		const wb = XLSX.utils.book_new();
		wb.Props = {
			Author: "Prediktor AS",
			CreatedDate: currentDate,
			Subject: exportName,
			Title: exportName,
		};

		wb.SheetNames.push(exportName);
		const wsData = [];
		wsData.push([ "Twin Class Id", "Name" ]);

		for (let i = 0; i < twinClasses.length; i++) {
			const { twinClassId, name, } = twinClasses[i];

			wsData.push([ twinClassId, name ]);
		}

		const ws = XLSX.utils.aoa_to_sheet(wsData);
		wb.Sheets[exportName] = ws;

		const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary", });
		FileSaver.saveAs(new Blob([ stringToBlob(wbout) ], { type: "application/octet-stream", }), `${exportName}_${currentDate.toLocaleDateString()}.xlsx`);
	}

	/**
	 * Imports a twin class file.
	 */
	function handleImport () {
		const current: any = fileBrowser?.current;
		if (current) {
			current.click();
		}
	}

	/**
	 * Called when the user selects a file.
	 */
	function handleChangeFile () {
		if (fileBrowser) {
			const current: any = fileBrowser?.current;

			if (current) {
				const { files, } = current;

				if (files && files.length > 0) {
					const file = files[0];
					const reader = new FileReader();

					reader.onloadend = function (e) {
						if (e.target) {
							fileLoaded(file.name, e.target.result);
						}
					};

					if (file) {
						reader.readAsArrayBuffer(file);
					}
				}
			}
		}
	}

	/**
	 * Called when the import file is loaded.
	 */
	function fileLoaded (fileName: string, fileContent: ArrayBuffer | string | null) {
		if (!fileContent || typeof fileContent === "string") {
			return;
		}

		const data = new Uint8Array(fileContent);
		const workbook = XLSX.read(data, { type: "array", });
		const Sheets = workbook.Sheets;

		const twinClassSheet = Sheets[exportName];
		const rows: Array<Array<{ columnNumber: number, value: string }>> = [];

		// eslint-disable-next-line no-unused-vars
		for (const cellName in twinClassSheet) {
			if (cellName.substring(0, 1) !== "!") {
				const inputCell = twinClassSheet[cellName];

				const { rowNumber, columnNumber, } = breakUpCellName(
					cellName
				);

				if (!rows[rowNumber]) {
					rows[rowNumber] = [];
				}

				rows[rowNumber][columnNumber] = {
					columnNumber,
					value: inputCell.v,
				};
			}
		}

		const newTwinClasses: Array<TwinClassType> = [];
		const updatedTwinClasses: Array<TwinClassType> = [];
		for (let i = 0; i < rows.length; i++) {
			const row = rows[i];

			if (row) {
				const sourceId = row[1] ? row[1].value : "0";
				if (sourceId !== "Twin Class Id") {
					const twinClassId = parseInt(sourceId, 10);
					const name = row[2] ? row[2].value : "Unknown";

					if (twinClassId) {
						updatedTwinClasses.push({
							name,
							twinClassId,
						});
					} else {
						newTwinClasses.push({
							name,
							twinClassId,
						});
					}
				}
			}
		}

		for (let i = 0; i < updatedTwinClasses.length; i++) {
			const updatedClass: TwinClassType = updatedTwinClasses[i];

			for (let j = 0; j < twinClasses.length; j++) {
				const twinClass = twinClasses[i];

				if (twinClass.twinClassId === updatedClass.twinClassId) {
					if (twinClass.name !== updatedClass.name) {
						newTwinClasses.push(updatedClass);
						break;
					}
				}
			}
		}

		if (newTwinClasses.length > 0) {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: saveCompleted,
				parameters: newTwinClasses,
				requestId: isoDate,
				requestType: "TwinClassBatchUpdate",
			};

			sendTwinHubRequest(requestPackage);
		} else {
			dispatch(
				AddMessage({
					message: "No updated or added twin classes found in the Excel spreadsheet.",
					type: "error",
				})
			);
		}
	}

	/**
	 * Called when the save completes.
	 */
	function saveCompleted () {
		dispatch(
			AddMessage({
				message: `${exportName} Updated`,
				type: "info",
			})
		);

		handleLoadTwinClasses();
	}

	if (twinClasses == null) {
		return (
			<PleaseWait />
		);
	}

	return (
		<div>
			<p>
				{`${twinClasses.length} Classes Found`}
			</p>
			<button
				className="button"
				onClick={handleExport}
			>
				Export Classes
			</button>
			<button
				className="button"
				onClick={handleImport}
			>
				Import Classes
			</button>
			<input
				accept="*.*"
				onChange={handleChangeFile}
				ref={fileBrowser}
				style={{
					display: "none",
				}}
				type="file"
			/>
		</div>
	);
};
