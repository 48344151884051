import React, { useEffect, useState } from "react";
import { TwinClassType, TwinType } from "../../../../Types";

type Props = {
	onClose: Function,
	onReload: Function,
	sendTwinHubRequest: Function,
	twin: TwinType | null,
	twins: Array<TwinType>,
	twinClasses: Array<TwinClassType>,
}

/**
 * Lets the user create a new twin.
 */
export const TwinModal = (props: Props) => {
	const {
		onClose,
		onReload,
		sendTwinHubRequest,
		twin,
		twins,
		twinClasses,
	} = props;
	const [ editTwin, setEditTwin ] = useState<TwinType>({
		name: "",
		parentTwinId: -1,
		twinClassId: -1,
	});

	useEffect(() => {
		if (twin) {
			setEditTwin(twin);
		} else {
			setEditTwin({
				name: "",
				parentTwinId: -1,
				twinClassId: -1,
			});
		}
	}, [ twin ]);

	/**
	 * Adds a new Twin to the backend.
	 */
	function handleSave (close: Boolean) {
		if (editTwin.twinId) {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: close === true ? saveCompleted : blurCompleted,
				parameters: {
					...editTwin,
					...{
						parentTwinId: editTwin.parentTwinId === -1 ? null : editTwin.parentTwinId,
						twinClassId: editTwin.twinClassId === -1 ? null : editTwin.twinClassId,
					},
				},
				requestId: isoDate,
				requestType: "TwinUpdate",
			};

			sendTwinHubRequest(requestPackage);
		} else {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: close === true ? saveCompleted : blurCompleted,
				parameters: {
					...editTwin,
					...{
						parentTwinId: editTwin.parentTwinId === -1 ? null : editTwin.parentTwinId,
						twinClassId: editTwin.twinClassId === -1 ? null : editTwin.twinClassId,
					},
				},
				requestId: isoDate,
				requestType: "TwinAdd",
			};

			sendTwinHubRequest(requestPackage);
		}
	}

	/**
	 * Called when the add completes.
	 */
	function saveCompleted (newTwin: TwinType) {
		setEditTwin({
			name: "",
			parentTwinId: -1,
		});
		onReload();
		onClose();
	}

	/**
	 * Called when the blur of a field completes.
	 */
	function blurCompleted (newRecord: TwinType) {
		setEditTwin(newRecord);
	}

	return (
		<div className="modal is-active">
			<div className="modal-background">&nbsp;</div>
			<div className="modal-content">
				<div className="field">
					<label htmlFor="" className="label">ID</label>
					<div className="control">
						<label>{editTwin.twinId}</label>
					</div>
				</div>
				<div className="field">
					<label htmlFor="" className="label">Name</label>
					<div className="control">
						<input
							type="text"
							className="input"
							onChange={(event) => {
								if (!event) {
									return;
								}

								const { target, } = event;
								if (!target) {
									return;
								}

								setEditTwin({
									...editTwin,
									...{
										name: target.value,
									},
								});
							}}
							placeholder="Twin Name"
							value={editTwin.name}
						/>
					</div>
				</div>
				<div className="field">
					<label htmlFor="" className="label">Parent</label>
					<div className="control">
						<div className="select">
							<select
								onChange={(event) => {
									if (!event) {
										return;
									}

									const { target, } = event;
									if (!target) {
										return;
									}

									const numberValue = parseInt(target.value, 10);
									setEditTwin({
										...editTwin,
										...{
											parentTwinId: numberValue,
										},
									});
								}}
								value={editTwin.parentTwinId}
							>
								<option value={-1}>None</option>
								{twins && twins.length > 0 && twins.map((item, index) => {
									return <option key={index} value={item.twinId}>{item.name}</option>;
								})}
							</select>
						</div>
					</div>
				</div>
				<div className="field">
					<label htmlFor="" className="label">Class</label>
					<div className="control">
						<div className="select">
							<select
								onChange={(event) => {
									if (!event) {
										return;
									}

									const { target, } = event;
									if (!target) {
										return;
									}

									const numberValue = parseInt(target.value, 10);
									setEditTwin({
										...editTwin,
										...{
											twinClassId: numberValue,
										},
									});
								}}
								value={editTwin.twinClassId}
							>
								<option value={-1}>None</option>
								{twinClasses && twinClasses.length > 0 && twinClasses.map((item, index) => {
									return <option key={index} value={item.twinClassId}>{item.name}</option>;
								})}
							</select>
						</div>
					</div>
				</div>
				<button className="button" onClick={() => { handleSave(true); }}>Save &amp; Close</button>
			</div>
			<button className="modal-close is-large" aria-label="close" onClick={() => { onClose(); }} />
		</div>
	);
};
