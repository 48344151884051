import React from "react";
import { formatDisplayNumber } from "../../../functions/formatDisplayNumber";
import { scaleUnitAndAmount } from "../../../functions/scaleUnitAndAmount";

type Props = {
	annualCO2FootprintForecast?: number,
	dailyProduction?: number,
	monthlyProduction?: number,
	totalActiveEnergy?: number,
}

/**
 * Displays production counters for a site.
 */
export const ProductionCounters = (props: Props) => {
	const day = props?.dailyProduction || 0;
	const month = props?.monthlyProduction || 0;
	const total = props?.totalActiveEnergy || 0;
	const co2 = props?.annualCO2FootprintForecast || 0;

	const {
		amount: displayTotal,
		unit: displayTotalUnit,
	} = scaleUnitAndAmount(total, "W");

	const {
		amount: displayCO2,
		unit: displayCO2Unit,
	} = scaleUnitAndAmount(co2, "Kt");

	return (
		<ul className="mt-3">
			<li className="is-flex">
				Present Day
				<span className="divider is-dashed is-right has-text-weight-semibold ml-3">{formatDisplayNumber(day)}&nbsp;MW</span>
			</li>
			<li className="is-flex">
				Present Month
				<span className="divider is-dashed is-right has-text-weight-semibold ml-3">{formatDisplayNumber(month)}&nbsp;MW</span>
			</li>
			<li className="is-flex">
				Total
				<span className="divider is-dashed is-right has-text-weight-semibold ml-3">{formatDisplayNumber(displayTotal)}&nbsp;{displayTotalUnit}</span>
			</li>
			<li className="is-flex">
				Annual CO<sup className="is-size-8 pr-1"><small>2</small></sup>
				<span className="divider is-dashed is-right has-text-weight-semibold ml-3">{formatDisplayNumber(displayCO2)}&nbsp;{displayCO2Unit}</span>
			</li>
		</ul>
	);
};
