import React from "react";
import { TwinSignalType } from "../../../../Types";
import { TwinSignal } from "./TwinSignal";

type Props = {
	onDelete: Function,
	onEdit: Function,
	twinSignals: Array<TwinSignalType>,
}

/**
 * Displays a table of the twin signals available.
 */
export const TwinSignals = (props: Props) => {
	const { onDelete, onEdit, twinSignals, } = props;

	/**
	 * Displays a row for each twin signal.
	 */
	function eachTwinSignal (item: TwinSignalType, index: number) {
		return (
			<TwinSignal key={index} item={item} name={item?.name} onDelete={onDelete} onEdit={onEdit} />
		);
	}

	return (
		<table className="table is-fullwidth is-hoverable">
			<thead>
				<tr>
					<th className="is-size-7 is-uppercase has-text-weight-semibold">Name</th>
					<th><span className="is-hidden">Actions</span></th>
				</tr>
			</thead>
			<tbody>
				{twinSignals && twinSignals.length > 0 && twinSignals.map(eachTwinSignal)}
			</tbody>
		</table>
	);
};
