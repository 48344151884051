import React from "react";
import { StringSetType } from "../../../Types";

type Props = {
	stringCombinerDetails: StringSetType,
    time: string,
	onInverterClick: Function,
}

/** Summary card for String */
export const StringSummary = (props: Props) => {
	const { stringCombinerDetails, time, onInverterClick, } = props;

	const dcNominalPower = stringCombinerDetails?.dcNominalPower;
	const dcNominalPowerUnit = "W";
	const tilt = stringCombinerDetails?.tilt;
	const tiltUnit = "%";
	const azimuth = stringCombinerDetails?.azimuth;
	const azimuthUnit = "%";
	const status = stringCombinerDetails?.status;
	const inverterParent = stringCombinerDetails?.parentName;
	const name = stringCombinerDetails?.name;

	let stringStatusText = "OK";
	let stringStatusColor = "";

	switch (status) {
		case 0:
			stringStatusText = "OK";
			stringStatusColor = "is-success";
			break;
		case 1:
			stringStatusText = "Warning";
			stringStatusColor = "is-warning";
			break;
		case 2:
			stringStatusText = "Error";
			stringStatusColor = "is-danger";
			break;
		case 3:
			stringStatusText = "Idle";
			stringStatusColor = "";
			break;
		case 4:
			stringStatusText = "Communication Loss";
			stringStatusColor = "is-danger";
			break;
		case 5:
			stringStatusText = "Indeterminate";
			stringStatusColor = "is-info";
			break;
		default:
			stringStatusText = "OK";
			stringStatusColor = "is-success";
			break;
	}

	return (
		<div className="card is-full-height flex-column">
			<div className="is-flex">
				<span title={stringStatusText} className={`${stringStatusColor} tag is-small is-borderless is-shadowless has-text-weight-semibold px-2 ml-3 mt-3`}>
					<i className="fas fa-xs fa-check mr-1 has-text-vcentered" aria-hidden="true" />
					<span className="has-text-vcentered">{stringStatusText}</span>
				</span>
				<div className="ml-auto mt-2 mr-2">
					<small
						className="has-text--grey-dark--grey is-size-8 pt-2 pr-3"
					>
						<i className="mi mi-schedule has-text-vcentered mr-1" aria-hidden="true" />
						{time}
					</small>
					<div className="dropdown is-hoverable">
						<div className="dropdown-trigger">
							<button type="button" className="button icon is-light is-rounded is-small" aria-label="Edit">
								<span className="icon">
									<i className="mi mi-edit" aria-hidden="true" />
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="card-content px-0 has-line-height-1 has-text-centered mt-auto">
				<span className="icon is-large has-text--grey--grey-dark mb-2">
					<i className="icon-string is-size-1" aria-hidden="true" />
				</span>
				<h1>
					<span className="has-text-weight-semibold is-size-4 is-block mb-1">{name}</span>
					<span className="link is-clickable is-size-7" onClick={() => { onInverterClick(); }}>{inverterParent}</span>
				</h1>
			</div>
			<footer className="is-size-7 px-4 py-4 mt-auto">
				<ul>
					<li className="is-flex">
						DC Nominal Power
						<span className="divider is-dashed is-right has-text-weight-semibold ml-3">
							{dcNominalPower + " " + dcNominalPowerUnit}
						</span>
					</li>
					<li className="is-flex">
						Tilt
						<span className="divider is-dashed is-right has-text-weight-semibold ml-3">
							{tilt + " " + tiltUnit}
						</span>
					</li>
					<li className="is-flex">
						Azimuth
						<span className="divider is-dashed is-right has-text-weight-semibold ml-3">
							{azimuth + " " + azimuthUnit}
						</span>
					</li>
				</ul>
			</footer>
		</div>
	);
};
