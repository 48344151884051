import React from "react";
import { formatDisplayNumber } from "../functions/formatDisplayNumber";

type Props = {
	categories: Array<{
		category: string,
		progressbars: Array<{
			name: string,
			value: number,
			dotted: boolean,
			color: string
		}>,
	}>,
}

/**
 * Categorized progress
 */
export const CategorizedProgressBar = (props: Props) => {
	const { categories, } = props;

	if (!categories || categories.length < 0) {
		return null;
	}

	/**
	 * Displays a section for each category.
	 */
	function eachCategory (item: {
		category: string,
		progressbars: Array<{
			name: string,
			value: number,
			dotted: boolean,
			color: string
		}>,
	}, index: number) {
		const { category, progressbars, } = item;

		return (
			<>
				<dt
					className="has-text--grey--grey-dark has-text-weight-semibold is-size-8 is-uppercase pt-2"
					key={index}
				>
					{category}
				</dt>
				{progressbars && progressbars.length > 0 && progressbars?.map(eachProgressBar)}
			</>
		);
	}

	/**
	 * Displays each of the progress bars.
	 */
	function eachProgressBar (item: {
		name: string,
		value: number,
		dotted: boolean,
		color: string
	}, index: number) {
		if (!item) {
			return null;
		}

		const { name, value, dotted, color, } = item;

		const displayValue = formatDisplayNumber(value, 0);
		return (
			<dd className="flex-row has-line-height-1 pb-1" key={name + "_" + index}>
				<div
					className="has-width-150 pr-2"
					key={"progressBarDiv" + index}
				>
					<small key={"progressBarNameSpan" + index}>
						{name}
					</small>
				</div>
				{
					dotted
						? (
							<span
								className={`icon ${value === 0 ? "is-off" : "is-on"} is-small is-size-7 has-tooltip-arrow`}
								data-tooltip={`${name}: ${value === 0 ? "Off" : "On"}`}
								key={"progressBarDottedSpan" + index}
							>
								<i
									className={`fas fa-xs fa-circle has-text-${color}`}
									aria-hidden="true"
									key={"progressBarDottedCircle" + index}
								/>
							</span>
						)
						: (
							<progress
								className={`progress is-smaller is-radiusless m-auto has-tooltip-arrow ${color ? "is-" + color : ""}`}
								data-tooltip={`${name}: ${displayValue}%`}
								key={"progressBarProgress" + index}
								value={value}
								max="100"
							>
								{value}%
							</progress>
						)
				}
			</dd>
		);
	}

	return (
		<dl className="chart__kpi flex-column is-full-height">
			{categories && categories.length > 0 && categories?.map(eachCategory)}

			<dt className="flex-row chart__kpi-xaxis is-full-height">
				<div className="" />
				<div className="columns is-mobile is-full-width is-gapless has-text--grey-dark--grey-light has-text-weight-semibold mt-auto">
					<div className="column is-one-fifth"><span /></div>
					<div className="column is-one-fifth"><span /></div>
					<div className="column is-one-fifth"><span /></div>
					<div className="column is-one-fifth"><span /></div>
					<div className="column is-one-fifth"><span /></div>
				</div>
			</dt>
		</dl>
	);
};
