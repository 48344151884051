import React, { useContext } from "react";
import { StyleContext } from "../../../MainScreen/MainScreen";

/**
 * Displays the logo.
 */
export const Logo = () => {
	const {
		style,
		mode,
	} = useContext(StyleContext);

	return (
		<img
			className={style === "scatec" ? "image has-max-width-100-mobile ml-4-desktop" : "image has-max-height-unset has-width-100 has-max-width-60-mobile ml-4-desktop"}
			alt={style === "scatec" ? "Scatec Logo" : "PowerView Logo"}
			src={style === "scatec" ? "Images/logo/scatec/logo--negative.svg" : mode === "light" ? "Images/logo/powerview/prediktor_pos.svg" : "Images/logo/powerview/prediktor_neg.svg"}
		/>
	);
};
