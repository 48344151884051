import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TwinParameters } from "./components/TwinParameters";
import { HubContext } from "../../MainScreen/MainScreen";
import { TwinParameterModal } from "./components/TwinParameterModal";
import { TwinParameterType } from "../../../Types";

/**
 * The initial screen.
 */
export const TwinParameterAdminScreen = () => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const twinIdString = urlParams.get("twinId") || "";
	const twinId = parseInt(twinIdString, 10);
	const twinClassIdString = urlParams.get("twinClassId");
	const twinClassId = twinClassIdString ? parseInt(twinClassIdString, 10) : undefined;
	const twinNodeTemplateIdString = urlParams.get("twinNodeTemplateId");
	const twinNodeTemplateId = twinNodeTemplateIdString ? parseInt(twinNodeTemplateIdString, 10) : undefined;
	const history = useHistory();

	const [ twinParameters, setTwinParameters ] = useState<Array<TwinParameterType>>([]);
	const [ modalTwinParameter, setModalTwinParameter ] = useState<TwinParameterType | null>(null);
	const { sendTwinHubRequest, } = useContext(HubContext);

	useEffect(() => {
		handleLoadTwinParameters();
	}, []);

	/**
	 * Loads the twin parameters from the backend.
	 */
	function handleLoadTwinParameters () {
		const isoDate = (new Date()).toISOString();

		const requestPackage = {
			onSuccess: (response: Array<TwinParameterType>) => { setTwinParameters(response); },
			parameters: {
				twinClassId,
				twinId,
				twinNodeTemplateId,
			},
			requestId: isoDate,
			requestType: "TwinParameterGet",
		};
		sendTwinHubRequest(requestPackage);
	}

	return (
		<div className="container is-fluid" style={{ height: "100vh", }}>
			<section className="section px-6">
				<div className="level">
					<div className="level-left">
						<h1 className="title is-size-4 has-text-weight-medium">Twin Parameter Manager</h1>
					</div>
					<div className="level-right">
						<a
							className="button is-icon has-tooltip-arrow"
							data-tooltip="Back"
							onClick={() => { history.goBack(); }}
						>
							<span className="icon">
								<i className="fas fa-arrow-left" aria-hidden="true" />
							</span>
						</a>
						<a
							className="button is-icon has-tooltip-arrow"
							data-tooltip="Create Parameter"
							onClick={() => { setModalTwinParameter({}); }}
						>
							<span className="icon">
								<i className="fas fa-plus" aria-hidden="true" />
							</span>
						</a>
					</div>
				</div>
			</section>
			<section className="section pt-0 px-6">
				<div className="has-shadow--small">
					<TwinParameters
						onDelete={(twinParameter: TwinParameterType) => {
							const confirm = window.confirm("Permanently delete this parameter?");
							if (confirm) {
								const isoDate = (new Date()).toISOString();

								const requestPackage = {
									onSuccess: handleLoadTwinParameters,
									parameters: {
										twinParameterId: twinParameter.twinParameterId,
									},
									requestId: isoDate,
									requestType: "TwinParameterDelete",
								};
								sendTwinHubRequest(requestPackage);
							}
						}}
						onEdit={(twinParameter: TwinParameterType) => { setModalTwinParameter(twinParameter); }}
						twinParameters={twinParameters}
					/>
				</div>
			</section>
			<div className={modalTwinParameter ? "is-block" : "is-hidden"}>
				<TwinParameterModal
					onClose={() => { setModalTwinParameter(null); }}
					onReload={handleLoadTwinParameters}
					sendTwinHubRequest={sendTwinHubRequest}
					twinId={twinId}
					twinClassId={twinClassId}
					twinNodeTemplateId={twinNodeTemplateId}
					twinParameter={modalTwinParameter}
				/>
			</div>
		</div>
	);
};
