import React, { useContext, useEffect, useState } from "react";
import { HubContext } from "../../MainScreen/MainScreen";
import { useHistory } from "react-router-dom";
import { TwinNodeTemplateRow } from "./components/TwinNodeTemplateRow";
import { TwinNodeTemplateType, TwinType } from "../../../Types";

/**
 * The initial screen.
 */
export const TwinNodeTemplateSignalTestScreen = () => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const twinNodeTemplateIdString = urlParams.get("twinNodeTemplateId");
	const twinNodeTemplateId = twinNodeTemplateIdString ? parseInt(twinNodeTemplateIdString, 10) : null;
	const parentUaNodeId = urlParams.get("parentUaNodeId");
	const history = useHistory();

	const [ twins, setTwins ] = useState<Array<TwinType>>([]);
	const [ childTemplates, setChildTemplates ] = useState<Array<TwinNodeTemplateType>>([]);
	const { sendTwinHubRequest, } = useContext(HubContext);

	useEffect(() => {
		handleLoadTwin();
	}, [ twinNodeTemplateId ]);

	/**
	 * Loads the twins from the backend.
	 */
	function handleLoadTwin () {
		const isoDate = (new Date()).toISOString();

		const requestPackage = {
			onSuccess: handleTwinSignals,
			parameters: {
				parentUaNodeId,
				twinNodeTemplateId,
			},
			requestId: "TwinNodeTemplate" + isoDate,
			requestType: "TwinNodeTemplateGet",
		};
		sendTwinHubRequest(requestPackage);

		const requestPackage2 = {
			onSuccess: setChildTemplates,
			parameters: {
				twinNodeTemplateId,
			},
			requestId: "TwinNodeTemplateChildren" + isoDate,
			requestType: "TwinNodeTemplateGetChildren",
		};
		sendTwinHubRequest(requestPackage2);
	}

	/**
	 * Called when the backend returns the twin signal values.
	 */
	function handleTwinSignals (response: Array<TwinType> | TwinType) {
		if (!response) {
			return;
		}

		const newTwins: Array<TwinType> = [];

		if (Array.isArray(response)) {
			for (let i = 0; i < response.length; i++) {
				const resp = response[i];

				if (resp) {
					if (resp.extendedProperties) {
						const newSignals = [];
						for (const prop in resp.extendedProperties) {
							newSignals.push({
								name: prop,
								value: resp.extendedProperties[prop],
							});
						}

						newTwins.push({
							nodeId: resp.uaNodeId,
							signals: newSignals,
						});
					}
				}
			}
		} else {
			if (response?.extendedProperties) {
				const newSignals = [];
				for (const prop in response?.extendedProperties) {
					newSignals.push({
						name: prop,
						value: response.extendedProperties[prop],
					});
				}

				newTwins.push({
					nodeId: response?.uaNodeId,
					signals: newSignals,
				});
			}
		}

		setTwins(newTwins);
	}

	return (
		<div className="container is-fluid is-scrollable-y" style={{ height: "100vh", }}>
			<section className="section px-6">
				<div className="level">
					<div className="level-left">
						<h1 className="title is-size-4 has-text-weight-medium">Twin Node Template Signal Tester</h1>
					</div>
					<div className="level-right">
						<a
							className="button is-icon has-tooltip-arrow"
							data-tooltip="Back"
							onClick={() => { history.goBack(); }}
						>
							<span className="icon">
								<i className="fas fa-arrow-left" aria-hidden="true" />
							</span>
						</a>
					</div>
				</div>
			</section>
			{twins && twins.length > 0 && twins.map((item: TwinType, index: number) => {
				const {
					signals,
					uaNodeId,
				} = item;

				return <TwinNodeTemplateRow key={index} childTemplates={childTemplates} signals={signals} nodeId={uaNodeId} />;
			})}
		</div>
	);
};
