import React from "react";
import { Gauge } from "../../../components/Gauge";
import { formatDisplayNumber } from "../../../functions/formatDisplayNumber";

type Props = {
	acPowerActive: Number,
};

/**
 * Displays the production current information.
 */
export const ProductionCurrent = (props: Props) => {
	const {
		acPowerActive,
	} = props;

	const convertedACPowerActive = formatDisplayNumber(acPowerActive);

	return (
		<div className="card flex-column is-full-height">
			<header className="card-header is-shadowless">
				<h2 className="card-header-title is-uppercase is-size-7">Current Production</h2>
			</header>
			<div className="card-content pb-0 has-text-centered">
				<div className="icon gauge--2 is-largest m-auto">
					<Gauge
						value={convertedACPowerActive === "N/A" ? 0 : convertedACPowerActive}
						min={0}
						max={100}
					/>
				</div>
			</div>
			<footer className="has-text-centered mt-auto pb-5">
				<h2>
					<span className="has-text-weight-semibold is-size-4 is-block has-line-height-1">
						{convertedACPowerActive}<small className="is-size-7 has-text-weight-light">&nbsp;kW</small>
					</span>
				</h2>
			</footer>
		</div>
	);
};
