import React from "react";
import { ValueUnit } from "./ValueUnit";

type Props = {
	noOfInverters?: number,
}

/**
 * Displays the Inverters information.
 */
export const Inverters = (props: Props) => {
	const inverters = props?.noOfInverters;
	const name = "Inverters";

	return (
		<>
			<div className="is-flex is-align-items-center mr-0-fullhd mr-5-widescreen">
				<span className="icon is-large is-rounded has-text-theme--1 has-background-theme--2 mr-3">
					<i className="icon-inverter-outlined is-size-4" aria-hidden="true" />
				</span>
				<ValueUnit value={inverters || 0} name={name} />
			</div>
		</>
	);
};
