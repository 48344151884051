import React from "react";
import { isNull } from "../../../functions/UtilityFunctions";
import { useHistory } from "react-router-dom";
import { CategorizedProgressBar } from "../../../components/CategorizedProgressBar";
import { ButtonExpand } from "../../../components/ButtonExpand";

type Props = {
	capacityAvailability?: number,
	efficiency?: number,
	gridComms?: number,
	networkComms?: number,
	pr?: number,
	technicalAvailability?: number,
}

/** */
export const KPIs = (props: Props) => {
	const categories: Array<{
		category: string,
		progressbars: Array<{
			name: string,
			value: number,
			dotted: boolean,
			color: string
		}>,
	}> = [];
	const history = useHistory();
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const id = urlParams.get("i");

	// Efficiency
	let performanceCategory = -1;
	if (!isNull(props?.efficiency)) {
		categories.push({
			category: "Performance",
			progressbars: [
				{
					color: "",
					dotted: false,
					name: "Efficiency",
					value: props?.efficiency || 0,
				}
			],
		});

		performanceCategory = categories.length - 1;
	}

	// PR
	if (!isNull(props?.pr)) {
		if (performanceCategory < 0) {
			categories.push({
				category: "Performance",
				progressbars: [],
			});

			performanceCategory = categories.length - 1;
		}

		categories[performanceCategory].progressbars.push({
			color: "danger",
			dotted: false,
			name: "PR",
			value: props?.pr || 0,
		});
	}

	// Capacity
	let availabilityCategory = -1;
	if (!isNull(props?.capacityAvailability)) {
		categories.push({
			category: "Availability",
			progressbars: [
				{
					color: "",
					dotted: false,
					name: "Capacity",
					value: props?.capacityAvailability || 0,
				}
			],
		});

		availabilityCategory = categories.length - 1;
	}

	// Technical
	if (!isNull(props?.technicalAvailability)) {
		if (availabilityCategory < 0) {
			categories.push({
				category: "Availability",
				progressbars: [],
			});

			availabilityCategory = categories.length - 1;
		}

		categories[availabilityCategory].progressbars.push({
			color: "",
			dotted: false,
			name: "Technical",
			value: props?.technicalAvailability || 0,
		});
	}

	// Grid
	let communicationCategory = -1;
	if (!isNull(props?.gridComms)) {
		categories.push({
			category: "Communication",
			progressbars: [
				{
					color: "darkgrey",
					dotted: true,
					name: "Grid",
					value: props?.gridComms || 0,
				}
			],
		});

		communicationCategory = categories.length - 1;
	}

	// Network
	if (!isNull(props?.networkComms)) {
		if (communicationCategory < 0) {
			categories.push({
				category: "Communication",
				progressbars: [],
			});

			communicationCategory = categories.length - 1;
		}

		categories[communicationCategory].progressbars.push({
			color: "green",
			dotted: true,
			name: "Network",
			value: props?.networkComms || 0,
		});
	}

	return (
		<div className="card is-full-height">
			<header className="card-header pr-5">
				<h2 className="card-header-title is-uppercase is-size-7">
					KPI'<span className="is-lowercase">s</span>
				</h2>

				<div className="m-auto pr-3">
					{/* <PeriodSelect /> */}
					{/* <span className="is-size-7 is-lowercase">60 Minutes</span> */}
				</div>

				<span className="is-absolute is-top is-right">
					<ButtonExpand onClick={() => { history.push(`/solarInverterKPIs?i=${id}`); }} />
				</span>

			</header>
			<div className="card-content px-4" style={{ height: "calc(100% - 42px)", }}>
				{categories && categories.length > 0
					? <CategorizedProgressBar categories={categories} />
					: (
						<h4 className="flex-column is-full-height justify-center has-text-centered has-text-opacity--60 has-background-opacity--5 py-4">No signals found</h4>
					)}
			</div>
		</div>

	);
};
