import React, { useState } from "react";
import Chart from "react-apexcharts";
import { isNull } from "../../functions/UtilityFunctions";
import { ApexChartSeriesType } from "../../Types";
import { Legend } from "./components/Legend";

type Props = {
    labels: Array<string>,
    series: Array<ApexChartSeriesType>,
};

/**
 * Displays a chart with a mix of bars and lines.
 */
export const MixedLineAndBarChart = (props: Props) => {
	const [ seriesVisibility, setSeriesVisibility ] = useState<any>({});
	let { labels, series, } = props;

	if (!series || series.length <= 0) {
		return <h4 className="flex-column is-full-height justify-center has-text-centered has-text-opacity--60 has-background-opacity--5">No data available</h4>;
	}
	if (!labels) {
		labels = [];
	}

	let yaxis: Array<{
		labels: any,
		lines: {
			show: boolean,
		},
		opposite: boolean,
		seriesName: string,
		show: boolean,
		title: {
			show: boolean,
		},
	}> | {
		labels?: any,
		lines?: {
			show: boolean,
		},
		opposite?: boolean,
		seriesName?: string,
		show?: boolean,
		title?: {
			show: boolean,
		},
	} = [];
	const visibleSeries = [];
	const dataLabelsEnabled = [];
	let legendsExist = false;
	for (let i = 0; i < series.length; i++) {
		const serie = series[i];
		if (serie.axisIndex !== null && serie.axisIndex !== undefined) {
			yaxis.push({
				labels: {
					formatter: (val: number) => {
						if (serie?.unit && serie?.unitPosition !== "before") {
							return val?.toFixed(isNull(serie.numberOfDecimals) ? 3 : serie.numberOfDecimals) + " " + serie.unit;
						} else if (serie.unit) {
							return serie.unit + " " + val?.toFixed(isNull(serie.numberOfDecimals) ? 3 : serie.numberOfDecimals);
						} else {
							return val?.toFixed(isNull(serie.numberOfDecimals) ? 3 : serie.numberOfDecimals);
						}
					},
				},
				lines: {
					show: false,
				},
				opposite: serie.axisPosition === "right",
				seriesName: series[serie.axisIndex].name || "Unknown",
				show: serie.axisIndex === i,
				title: {
					show: false,
				},
			});
		}
		if (serie.type === "line") {
			dataLabelsEnabled.push(i);
		}
		if (seriesVisibility[serie.name || "Unknown"] !== false) {
			visibleSeries.push(serie);
		}
		if (isNull(serie.hasLegend) || serie.hasLegend) {
			legendsExist = true;
		}
	}

	if (yaxis.length <= 0) {
		yaxis = {
			labels: {
				formatter: (val: number) => {
					return val?.toFixed(3);
				},
			},
			lines: {
				show: false,
			},
			title: {
				show: false,
			},
		};
	}

	const options = {
		chart: {
			animations: {
				enabled: true,
			},
			// background: "transparent",
			height: 350,
			toolbar: {
				show: false,
			},
			type: "line",
		},
		dataLabels: {
			enabled: false,
			enabledOnSeries: dataLabelsEnabled,
		},
		dropShadow: {
			blur: 5,
			enabled: true,
			left: -7,
			opacity: 0.3,
			top: 22,
		},
		fill: {
			gradient: {
				inverseColors: false,
				opacityFrom: 0.85,
				opacityTo: 0.55,
				shade: "light",
				stops: [ 0, 100, 100, 100 ],
				type: "vertical",
			},
			opacity: [ 0.85, 0.75, 1, 0.25 ],
		},
		grid: {
			borderColor: "#1e272e",
			show: true,
		},
		legend: {
			show: false,
		},
		markers: {
			size: dataLabelsEnabled ? [ 0, 5 ] : 0,
			strokeColors: [ "", "#000" ],
			strokeOpacity: 1,
			strokeWidth: 3,
		},
		plotOptions: {
			bar: {
				columnWidth: "50%",
				// endingShape: "rounded",
				horizontal: false,
			},
		},
		stroke: {
			curve: "straight",
			show: true,
			width: [ 2, 1, 1 ],
		},
		theme: {
			monochrome: {
				// color: "#0C253F",
				enabled: true,
				shadeIntensity: 0.75,
				shadeTo: "light",
			},
		},
		tooltip: {
			intersect: false,
			shared: true,
			y: {
				show: false,
			},
		},
		xaxis: {
			categories: labels,
			lines: {
				show: false,
			},
		},
		yaxis,
	};

	/**
     * Toggles a series between visible and invisible.
     */
	function toggleSeries (seriesName: string) {
		let newSeriesVisibility = false;
		if (seriesVisibility[seriesName] === false) {
			newSeriesVisibility = true;
		}

		setSeriesVisibility({
			...seriesVisibility,
			...{ [seriesName]: newSeriesVisibility, },
		});
	}

	/**
     * Displays a box with a minature graph for each legend.
     */
	function eachLegend (item: ApexChartSeriesType, index: number) {
		const name = item?.name || "Unknown";

		return (
			<Legend
				key={index}
				onToggle={() => { toggleSeries(name); }}
				series={{ name, }}
				visible={
					seriesVisibility[name] === true ||
                    seriesVisibility[name] === null ||
                    seriesVisibility[name] === undefined
				}
			/>
		);
	}

	return (
		<>
			<Chart
				height={legendsExist ? "80%" : "100%"}
				options={options}
				series={visibleSeries}
				type="bar"
				width="100%"
			/>
			{legendsExist && (
				<div
					style={{
						display: "grid",
						gridGap: "10px",
						gridTemplateColumns: "1fr 1fr 1fr 1fr",
						height: "20%",
						width: "100%",
					}}
				>
					{series && series.length > 0 && series.map(eachLegend)}
				</div>
			)}
		</>
	);
};
