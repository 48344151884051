import React from "react";
import { formatDisplayNumber } from "../../../../functions/formatDisplayNumber";
import { isNull } from "../../../../functions/UtilityFunctions";

type Props = {
	totalACCapacity?: number,
}

/**
 * The total capacity
 */
export const TotalCapacity = (props: Props) => {
	const totalCapacity = props?.totalACCapacity;
	const totalCapacityUnit = "MWp";

	return (
		<div className="is-flex is-align-items-center px-4 py-2">
			<span className="icon is-large is-rounded has-background-theme--1 mr-2">
				<i className="icon-capacity has-line-height-1 is-size-4" aria-hidden="true" />
			</span>
			<h3 className="has-line-height-1">
				<span className="has-text-weight-semibold is-size-3 is-size-4-mobile">
					<small>
						{isNull(totalCapacity) ? "N/A" : formatDisplayNumber(totalCapacity)}
					</small>
				</span>
				<span className="has-text-weight-medium is-size-7-mobile">&nbsp;{totalCapacityUnit}</span>
				<span className="is-block pt-1">Installed Capacity</span>
			</h3>
		</div>
	);
};
