import React, { useEffect, useState } from "react";
import { TwinStoredProcedureParameterType } from "../../../../Types";

type Props = {
	onClose: Function,
	onReload: Function,
	sendTwinHubRequest: Function,
	twinStoredProcedureId: number,
	twinStoredProcedureParameter: TwinStoredProcedureParameterType | null,
}

/**
 * Lets the user create a new twin stored procedure.
 */
export const TwinStoredProcedureParameterModal = (props: Props) => {
	const {
		onClose,
		onReload,
		sendTwinHubRequest,
		twinStoredProcedureId,
		twinStoredProcedureParameter,
	} = props;
	const [ editTwinStoredProcedureParameter, setEditTwinStoredProcedureParameter ] = useState<TwinStoredProcedureParameterType>({
		name: "",
		twinStoredProcedureId,
		value: "",
		valueType: "",
	});

	useEffect(() => {
		if (twinStoredProcedureParameter) {
			setEditTwinStoredProcedureParameter(twinStoredProcedureParameter);
		} else {
			setEditTwinStoredProcedureParameter({
				name: "",
				twinStoredProcedureId,
				value: "",
				valueType: "",
			});
		}
	}, [ twinStoredProcedureParameter, twinStoredProcedureId ]);

	/**
	 * Adds a new Twin Stored procedure to the backend.
	 */
	function handleSave (close: Boolean) {
		if (editTwinStoredProcedureParameter.twinStoredProcedureParameterId) {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: close === true ? saveCompleted : blurCompleted,
				parameters: {
					...editTwinStoredProcedureParameter,
					...{
						twinStoredProcedureId,
					},
				},
				requestId: isoDate,
				requestType: "TwinStoredProcedureParameterUpdate",
			};

			sendTwinHubRequest(requestPackage);
		} else {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: close === true ? saveCompleted : blurCompleted,
				parameters: {
					...editTwinStoredProcedureParameter,
					...{
						twinStoredProcedureId,
					},
				},
				requestId: isoDate,
				requestType: "TwinStoredProcedureParameterAdd",
			};

			sendTwinHubRequest(requestPackage);
		}
	}

	/**
	 * Called when the add completes.
	 */
	function saveCompleted (newTwinStoredProcedureParameter: TwinStoredProcedureParameterType) {
		setEditTwinStoredProcedureParameter({
			name: "",
			twinStoredProcedureId,
			value: "",
			valueType: "",
		});
		onReload();
		onClose();
	}

	/**
	 * Called when the blur of a field completes.
	 */
	function blurCompleted (newRecord: TwinStoredProcedureParameterType) {
		setEditTwinStoredProcedureParameter(newRecord);
	}

	return (
		<div className="modal is-active">
			<div className="modal-background">&nbsp;</div>
			<div className="modal-content">
				<div className="field">
					<label htmlFor="" className="label">Name</label>
					<div className="control">
						<input
							type="text"
							className="input"
							onChange={(event) => {
								if (!event) {
									return;
								}

								const { target, } = event;
								if (!target) {
									return;
								}

								setEditTwinStoredProcedureParameter({
									...editTwinStoredProcedureParameter,
									...{
										name: target.value,
									},
								});
							}}
							placeholder="Parameter Name"
							value={editTwinStoredProcedureParameter.name}
						/>
					</div>
				</div>
				<div className="field">
					<label htmlFor="" className="label">Value</label>
					<div className="control">
						<input
							type="text"
							className="input"
							onChange={(event) => {
								if (!event) {
									return;
								}

								const { target, } = event;
								if (!target) {
									return;
								}

								setEditTwinStoredProcedureParameter({
									...editTwinStoredProcedureParameter,
									...{
										value: target.value,
									},
								});
							}}
							placeholder="Parameter Value"
							value={editTwinStoredProcedureParameter.value}
						/>
					</div>
				</div>
				<div className="field">
					<label htmlFor="" className="label">Value Type</label>
					<div className="control">
						<input
							type="text"
							className="input"
							onChange={(event) => {
								if (!event) {
									return;
								}

								const { target, } = event;
								if (!target) {
									return;
								}

								setEditTwinStoredProcedureParameter({
									...editTwinStoredProcedureParameter,
									...{
										valueType: target.value,
									},
								});
							}}
							placeholder="Parameter Value Type"
							value={editTwinStoredProcedureParameter.valueType}
						/>
					</div>
				</div>
				<button className="button" onClick={() => { handleSave(true); }}>Save &amp; Close</button>
			</div>
			<button className="modal-close is-large" aria-label="close" onClick={() => { onClose(); }} />
		</div>
	);
};
