
/** Get SolarSite status text based on status number */
export function getSolarSiteStatusText (statusNumber: number) {
	switch (statusNumber) {
		case 0:
			return "OK";
		case 1:
			return "Warning";
		case 2:
			return "Error";
		case 3:
			return "Idle";
		case 4:
			return "Communication Loss";
		default:
			return "Indeterminate";
	}
}

/** Get SolarInverter status text based on status number */
export function getSolarInverterStatusText (statusNumber: number) {
	switch (statusNumber) {
		case 0:
			return "OK";
		case 1:
			return "Warning";
		case 2:
			return "Error";
		case 3:
			return "Idle";
		case 4:
			return "Communication Loss";
		case 5:
			return "Indeterminate";
		default:
			return "OK";
	}
}

/** Get SolarStringSet status text based on status number */
export function getSolarStringSetStatusText (statusNumber: number) {
	switch (statusNumber) {
		case 0:
			return "OK";
		case 1:
			return "Underperformance warning";
		case 2:
			return "Underperformance error";
		case 3:
			return "Idle";
		case 4:
			return "Communication Loss";
		case 5:
			return "Indeterminate";
		default:
			return "OK";
	}
}

/** Takes status number as param and returns the bulma color name */
export function getSolarStatusColor (statusNumber: number) {
	switch (statusNumber) {
		case 0:
			return "#0FB1B6";
		case 1:
			return "#FCB560";
		case 2:
			return "#EB5A55";
		case 3:
			return "#5A6E85";
		case 4:
			return "#FBF8F8";
		case 5:
			return "#B372BD";
		default:
			return "#0FB1B6";
	}
}

/** Takes status number as param and returns the bulma color name */
export function getSolarStatusClass (statusNumber: number) {
	switch (statusNumber) {
		case 0:
			return "is-success";
		case 1:
			return "is-warning";
		case 2:
			return "is-danger";
		case 3:
			return "";
		case 4:
			return "is-danger";
		case 5:
			return "is-info";
		default:
			break;
	}
}
