import React from "react";

type Props = {
	item: any,
	onRead: Function,
	onUpdate: Function,
	onWrite: Function,
}

/**
 * A screen for automated testing of the backend API.
 */
export const TestLine = (props: Props) => {
	const {
		item,
		onRead,
		onUpdate,
		onWrite,
	} = props;

	const {
		value,
	} = item;

	return (
		<tr>
			<td>
				<input
					type="text"
					className="input"
					onBlur={(event) => {
						if (!event) {
							return;
						}

						const { target, } = event;
						if (!target) {
							return;
						}

						onUpdate({
							...item,
							...{
								server: target.value,
							},
						});
					}}
					placeholder="Server"
				/>
			</td>
			<td>
				<input
					type="text"
					className="input"
					onBlur={(event) => {
						if (!event) {
							return;
						}

						const { target, } = event;
						if (!target) {
							return;
						}

						onUpdate({
							...item,
							...{
								server: target.value,
							},
						});
					}}
					placeholder="Signal Name"
				/>
			</td>
			<td>
				<button
					className="button"
					onClick={() => onRead()}
				>
					Read
				</button>
			</td>
			<td>
				<label>{value}</label>
			</td>
			<td>
				<input
					type="text"
					className="input"
					onBlur={(event) => {
						if (!event) {
							return;
						}

						const { target, } = event;
						if (!target) {
							return;
						}

						onUpdate({
							...item,
							...{
								server: target.value,
							},
						});
					}}
					placeholder="Write Value"
				/>
			</td>
			<td>
				<button
					className="button"
					onClick={() => onWrite()}
				>
					Write
				</button>
			</td>
		</tr>
	);
};
