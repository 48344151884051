import React from "react";
import { formatDisplayNumber } from "../../../../functions/formatDisplayNumber";
import { isNull } from "../../../../functions/UtilityFunctions";

type Props = {
	annualCO2FootprintForecast?: number,
	annualHouseholdsForecast?: number,
	annualYieldForecast?: number,
}

/**
 * Displays the annual forecast information.
 */
export const Forecast = (props: Props) => {
	const forecast = props?.annualYieldForecast;
	const households = props?.annualHouseholdsForecast;
	const co2Avoided = props?.annualCO2FootprintForecast;

	return (
		<>
			<div
				className="has-radius has-background-theme--2 px-4 py-2"
			>
				<h3 className="has-text-weight-semibold pt-1 pb-2 pb-1-tablet-only">Annual Forecast</h3>
				<ul>
					<li className="is-flex is-size-small mb-1 mb-0-tablet-only">
						<span>Production</span>
						<div className="divider is-dashed is-right ml-3">
							<span className="has-text-weight-semibold is-narrowed">
								{isNull(forecast) ? "N/A" : formatDisplayNumber(forecast)}
							</span>
							<span className="has-text-weight-semibold">&nbsp;MWh</span>
						</div>
					</li>
					<li className="is-flex is-size-small mb-1 mb-0-tablet-only">
						<span>Households</span>
						<div className="divider is-dashed is-right ml-3">
							<span className="has-text-weight-semibold is-narrowed">
								{isNull(households) ? "N/A" : formatDisplayNumber(households)}
							</span>
							<span className="has-text-weight-semibold">&nbsp;K</span>
						</div>
					</li>
					<li className="is-flex is-size-small mb-1 mb-0-tablet-only">
						<span>CO<small><sup>2</sup></small> Avoided</span>
						<div className="divider is-dashed is-right ml-3">
							<span className="has-text-weight-semibold is-narrowed">
								{isNull(co2Avoided) ? "N/A" : formatDisplayNumber(co2Avoided)}
							</span>
							<span className="has-text-weight-semibold">&nbsp;tons</span>
						</div>
					</li>
				</ul>
			</div>
		</>
	);
};
