import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { isNull } from "../functions/UtilityFunctions";

type Props = {
    unit?: string,
    value?: number,
};

/**
 * Displays a circular progress bar with a value inside it.
 */
export const CircularProgressBar = (props: Props) => {
	const { unit, value, } = props;

	return (
		<CircularProgressbarWithChildren
			strokeWidth={5}
			styles={{
				path: {
					stroke: `rgba(35, 92, 175, ${100 / 100})`,
					// Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
					strokeLinecap: "butt",

					// Rotate the path
					transform: "rotate(1turn)",

					transformOrigin: "center center",
					// Customize transition animation
					transition: "stroke-dashoffset 0.5s ease 0s",
				},
				trail: {
					stroke: "#27333B",
					strokeLinecap: "butt",
				},
			}}
			value={value || 0}
		>
			<p className="is-hidden">{isNull(value) ? "N/A" : value + (unit || "")}</p>
		</CircularProgressbarWithChildren>
	);
};
