import React from "react";
import { AlarmList } from "../../../components/AlarmList";
/**
 * This dashboard displays details about a site/plant.
 */
export const SolarInverterAlarmsScreen = () => {
	return (
		<section>
			<div className="columns">
				<AlarmList uaNodeIds={[]} />
			</div>
		</section>
	);
};
