import React from "react";
import Chart from "react-apexcharts";
import { isNull } from "../functions/UtilityFunctions";

type Props = {
    labels: Array<string>,
	series: Array<{
		data: Array<number>,
		name: string,
		numberOfDecimals: number,
		unit: string,
		unitPosition: string,
	}>,
	average: number,
	max: number,
	min: number,
};

/**
 * Displays a bar chart with a straight line for a limit.
 */
export function BarChartWithLimit (props: Props) {
	let { labels, series, average, max, min, } = props;

	if (!series || series.length <= 0) {
		return <h4 className="flex-column is-full-height justify-center has-text-centered has-text-opacity--60 has-background-opacity--5">No data available</h4>;
	}
	if (!labels) {
		labels = [];
	}

	const yaxis = {
		labels: {},
		lines: {
			show: false,
		},
		title: {
			show: false,
		},
	};
	if (series && series.length > 0 && labels) {
		yaxis.labels = {
			formatter: (val: number) => {
				if (series[0]?.unit && series[0]?.unitPosition !== "before") {
					return val.toFixed(isNull(series[0].numberOfDecimals) ? 3 : series[0].numberOfDecimals) + " " + series[0].unit;
				} else if (series[0].unit) {
					return series[0].unit + " " + val.toFixed(isNull(series[0].numberOfDecimals) ? 3 : series[0].numberOfDecimals);
				} else {
					return val.toFixed(isNull(series[0].numberOfDecimals) ? 3 : series[0].numberOfDecimals);
				}
			},
		};
	}

	const options = {
		annotations: {
			yaxis: [
				{
					borderColor: "#FCB560",
					strokeDashArray: 0,
					y: average,
				}
			],
		},
		chart: {
			animations: {
				animateGradually: {
					delay: 1000,
					enabled: true,
				},
				dynamicAnimation: {
					speed: 1000,
				},
				easing: "linear",
				enabled: true,
				speed: 600,
			},
			background: "transparent",
			toolbar: {
				show: false,
			},
			type: "bar",
		},
		dataLabels: {
			enabled: false,
		},
		dropShadow: {
			blur: 5,
			enabled: true,
			left: -7,
			opacity: 0.3,
			top: 22,
		},
		fill: {
			opacity: 1,
		},
		grid: {
			padding: {
				left: -15,
				right: -10,
			},
			show: false,
		},
		plotOptions: {
			bar: {
				colors: {
					ranges: [
						{
							color: "#EB5A55",
							from: min,
							to: average,
						},
						{
							color: "#3FC1C5",
							from: average,
							to: max,
						}
						// {
						// color: "#0F5CAF",
						// from: 20,
						// to: 50,
						// }
					],
				},
				columnWidth: "80%",
				endingShape: "flat",
				horizontal: false,
				rangeBarGroupRows: true,
			},
		},
		theme: {
			monochrome: {
				enabled: true,
			},
		},
		tooltip: {
			y: {
				formatter: (value: number) => value + series[0]?.unit,
			},
		},
		xaxis: {
			axisBorder: { show: false, },
			axisTicks: {
				show: false,
			},
			categories: labels,
			labels: {
				show: false,
			},
			// type: xAxisLabelType || "datetime",
		},
		yaxis: {
			labels: {
				show: false,
			},
		},
	};

	return (
		<Chart
			height="100%"
			options={options}
			series={series}
			type="bar"
			width="100%"
		/>
	);
}
