import React from "react";
import { TwinStoredProcedureParameterType } from "../../../../Types";
import { TwinStoredProcedureParameter } from "./TwinStoredProcedureParameter";

type Props = {
	onDelete: Function,
	onEdit: Function,
	twinStoredProcedureParameters: Array<TwinStoredProcedureParameterType>,
}

/**
 * Displays a table of the twin stored procedures available.
 */
export const TwinStoredProcedureParameters = (props: Props) => {
	const { onDelete, onEdit, twinStoredProcedureParameters, } = props;

	/**
	 * Displays a row for each twin stored procedure.
	 */
	function eachTwinStoredProcedureParameter (item: TwinStoredProcedureParameterType, index: number) {
		return (
			<TwinStoredProcedureParameter key={index} item={item} name={item?.name} onDelete={onDelete} onEdit={onEdit} />
		);
	}

	return (
		<table className="table is-fullwidth is-hoverable">
			<thead>
				<tr>
					<th className="is-size-7 is-uppercase has-text-weight-semibold">Name</th>
					<th><span className="is-hidden">Actions</span></th>
				</tr>
			</thead>
			<tbody>
				{twinStoredProcedureParameters && twinStoredProcedureParameters.length > 0 && twinStoredProcedureParameters.map(eachTwinStoredProcedureParameter)}
			</tbody>
		</table>
	);
};
