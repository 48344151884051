import React, { useEffect, useState } from "react";
import { AvailableConnectionType, TwinSignalType } from "../../../../Types";

type Props = {
	availableConnections: Array<AvailableConnectionType>,
	onClose: Function,
	onReload: Function,
	sendTwinHubRequest: Function,
	twinId: number,
	twinClassId?: number,
	twinNodeTemplateId?: number,
	twinSignal: object | null,
}

/**
 * Lets the user create a new twin signal.
 */
export const TwinSignalModal = (props: Props) => {
	const {
		availableConnections,
		onClose,
		onReload,
		sendTwinHubRequest,
		twinId,
		twinClassId,
		twinNodeTemplateId,
		twinSignal,
	} = props;
	const [ editTwinSignal, setEditTwinSignal ] = useState<TwinSignalType>({
		apiServerName: "",
		moduleName: "",
		name: "",
		signalName: "",
		twinClassId,
		twinId,
		twinNodeTemplateId,
	});

	useEffect(() => {
		if (twinSignal) {
			setEditTwinSignal(twinSignal);
		} else {
			setEditTwinSignal({
				apiServerName: "",
				moduleName: "",
				name: "",
				signalName: "",
				twinClassId,
				twinId,
				twinNodeTemplateId,
			});
		}
	}, [ twinSignal, twinClassId, twinId, twinNodeTemplateId ]);

	/**
	 * Adds a new Twin Signal to the backend.
	 */
	function handleSave (close: Boolean) {
		if (editTwinSignal.twinSignalId) {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: close === true ? saveCompleted : blurCompleted,
				parameters: {
					...editTwinSignal,
					...{
						twinClassId,
						twinId,
						twinNodeTemplateId,
					},
				},
				requestId: isoDate,
				requestType: "TwinSignalUpdate",
			};

			sendTwinHubRequest(requestPackage);
		} else {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: close === true ? saveCompleted : blurCompleted,
				parameters: {
					...editTwinSignal,
					...{
						twinClassId,
						twinId,
						twinNodeTemplateId,
					},
				},
				requestId: isoDate,
				requestType: "TwinSignalAdd",
			};

			sendTwinHubRequest(requestPackage);
		}
	}

	/**
	 * Called when the add completes.
	 */
	function saveCompleted (newTwinSignal: TwinSignalType) {
		setEditTwinSignal({
			apiServerName: "",
			moduleName: "",
			name: "",
			signalName: "",
			twinClassId,
			twinId,
			twinNodeTemplateId,
		});
		onReload();
		onClose();
	}

	/**
	 * Called when the blur of a field completes.
	 */
	function blurCompleted (newRecord: TwinSignalType) {
		setEditTwinSignal(newRecord);
	}

	return (
		<div className="modal is-active">
			<div className="modal-background">&nbsp;</div>
			<div className="modal-content">
				<div className="field">
					<label htmlFor="" className="label">Name</label>
					<div className="control">
						<input
							type="text"
							className="input"
							onChange={(event) => {
								if (!event) {
									return;
								}

								const { target, } = event;
								if (!target) {
									return;
								}

								setEditTwinSignal({
									...editTwinSignal,
									...{
										name: target.value,
									},
								});
							}}
							placeholder="Signal Name"
							value={editTwinSignal.name}
						/>
					</div>
				</div>
				<div className="field">
					<label htmlFor="" className="label">OPC Server</label>
					<div className="control">
						<div className="select">
							<select
								onChange={(event) => {
									if (!event) {
										return;
									}

									const { target, } = event;
									if (!target) {
										return;
									}

									setEditTwinSignal({
										...editTwinSignal,
										...{
											apiServerName: target.value,
										},
									});
								}}
								value={editTwinSignal.apiServerName}
							>
								<option value="None">None</option>
								{availableConnections && availableConnections.length > 0 && availableConnections.map((item, index) => {
									return <option key={index} value={item.serverName}>{item.serverName}</option>;
								})}
							</select>
						</div>
					</div>
				</div>
				<div className="field">
					<label htmlFor="" className="label">Module Name</label>
					<div className="control">
						<input
							type="text"
							className="input"
							onChange={(event) => {
								if (!event) {
									return;
								}

								const { target, } = event;
								if (!target) {
									return;
								}

								setEditTwinSignal({
									...editTwinSignal,
									...{
										moduleName: target.value,
									},
								});
							}}
							placeholder="Module Name"
							value={editTwinSignal.moduleName}
						/>
					</div>
				</div>
				<div className="field">
					<label htmlFor="" className="label">Signal Name</label>
					<div className="control">
						<input
							type="text"
							className="input"
							onChange={(event) => {
								if (!event) {
									return;
								}

								const { target, } = event;
								if (!target) {
									return;
								}

								setEditTwinSignal({
									...editTwinSignal,
									...{
										signalName: target.value,
									},
								});
							}}
							placeholder="Signal Name"
							value={editTwinSignal.signalName}
						/>
					</div>
				</div>
				<button className="button" onClick={() => { handleSave(true); }}>Save &amp; Close</button>
			</div>
			<button className="modal-close is-large" aria-label="close" onClick={() => { onClose(); }} />
		</div>
	);
};
