import React, { useContext } from "react";
import { SiteInfoBar } from "./SiteInfoBar";
import { WorldMapContext } from "../../WorldMapScreen";

type Props = {}

/**
 * Displays slides of each site in the bottom bar.
 */
export const WorldMapBottomSlider = (props: Props) => {
	let { currentSite, } = useContext(WorldMapContext);
	if (!currentSite) {
		currentSite = {
			id: "0",
			localTime: new Date(),
			name: "N/A",
			nodeName: "N/A",
			startedDate: new Date(),
			uaNodeId: "N/A",
		};
	}

	return (
		<SiteInfoBar
			{...currentSite}
		/>
	);
};
