import React from "react";

type Props = {
	internetValue?: number,
	networkValue?: number,
}

/** */
export const SiteCommunication = (props: Props) => {
	return (
		<div className="tags are-small is-flex-wrap-nowrap">
			<div className={props?.networkValue ? "tag is-danger has-tooltip-arrow px-1" : "is-hidden"} data-tooltip="Device communication">
				<i className="fa fa-wifi mr-1" aria-hidden="true" />
				<span className="has-text-weight-bold">{props?.networkValue}</span>
			</div>
			<div className={props?.internetValue ? "tag is-danger has-tooltip-arrow px-1" : "is-hidden"} data-tooltip="Network communication">
				<i className="fa fa-network-wired mr-1" aria-hidden="true" />
				<span className="has-text-weight-bold">{props?.internetValue}</span>
			</div>
		</div>
	);
};
