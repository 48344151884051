import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TwinClasses } from "./components/TwinClasses";
import { HubContext } from "../../MainScreen/MainScreen";
import { TwinClassModal } from "./components/TwinClassModal";
import { TwinClassType } from "../../../Types";

/**
 * The initial screen.
 */
export const TwinClassAdminScreen = () => {
	const [ classes, setClasses ] = useState<Array<TwinClassType>>([]);
	const [ modalClass, setModalClass ] = useState<TwinClassType | null>(null);
	const { sendTwinHubRequest, } = useContext(HubContext);
	const history = useHistory();

	useEffect(() => {
		handleLoadClasses();
	}, []);

	/**
	 * Loads the classes from the backend.
	 */
	function handleLoadClasses () {
		const isoDate = (new Date()).toISOString();

		const requestPackage = {
			onSuccess: (response: Array<TwinClassType>) => { setClasses(response); },
			requestId: isoDate,
			requestType: "TwinClassGetAll",
		};
		sendTwinHubRequest(requestPackage);
	}

	return (
		<div className="container is-fluid" style={{ height: "100vh", }}>
			<section className="section px-6">
				<div className="level">
					<div className="level-left">
						<h1 className="title is-size-4 has-text-weight-medium">Class Manager</h1>
					</div>
					<div className="level-right">
						<a
							className="button is-icon has-tooltip-arrow"
							data-tooltip="Back"
							onClick={() => { history.goBack(); }}
						>
							<span className="icon">
								<i className="fas fa-arrow-left" aria-hidden="true" />
							</span>
						</a>
						<a
							className="button is-icon has-tooltip-arrow"
							data-tooltip="Create Class"
							onClick={() => { setModalClass({}); }}
						>
							<span className="icon">
								<i className="fas fa-plus" aria-hidden="true" />
							</span>
						</a>
					</div>
				</div>
			</section>
			<section className="section pt-0 px-6">
				<div className="has-shadow--small">
					<TwinClasses
						onDelete={(twinClass: TwinClassType) => {
							const confirm = window.confirm("Permanently delete this class?");
							if (confirm) {
								const isoDate = (new Date()).toISOString();

								const requestPackage = {
									onSuccess: handleLoadClasses,
									parameters: {
										twinClassId: twinClass.twinClassId,
									},
									requestId: isoDate,
									requestType: "TwinClassDelete",
								};
								sendTwinHubRequest(requestPackage);
							}
						}}
						onEdit={(twinClass: TwinClassType) => { setModalClass(twinClass); }}
						classes={classes}
					/>
				</div>
			</section>
			<div className={modalClass ? "is-block" : "is-hidden"}>
				<TwinClassModal
					onClose={() => { setModalClass(null); }}
					onReload={handleLoadClasses}
					sendTwinHubRequest={sendTwinHubRequest}
					twinClass={modalClass}
				/>
			</div>
		</div>
	);
};
