import React, { useContext, useEffect, useState } from "react";
import { HubContext } from "../../MainScreen/MainScreen";
import { useHistory } from "react-router-dom";
import { TwinSignal } from "./components/TwinSignal";
import { TwinPropertyType, TwinType } from "../../../Types";

/**
 * The initial screen.
 */
export const TwinSignalTestScreen = () => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const twinIdString = urlParams.get("twinId") || "";
	const twinId = parseInt(twinIdString, 10);
	const twinClassIdString = urlParams.get("twinClassId");
	const twinClassId = twinClassIdString ? parseInt(twinClassIdString, 10) : null;
	const twinNodeTemplateIdString = urlParams.get("twinNodeTemplateId");
	const twinNodeTemplateId = twinNodeTemplateIdString ? parseInt(twinNodeTemplateIdString, 10) : null;
	const history = useHistory();

	const [ twinSignals, setTwinSignals ] = useState<Array<TwinPropertyType>>([]);
	const { sendTwinHubRequest, } = useContext(HubContext);

	useEffect(() => {
		handleLoadTwin();
	}, []);

	/**
	 * Loads the twins from the backend.
	 */
	function handleLoadTwin () {
		const isoDate = (new Date()).toISOString();

		const requestPackage = {
			onSuccess: handleTwinSignals,
			parameters: {
				twinClassId,
				twinId,
				twinNodeTemplateId,
			},
			requestId: isoDate,
			requestType: "TwinGet",
		};
		sendTwinHubRequest(requestPackage);
	}

	/**
	 * Called when the backend returns the twin signal values.
	 */
	function handleTwinSignals (response: TwinType) {
		if (response?.properties) {
			const newSignals = [];
			for (const prop in response?.properties) {
				newSignals.push({
					name: prop,
					value: response.properties[prop],
				});
			}

			setTwinSignals(newSignals);
		}
	}

	/**
	 * Displays each signal and its value.
	 */
	function eachTwinSignal (item: TwinPropertyType, index: number) {
		const { name, value, } = item;

		return (
			<TwinSignal key={index} name={name} value={value} />
		);
	}

	return (
		<div className="container is-fluid" style={{ height: "100vh", }}>
			<section className="section px-6">
				<div className="level">
					<div className="level-left">
						<h1 className="title is-size-4 has-text-weight-medium">Signal Tester</h1>
					</div>
					<div className="level-right">
						<a
							className="button is-icon has-tooltip-arrow"
							data-tooltip="Back"
							onClick={() => { history.goBack(); }}
						>
							<span className="icon">
								<i className="fas fa-arrow-left" aria-hidden="true" />
							</span>
						</a>
					</div>
				</div>
			</section>
			<section className="section pt-0 px-6">
				<div className="has-shadow--small">
					<table className="table is-fullwidth is-hoverable">
						<thead>
							<tr>
								<th className="is-size-7 is-uppercase has-text-weight-semibold">Name</th>
								<th><span className="is-hidden">Value</span></th>
							</tr>
						</thead>
						<tbody>
							{twinSignals && twinSignals.length > 0 && twinSignals.map(eachTwinSignal)}
						</tbody>
					</table>
				</div>
			</section>
		</div>
	);
};
