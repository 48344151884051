import { createSlice } from "@reduxjs/toolkit";

/**
 * Saves the current location.
 */
const locationSlice = createSlice({
	initialState: {},
	name: "locations",
	reducers: {
		popToLocation (state, action) {
			const index = action.payload;

			if (!state.locationMap) {
				state.locationMap = [];
			}

			state.locationMap.splice(index + 1);
		},
		pushLocation (state, action) {
			const location = action.payload;

			if (!state.locationMap) {
				state.locationMap = [];
			}

			state.locationMap.push(location);
		},
		resetLocation (state, action) {
			state = {};
		},
		saveLocation (state, action) {
			state.locationMap = action.payload;
		},
	},
});

export const { popToLocation, pushLocation, resetLocation, saveLocation, } = locationSlice.actions;

/**
 * Clears all of the location.
 */
export const ResetLocation = () => async (dispatch) => {
	dispatch(resetLocation());
};

/**
 * Saves location to Redux.
 * @param {*} locationData The location data object to save.
 */
export const SaveLocation = (locationData) => async (dispatch) => {
	dispatch(saveLocation(locationData));
};

/**
 * Pops all the following locations from Redux.
 */
export const PopToLocation = (index) => async (dispatch) => {
	dispatch(popToLocation(index));
};

/**
 * Pushes a location to Redux.
 */
export const PushLocation = (location) => async (dispatch) => {
	dispatch(pushLocation(location));
};

// Export reducers for use in store.js etc
export default locationSlice.reducer;
