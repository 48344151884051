import React from "react";
import { Gauge } from "../../../../components/Gauge";

type Props = {
	max?: number,
	min?: number,
	value?: number,
}

/**
 * Displays a gauge with the current production power.
 */
export const ProductionGauge = (props: Props) => {
	let max = props?.max || 100;
	if (props?.value && props.value > max) {
		max = props?.value;
	}

	return <Gauge max={max} min={props?.min || 0} value={props?.value} />;
};
