import React from "react";
import { Metrics } from "./Summary/Metrics";

type Props = {
	plantName: String,
	plantPicture: String,
	plantLogo: String,
	maxACPower: Number,
	nominalDCPower: Number,
	timezone: String,
}

/**
 * Displays summary information for the lobby dashboard.
 */
export const Summary = (props: Props) => {
	const {
		plantName,
		plantPicture,
		plantLogo,
		maxACPower,
		nominalDCPower,
		timezone,
	} = props;

	return (
		<div className="card flex-column is-full-height">
			<div className="card-image has-border-bottom-width-2 has-border-bottom--grey-light--black-ter mb-5">
				<figure className={plantPicture ? "image is-2by1" : "is-hidden"}>
					<img src={plantPicture} alt={plantName + " image"} />
				</figure>
				<figure className={plantLogo ? "image image--brand is-64x64 is-absolute is-left is-right ml-auto mr-auto" : "is-hidden"}>
					<img
						className="is-rounded has-background-white has-border-width-2 has-border--grey-light--black-ter"
						src={plantLogo}
						alt={plantName + " logo"}
					/>
				</figure>
			</div>
			<div className="card-content p-0 has-line-height-1 has-text-centered mt-auto">
				<h1 className="has-text-weight-semibold is-size-4">{plantName}</h1>
			</div>
			<footer className="is-size-7 px-4 pb-4 mt-auto">
				<Metrics
					maxACPower={maxACPower}
					nominalDCPower={nominalDCPower}
					timezone={timezone}
				/>
			</footer>
		</div>
	);
};
