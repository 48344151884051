import React, { useContext, useEffect, useState } from "react";
import { MixedLineAndBarChart } from "../../../components/MixedLineAndBarChart/MixedLineAndBarChart";
import { ApexChartSeriesType, ExtendedSiteType, HistoricalResponseType } from "../../../Types";
import { HubContext } from "../../MainScreen/MainScreen";

type Props = {
	extendedProperties: ExtendedSiteType | null,
	startTime: Date,
	endTime: Date,
}

/**
 * Displays the production chart.
 */
export const ProductionChart = (props: Props) => {
	const { extendedProperties, startTime, endTime, } = props;

	const [ labels, setLabels ] = useState<Array<string>>([]);
	// const [ availabilitySeries, setAvailabilitySeries ] = useState([]);
	const [ totalPowerSeries, setTotalPowerSeries ] = useState<Array<number | undefined>>([]);
	// const [ solarIrradianceSeries, setSolarIrradianceSeries ] = useState([]);

	const { sendTwinHubRequest, } = useContext(HubContext);

	useEffect(() => {
		if (startTime && endTime && extendedProperties) {
			// Availability, Total Power, Solar Irradiance
			const { aCActivePower, } = extendedProperties;

			const dateDifferenceMS = endTime.getTime() - startTime.getTime();
			const interval = Math.floor(dateDifferenceMS / 144);

			if (aCActivePower && aCActivePower?.nodeId) {
				const isoDate = (new Date()).toISOString();

				const requestPackage = {
					onSuccess: (response: HistoricalResponseType) => {
						if (!response?.values) {
							return;
						}

						const values: Array<number | undefined> = [];
						const newLabels = [];
						for (let i = 0; i < response.values.length; i++) {
							const newLabel = new Date(response.values[i].timestamp || new Date());

							values.push(response.values[i].value);
							newLabels.push(newLabel.getHours() + ":" + newLabel.getMinutes());
						}

						setTotalPowerSeries(values);
						setLabels(newLabels);
					},
					parameters: {
						aggregation: "average",
						endTime: endTime.toISOString(),
						interval,
						numValues: 10000,
						startTime: startTime.toISOString(),
						twinNodeTemplateId: 3,
						uaNodeId: aCActivePower.nodeId,
					},
					requestId: "TwinNodeTemplate" + isoDate,
					requestType: "TwinNodeTemplateGetHistory",
				};
				sendTwinHubRequest(requestPackage);
			}

			/*
			if (nominalPlantAvailability && nominalPlantAvailability?.nodeId) {
				const isoDate = (new Date()).toISOString();

				const requestPackage = {
					onSuccess: (response) => setAvailabilitySeries(response?.values),
					parameters: {
						endTime: endTime.toISOString(),
						numValues: 1000,
						startTime: startTime.toISOString(),
						twinNodeTemplateId: 3,
						uaNodeId: nominalPlantAvailability.nodeId,
					},
					requestId: "TwinNodeTemplate" + isoDate,
					requestType: "TwinNodeTemplateGetHistory",
				};
				sendTwinHubRequest(requestPackage);
			}

			if (nominalPlantAvailability && nominalPlantAvailability?.nodeId) {
				const isoDate = (new Date()).toISOString();

				const requestPackage = {
					onSuccess: (response) => setAvailabilitySeries(response?.values),
					parameters: {
						endTime: endTime.toISOString(),
						numValues: 1000,
						startTime: startTime.toISOString(),
						twinNodeTemplateId: 3,
						uaNodeId: nominalPlantAvailability.nodeId,
					},
					requestId: "TwinNodeTemplate" + isoDate,
					requestType: "TwinNodeTemplateGetHistory",
				};
				sendTwinHubRequest(requestPackage);
			}
			*/
		}
	}, [ startTime, endTime, extendedProperties ]);

	const series: Array<ApexChartSeriesType> = [
		{
			axisIndex: 0,
			axisPosition: "right",
			data: [],
			name: "Availability",
			numberOfDecimals: 1,
			type: "column",
			unit: "%",
			unitPosition: "before",
		},
		{
			axisIndex: 1,
			axisPosition: "left",
			data: totalPowerSeries,
			name: "Total Power",
			numberOfDecimals: 1,
			type: "line",
			unit: "kW",
			unitPosition: "before",
		},
		{
			axisIndex: 2,
			axisPosition: "left",
			data: [],
			name: "Solar Irradiance",
			numberOfDecimals: 1,
			type: "area",
			unit: "W/m²",
			unitPosition: "before",
		}
	];

	return (
		<MixedLineAndBarChart labels={labels} series={series} />
	);
};
