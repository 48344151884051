import React, { useEffect, useState } from "react";
import { TwinStoredProcedureType } from "../../../../Types";

type Props = {
	onClose: Function,
	onReload: Function,
	sendTwinHubRequest: Function,
	twinId: number,
	twinClassId?: number,
	twinNodeTemplateId?: number,
	twinStoredProcedure: TwinStoredProcedureType | null,
}

/**
 * Lets the user create a new twin stored procedure.
 */
export const TwinStoredProcedureModal = (props: Props) => {
	const {
		onClose,
		onReload,
		sendTwinHubRequest,
		twinId,
		twinClassId,
		twinNodeTemplateId,
		twinStoredProcedure,
	} = props;
	const [ editTwinStoredProcedure, setEditTwinStoredProcedure ] = useState<TwinStoredProcedureType>({
		storedProcedureName: "",
		twinClassId,
		twinId,
		twinNodeTemplateId,
	});

	useEffect(() => {
		if (twinStoredProcedure) {
			setEditTwinStoredProcedure(twinStoredProcedure);
		} else {
			setEditTwinStoredProcedure({
				storedProcedureName: "",
				twinClassId,
				twinId,
				twinNodeTemplateId,
			});
		}
	}, [ twinStoredProcedure, twinClassId, twinId, twinNodeTemplateId ]);

	/**
	 * Adds a new Twin Stored procedure to the backend.
	 */
	function handleSave (close: Boolean) {
		if (editTwinStoredProcedure.twinStoredProcedureId) {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: close === true ? saveCompleted : blurCompleted,
				parameters: {
					...editTwinStoredProcedure,
					...{
						twinClassId,
						twinId,
						twinNodeTemplateId,
					},
				},
				requestId: isoDate,
				requestType: "TwinStoredProcedureUpdate",
			};

			sendTwinHubRequest(requestPackage);
		} else {
			const isoDate = (new Date()).toISOString();

			const requestPackage = {
				onSuccess: close === true ? saveCompleted : blurCompleted,
				parameters: {
					...editTwinStoredProcedure,
					...{
						twinClassId,
						twinId,
						twinNodeTemplateId,
					},
				},
				requestId: isoDate,
				requestType: "TwinStoredProcedureAdd",
			};

			sendTwinHubRequest(requestPackage);
		}
	}

	/**
	 * Called when the add completes.
	 */
	function saveCompleted (newTwinStoredProcedure: TwinStoredProcedureType) {
		setEditTwinStoredProcedure({
			storedProcedureName: "",
			twinClassId,
			twinId,
			twinNodeTemplateId,
		});
		onReload();
		onClose();
	}

	/**
	 * Called when the blur of a field completes.
	 */
	function blurCompleted (newRecord: TwinStoredProcedureType) {
		setEditTwinStoredProcedure(newRecord);
	}

	return (
		<div className="modal is-active">
			<div className="modal-background">&nbsp;</div>
			<div className="modal-content">
				<div className="field">
					<label htmlFor="" className="label">Name</label>
					<div className="control">
						<input
							type="text"
							className="input"
							onChange={(event) => {
								if (!event) {
									return;
								}

								const { target, } = event;
								if (!target) {
									return;
								}

								setEditTwinStoredProcedure({
									...editTwinStoredProcedure,
									...{
										storedProcedureName: target.value,
									},
								});
							}}
							placeholder="Stored Procedure Name"
							value={editTwinStoredProcedure.storedProcedureName}
						/>
					</div>
				</div>
				<button className="button" onClick={() => { handleSave(true); }}>Save &amp; Close</button>
			</div>
			<button className="modal-close is-large" aria-label="close" onClick={() => { onClose(); }} />
		</div>
	);
};
