import React from "react";
import { TwinParameterType } from "../../../../Types";
import { TwinParameter } from "./TwinParameter";

type Props = {
	onDelete: Function,
	onEdit: Function,
	twinParameters: Array<TwinParameterType>,
}

/**
 * Displays a table of the twin parameters available.
 */
export const TwinParameters = (props: Props) => {
	const { onDelete, onEdit, twinParameters, } = props;

	/**
	 * Displays a row for each twin parameter.
	 */
	function eachTwinParameter (item: TwinParameterType, index: number) {
		return (
			<TwinParameter key={index} item={item} name={item?.name} onDelete={onDelete} onEdit={onEdit} />
		);
	}

	return (
		<table className="table is-fullwidth is-hoverable">
			<thead>
				<tr>
					<th className="is-size-7 is-uppercase has-text-weight-semibold">Name</th>
					<th><span className="is-hidden">Actions</span></th>
				</tr>
			</thead>
			<tbody>
				{twinParameters && twinParameters.length > 0 && twinParameters.map(eachTwinParameter)}
			</tbody>
		</table>
	);
};
