import React from "react";

type Props = {
	setZoomLevel: Function,
	zoomLevel: number,
}

/**
 * The Scaling Map buttons
 */
export const MapScalingButtons = (props: Props) => {
	const setZoomLevel = props?.setZoomLevel;
	const zoomLevel = props?.zoomLevel;

	return (
		<div className="field is-grouped is-absolute is-top is-right p-3">
			<p className="control">
				<button
					className="button icon is-light is-rounded is-small" aria-label="Zoom in" onClick={() => {
						setZoomLevel(zoomLevel + 0.5);
					}}
				>
					<span className="icon is-small">
						<i className="fas fa-plus" />
					</span>
				</button>
			</p>
			<p className="control">
				<button
					className="button icon is-light is-rounded is-small" aria-label="Zoom out" onClick={() => {
						setZoomLevel(zoomLevel - 0.5);
					}}
				>
					<span className="icon is-small">
						<i className="fas fa-minus" />
					</span>
				</button>
			</p>
			<p className="control">
				<button
					className="button icon is-light is-rounded is-small" aria-label="Fit to page" onClick={() => {
						setZoomLevel(1);
					}}
				>
					<span className="icon is-small">
						<i className="fas fa-map" />
					</span>
				</button>
			</p>

		</div>
	);
};
