import React, { useContext, useEffect, useState, useRef } from "react";
import { WorldMap } from "./components/WorldMap";
import { WorldMapBottomSlider } from "./components/BottomSlider/WorldMapBottomSlider";
import { WorldMapTopBar } from "./components/TopBar/WorldMapTopBar";
import { HubContext, StyleContext } from "../MainScreen/MainScreen";
import { PleaseWait } from "../../components/PleaseWait";
import { WorldMapType } from "../../Types";
import { isNull } from "../../functions/UtilityFunctions";
import Style from "style-it";

export const WorldMapContext = React.createContext<WorldMapType>({
	onSiteClick: () => {},
});

/**
 * Displays the lobby world map screen.
 */
export const WorldMapScreen = () => {
	const { setMessagesVisible, } = useContext(StyleContext);
	const currentSite = useRef<number>(0);
	const [ overrideSite, setOverrideSite ] = useState<null | { expiry: Date, siteNumber: number }>(null);
	const worldmapElement = useRef(null);

	const {
		enterprise,
		regions,
		sites,
	} = useContext(HubContext);

	const numberOfSites = sites ? sites.length : null;
	useEffect(() => {
		const interval = setInterval(() => {
			handleNextSite(numberOfSites);
		}, 10000);
		return () => clearInterval(interval);
	}, [ numberOfSites ]);

	useEffect(() => {
		setMessagesVisible(false);
	}, []);

	/**
	 * Rolls to the next site.
	 */
	function handleNextSite (numberOfSites: Number | null) {
		if (!numberOfSites || numberOfSites <= 0) {
			return;
		}

		let newCurrentSite = currentSite.current + 1;
		if (newCurrentSite === numberOfSites) {
			newCurrentSite = 0;
		}

		currentSite.current = newCurrentSite;
	}

	let countriesPerRegion: Array<Record<string, object>> = [];
	if (sites && sites.length && regions && regions.length) {
		const newCountries: any = {};

		for (let i = 0; i < sites.length; i++) {
			const site = sites[i];

			for (let j = 0; j < regions.length; j++) {
				const region = regions[j];

				if (region?.properties?.regionKey === site?.properties?.regionKey) {
					const { regionKey, } = region.properties;
					const { countryCode, } = site.properties;

					if (!newCountries[regionKey]) {
						newCountries[regionKey] = {};
					}

					if (countryCode && !newCountries[regionKey][countryCode]) {
						newCountries[regionKey][countryCode] = true;
					}
				}
			}
		}

		countriesPerRegion = newCountries;
	}

	/**
	 * Goes to the next site before the timer elapses.
	 */
	function handleChangeSite () {
		if (sites) {
			let newCurrentSite = isNull(overrideSite) ? currentSite.current + 1 : overrideSite.siteNumber + 1;
			if (newCurrentSite === sites.length) {
				newCurrentSite = 0;
			}

			setOverrideSite({
				expiry: new Date(),
				siteNumber: newCurrentSite,
			});
		}
	}

	/**
	 * Called when the user clicks a site.
	 */
	function handleSiteClick (siteNumber: number) {
		currentSite.current = siteNumber;
	}

	if (!enterprise || !regions || !sites || !sites[currentSite.current]) {
		return (
			<PleaseWait />
		);
	}

	const siteData = [];
	for (let i = 0; i < sites.length; i++) {
		siteData.push(sites[i].properties);
	}
	const regionData = [];
	for (let i = 0; i < regions.length; i++) {
		regionData.push(regions[i].properties);
	}

	const now = new Date();
	const expiredDate = new Date(now.getTime() - 60000);

	const siteToShow = isNull(overrideSite) || overrideSite.expiry < expiredDate ? currentSite.current : overrideSite.siteNumber;
	const siteInfo = sites[siteToShow].properties;

	let shadowWidth = 0;
	const screenWidth = window.innerWidth;

	if (worldmapElement.current) {
		const mapWidth = worldmapElement.current.children[0].children[1].getBoundingClientRect().width;
		shadowWidth = (screenWidth - mapWidth) / 2;
	}

	return (
		<WorldMapContext.Provider
			value={{
				countriesPerRegion,
				currentSite: siteInfo,
				enterpriseData: enterprise.properties,
				onSiteClick: handleSiteClick,
				regionData,
				siteData,
			}}
		>
			<div className="container is-fluid p-0 is-scrollable-y-touch">
				<div className="worldmap has-page-height is-full-width is-flex is-flex-direction-column is-align-items-center">
					<div className="is-flex-touch is-align-items-center is-full-width has-height-auto-touch has-gradient-theme--1 has-z-index-1 py-4-tablet-only pl-4-tablet-only" style={{ height: "5.25rem", }}>
						<WorldMapTopBar />
					</div>
					<Style>
						{`
							.worldmap-outer-wrapper:before{
								width: ${shadowWidth}px;
								right: ${shadowWidth}px;
							}
							.worldmap-inner-wrapper:before {
								width: ${shadowWidth}px;
								left: ${shadowWidth}px;
							}
						`}
					</Style>
					<div className="worldmap-outer-wrapper is-full-width has-height-auto-touch" style={{ height: "calc(100% - 14.25rem", }} ref={worldmapElement}>
						<WorldMap />
					</div>

					<div
						className="is-full-width is-full-height-mobile has-z-index-1" style={{ height: "9rem", }}
						onClick={handleChangeSite}
					>
						<WorldMapBottomSlider />
					</div>
				</div>
			</div>
		</WorldMapContext.Provider>
	);
};
