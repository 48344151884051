import React from "react";
import { TwinNodeTemplateHistoryType } from "../../../../Types";

/**
 * Displays a logged history value.
 */
export const HistoryRow = (props: TwinNodeTemplateHistoryType) => {
	const { status, timestamp, value, } = props;

	return (
		<tr className="level--0">
			<td className="is-borderless">
				{value}
			</td>
			<td className="is-borderless">
				{timestamp}
			</td>
			<td className="is-borderless">
				{status}
			</td>
		</tr>
	);
};
