import React from "react";
import { useHistory } from "react-router-dom";
import { formatDisplayNumber } from "../../../functions/formatDisplayNumber";

type Props = {
	country?: string,
	id: String,
	location?: string,
	nominalPlantAvailability?: number,
	pRMeasure?: number,
	pRTarget?: number,
	weatherSymbolNumber?: string,
}

/** Displays site card in portfolio grid view  */
export const PortfolioSiteCard = (props: Props) => {
	const { id, } = props;
	const history = useHistory();

	/** Pushes the user to the screen for the site row */
	function handleCardClick () {
		history.push("/solarSite?i=" + id);
	}

	const availability = props?.nominalPlantAvailability;
	const pr = props?.pRMeasure;
	const prTarget = props?.pRTarget;

	let prClass = "has-background-success has-text--inverted";
	if (pr && prTarget) {
		if (pr > prTarget) {
			prClass = "has-background-success has-text--inverted";
		} else if (pr < prTarget - 5) {
			prClass = "has-background-danger has-text--inverted";
		} else {
			prClass = "has-background--white-ter--black-ter";
		}
	}

	const symbol = props?.weatherSymbolNumber ? props?.weatherSymbolNumber : 1;
	let symbolUrl = "";

	try {
		if (symbol >= 10) {
			symbolUrl = symbol && symbol !== "" ? require(`./../../../assets/images/yr/svg/${symbol}d.svg`).default : "";
		} else {
			symbolUrl = symbol && symbol !== "" ? require(`./../../../assets/images/yr/svg/0${symbol}d.svg`).default : "";
		}
	} catch (err) {
		try {
			if (symbol >= 10) {
				symbolUrl = symbol && symbol !== "" ? require(`./../../../assets/images/yr/svg/${symbol}.svg`).default : "";
			} else {
				symbolUrl = symbol && symbol !== "" ? require(`./../../../assets/images/yr/svg/0${symbol}.svg`).default : "";
			}
		} catch (err) {}
	}

	return (
		<div className="card is-clickable" onClick={handleCardClick}>
			<header className="card-header is-shadowless has-background--grey-lightest--black">
				<h3 className="card-header-title is-truncated py-1 px-2">
					<span className="is-size-small is-truncated">{props?.location + ", " + props?.country}</span>
					<div className="has-text-centered ml-auto">
						<figure className="image is-24x24 is-inline-block has-text-vcentered">
							{symbolUrl !== "" && <img alt={symbol.toString()} src={symbolUrl} />}
						</figure>
					</div>
				</h3>
			</header>
			<div className="card-content has-border-top-opacity--20 has-border-top-width-1 p-2">
				<div className="columns is-mobile is-variable is-1 is-vcentered">
					<div className="column">
						<article className={`media has-radius py-2 px-2 ${prClass}`}>
							<h4 className="media-content has-line-height-1">
								<span className="is-size-5 has-text-weight-bold">{formatDisplayNumber(pr) + " %"}</span><br />
								<small className="is-size-7 has-text-weight-medium">PR</small>
							</h4>
						</article>
					</div>
					<div className="column">
						<article className="media py-2 px-2 has-background--white-ter--black-ter">
							<h4 className="media-content has-line-height-1">
								<span className="is-size-5 has-text-weight-bold">{formatDisplayNumber(availability) + " %"}</span><br />
								<small className="is-size-7 has-text-weight-medium">Availability</small>
							</h4>
						</article>

					</div>
				</div>
			</div>
		</div>
	);
};
