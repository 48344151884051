import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { StringSummary } from "./components/StringSummary";
import { CurrentAndVoltageChart } from "./components/CurrentAndVoltageChart";
import { PowerChart } from "./components/PowerChart";
import { HubContext } from "../MainScreen/MainScreen";
import { Gauge } from "../../components/Gauge";
import { IconValueStats } from "../../components/IconValueStats";
import { PeriodSelect } from "../../components/PeriodSelect";
import { AlarmList } from "../../components/AlarmList";
import { ButtonExpand } from "../../components/ButtonExpand";
import { RawStringSetType, StringSetType } from "../../Types";
import { formatDisplayNumber } from "../../functions/formatDisplayNumber";

/**
 * Displays an overview of the strings for the string combiner.
 */
export const SolarStringSet = () => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const uaNodeId = urlParams.get("uaNodeId");
	const { sendTwinHubRequest, } = useContext(HubContext);

	const history = useHistory();
	const [ stringCombinerDetails, setStringCombinerDetails ] = useState<StringSetType>({});

	const currentDate = new Date();
	const oneDayAgo = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours() - 24, currentDate.getMinutes(), currentDate.getSeconds());
	const [ currentAndVoltageChartTimes, setCurrentAndVoltageChartTimes ] = useState({
		endTime: currentDate,
		startTime: oneDayAgo,
	});
	const [ powerChartTimes, setPowerChartTimes ] = useState({
		endTime: currentDate,
		startTime: oneDayAgo,
	});

	useEffect(() => {
		if (uaNodeId) {
			const isoDate = (new Date()).toISOString();

			const enterpriseRequestPackage = {
				onSuccess: handleStringSetLoaded,
				parameters: {
					twinNodeTemplateId: 9,
					uaNodeId,
				},
				requestId: "StringSet" + isoDate,
				requestType: "TwinNodeTemplateGet",
			};
			sendTwinHubRequest(enterpriseRequestPackage);
		}
	}, [ uaNodeId ]);

	/**
	 * Called when the details are loaded.
	 */
	function handleStringSetLoaded (response: RawStringSetType) {
		if (!response) {
			return;
		}

		const { properties, uaNodeId, } = response;
		setStringCombinerDetails({
			...properties,
			...{
				uaNodeId,
			},
		});
	}

	const [ time, setTime ] = useState("N/A");
	/** Get time (returns: HH:MM) */
	function getTime () {
		const UTCOffset = 0;

		const currentdate = new Date();
		let hours: number | string = currentdate.getUTCHours() + UTCOffset;

		// correct for number over 24, and negatives
		if (hours >= 24) { hours -= 24; }
		if (hours < 0) { hours += 12; }

		// add leading zero, first convert hours to string
		hours = hours.toString() + "";
		if (hours.length === 1) { hours = "0" + hours; }

		// minutes are the same on every time zone
		let minutes: number | string = currentdate.getUTCMinutes();

		// add leading zero, first convert hours to string
		minutes = minutes + "";
		if (minutes.length === 1) { minutes = "0" + minutes; }
		setTime(hours + ":" + minutes);
	}

	useEffect(() => {
		const interval = setInterval(() => {
			getTime();
		}, 60000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		getTime();
	}, []);

	return (
		<div className="container my-4 mx-2-mobile">
			<section>
				<div className="columns">
					<div className="column is-4">
						<StringSummary
							stringCombinerDetails={stringCombinerDetails}
							time={time}
							onInverterClick={() => { history.push(`/solarInverter?i=${stringCombinerDetails?.parentId}`); }}
						/>
					</div>
					<div className="column is-4">
						<div className="card flex-column is-full-height">
							<div className="card-content">
								<div className="columns is-mobile is-multiline graph__divider--ewsn">
									<div className="column is-6 has-text-centered">
										<div className="card flex-column is-shadowless is-full-height p-3">
											<div className="icon gauge--2 is-larger m-auto">
												<Gauge
													min={0}
													max={100}
													value={stringCombinerDetails?.dcRelativePower}
												/>
											</div>
											<IconValueStats
												value={formatDisplayNumber(stringCombinerDetails?.dcRelativePower || 0).toString()}
												unit="W"
												description="Relative Power"
											/>
										</div>
									</div>
									<div className="column is-6 has-text-centered">
										<div className="card flex-column is-shadowless is-full-height p-3">
											<div className="icon gauge--2 is-larger m-auto">
												<Gauge
													min={0}
													max={100}
													value={stringCombinerDetails?.dcActivePower}
												/>
											</div>
											<IconValueStats
												value={formatDisplayNumber(stringCombinerDetails?.dcActivePower || 0).toString()}
												unit="W"
												description="Power"
											/>
										</div>
									</div>
									<div className="column is-6 has-text-centered">
										<div className="card flex-column is-shadowless is-full-height p-3">
											<div className="icon gauge--2 is-larger m-auto">
												<Gauge
													min={0}
													max={100}
													value={stringCombinerDetails?.dcCurrent}
												/>
											</div>
											<IconValueStats
												value={formatDisplayNumber(stringCombinerDetails?.dcCurrent || 0).toString()}
												unit="V"
												description="Current"
											/>
										</div>
									</div>
									<div className="column is-6 has-text-centered">
										<div className="card flex-column is-shadowless is-full-height p-3">
											<div className="icon gauge--2 is-larger m-auto">
												<Gauge
													min={0}
													max={100}
													value={stringCombinerDetails?.dcVoltage}
												/>
											</div>
											<IconValueStats
												value={formatDisplayNumber(stringCombinerDetails?.dcVoltage || 0).toString()}
												unit="V"
												description="Voltage"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="column is-4">
						<h2 className="divider is-left is-size-5 has-text-weight-medium mb-3">
							Alarms
						</h2>
						<div className="card" style={{ height: "calc(100% - 42px)", }}>
							<span className="is-absolute is-top is-right">
								<ButtonExpand onClick={() => { history.push(`/solarStringSetAlarms?i=${uaNodeId}`); }} />
							</span>
							<AlarmList uaNodeIds={uaNodeId ? [ uaNodeId ] : []} />
						</div>
					</div>
				</div>
				<div className="columns">
					<div className="column is-6">
						<div className="card flex-column is-full-height">
							<header className="card-header pr-5">
								<h2 className="card-header-title is-uppercase is-size-7">Current &amp; Voltage</h2>
								<div className="m-auto pr-3">
									<PeriodSelect
										classNames="is-smaller"
										onSelect={(startTime, endTime) => {
											setCurrentAndVoltageChartTimes({
												endTime,
												startTime,
											});
										}}
										value="Last 24 hours"
									/>
								</div>
								<span className="is-absolute is-top is-right">
									<ButtonExpand onClick={() => { history.push(`/solarStringSetCurrentAndVoltage?i=${uaNodeId}`); }} />
								</span>
							</header>
							<div className="card-content px-2">
								<div className="chart is-full is-large-mobile" style={{ height: "20rem", }}>
									<CurrentAndVoltageChart
										id={uaNodeId || "Unknown"}
										startTime={currentAndVoltageChartTimes.startTime}
										endTime={currentAndVoltageChartTimes.endTime}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="column is-6">
						<div className="card flex-column is-full-height">
							<header className="card-header pr-5">
								<h2 className="card-header-title is-uppercase is-size-7">Power</h2>
								<div className="m-auto pr-3">
									<PeriodSelect
										classNames="is-smaller"
										onSelect={(startTime, endTime) => {
											setPowerChartTimes({
												endTime,
												startTime,
											});
										}}
										value="Last 24 hours"
									/>
								</div>
								<span className="is-absolute is-top is-right">
									<ButtonExpand onClick={() => { history.push(`/solarStringSetPower?i=${uaNodeId}`); }} />
								</span>
							</header>
							<div className="card-content px-2">
								<div className="chart is-full is-large-mobile" style={{ height: "20rem", }}>
									<PowerChart
										id={uaNodeId || "Unknown"}
										startTime={powerChartTimes.startTime}
										endTime={powerChartTimes.endTime}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};
