/* eslint-disable no-tabs */
import React, { useContext, useState } from "react";
import {
	ComposableMap,
	Geographies,
	Geography,
	Marker,
	ZoomableGroup
} from "react-simple-maps";
import { SunShadow } from "./SunShadow";
import { MapScalingButtons } from "./MapScalingButtons";
import { RegionInfoCard } from "./RegionInfoCard";
import { PoweredBy } from "./PoweredBy";
import { WorldMapContext } from "../WorldMapScreen";
import { geoEquirectangular } from "d3-geo";
import { isNumber } from "../../../functions/UtilityFunctions";
import { RegionType, SiteType } from "../../../Types";

const geoUrl = "Map/world-110m.json";

type Props = {}

/**
 * Displays a world map.
 */
export const WorldMap = (props: Props) => {
	const {
		currentSite,
		countriesPerRegion,
		onSiteClick,
		regionData,
		siteData,
	} = useContext(WorldMapContext);
	const currentNodeName = currentSite?.siteName;
	const currentRegion = currentSite?.regionKey;

	const regionName = currentRegion || 0;
	const currentCountries: Record<string, object> = countriesPerRegion && countriesPerRegion[regionName] ? countriesPerRegion[regionName] : {};

	const [ zoomLevel, setZoomLevel ] = useState(1);

	const height = 400;
	const width = 800;
	const scaling = 130;

	const projection = geoEquirectangular()
		.scale(zoomLevel * scaling)
		.translate([ width / 2, height / 2 ])
		.precision(0.1);

	/**
	 * Displays a marker for each site.
	 */
	function eachSite (item: SiteType, index: number) {
		const { coordinateLatitude, coordinateLongitude, siteName, } = item;

		return (
			<Marker
				coordinates={[ coordinateLongitude || 0, coordinateLatitude || 0 ]}
				key={index}
				className={currentNodeName === siteName ? "worldmap-marker is-active" : "worldmap-marker"}
				onClick={() => { onSiteClick(index); }}
			>
				<circle r={2} />
			</Marker>
		);
	}

	/**
	 * Displays a box for each region.
	 */
	function eachRegion (item: RegionType, index: number) {
		const {
			regionKey,
			regionTitle,
			totalActiveEnergy,
			totalActivePower,
			totalACCapacity,
		} = item;
		const regionInfoProps = {
			regionTitle,
			totalACCapacity,
			totalActiveEnergy,
			totalActivePower,
		};

		if (regionKey !== currentRegion) {
			return null;
		}

		return (
			<Marker coordinates={[ -170, -10 ]} key={index}>
				<RegionInfoCard {...regionInfoProps} />
			</Marker>
		);
	}

	let strokeWidth = 0.75 / (zoomLevel * scaling);
	if (!isNumber(strokeWidth)) {
		strokeWidth = 0.75;
	}

	/**
	 * Displays each part of the map.
	 */
	function eachGeography (item: {
		projection: object,
		properties: {
			ISO_A3: string,
		},
		rsmKey: string,
	}, index: number) {
		const isoCode = item?.properties?.ISO_A3;
		let active = false;
		if (isoCode && currentCountries && currentCountries[isoCode]) {
			active = true;
		}

		return (
			<Geography
				className={active ? "is-active" : ""}
				key={item.rsmKey}
				geography={item}
				// projection={projection}
				style={{
					default: {},
					hover: {},
					pressed: {},
				}}
			/>
		);
	}

	return (
		<div className="worldmap-inner-wrapper is-relative is-full-height is-full-width">
			<MapScalingButtons setZoomLevel={setZoomLevel} zoomLevel={zoomLevel} />
			<ComposableMap
				// projection={projection}
				projectionConfig={{
					scale: zoomLevel * scaling,
				}}
				className="worldmap is-block m-auto"
				width={width}
				height={height}
				style={{ height: "100%", width: "auto", }}
			>
				<ZoomableGroup
					// filterZoomEvent={(evt: any) => {
					// 	return evt.type !== "wheel";
					// }}
					zoom={zoomLevel}
				>
					<Geographies
						geography={geoUrl}
					>
						{({ geographies, }) => geographies.map(eachGeography)}
					</Geographies>
					{siteData && siteData.length > 0 && siteData.map(eachSite)}
					<SunShadow projection={projection} />
					{regionData && regionData.length > 0 && regionData.map(eachRegion)}
				</ZoomableGroup>
			</ComposableMap>
			<PoweredBy />
		</div>
	);
};
