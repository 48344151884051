import React from "react";
import { formatDisplayNumber } from "../../../../functions/formatDisplayNumber";

type Props = {
	ambientTemperature?: number,
	weatherSymbolNumber?: number,
}

/**
 * Displays a card with weather information.
 */
export const WeatherCard = (props: Props) => {
	const symbol = getWeatherSymbol(props?.weatherSymbolNumber || 0);
	const temperature = props.ambientTemperature;

	let symbolUrl = "";

	try {
		symbolUrl = symbol ? require(`./../../../../assets/images/yr/svg/${symbol}.svg`).default : "";
	} catch (err) { }

	/**
	 * Gets the symbol for the weather
	 */
	function getWeatherSymbol (weatherSymbolNumber: number) {
		let symbol = "";

		if (weatherSymbolNumber < 10 && weatherSymbolNumber > 0) {
			if (weatherSymbolNumber === 4 || weatherSymbolNumber === 9) {
				symbol = "0" + weatherSymbolNumber;
			} else {
				symbol = "0" + weatherSymbolNumber + "d";
			}
		}

		if (weatherSymbolNumber >= 10 && weatherSymbolNumber <= 50) {
			if (
				(weatherSymbolNumber >= 10 && weatherSymbolNumber <= 15) ||
				weatherSymbolNumber === 22 || weatherSymbolNumber === 23 ||
				(weatherSymbolNumber >= 30 && weatherSymbolNumber <= 34) ||
				(weatherSymbolNumber >= 46 && weatherSymbolNumber <= 50)
			) {
				symbol = weatherSymbolNumber + "";
			} else {
				symbol = weatherSymbolNumber + "d";
			}
		}

		return symbol;
	}

	return (
		<div className="is-flex is-align-items-center">
			<div className="has-width-50 mr-1">
				<figure className="image is-square is-full-width is-flex is-justify-content-center is-align-items-center">
					{symbolUrl !== "" && <img alt="Site Weather" src={symbolUrl} />}
				</figure>
			</div>
			<h3>
				<span className="has-text-weight-semibold is-size-3 is-size-4-touch">
					<small>
						{formatDisplayNumber(temperature || 0)}
					</small>
				</span>
				{temperature && (
					<span className="has-text-weight-medium is-size-7-mobile">&nbsp;°C</span>
				)}
			</h3>
		</div>
	);
};
