import React from "react";
import { roundNumber } from "../../../functions/UtilityFunctions";

type Props = {
	weatherValue?: number,
	weatherValueUnit?: string,
	weatherValueSup?: string,
	weatherValueProgressValue?: number,
}

/** Displays */
export const SiteWeather = (props: Props) => {
	const { weatherValue, weatherValueUnit, weatherValueSup, weatherValueProgressValue, } = props;
	const value = roundNumber(weatherValue || 0, 1);

	return (
		<>
			<small>{(value || 0) + " " + weatherValueUnit}{weatherValueSup && <sup className="is-size-8">{weatherValueSup}</sup>}</small>
			<progress className="progress is-smallest mt-1" value={weatherValueProgressValue} max={1200}>{weatherValueProgressValue || 0}%</progress>
		</>
	);
};
