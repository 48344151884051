/* eslint-disable require-jsdoc */

import React from "react";

type Props = {
	onClick: Function,
};

/**
 * Displays a simple gauge.
 */
export const ButtonExpand = (props: Props) => {
	// Button that expands a card
	const { onClick, } = props;

	return (
		<button
			type="button"
			className="button icon is-smaller is-text is-light is-transparent has-tooltip-arrow"
			data-tooltip="Expand"
			aria-label="expand"
			onClick={() => { onClick(); }}
		>
			<i className="icon-expand" aria-hidden="true" />
		</button>
	);
};
