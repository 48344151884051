import { isNull } from "./UtilityFunctions";

/**
 * Formats a number for display.
 */
export function formatDisplayNumber (value: number, numberOfDecimals?: number): string {
	if (isNull(value) || isNaN(value)) {
		return "N/A";
	}

	if (isNaN(value)) {
		return value.toString();
	}

	if (!isFinite(value)) {
		return "N/A";
	}

	const stringValue = value.toFixed(numberOfDecimals || 0);

	return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
