import React from "react";
import { formatDisplayNumber } from "../../../functions/formatDisplayNumber";
import { getDayHelper, getMonthName, isNull } from "../../../functions/UtilityFunctions";

type Props = {
	treeSavingsToday: Number,
	co2SavingsToday: Number,
	treeSavingsThisMonth: Number,
	co2SavingsThisMonth: Number,
	treeSavingsThisYear: Number,
	co2SavingsThisYear: Number,
	treeSavingsLifetime: Number,
	co2SavingsLifetime: Number,
	installationDate: Date,
};

/**
 * Displays the savings information in the lobby.
 */
export const SavingsLobby = (props: Props) => {
	const {
		treeSavingsToday,
		co2SavingsToday,
		treeSavingsThisMonth,
		co2SavingsThisMonth,
		treeSavingsThisYear,
		co2SavingsThisYear,
		treeSavingsLifetime,
		co2SavingsLifetime,
		installationDate,
	} = props;
	const today = new Date();

	return (
		<div className="card flex-column is-full-height">
			<header className="card-header is-shadowless">
				<h2 className="card-header-title is-uppercase is-size-7">Savings</h2>
			</header>
			<div className="card-content px-2 pt-0 mt-auto">
				<table className="table is-fullwidth is-hoverable is-size-7">
					<thead>
						<tr>
							<th className="has-border-bottom-width-2 has-border-bottom--grey-light--black" />
							<th className="has-text-centered has-text--black--grey-light has-background-opacity--5 has-border-bottom-width-2 has-border-bottom--grey-light--black">
								<i className="icon-tree is-size-4 has-text--grey--grey-dark" aria-hidden="true" />
								<small className="has-text-weight-bold has-line-height-1 is-size-7 is-block is-uppercase">trees</small>
							</th>
							<th className="has-text-centered has-text--black--grey-light has-background-opacity--10 has-border-bottom-width-2 has-border-bottom--grey-light--black">
								<i className="icon-co2 is-size-4 has-text--grey--grey-dark" aria-hidden="true" />
								<small className="has-text-weight-bold has-line-height-1 is-size-7 is-block is-uppercase">kg</small>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{today.getDate() + getDayHelper(today.getDate()) + " " + getMonthName(today.getMonth())}</td>
							<td className="has-text-centered has-text-weight-semibold has-background-opacity--5">{formatDisplayNumber(treeSavingsToday)}</td>
							<td className="has-text-centered has-text-weight-semibold has-background-opacity--10">{formatDisplayNumber(co2SavingsToday)}</td>
						</tr>
						<tr>
							<td>{getMonthName(today.getMonth())}</td>
							<td className="has-text-centered has-text-weight-semibold has-background-opacity--5">{formatDisplayNumber(treeSavingsThisMonth)}</td>
							<td className="has-text-centered has-text-weight-semibold has-background-opacity--10">{formatDisplayNumber(co2SavingsThisMonth)}</td>
						</tr>
						<tr>
							<td>{today.getFullYear()}</td>
							<td className="has-text-centered has-text-weight-semibold has-background-opacity--5">{formatDisplayNumber(treeSavingsThisYear)}</td>
							<td className="has-text-centered has-text-weight-semibold has-background-opacity--10">{formatDisplayNumber(co2SavingsThisYear)}</td>
						</tr>
						<tr>
							<td>Lifetime</td>
							<td className="has-text-centered has-text-weight-semibold has-background-opacity--5">{formatDisplayNumber(treeSavingsLifetime)}</td>
							<td className="has-text-centered has-text-weight-semibold has-background-opacity--10">{formatDisplayNumber(co2SavingsLifetime)}</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td className="has-text--grey-dark--grey">Installation Date</td>
							<td className="has-text-right has-text--grey-dark--grey" colSpan={2}>{!isNull(installationDate) ? (new Date(installationDate)).toDateString() : "N/A"}</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	);
};
