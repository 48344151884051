import React from "react";
import { SiteRow } from "./SiteRow";
import { PleaseWait } from "../../../components/PleaseWait";
import { SiteType } from "../../../Types";

type Props = {
	sites: Array<SiteType>,
}

/** */
export const PlantTable = (props: Props) => {
	const { sites, } = props;

	if (!sites) {
		return (
			<PleaseWait />
		);
	}

	/**
	 * Displays a coloured block per inverter.
	 */
	function eachSite (siteProps: SiteType, index: number) {
		return <SiteRow key={index} {...siteProps} />;
	}

	return (
		<div className="table-container column has-background--white--black-bis has-shadow--smaller has-radius py-1">
			<table className="table is-fullwidth is-hoverable is-striped is-bordered has-text-vcentered">
				<thead className="is-borderless is-size-7 is-uppercase has-border-bottom-width-2">
					<tr>
						<th className="sorting" colSpan={2} aria-sort="ascending">Plant
						</th>
						<th className="sorting has-width-200">Weather</th>
						<th className="sorting has-width-150">Power</th>
						<th className="sorting has-text-right is-narrow">Nominal</th>
						<th className="sorting has-text-right has-width-120 has-min-width-100">PR</th>
						<th className="sorting">Communication</th>
						<th className="sorting">Production</th>
						<th className="sorting" />
						<th className="sorting" />
					</tr>
				</thead>
				<tbody>
					{sites && sites.length > 0 && sites.map(eachSite)}
				</tbody>
			</table>
		</div>
	);
};
