export default {
	assetDescription: {
		signalName: "asset.description",
	},
	assetGpsLatitude: {
		signalName: "asset.gps_latitude",
	},
	assetGpsLongitude: {
		signalName: "asset.gps_longitude",
	},
	assetID: {
		signalName: "asset.id",
	},
	assetName: {
		signalName: "asset.name",
	},
	azimuth: {
		defaultValue: "N/A",
		signalName: "params.orientation.azimuth",
		skipUnitConversion: true,
		unit: "°",
	},
	dcActivePower: {
		max: 100,
		min: 0,
		signalName: "signals.dc.power",
		unit: "W",
	},
	dcCurrent: {
		max: 100,
		min: 0,
		signalName: "signals.dc.current",
		unit: "A",
	},
	dcNominalPower: {
		defaultValue: "N/A",
		max: 100,
		min: 0,
		signalName: "params.orientation.dc_power",
		unit: "W",
	},
	dcRelativePower: {
		defaultValue: "N/A",
		max: 100,
		min: 0,
		signalName: "signals.dc.relative",
		skipUnitConversion: true,
		unit: "%",
	},
	dcVoltage: {
		max: 1000,
		min: 0,
		signalName: "signals.dc.voltage",
		unit: "V",
	},
	deviceModel: {
		signalName: "device.model",
	},
	deviceSerialNb: {
		signalName: "device.serial_nb",
	},
	deviceSupplier: {
		signalName: "device.supplier",
	},
	deviceVersion: {
		signalName: "device.version",
	},
	id: {
		signalName: "id",
	},
	kind: {
		signalName: "kind",
	},
	name: {
		signalName: "asset.name",
	},
	paramAzimuth: {
		signalName: "params.orientation.azimuth",
	},
	paramNominalDCPower: {
		signalName: "params.orientation.dc_power",
	},
	paramTilt: {
		signalName: "params.orientation.tilt",
	},
	paramTracked: {
		signalName: "params.orientation.tracked",
	},
	parent: {
		signalName: "relations.parent",
	},
	shortName: {
		signalName: "asset.short_name",
	},
	status: {
		signalName: "signals.status",
	},
	tilt: {
		defaultValue: "N/A",
		signalName: "params.orientation.tilt",
		skipUnitConversion: true,
		unit: "°",
	},
	weatherAirTemperature: {
		definitionAddress: "Weather.properties.air_temperature",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.air_temperature",
		skipUnitConversion: true,
		unit: "°C",
	},
	weatherAzimuth: {
		definitionAddress: "Weather.properties.azimuth",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.azimuth",
		skipUnitConversion: true,
		unit: "°",
	},
	weatherCloudOpacity: {
		definitionAddress: "Weather.properties.cloud_opacity",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.cloud_opacity",
		skipUnitConversion: true,
		unit: "",
	},
	weatherDHI: {
		definitionAddress: "Weather.properties.dhi",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.dhi",
		skipUnitConversion: true,
		unit: "W/m²",
	},
	weatherDNI: {
		definitionAddress: "Weather.properties.dni",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.dni",
		skipUnitConversion: true,
		unit: "W/m²",
	},
	weatherEBH: {
		definitionAddress: "Weather.properties.ebh",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.ebh",
		skipUnitConversion: true,
		unit: "W/m²",
	},
	weatherElevation: {
		definitionAddress: "Weather.properties.elevation",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.elevation",
		skipUnitConversion: true,
		unit: "°",
	},
	weatherEquationOfTime: {
		definitionAddress: "Weather.properties.equation_of_time",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.equation_of_time",
		skipUnitConversion: true,
		unit: "s",
	},
	weatherGHI: {
		definitionAddress: "Weather.properties.ghi",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.ghi",
		skipUnitConversion: true,
		unit: "W/m²",
	},
	weatherPOA: {
		definitionAddress: "Weather.properties.poa",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.poa",
		skipUnitConversion: true,
		unit: "W/m²",
	},
	weatherRelativeHumidity: {
		definitionAddress: "Weather.properties.relative_humidity",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.relative_humidity",
		skipUnitConversion: true,
		unit: "%",
	},
	weatherSymbolDescription: {
		definitionAddress: "Weather.properties.weather_symbol_description",
		signalName: "signals.weather_observed.weather_symbol_description",
		skipUnitConversion: true,
		unit: "",
	},
	weatherTrackerTheta: {
		definitionAddress: "Weather.properties.tracker_theta",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.tracker_theta",
		skipUnitConversion: true,
		unit: "°",
	},
	weatherWindFromDirection: {
		definitionAddress: "Weather.properties.wind_from_direction",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.wind_from_direction",
		skipUnitConversion: true,
		unit: "°",
	},
	weatherWindSpeed: {
		definitionAddress: "Weather.properties.wind_speed",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.wind_speed",
		skipUnitConversion: true,
		unit: "m/s",
	},
	weatherWindSpeedOfGust: {
		definitionAddress: "Weather.properties.wind_speed_of_gust",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.wind_speed_of_gust",
		skipUnitConversion: true,
		unit: "m/s",
	},
	weatherZenith: {
		definitionAddress: "Weather.properties.zenith",
		numberOfDecimals: 1,
		signalName: "signals.weather_observed.zenith",
		skipUnitConversion: true,
		unit: "°",
	},
};
