import React from "react";
import { AlarmList } from "../../../components/AlarmList";

/**
 * This dashboard displays details about a site/plant.
 */
export const SolarSiteAlarmsScreen = () => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const uaNodeId = urlParams.get("uaNodeId");

	return (
		<section>
			<div className="columns">
				<AlarmList uaNodeIds={uaNodeId ? [ uaNodeId ] : []} />
			</div>
		</section>
	);
};
