import React from "react";
import { TwinClasses } from "./components/TwinClasses";
import { TwinParameters } from "./components/TwinParameters";
import { Twins } from "./components/Twins";
import { TwinSignals } from "./components/TwinSignals";
import { TwinStoredProcedureParameters } from "./components/TwinStoredProcedureParameters";
import { TwinStoredProcedures } from "./components/TwinStoredProcedures";

/**
 * Handles exporting and importing twin information from Excel.
 */
export const TwinExcelScreen = () => {
	return (
		<div className="container is-fluid" style={{ height: "100vh", overflowY: "auto", }}>
			<section className="section px-6">
				<h1 className="title is-size-4 has-text-weight-medium">Excel Export/Import</h1>
			</section>
			<section className="section px-6">
				<h1 className="title is-size-5 has-text-weight-medium">Twin Classes</h1>
				<TwinClasses />
			</section>
			<section className="section px-6">
				<h1 className="title is-size-5 has-text-weight-medium">Twins</h1>
				<Twins />
			</section>
			<section className="section px-6">
				<h1 className="title is-size-5 has-text-weight-medium">Parameters</h1>
				<TwinParameters />
			</section>
			<section className="section px-6">
				<h1 className="title is-size-5 has-text-weight-medium">Signals</h1>
				<TwinSignals />
			</section>
			<section className="section px-6">
				<h1 className="title is-size-5 has-text-weight-medium">Stored Procedures</h1>
				<TwinStoredProcedures />
			</section>
			<section className="section px-6">
				<h1 className="title is-size-5 has-text-weight-medium">Stored Procedure Parameters</h1>
				<TwinStoredProcedureParameters />
			</section>
		</div>
	);
};
