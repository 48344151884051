import React from "react";

type Props = {
	value: number,
	valueUnit: string,
}

/** Displays the nominal value and unit */
export const SiteNominal = (props: Props) => {
	const { value, valueUnit, } = props;
	return (
		<small>{(value || 0) + " " + valueUnit}</small>
	);
};
