import React from "react";
import { isNull } from "../functions/UtilityFunctions";

type Props = {
    wind: number,
    wUnit: string,
    rain: number,
    rUnit: string,
    humidity: number,
    hUnit: string,
};

/** Visual weather stats as list (atm wind, rain, humidity) */
export const WeatherListStats = (props: Props) => {
	const { wind, wUnit, rain, rUnit, humidity, hUnit, } = props;

	return (
		<ul className="is-size-7 has-text-weight-semibold">
			{!isNull(wind) && (
				<li>
					<span
						className="icon has-text--grey--grey-dark has-tooltip-arrow"
						data-tooltip="Wind"
					>
						<i className="fas fa-wind" aria-hidden="true" />
					</span>
					{wind}&nbsp;{wUnit}
				</li>
			)}
			{!isNull(rain) && (
				<li>
					<span
						className="icon has-text--grey--grey-dark has-tooltip-arrow"
						data-tooltip="Rain"
					>
						<i className="fas fa-cloud-rain" aria-hidden="true" />
					</span>
					{rain}&nbsp;{rUnit}
				</li>
			)}
			{!isNull(humidity) && (
				<li>
					<span
						className="icon has-text--grey--grey-dark has-tooltip-arrow"
						data-tooltip="Humidity"
					>
						<i className="fas fa-tint" aria-hidden="true" />
					</span>
					{humidity}&nbsp;{hUnit}
				</li>
			)}
		</ul>
	);
};
