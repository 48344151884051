import React from "react";
import { PortfolioSiteCard } from "./PortfolioSiteCard";
import { PleaseWait } from "../../../components/PleaseWait";
import { SiteType } from "../../../Types";

type Props = {
	sites: Array<SiteType>,
}

/** */
export const SiteCards = (props: Props) => {
	const { sites, } = props;

	if (!sites) {
		return (
			<PleaseWait />
		);
	}

	/**
	 * Displays card for each site.
	 */
	function eachSite (
		siteProps: SiteType,
		index: number
	) {
		const {
			country,
			id,
			location,
			nominalPlantAvailability,
			pRMeasure,
			pRTarget,
			weatherSymbolNumber,
		} = siteProps;

		return (
			<PortfolioSiteCard
				key={index}
				country={country}
				id={id}
				location={location}
				nominalPlantAvailability={nominalPlantAvailability}
				pRMeasure={pRMeasure}
				pRTarget={pRTarget}
				weatherSymbolNumber={weatherSymbolNumber}
			/>
		);
	}

	return (
		<div className="grid is-6-fullhd is-5-widescreen is-4-desktop is-3-tablet is-1-mobile has-gap-small">
			{sites && sites.length > 0 && sites.map(eachSite)}
		</div>
	);
};
