import React from "react";
import { formatDisplayNumber } from "../../../../functions/formatDisplayNumber";
import { isNull } from "../../../../functions/UtilityFunctions";

type Props = {
	totalActiveEnergy?: number,
}

/**
 * The total production
 */
export const TotalProduction = (props: Props) => {
	const totalProduction = props?.totalActiveEnergy;
	const totalProductionUnit = "MWh";

	return (
		<div className="is-flex is-align-items-center px-4 py-2">
			<span className="icon is-large is-rounded has-background-theme--1 mr-2">
				<i className="fas fa-bolt" aria-hidden="true" />
			</span>
			<h3 className="has-line-height-1">
				<span className="has-text-weight-semibold is-size-3 is-size-4-mobile">
					<small>
						{isNull(totalProduction) ? "N/A" : formatDisplayNumber(totalProduction)}
					</small>
				</span>
				<span className="has-text-weight-medium is-size-7-mobile">&nbsp;{totalProductionUnit}</span>
				<span className="is-block pt-1">Lifetime Production</span>
			</h3>
		</div>
	);
};
