import React from "react";
import { geoPath, geoCircle } from "d3-geo";
import * as solar from "solar-calculator";
import worldData from "./Map/world-110m.json";
import { feature } from "topojson-client";

type Props = {
	projection: Object,
}

/**
 * Displays a shadow to indicate the position of the sun.
 */
export const SunShadow = (props: Props) => {
	const { projection, } = props;
	const circle = geoCircle().radius(90);

	/**
	 * Functions for drawing the solar termination / curve. Calculates the sun position.
	 * Functions under here is rewritten from:
	 * http://bl.ocks.org/mbostock/4597134
	 * Equations based on NOAA’s Solar Calculator; all angles in radians.
	 * http://www.esrl.noaa.gov/gmd/grad/solcalc/
	 */
	function antiPode ([ longitude, latitude ]: Array<number>) {
		return [ longitude, latitude * -1 ];
	};

	/**
	 * Calculates the position of the sun.
	 */
	function solarPosition (): Array<number> {
		const now = new Date();
		const day = new Date(+now).setUTCHours(0, 0, 0, 0);
		const t = solar.century(now);
		const longitude = (day - now.getTime()) / 864e5 * 360;
		return [ longitude - solar.equationOfTime(t) / 4, solar.declination(t) ];
	};

	const { objects, } = worldData;
	const { ne_110m_admin_0_countries: land, } = objects;
	const landData = feature(worldData, land).features;

	const solarData = landData.map((d: any) => {
		return circle.center(antiPode(solarPosition()))(d);
	});

	if (!solarData || solarData <= 0) {
		return null;
	}

	const geoData = geoPath().projection(projection)(solarData[0]);

	return (
		<g>
			<path
				className="worldmap-night"
				d={geoData || undefined}
			/>
		</g>
	);
};
