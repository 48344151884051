import React from "react";
import { CurrentProduction } from "./CurrentProduction";
import { TotalCapacity } from "./TotalCapacity";
import { TotalProduction } from "./TotalProduction";
import { WeatherCard } from "./WeatherCard";
import { WindRain } from "./WindRain";
import { Irradiance } from "./Irradiance";
import { Inverters } from "./Inverters";
import { SiteType } from "../../../../Types";

/**
 * Combines all site data components.
 */
export const SiteData = (props: SiteType) => {
	return (
		<div className="is-inline-flex-desktop is-scrollable-x has-overflow-y-hidden has-background-theme--3 has-radius mb-2-touch mt-auto">
			<div className="columns is-multiline-tablet-only is-vcentered is-gapless has-line-height-1 my-0 py-2 px-4">
				<div className="column is-narrow-desktop is-4-tablet mr-5-desktop mb-4-mobile">
					<WeatherCard
						ambientTemperature={props?.ambientTemperature}
						weatherSymbolNumber={props?.weatherSymbolNumber}
					/>
				</div>
				<div className="column is-narrow-desktop is-4-tablet mr-5-desktop mb-4-touch">
					<WindRain
						rainAmount={props?.rainAmount}
						windSpeed={props?.windSpeed}
					/>
				</div>
				<div className="column is-narrow-desktop is-4-tablet mr-5-desktop mb-4-touch">
					<Irradiance
						horizontalIrradiation={props?.horizontalIrradiation}
					/>
				</div>
				<div className="column is-narrow-desktop is-4-tablet mr-5-desktop mb-4-touch">
					<CurrentProduction
						totalACCapacity={props?.totalACCapacity}
						totalActivePowerExport={props?.totalActivePowerExport}
					/>
				</div>
				<div className="column is-narrow-desktop is-4-tablet mr-5-desktop mb-4-touch">
					<TotalProduction
						totalActiveEnergy={props?.totalActiveEnergy}
					/>
				</div>
				<div className="column is-narrow-desktop is-4-tablet mr-5-desktop mb-4-touch">
					<TotalCapacity
						totalACCapacity={props?.totalACCapacity}
					/>
				</div>
				<div className="column is-narrow-desktop is-4-tablet mr-5-desktop mb-4-touch">
					<Inverters noOfInverters={props?.noOfInverters} />
				</div>
			</div>
		</div>
	);
};
