import { createSlice } from "@reduxjs/toolkit";
import { callApi } from "./Network";
import { getGUID } from "../../functions/UtilityFunctions";

/**
 * Provider Class redux slice which fetches data from jsonplaceholder (defined in services)
 */
const backendSlice = createSlice({
	initialState: {},
	name: "backend",
	reducers: {
		addToBackend (state, action) {
			const { dataBindingId, item, } = action.payload;
			if (dataBindingId && !state[dataBindingId]) {
				state[dataBindingId] = [];
			}
			state[dataBindingId] = [];

			state[dataBindingId] = [ item, ...state[dataBindingId] ];
		},
		backendLoading (state, action) {
			// Use a "state machine" approach for loading state instead of booleans
			const loadingId = action.payload + "_loading";
			if (state[loadingId] === "idle") {
				state[loadingId] = "pending";
			}
		},
		backendReceived (state, action) {
			const { dataBindingId, items, } = action.payload;
			const loadingId = dataBindingId + "_loading";

			if (state[loadingId] === "pending") {
				state[loadingId] = "idle";
			}

			if (dataBindingId) {
				state[dataBindingId] = items;
			} else {
				state.default = items;
			}
		},
		deleteFromBackend (state, action) {
			const { dataBindingId, itemId, itemIdName, } = action.payload;

			const items = state[dataBindingId];
			let index = null;
			for (let i = 0; i < items.length; i++) {
				const item = items[i];
				if (item[itemIdName] === itemId) {
					index = i;
				}
			}

			if (index !== null) {
				items.splice(index, 1);
			}
		},
		updateOnBackend (state, action) {
			const { dataBindingId, item, itemId, itemIdName, } = action.payload;

			if (!state[dataBindingId]) {
				state[dataBindingId] = {};
			}

			const items = state[dataBindingId];
			let index = null;
			for (let i = 0; i < items.length; i++) {
				const item = items[i];
				if (item[itemIdName] === itemId) {
					index = i;
				}
			}

			if (index !== null) {
				items[index] = item;
			}
		},
	},
});

export const {
	addToBackend,
	backendLoading,
	backendReceived,
	deleteFromBackend,
	updateOnBackend,
} = backendSlice.actions;

/**
 * Export function for running a backend api call.
 * @param {*} id The id of the data binding.
 * @param {*} method The function type to use GET, POST, PUT, DELETE.
 * @param {*} parameters The parameters to send to the backend.
 * @param {*} url The url on the backend to call.
 */
export const RunBackendFunction = ({
	id,
	itemIdName,
	method,
	onSuccess,
	overrideBaseUrl,
	parameters,
	skipOrganisationId,
	url,
	token,
}) => async (dispatch) => {
	// set loading
	dispatch(backendLoading(id));

	// Post new data to jsonplaceholder, and dispatch response to state
	const headers = {
		Accept: "application/json",
		"Content-Type": "application/json",
	};

	if (token) {
		headers.Authorization = `Bearer ${token}`;
	}

	if (parameters) {
		parameters.correlationId = getGUID();
		parameters.correlationTimestamp = (new Date()).toISOString();

		if (!skipOrganisationId) {
			parameters.organisationId = window.REACT_APP_ORGANISATION_ID;
		}
	}

	await callApi({
		body:
			method === "GET" || !parameters
				? undefined
				: JSON.stringify(parameters),
		headers,
		method,
		overrideBaseUrl,
		url,
	})
		.then((response) => {
			if (onSuccess) {
				onSuccess(response);
			}

			switch (method) {
				case "GET":
					dispatch(
						backendReceived({
							dataBindingId: id,
							items: response,
						})
					);
					break;
				case "DELETE":
					dispatch(
						deleteFromBackend({
							dataBindingId: id,
							itemId: response[itemIdName],
							itemIdName,
						})
					);
					break;
				case "POST":
					dispatch(
						addToBackend({
							dataBindingId: id,
							item: response,
						})
					);
					break;
				case "PUT":
					dispatch(
						updateOnBackend({
							dataBindingId: id,
							item: response,
							itemId: response[itemIdName],
							itemIdName,
						})
					);
					break;
				default:
					dispatch(
						backendReceived({
							dataBindingId: id,
							items: response,
						})
					);
					break;
			}
		})
		.catch((err) => {
			console.error(err);
		});
};

/**
 * Adds a new item to a backend collection.
 */
export const AddBackendItem = ({ dataBindingId, item, itemId, }) => async (
	dispatch
) => {
	dispatch(
		addToBackend({
			dataBindingId,
			item,
			itemId,
		})
	);
};

/**
 * Updates an item in the backend collection.
 */
export const UpdateBackendItem = ({ dataBindingId, item, itemId, }) => async (
	dispatch
) => {
	dispatch(
		updateOnBackend({
			dataBindingId,
			item,
			itemId,
		})
	);
};

/**
 * Removes an item from the backend collection.
 */
export const DeleteBackendItem = ({ dataBindingId, itemId, }) => async (
	dispatch
) => {
	dispatch(
		deleteFromBackend({
			dataBindingId,
			itemId,
		})
	);
};

/**
 * Fetches a historian data.
 */
export const FetchHistorianData = ({
	aggregates,
	endTime,
	entityIds,
	fill,
	granularity,
	limit,
	onFailure,
	onSuccess,
	order,
	startTime,
	token,
	variables,
}: {
	aggregates?: Array<string>,
	endTime: string,
	entityIds: Array<string>,
	fill?: boolean,
	granularity: string,
	limit?: number,
	onFailure?: Function,
	onSuccess?: Function,
	order?: string,
	startTime: string,
	token?: string,
	variables: Array<string>,
}) => async (
	dispatch
) => {
	return null;
};

/**
 * Fetches all of the details for an twin from the backend.
 */
export const FetchTwinDetails = ({
	id,
	json,
	onSuccess,
	token,
}: {
	id: string,
	json: string,
	onSuccess?: Function,
	token?: string,
}) => async (
	dispatch
) => {
	return null;
};

/**
 * Fetches all of the details for a twin's children from the backend.
 */
export const FetchTwinChildDetails = ({
	id,
	json,
	onSuccess,
	token,
}: {
	id: string,
	json: string,
	onSuccess?: Function,
	token?: string,
}) => async (
	dispatch
) => {
	return null;
};

// Export reducers for use in store.js etc
export default backendSlice.reducer;
