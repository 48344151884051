import React from "react";
import { isNull, makeTwoDigit } from "../../../functions/UtilityFunctions";
import { formatDisplayNumber } from "../../../functions/formatDisplayNumber";
import { MixedLineAndBarChart } from "../../../components/MixedLineAndBarChart/MixedLineAndBarChart";

type Props = {
	dailyProductionSolarIrradiation: Array<Number>,
	dailyProductionTotalPower: Array<Number>,
	dailyProductionActivePower: Number,
	maxACPower: Number,
};

/**
 * Displays the daily information in the lobby.
 */
export const ProductionDailyLobby = (props: Props) => {
	const {
		dailyProductionSolarIrradiation,
		dailyProductionTotalPower,
		dailyProductionActivePower,
		maxACPower,
	} = props;

	const fixedDailyProductionActivePower = formatDisplayNumber(dailyProductionActivePower);

	const labels = [];
	const powerData = [];
	const solarData = [];
	if (dailyProductionTotalPower && dailyProductionSolarIrradiation) {
		if (dailyProductionTotalPower.length === dailyProductionSolarIrradiation.length) {
			for (let i = 0; i < dailyProductionTotalPower.length; i++) {
				const time = new Date(dailyProductionTotalPower[i].time);

				const powerValue = !isNull(dailyProductionTotalPower[i].value) ? dailyProductionTotalPower[i].value / 1000 : 0;
				const solarValue = !isNull(dailyProductionSolarIrradiation[i].value) ? dailyProductionSolarIrradiation[i].value : 0;

				labels.push(time.getHours() + ":" + makeTwoDigit(time.getMinutes()));
				powerData.push(formatDisplayNumber(powerValue));
				solarData.push(formatDisplayNumber(solarValue));
			}
		}
	}

	return (
		<div className="card flex-column is-full-height">
			<header className="card-header">
				<h2 className="card-header-title is-uppercase is-size-7">Daily Production</h2>
			</header>
			<div className="card-content py-1">
				<div className="chart is-large">
					<MixedLineAndBarChart
						labels={labels}
						series={[
							{
								axisIndex: 0,
								axisPosition: "left",
								data: solarData,
								hasLegend: false,
								name: "Solar Irradiation",
								numberOfDecimals: 0,
								type: "line",
								unit: "W/m²",
							},
							{
								axisIndex: 1,
								axisPosition: "right",
								data: powerData,
								hasLegend: false,
								max: maxACPower || undefined,
								name: "Total Power",
								numberOfDecimals: 0,
								type: "area",
								unit: "kW",
							}
						]}
					/>
				</div>
			</div>
			<footer className="px-5 pb-4 has-text-right mt-auto">
				<h2>
					<span className="has-text-weight-semibold is-size-4 is-block has-line-height-1">
						{fixedDailyProductionActivePower}<small className="is-size-7 has-text-weight-light">&nbsp;kWh</small>
					</span>
				</h2>
			</footer>
		</div>
	);
};
