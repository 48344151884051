import React from "react";

type Props = {
	imageURL?: string,
}

/**
 * Displays site image
 */
export const SiteImage = (props: Props) => {
	const { imageURL, } = props;

	if (!imageURL) {
		return null;
	}

	return (
		<img
			alt=""
			className="has-radius"
			onError={(event: any) => {
				event.target.onerror = null;
				event.target.src = "Images/Blank.png";
			}}
			src={imageURL}
		/>
	);
};
