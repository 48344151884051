import React from "react";
import { ValueUnit } from "./ValueUnit";

type Props = {
	totalActiveEnergy?: number,
}

/**
 * The total production
 */
export const TotalProduction = (props: Props) => {
	const totalProduction = props?.totalActiveEnergy;
	const totalProductionUnit = "MWh";
	const name = "Lifetime Production";

	return (
		<div className="is-flex is-align-items-center">
			<span className="icon is-large is-rounded has-text-theme--1 has-background-theme--2 mr-3">
				<i className="fas fa-bolt" aria-hidden="true" />
			</span>
			<ValueUnit value={totalProduction || 0} uom={totalProductionUnit} name={name} />
		</div>
	);
};
