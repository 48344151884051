import React, { useContext } from "react";
import { Clock } from "./Clock";
import { CurrentProduction } from "./CurrentProduction";
import { Logo } from "./Logo";
import { TotalCapacity } from "./TotalCapacity";
import { TotalProduction } from "./TotalProduction";
import { WorldMapContext } from "../../WorldMapScreen";

type Props = {

}

/**
 * Displays the bar at the top of the page.
 */
export const WorldMapTopBar = (props: Props) => {
	const { enterpriseData, } = useContext(WorldMapContext);

	/**
 * Handles click for toggling nav menu.
 */
	function toggleMenu (event: any) {
		event.preventDefault();
		const hamburgerIcon = event.currentTarget;
		hamburgerIcon.classList.toggle("is-active");

		const navBarMenu = document.querySelector(".navbar-menu");
		if (navBarMenu) {
			navBarMenu.classList.toggle("is-active");
		}
	}
	return (
		<nav className="navbar in-dark-mode is-full-width-touch is-transparent" role="navigation" aria-label="main navigation">
			<div className="navbar-brand">
				<span className="navbar-item">
					<Logo />
				</span>
				<a
					role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarWorldmap" onClick={toggleMenu}
				>
					<span aria-hidden="true" />
					<span aria-hidden="true" />
					<span aria-hidden="true" />
				</a>
			</div>

			<div id="navbarWorldmap" className="navbar-menu is-transparent">
				<div className="navbar-start mx-auto">
					<div className="navbar-item">
						<TotalProduction {...enterpriseData} />
					</div>
					<div className="navbar-item">
						<CurrentProduction {...enterpriseData} />
					</div>
					<div className="navbar-item">
						<TotalCapacity {...enterpriseData} />
					</div>
				</div>
				<div className="navbar-end is-align-items-flex-start m-0">
					<div className="navbar-item has-text-right">
						<Clock locale="en" meridiem={false} timezone="GMT" />
					</div>
				</div>
			</div>
		</nav>
	);
};
