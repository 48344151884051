import React from "react";
import { formatDisplayNumber } from "../../../../functions/formatDisplayNumber";
import { isNull, roundNumber } from "../../../../functions/UtilityFunctions";

type Props = {
	name: string,
	numberOfDecimals?: number,
	uom?: string,
	value: number,
}

/**
 * Displays Value uom and name information.
 */
export const ValueUnit = (props: Props) => {
	const { value, uom, name, numberOfDecimals, } = props;

	let formattedValue = isNull(value) ? "N/A" : formatDisplayNumber(value);
	if (numberOfDecimals && value) {
		formattedValue = roundNumber(value, numberOfDecimals).toString();
	}

	return (
		<h3>
			<span className="has-text-weight-semibold is-size-3 is-size-4-touch has-line-height-1">
				<small>
					{formattedValue}
				</small>
			</span>
			<span className={uom ? "has-text-weight-medium is-size-7-touch" : "is-hidden"}>&nbsp;{uom}</span>
			<span className="is-block pt-1">{name}</span>
		</h3>
	);
};
